const INIT_STATE = {
  loading: false,
  error: null,
  data: null,
};
export const getClientSecret = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case "GET_PRESAVING_SECRER_LOADING":
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case "GET_PRESAVING_SECRER_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_PRESAVING_SECRER_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getPaymentList = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case "GET_PAYMENT_LIST_LOADING":
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case "GET_PAYMENT_LIST_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_PAYMENT_LIST_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getAuthFailed = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case "GET_AUTH_FAILED_LOADING":
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case "GET_AUTH_FAILED_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_AUTH_FAILED_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
