import { Field, ErrorMessage } from "formik";
import "./Input.scss";
const Input = (props) => {
  return (
    <>
      <label className="w-100 login-label" htmlFor={props.name}>
        {props.label}
      </label>
      <div className="field-wrap w-100">
        <Field
          as={props.as}
          className={props.className}
          placeholder={props.label ? props.label : props.placeholder}
          name={props.name}
          autoComplete="off"
          type={props.type}
        />
      </div>
      <ErrorMessage
        name={props.name}
        component="div"
        className="text-danger errorMsg"
      />
    </>
  );
};
export default Input;
// export const Input = (props) => {
//   return (
//     <>
//       <label className="w-100 login-label" htmlFor={props.name}>
//         {props.label}
//       </label>
//       <div className="field-wrap w-100">
//         <Field
//           className={props.className}
//           placeholder={props.label ? props.label : props.placeholder}
//           name={props.name}
//           autoComplete="off"
//           type={props.type}
//         />
//       </div>
//       <ErrorMessage
//         name={props.name}
//         component="div"
//         className="text-danger errorMsg"
//       />
//     </>
//   );
// };
