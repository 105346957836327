import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";
import { getPatientOneQuestions } from "../../../../../store/actions";
import { style } from "../../../../../global/style";
import { Button, Text, Box, useToast, useColorMode } from "@chakra-ui/react";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../../global/changeColor";
import moment from "moment";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const ViewQ = ({ oneQuestion, getPatientOneQuestions }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [question, setQuestion] = useState(null);
  let params = useParams();
  let query = useQuery().get("consultationId");
  let toast = useToast();
  let history = useHistory();
  const { colorMode } = useColorMode();
  useEffect(() => {
    if (params && query) {
      getPatientOneQuestions(query, params.id, toast, history);
      console.log(params.id);
    }
  }, [params, query, getPatientOneQuestions, toast, history]);

  useEffect(() => {
    const { loading, error, data } = oneQuestion;
    setLoading(loading);
    setError(error);
    if (data) {
      setQuestion(data);
    }
  }, [oneQuestion]);

  const renderView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      // handeleError(error, toast);
      return "error";
    }
    if (question) {
      return (
        <div>
          <div className="col-12 d-flex py-4 align-items-center ps-0 justify-content-between flex-wrap">
            <div className="d-flex col-md-6 col-12 ps-0">
              <Button
                onClick={() => history.goBack()}
                className="nav-link h5 me-4 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: "45px", height: "45px" }}
                color={colorMode === "dark" ? "secondary" : "primary"}
                _hover={{
                  color: colorMode === "dark" ? "secondary" : "primary",
                }}
              >
                <i className="fas fa-chevron-left"></i>
              </Button>

              <Text fontSize="3xl" className="fw-bold" color={style.secondary}>
                View question
              </Text>
            </div>
            {question.reply ? null : (
              <div className="col-md-6 col-12 ps-0 text-end">
                <Button
                  color={checkColorMode(colorMode)}
                  as={Link}
                  className="me-2"
                  to={`/consultations/over-view/questions/replay-question/${question.id}?consultationId=${question.consultation.id}`}
                  variant="ghost"
                  _hover={{
                    color: checkColorModeHover(colorMode),
                  }}
                >
                  <span className="me-2">
                    <i className="fas fa-reply"></i>
                  </span>{" "}
                  Answer
                </Button>
              </div>
            )}

            <Box
              bg={checkBgColorMode(colorMode)}
              className="col-12 p-4 rounded mt-4"
            >
              <div className="header row m-0 align-items-center">
                <Box className="col-md-6 ">
                  <Text className="h4 fw-bold mb-0" color={style.secondary}>
                    {question.title}
                  </Text>
                  <Text color={style.smallTextGray} fontSize="12px">
                    {moment
                      .unix(question.updatedAt)
                      .format("DD/MM/YYYY - hh:mm A")}
                  </Text>
                </Box>
              </div>

              <Box
                boxShadow="xs"
                className="rounded p-3 mt-3"
                bg={style.smallTextGrayLight}
                border={`1px solid ${style.smallTextGrayLightBorder}`}
              >
                <Text>
                  <span className="h6">Doctor:</span> {question.body}
                </Text>
                <Text className="mt-1">
                  <span className="h6">You:</span> {question.reply}
                </Text>
              </Box>
            </Box>
          </div>
        </div>
      );
    } else {
      return "nothing";
    }
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    oneQuestion: state.oneQuestion,
  };
};
export default connect(mapStateToProps, { getPatientOneQuestions })(ViewQ);
