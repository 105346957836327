import React, { useEffect, useState } from "react";
import { useLocation, useRouteMatch, Switch, Route } from "react-router-dom";
import { Box, useToast, Button, useColorMode } from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  getPatientOneConsultation,
  getPatientQuestions,
  getPatientAppointmnts,
} from "../../../store/actions";
import OverView from "./OverView";
import Child from "./Child";
import { AnimatePresence, motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../../global/Animations/Animations";
import SideNave from "../SideNave";
import { checkColorMode } from "../../../global/changeColor";

const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const Consultationsoverview = ({
  getPatientOneConsultation,
  consultation,
  getPatientQuestions,
  questions,
  history,
  patientProfile,
}) => {
  const { url, path } = useRouteMatch();
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [oneConsultation, setOneConsultation] = useState(null);

  const [scanRequests, setScanRequests] = useState([]);
  const [medicalReport, setMedicalReport] = useState(null);
  const [Questions, setQuestions] = useState(null);
  const [QuestionsNoReply, setQuestionsNoReply] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const [hospitalReservation, setHospitalReservation] = useState(null);
  const [smallSideNav, setSmallSideNav] = useState(false);
  const [overLay, setOverLay] = useState(true);

  let query = useQuery().get("consultationId");
  let queryQ = useQuery().get("page");
  const toast = useToast();
  const loaction = useLocation();
  const closeSideNave = (someData) => {
    setOverLay(!overLay);
    setSmallSideNav(!smallSideNav);
  };
  useEffect(() => {
    if (query) {
      getPatientOneConsultation(query, toast, history);
      getPatientQuestions(query, queryQ);
    }
  }, [
    queryQ,
    query,
    getPatientOneConsultation,
    getPatientQuestions,
    toast,
    history,
  ]);

  useEffect(() => {
    const { loading, error, data } = consultation;
    setLoading(loading);
    setError(error);
    if (data) {
      setOneConsultation(data);
      setMedicalReport(data.medicalReport);
      setAppointment(data.ConsultantAppointment);

      setHospitalReservation(data.hospitalReservation);
      if (data.scanRequests) {
        const renderRequests = data.scanRequests.filter(
          (request) => request.isUploaded === 0
        );
        setScanRequests(renderRequests);
      }
    }
  }, [consultation]);

  useEffect(() => {
    if (questions) {
      const { data } = questions;

      if (data && data.data) {
        const filterQ = data.data.filter((item) => item.reply == null);
        setQuestionsNoReply(filterQ);
      }
      setQuestions(questions);
    }
  }, [questions]);

  const renderView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      return "error";
    }
    if (oneConsultation) {
      return (
        <>
          <div className="d-none d-lg-block p-0 m-0 col-2">
            <SideNave
              url={url}
              scanRequests={scanRequests}
              medicalReport={medicalReport}
              QuestionsNoReply={QuestionsNoReply}
              appointment={appointment}
              hospitalReservation={hospitalReservation}
              consultation={consultation}
            />
          </div>
          <div className="d-xl-none d-lg-none d-xl-block mt-4">
            <Button
              color={checkColorMode(colorMode)}
              onClick={() => {
                setOverLay(!overLay);
                setSmallSideNav(!smallSideNav);
              }}
            >
              <i className="fas fa-bars"></i>
            </Button>
          </div>
          <Box className="col-lg-10 row m-0 align-items-start pb-5">
            <AnimatePresence exitBeforeEnter>
              <Switch location={loaction} key={loaction.pathname}>
                <Route
                  exact
                  path={path}
                  render={() => (
                    <OverView
                      Consultation={oneConsultation}
                      medicalReport={medicalReport}
                      history={history}
                      appointment={appointment}
                      hospitalReservation={hospitalReservation}
                    />
                  )}
                />
                <Route
                  path={`${path}/:overViewChild`}
                  render={() => (
                    <Child
                      requestes={scanRequests}
                      medicalReport={medicalReport}
                      questions={Questions}
                      history={history}
                      consultation={oneConsultation}
                      patientProfile={patientProfile}
                    />
                  )}
                />
              </Switch>
            </AnimatePresence>
          </Box>
        </>
      );
    } else {
      return "";
    }
  };

  return (
    <motion.div
      className="container-fluid  p-0 position-relative"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div
        className="overLay position-fixed "
        style={{
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "rgba(0,0,0,.2)",
          zIndex: "20",
          display: overLay ? "none" : "block",
        }}
        onClick={() => {
          closeSideNave();
        }}
      ></div>
      <div className="row position-relative">
        {renderView()}
        <div
          className="position-fixed  p-0 m-0 small-side-nave"
          style={{
            top: 0,
            left: smallSideNav ? "0" : "-340px",
            zIndex: "250",
            bottom: 0,
          }}
        >
          <SideNave
            url={url}
            scanRequests={scanRequests}
            medicalReport={medicalReport}
            QuestionsNoReply={QuestionsNoReply}
            appointment={appointment}
            hospitalReservation={hospitalReservation}
            consultation={consultation}
            closeSideNave={closeSideNave}
            withOpen={true}
          />
        </div>
      </div>
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    consultation: state.oneConsultation,
    questions: state.Questions,
    patientProfile: state.PatientProfile.data,
    AppointmentList: state.appointmntsList.data,
  };
};
export default connect(mapStateToProps, {
  getPatientOneConsultation,
  getPatientQuestions,
  getPatientAppointmnts,
})(Consultationsoverview);
