import axios from "axios";
import { handeleSuccess, handeleError } from "./toast";

const API_URL = process.env.REACT_APP_API_URL;

export const getPatientAppointmnts = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_APPOINTMENTS_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/appointments`,
      });
      dispatch({
        type: "GET_APPOINTMENTS_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_APPOINTMENTS_ERROR",
        payload: err,
      });
    }
  };
};

/// Add And Edit with payment
export const addPatientAppointmnts = (
  data,
  toast,
  history,
  time,
  setTime,
  onClose,
  paymentList,
  consultation,
  PrePaymentData,
  stripe,
  deletePatientAppointmnts,
  consultationId,
  scheduleId
) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_APPOINTMENTS_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/consultation-request/${consultationId}/consultant-schedule/${scheduleId}/book-appointment`,
        // data: data,
      });
      if (res) {
        localStorage.setItem("appointment", res.data);
        if (
          paymentList &&
          paymentList.length &&
          consultation &&
          consultation.status !== "has_appointment"
        ) {
          try {
            // const { error, ...res } = await stripe.handleCardPayment
            const { error } = await stripe.handleCardPayment(
              PrePaymentData.client_secret,
              {
                payment_method: PrePaymentData.paymentMethodId,
              }
            );
            if (!error) {
              handeleSuccess(
                "Your appointment has been set successfully",
                toast
              );
              history.push(
                `/consultations/thank-you?consultationId=${data.patient_consultation_request_id}`
              );
              setTime(!time);
              onClose();
            } else {
              deletePatientAppointmnts(data.patient_consultation_request_id);
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          handeleSuccess("Your appointment has been set successfully", toast);
          history.push(
            `/consultations/thank-you?consultationId=${data.patient_consultation_request_id}`
          );
          setTime(!time);
          onClose();
        }
        dispatch({
          type: "POST_APPOINTMENTS_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "POST_APPOINTMENTS_ERROR",
        payload: err,
      });
    }
  };
};

export const bookAppointmnt = (
  toast,
  history,
  time,
  setTime,
  onClose,
  consultationId,
  scheduleId
) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_APPOINTMENTS_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/consultation-request/${consultationId}/consultant-schedule/${scheduleId}/book-appointment`,
      });
      if (res) {
        localStorage.setItem("appointment", res.data);
        handeleSuccess("Your appointment has been set successfully", toast);
        history.push(
          `/consultations/thank-you?consultationId=${consultationId}`
        );
        setTime(!time);
        onClose();
        dispatch({
          type: "POST_APPOINTMENTS_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "POST_APPOINTMENTS_ERROR",
        payload: err,
      });
    }
  };
};

export const updateAppointmnt = (
  data,
  toast,
  history,
  time,
  setTime,
  onClose,
  consultationId,
  scheduleId
) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_APPOINTMENTS_LOADING",
    });
    try {
      const res = await axios({
        method: "PUT",
        url: `${API_URL}/patient/consultation-request/${consultationId}/consultant-schedule/${scheduleId}/book-appointment`,
        data: data,
      });
      if (res) {
        localStorage.setItem("appointment", res.data);
        handeleSuccess("Your appointment has been set successfully", toast);
        history.push(
          `/consultations/thank-you?consultationId=${consultationId}`
        );
        setTime(!time);
        onClose();
        dispatch({
          type: "UPDATE_APPOINTMENTS_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "UPDATE_APPOINTMENTS_ERROR",
        payload: err,
      });
    }
  };
};

export const requestAppointmntsFromConsultant = (
  consultationId,
  toast,
  getPatientOneConsultation
) => {
  return async (dispatch) => {
    dispatch({
      type: "REQUEST_APPOINTMENTS_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/consultation-request/${consultationId}/request-appointment`,
      });
      if (res) {
        console.log(res);
        getPatientOneConsultation(consultationId, toast);
        handeleSuccess(
          "Your appointment request has been set successfully",
          toast
        );
        dispatch({
          type: "REQUEST_APPOINTMENTS_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "REQUEST_APPOINTMENTS_ERROR",
        payload: err,
      });
    }
  };
};

export const deletePatientAppointmnts = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_APPOINTMENTS_LOADING",
    });
    try {
      const res = await axios({
        method: "DELETE",
        url: `${API_URL}/patient/appointments/${id}`,
      });
      dispatch({
        type: "DELETE_APPOINTMENTS_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "DELETE_APPOINTMENTS_ERROR",
        payload: err,
      });
    }
  };
};
