import React, { useEffect, useState } from "react";
import { useRouteMatch, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getPatientMedical } from "./../../../store/actions";
import FirstView from "../Repeated-Component/FirstView";
import Pagination from "../../Pagination";
import { getPatientMedicalForpagination } from "../../../store/actions";
import medicalImg from "./../../../assets/medical.png";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const MidicalChild = ({
  getPatientMedical,
  medicalList,
  getPatientMedicalForpagination,
  history,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [medical, setMedical] = useState([]);
  const [filterMedical, setFilterMedical] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  const { url } = useRouteMatch();
  const location = useLocation();
  const params = useParams();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");

  let query = useQuery().get("page");

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    if (!query) getPatientMedical(params.patientId, sharedToken);
  }, [getPatientMedical, params, query, sharedToken]);

  useEffect(() => {
    if (query)
      getPatientMedicalForpagination(query, params.patientId, sharedToken);
  }, [getPatientMedicalForpagination, query, params, sharedToken]);

  useEffect(() => {
    const { loading, error, data } = medicalList;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      setMedical(list);
      setFilterMedical(list);
      setTotalResults(data.meta.total);
      setCuttentPage(data.meta.current_page);
    }
  }, [medicalList]);
  const renderView = () => {
    return (
      <>
        <FirstView
          headerName="Medical Reports"
          buttonLink={`${url}/add-midical`}
          cardLink={`${url}/view-midical`}
          data={medical}
          filterData={filterMedical}
          setFilter={setFilterMedical}
          loading={loading}
          error={error}
          imageForEmptyView={medicalImg}
          secondTitle="Medical Report"
        />
        {totalResults > 15 && filterMedical.length > 10 ? (
          <Pagination
            pages={pagesNumber}
            nextPage={nextPage}
            prevPage={prevPage}
            specificPage={specificPage}
            currentPage={currentPage}
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return renderView();
};
const mapStateToprops = (state) => {
  return {
    medicalList: state.medicalList,
  };
};
export default connect(mapStateToprops, {
  getPatientMedical,
  getPatientMedicalForpagination,
})(MidicalChild);
