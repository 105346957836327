import { types } from "../../types/types";
const { profileTypes } = types;
const INIT_GET_PROFILE_STATE = {
  loading: false,
  error: null,
  data: null,
};
const INIT_GET_HOSPITAL_INVITES_STATE = {
  loading: false,
  error: null,
  data: null,
};
const INIT_GET_HOSPITAL_INVITE_REQUEST_STATE = {
  loading: false,
  error: null,
  data: null,
};
const INIT_MARK_HOSPITAL_INVITE_REQUEST_AS_DONE_STATE = {
  loading: false,
  error: null,
  data: null,
};
export const getPatientProfileReducer = (
  state = INIT_GET_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_PROFILE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case "GET_PROFILE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_PROFILE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getPatientProfileShareReducer = (
  state = INIT_GET_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_PROFILE_SHARE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case "GET_PROFILE_SHARE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_PROFILE_SHARE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const updatePatientProfileReducer = (
  state = INIT_GET_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case "UPDATE_PROFILE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_PROFILE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_PROFILE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const deletePatientProfileReducer = (
  state = INIT_GET_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case "DELETE_PROFILE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_PROFILE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "DELETE_PROFILE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const changePasswordReducer = (
  state = INIT_GET_PROFILE_STATE,
  { type, payload }
) => {
  switch (type) {
    case profileTypes.CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case profileTypes.CHANGE_PASSWORD_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case profileTypes.CHANGE_PASSWORD_ERORR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getHospitalInvitesReducer = (
  state = INIT_GET_HOSPITAL_INVITES_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_HOSPITAL_INVITES_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case "GET_HOSPITAL_INVITES_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_HOSPITAL_INVITES_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getHospitalInviteRequestReducer = (
  state = INIT_GET_HOSPITAL_INVITE_REQUEST_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_HOSPITAL_INVITE_REQUEST_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case "GET_HOSPITAL_INVITE_REQUEST_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_HOSPITAL_INVITE_REQUEST_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const markHospitalInviteRequestAsDoneReducer = (
  state = INIT_MARK_HOSPITAL_INVITE_REQUEST_AS_DONE_STATE,
  { type, payload }
) => {
  switch (type) {
    case "MARK_HOSPITAL_INVITE_REQUEST_AS_DONE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case "MARK_HOSPITAL_INVITE_REQUEST_AS_DONE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "MARK_HOSPITAL_INVITE_REQUEST_AS_DONE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
