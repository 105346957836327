import { Route, Redirect } from "react-router-dom"
import isAuthenticated from "../utils/isAuthenticated";

const ProtectedRoutes = ({ component: Component, ...rest }) => {

    return (
        <Route {...rest} render={(props) => {
            if (isAuthenticated()) {
                return <Component />
            } else {
                return <Redirect to={{
                    pathname: "/login",
                    state: { from: props.location }
                }} />
            }
        }} />
    )
};


export default (ProtectedRoutes);