import React from "react";
import ConditionChild from "./conditions/conditionChild";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import AddCondition from "./add-condition/AddCondition";
import ViewConditions from "./view-conditions/view-conditions";

const Conditions = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={ConditionChild} />
        <Route exact path={`${path}/add-conditions`} component={AddCondition} />
        <Route path={`${path}/add-conditions/:Eid`} component={AddCondition} />
        <Route
          path={`${path}/view-conditions/:id`}
          component={ViewConditions}
        />
      </Switch>
    </>
  );
};

export default Conditions;
