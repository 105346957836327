import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import AddAllergies from "./AddAllergies";
import ViewAllergies from "./ViewAllergies";
import AllergiesChild from "./AllergiesChild";
const Allergies = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={AllergiesChild} />
      <Route exact path={`${path}/add-allergies`} component={AddAllergies} />
      <Route path={`${path}/add-allergies/:Eid`} component={AddAllergies} />
      <Route path={`${path}/view-allergies/:id`} component={ViewAllergies} />
    </Switch>
  );
};
export default Allergies;
