// background dark and light
export const checkBgColorMode = (colorMode) =>
  colorMode === "dark" ? "darkBg" : "white";
//   primary and secondary
export const checkColorMode = (colorMode) =>
  colorMode === "dark" ? "secondary" : "primary";

//   primary hover and secondary hover
export const checkColorModeHover = (colorMode) =>
  colorMode === "dark" ? "secondary_hover" : "primary_hover";
//   white and black
export const checkWhiteAndBlackColor = (colorMode) =>
  colorMode === "dark" ? "white" : "black";

export const checkButtonColor = (colorMode) =>
  colorMode === "dark" ? "secondary_hover_Opacity" : "primary";
