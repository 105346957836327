import React from "react";
import { Input, Button, Text, useColorMode } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";

import "./repeated.scss";
function HeaderSection({
  headerName,
  onInputChange,
  buttonLink,
  inputAndButton,
}) {
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const { colorMode } = useColorMode();
  return (
    <div className="h4 py-3 fw-bold d-flex flex-wrap align-items-center justify-content-between">
      <Text
        color={checkColorMode(colorMode)}
        fontWeight="semibold"
        className="my-2 me-3"
      >
        {headerName}{" "}
      </Text>
      {inputAndButton && (
        <div className="d-flex flex-wrap align-items-center my-2">
          <div className="me-3 input-search my-2">
            <Input
              w="300px"
              fontSize="15px"
              placeholder="Search"
              size="lg"
              className="ps-5"
              bg={checkBgColorMode(colorMode)}
              onChange={onInputChange}
            />
            <Text color={checkColorMode(colorMode)} className="icon-search">
              <i className="fas fa-search"></i>
            </Text>
          </div>
          {!sharedToken && (
            <div className="">
              <Button
                className="rounded text-white my-2"
                backgroundColor={checkColorMode(colorMode)}
                size="lg"
                _hover={{ backgroundColor: checkColorModeHover(colorMode) }}
                _active={{ backgroundColor: checkColorModeHover(colorMode) }}
                as={Link}
                to={buttonLink}
                fontSize="17px"
              >
                <span className="me-2">
                  <i className="fas fa-plus"></i>
                </span>{" "}
                Add
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default HeaderSection;
