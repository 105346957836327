import React, { useState, useEffect } from "react";
// import Colors from "../../Scss-vars/Colors.scss";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  useDisclosure,
  Text,
  useToast,
  useColorMode,
  Box,
} from "@chakra-ui/react";

import { InputControl } from "formik-chakra-ui";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  get_one_Patient_Rreading,
  post_Patient_Rreading,
  update_Patient_Rreading,
  getPatientRreadingList,
} from "../../store/actions";
import { checkReaddingType } from "./ReadingCard";
import { checkColorMode, checkColorModeHover } from "../../global/changeColor";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const ModalComponenet = ({
  img,
  icon,
  name,
  feildName,
  itemId,
  noReading,
  get_one_Patient_Rreading,
  oneRead,
  post_Patient_Rreading,
  update_Patient_Rreading,
  getPatientRreadingList,
}) => {
  let query = useQuery().get("readingId");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();
  const { colorMode } = useColorMode();
  // blood-pressure
  // height
  // weight
  // heart-rate
  // blood-sugar
  // temperature
  let patternOneDigisAfterComma = /^\d+(\.\d{0,1})?$/;
  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
  let patterbBloodPressure = /^\d+\/\d+$/;
  let patterHeartRate = /^\d+$/;
  let validationHeartRate = Yup.number()
    .test("is-decimal", `The ${name} should not be a decimal`, (val) => {
      if (val !== undefined) {
        return patterHeartRate.test(val);
      }
      return true;
    })
    .min(40, "minimum value is 40")
    .max(500, "maximum value is 500")
    .required("Required");

  let validationForHeight = Yup.number()
    .test(
      "is-decimal",
      `The ${name} should be a decimal with maximum ${
        feildName === "height" ? "one digit" : "two digits"
      } after comma`,
      (val) => {
        if (val !== undefined) {
          return patternOneDigisAfterComma.test(val);
        }
        return true;
      }
    )
    .min(20, "minimum value is 20")
    .max(500, "maximum value is 500")
    .required("Required");

  let validationBloodSugar = Yup.number()
    .test(
      "is-decimal",
      `The ${name} should be a decimal with maximum ${
        feildName === "height" ? "one digit" : "two digits"
      } after comma`,
      (val) => {
        if (val !== undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      }
    )
    .min(50, "minimum value is 50")
    .max(300, "maximum value is 300")
    .required("Required");

  let validationTemperature = Yup.number()
    .test(
      "is-decimal",
      `The ${name} should be a decimal with maximum ${
        feildName === "height" ? "one digit" : "two digits"
      } after comma`,
      (val) => {
        if (val !== undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      }
    )
    .min(35, "minimum value is 35")
    .max(45, "maximum value is 45")
    .required("Required");

  let validationWeight = Yup.number()
    .test(
      "is-decimal",
      `The ${name} should be a decimal with maximum ${
        feildName === "height" ? "one digit" : "two digits"
      } after comma`,
      (val) => {
        if (val !== undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      }
    )
    .min(20, "miminimum value isn 20")
    .max(500, "maximum value is 500")
    .required("Required");

  let validationBloodPressure = Yup.string()
    .test(
      "is-decimal",
      `For example the value should be something like this 120/20`,
      (val) => {
        if (val !== undefined) {
          return patterbBloodPressure.test(val);
        }
        return true;
      }
    )

    .required("Required");

  const chooseValidationType = () => {
    switch (feildName) {
      case "blood-pressure":
        return validationBloodPressure;
      case "height":
        return validationForHeight;
      case "weight":
        return validationWeight;
      case "heart-rate":
        return validationHeartRate;
      case "blood-sugar":
        return validationBloodSugar;
      case "temperature":
        return validationTemperature;

      default:
        break;
    }
  };

  const commonStringValidator = Yup.string()
    .test(
      "is-decimal",
      `The ${name} should be a decimal with maximum ${
        feildName === "height" ? "one digit" : "two digits"
      } after comma`,
      (val) => {
        if (val !== undefined) {
          return feildName === "height"
            ? patternOneDigisAfterComma.test(val)
            : patternTwoDigisAfterComma.test(val);
        }
        return true;
      }
    )

    .required("Required");
  const SignInSchema = Yup.object().shape({
    value: chooseValidationType(),
  });

  const [initValue, setInitVal] = useState({
    value: "",
    type: feildName,
  });

  const onModalClick = () => {
    if (itemId) history.push(`?readingId=${itemId}`);
  };

  useEffect(() => {
    if (query && oneRead) {
      const { data } = oneRead;
      if (data && data.value) {
        setInitVal((initValue) => {
          return {
            ...initValue,
            value: data.value,
          };
        });
      }
    }
  }, [query, oneRead]);
  const onModalClose = () => {
    if (queryParams.has("readingId")) {
      queryParams.delete("readingId");
      history.replace({
        search: queryParams.toString(),
      });
    }
    onClose();
  };

  const onFormSubmit = (value) => {
    if (query) {
      update_Patient_Rreading(value, query, toast).then(() => {
        return getPatientRreadingList();
      });
      onModalClose();
    } else {
      post_Patient_Rreading(value, toast).then(() => {
        return getPatientRreadingList();
      });
      onModalClose();
    }
  };

  return (
    <div>
      <Button
        onClick={() => {
          onModalClick();
          onOpen();
        }}
        color="#fff"
        bg={checkColorMode(colorMode)}
        _hover={{ bg: checkColorModeHover(colorMode) }}
        minW={noReading ? "175px" : "auto"}
      >
        {noReading ? `Add ${name}` : <i className="fas fa-plus"></i>}
      </Button>

      <Modal
        onClose={onModalClose}
        isOpen={isOpen}
        isCentered
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <div className="d-flex justify-content-center mt-5 flex-wrap">
              <Box
                className=" rounded-circle d-flex justify-content-center align-items-center text-white"
                style={{
                  width: "80px",
                  height: "80px",
                }}
                bg={checkColorMode(colorMode)}
              >
                {img ? (
                  <img src={img} alt={name} />
                ) : (
                  <span style={{ fontSize: "40px" }}>
                    <i className={icon}></i>
                  </span>
                )}
              </Box>
              <Text
                color={checkColorMode(colorMode)}
                fontSize="2xl"
                className="w-100 text-center my-3"
                fontWeight="semibold"
              >
                Add New Reading
              </Text>
              <Text className="w-100 text-center my-2">
                Add New {name} Reading
              </Text>
              <Formik
                initialValues={initValue}
                enableReinitialize
                validationSchema={SignInSchema}
                onSubmit={onFormSubmit}
              >
                <Form className="w-75">
                  <div className=" my-4 mt-5 helperText">
                    <InputControl
                      size={"lg"}
                      className="w-100"
                      name="value"
                      inputProps={{
                        placeholder: `Add Reading`,
                        type:
                          feildName === "blood-pressure" ? "text" : "number",
                      }}
                    />
                    <span>{checkReaddingType(feildName).text}</span>
                  </div>
                  <div className="my-3 d-flex justify-content-between">
                    <Button
                      w="35%"
                      type="submit"
                      color="#fff"
                      bg={checkColorMode(colorMode)}
                      _hover={{ bg: checkColorModeHover(colorMode) }}
                      size="lg"
                    >
                      Add
                    </Button>

                    <Button
                      type="button"
                      w="35%"
                      colorScheme={"red"}
                      size="lg"
                      variant="outline"
                      onClick={onModalClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </Formik>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    oneRead: state.oneReading,
  };
};
export default connect(mapStateToProps, {
  get_one_Patient_Rreading,
  post_Patient_Rreading,
  update_Patient_Rreading,
  getPatientRreadingList,
})(ModalComponenet);
