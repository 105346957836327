import { types } from "../../types/types";
const { notificationTypes } = types;
const INIT_NOTIFICATIONS_STATE = {
  loading: false,
  error: null,
  data: null,
};
export const getPatientNotificationsReducer = (
  state = INIT_NOTIFICATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case notificationTypes.GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case notificationTypes.GET_NOTIFICATIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case notificationTypes.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const getPatientNotificationsForPaginationReducer = (
  state = INIT_NOTIFICATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case notificationTypes.GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case notificationTypes.GET_NOTIFICATIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case notificationTypes.GET_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
// export const getPatientNotificationsForPaginationReducer = (
//   state = INIT_NOTIFICATIONS_STATE,
//   { type, payload }
// ) => {
//   switch (type) {
//     case notificationTypes.GET_FOR_PAGINATION_NOTIFICATIONS_LOADING:
//       return {
//         ...state,
//         loading: true,
//         error: null,
//         data: null,
//       };
//     case notificationTypes.GET_FOR_PAGINATION_NOTIFICATIONS_SUCSES:
//       return {
//         ...state,
//         loading: false,
//         error: null,
//         data: payload,
//       };
//     case notificationTypes.GET_FOR_PAGINATION_NOTIFICATIONS_ERROR:
//       return {
//         ...state,
//         loading: false,
//         error: payload,
//         data: null,
//       };
//     default:
//       return state;
//   }
// };

export const getPatientUnReadNotificationsReducer = (
  state = INIT_NOTIFICATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case notificationTypes.GET_UNREAD_NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case notificationTypes.GET_UNREAD_NOTIFICATIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case notificationTypes.GET_UNREAD_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const markAllNotificationAssReadReducer = (
  state = INIT_NOTIFICATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case notificationTypes.MARK_ALL_AS_READIND_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case notificationTypes.MARK_ALL_AS_READIND_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case notificationTypes.MARK_ALL_AS_READIND_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const markOneNotificationAssReadReducer = (
  state = INIT_NOTIFICATIONS_STATE,
  { type, payload }
) => {
  switch (type) {
    case notificationTypes.MARK_ONE_AS_READING_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case notificationTypes.MARK_ONE_AS_READING_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case notificationTypes.MARK_ONE_AS_READING_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
