import React, { useRef, useState, useEffect } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  AlertDialogCloseButton,
  MenuItem,
  Box,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { checkColorMode, checkColorModeHover } from "./changeColor";

const AlertComponent = ({
  removeOne,
  nameForAlert,
  consultation,
  hospitalInvite,
  deleteAccount,
  reading,
  fromOverView,
  draft,
  account,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirm, setConfirm] = useState(false);
  const cancelRef = useRef();
  const { colorMode } = useColorMode();
  const onConfirm = () => {
    setConfirm(true);
  };

  useEffect(() => {
    if (confirm) {
      onClose();
      removeOne();
      setConfirm(!confirm);
    }
  }, [confirm, onClose, removeOne]);

  const renderBtnView = () => {
    if (consultation) {
      return (
        <Button
          w={fromOverView ? "100%" : null}
          minW={fromOverView ? "230px" : null}
          colorScheme="red"
          variant="outline"
          size="lg"
          onClick={onOpen}
        >
          Close Consultation
        </Button>
      );
    } else if (hospitalInvite) {
      return (
        <Button
          onClick={onOpen}
          h="57px"
          bgColor={checkColorMode(colorMode)}
          color="#fff"
          className="rounded my-3 ml-3"
          _hover={{ bg: checkColorModeHover(colorMode) }}
        >
          Mark Requests as Done
        </Button>
      );
    } else if (deleteAccount) {
      return (
        <MenuItem className="p-3" onClick={onOpen}>
          <Link
            to="#"
            className="nav-link p-0 w-100  d-flex justify-content-between"
          >
            <div className="d-flex">
              <Text className="me-3" color="#979797">
                <i className="far fa-trash-alt"></i>
              </Text>
              <Text fontSize="15px" className="me-1" color="#979797">
                Delete Account
              </Text>
            </div>
            <Text color="#979797">
              <i className="fas fa-chevron-right"></i>
            </Text>
          </Link>
        </MenuItem>
      );
    } else if (reading) {
      return (
        <Button
          className=""
          onClick={onOpen}
          color="error"
          variant="outline"
          colorScheme
        >
          <span className="">
            <i className="fas fa-trash"></i>
          </span>
        </Button>
      );
    } else if (draft) {
      return (
        <Button
          colorScheme="red"
          onClick={onOpen}
          variant="outline"
          w="100%"
          size="lg"
          fontSize={16}
        >
          <span className="me-4">
            <i className="fas fa-trash-alt"></i>
          </span>{" "}
          Delete
        </Button>
      );
    } else {
      return (
        <Button
          className="me-2"
          onClick={onOpen}
          color="error"
          variant="ghost"
          _hover={{ color: "error", background: "error_hover" }}
        >
          <span className="me-3">
            <i className="fas fa-trash"></i>
          </span>
          Delete {account ? "Account" : ""}
        </Button>
      );
    }
  };
  return (
    <>
      {renderBtnView()}

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {hospitalInvite
              ? ` Are you sure you want to ${nameForAlert}`
              : `Are you sure you want to ${
                  consultation ? "close" : "delete"
                } this ${nameForAlert}`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={onConfirm}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AlertComponent;

export const AlertForRadiology = ({
  removeOne,
  nameForAlert,
  radiologyID,
  colorMode,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirm, setConfirm] = useState(false);
  const cancelRef = useRef();
  const onConfirm = () => {
    setConfirm(true);
    onClose();
    removeOne(radiologyID);
  };
  useEffect(() => {
    if (confirm) {
      onClose();
      removeOne();
    }
  }, [confirm, onClose, removeOne]);
  return (
    <>
      <Box
        as={Link}
        to={`#`}
        className="nav-link p-0 w-100"
        onClick={onOpen}
        color={colorMode === "dark" ? "secondary" : "black"}
        _hover={{ color: colorMode === "dark" ? "secondary" : "black" }}
      >
        <span className="me-3 text-error">
          <i className="far fa-trash-alt"></i>
        </span>
        Delete
      </Box>

      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Confirm Changes?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Are you sure you want to delete this {nameForAlert}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              No
            </Button>
            <Button colorScheme="red" ml={3} onClick={onConfirm}>
              Yes
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
