import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  getPatientconsultations,
  consultationTypeList,
  closePatientconsultation,
} from "../../store/actions";
import { useRouteMatch, Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../global/Animations/Animations";
import Consultationchild from "./consultationChild";
import Child from "./Child";
import "./Consultations.scss";

const Consultations = ({
  getPatientconsultations,
  consultationTypeList,
  PrePaymentData,
  closePatientconsultation,
}) => {
  const { path } = useRouteMatch();
  const loaction = useLocation();
  useEffect(() => {
    getPatientconsultations();
    consultationTypeList();
    // closePatientconsultation(1021);
  }, [getPatientconsultations, consultationTypeList]);
  return (
    <motion.div
      className="container-fluid Consultations"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <AnimatePresence exitBeforeEnter>
        <Switch location={loaction} key={loaction.pathname}>
          <Route exact path={`${path}`} component={Consultationchild} />
          <Route
            path={`${path}/:child`}
            component={Child}
            PrePaymentData={PrePaymentData}
          />
        </Switch>
      </AnimatePresence>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    PrePaymentData: state.PrePaymentData.data,
  };
};
export default connect(mapStateToProps, {
  getPatientconsultations,
  consultationTypeList,
  closePatientconsultation,
})(Consultations);
