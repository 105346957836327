import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
// import lapResultC from "./medicalChild";

// import ViewMedical from "./view-medical";
// import AddMedical from "./add-medical";
import lapResultChild from "./lapResultChild";
import viewLapResult from "./viewLapResult";
import addLapResult from "./addLapResult";

const LapResult = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={lapResultChild} />
        <Route exact path={`${path}/add-Lab-result`} component={addLapResult} />
        <Route path={`${path}/add-Lab-result/:Eid`} component={addLapResult} />
        <Route path={`${path}/view-Lab-result/:id`} component={viewLapResult} />
      </Switch>
    </>
  );
};
export default LapResult;
