import { Text, Box, useColorMode } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { style } from "../../global/style";
import { checkColorMode, checkBgColorMode } from "../../global/changeColor";

const Footer = () => {
  const { colorMode } = useColorMode();
  return (
    <div className="footer container-fluid p-0">
      <Box
        className="row text-dark m-0 shadow"
        bg={checkBgColorMode(colorMode)}
      >
        <div className="col-12 row m-0 p-3 align-items-center">
          <div className="col-md-4 me-auto">
            <Text
              fontSize="sm"
              fontWeight="semibold"
              color={checkColorMode(colorMode)}
            >
              &copy; 2022 PatRec
            </Text>
          </div>
          <Box
            className="col-md-6 d-flex justify-content-end"
            fontSize="sm"
            color={style.smallTextGray}
          >
            <Text>
              <a
                href="https://pat-rec.com/privacy-policy"
                target={"_blank"}
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Text>
            <span className="mx-2">.</span>
            <Text>
              <a
                href="https://pat-rec.com/terms-of-use"
                target={"_blank"}
                rel="noreferrer"
              >
                Terms of use
              </a>
            </Text>
            <span className="mx-2">.</span>
            <Text _hover={{ color: style.smallTextGray }} as={Link} to={"#"}>
              {" "}
              Cookies
            </Text>
            {/* <span className="mx-2">.</span>
            <Text className="d-flex">
              {" "}
              Language:
              <Text
                fontWeight="semibold"
                color={style.primary}
                _hover={{ color: style.primary_hover }}
                as={Link}
                to={"#"}
              >
                English
              </Text>
            </Text> */}
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default Footer;
