import { combineReducers } from "redux";

import {
  getMedicalReducer,
  deleteMedicalReducer,
  getOneMedicalReducer,
  updateMedicalReducer,
  postMedicalReducer,
} from "./Midical-Record/medicalReportReducers";

import {
  getPatientReaddingReducer,
  addReaddingReducer,
  deleteReaddingReducer,
  getOneReaddingReducer,
  updateReaddingReducer,
} from "./Redding_File/myReddingReducers";

import {
  getPatientAddressReducer,
  postPatientAddressReducer,
  updatePatientAddressReducer,
} from "./Patient_File/PatientAddressReducer";

import {
  getPatientEmergencyReducer,
  postPatientEmergencyReducer,
  updatePatientEmergencyReducer,
} from "./Patient_File/PatientEmergencyReducer";

import {
  addLapResultReducer,
  deleteLapResultReducer,
  getLapResultReducer,
  getOneLapResultReducer,
  updateLapResultReducer,
} from "./Midical-Record/patientLapResultReducers";

import {
  patientShareInformationReducer,
  patientStopShareInformationReducer,
} from "./Patient_File/patientShareInformationReducers";

import {
  getPatientNotificationsReducer,
  getPatientUnReadNotificationsReducer,
  markAllNotificationAssReadReducer,
  markOneNotificationAssReadReducer,
} from "./Notifications_File/getPatientNotificationsReducer";
import { postPatientProfilePictureReducer } from "./Patient_File/PatientProfilePictureReducer";
import {
  getPatientProfileReducer,
  getHospitalInvitesReducer,
  getHospitalInviteRequestReducer,
  markHospitalInviteRequestAsDoneReducer,
  updatePatientProfileReducer,
  changePasswordReducer,
  deletePatientProfileReducer,
  getPatientProfileShareReducer,
} from "./Patient_File/PatientProfileReducers";

import {
  loginReducer,
  forgetPasswordReducer,
  logoutReducer,
  signupReducer,
  patientVerifyMobileReducer,
  resendPatientVerifyMobileReducer,
} from "./Patient_File/PatientAuthReducers";

import {
  getAllergiesReducer,
  deleteAllergiesReducer,
  getOneAllergiesReducer,
  postAllergiesReducer,
  updateAllergiesReducer,
} from "./Midical-Record/patientAllergiesReducers";

import {
  getECGReducer,
  deleteECGReducer,
  getOneECGReducer,
  postECGReducer,
  updateECGReducer,
} from "./Midical-Record/patientEcgReducers";

import {
  getXRAYReducer,
  deleteXRAYReducer,
  getOneXRAYReducer,
  postXRAYReducer,
  updateXRAYReducer,
} from "./Midical-Record/patientXrayReducers";
import {
  getConsultationsReducer,
  getOneConsultationReducer,
  getRequestsReducer,
  getQuestionsReducer,
  getOneQuestionsReducer,
  getPatientPrePaymentDataReducer,
  postConsultationReducer,
} from "./Consultations _File/patientConsultationsReducers";

import {
  getConditionsReducer,
  deleteConditionsReducer,
  getOneConditionsReducer,
  postConditionsReducer,
  updateConditionsReducer,
} from "./Midical-Record/patient-Conditions-Reducers";

import {
  getOneRadiologyReducer,
  getRadiologyReducer,
  deleteRadiologyReducer,
  postRadiologyReducer,
  updateRadiologyReducer,
} from "./Midical-Record/Patient-Radiology-Reducers";

import {
  geBloodTypesReducer,
  getConsultationTypeListReducer,
  getPatientTypesReducer,
} from "./Common-reducers";
import {
  getAppointmntsReducer,
  getConsultantAppointmentReducer,
  addAppointmentReducer,
  deleteAppointmentReducer,
  getEmptyConsultantAppointmentReducer,
  requestAppointmentReducer,
} from "./Appointmnts/AppointmntsReducers";
import {
  getHospitalsQuotationsReducer,
  addHospitalReservationReducer,
  contactToAdminReducer,
  getOneHospitalsQuotationsReducer,
} from "./Hospital Reservation/HospitalReservationReducers";
import {
  getClientSecret,
  getAuthFailed,
  getPaymentList,
} from "./Payment/Payment";

export default combineReducers({
  //// Auth
  login: loginReducer,
  logout: logoutReducer,
  signup: signupReducer,
  forgetPassword: forgetPasswordReducer,
  verifyMobile: patientVerifyMobileReducer,
  resendPatientVerifyMobile: resendPatientVerifyMobileReducer,
  /// Condition
  patientCondition: getConditionsReducer,
  oneCondition: getOneConditionsReducer,
  addCondition: postConditionsReducer,
  updateCondition: updateConditionsReducer,
  deleteConditions: deleteConditionsReducer,

  //// Medical report
  addMedical: postMedicalReducer,
  medicalList: getMedicalReducer,
  oneMedical: getOneMedicalReducer,
  updateMedical: updateMedicalReducer,
  deleteMedical: deleteMedicalReducer,

  ////Lap Result
  addLapResult: addLapResultReducer,
  LapResultList: getLapResultReducer,
  oneLapResult: getOneLapResultReducer,
  updateLapResult: updateLapResultReducer,
  deleteLapResult: deleteLapResultReducer,

  //// Allergies
  addAllergies: postAllergiesReducer,
  AllergiesList: getAllergiesReducer,
  oneAllergies: getOneAllergiesReducer,
  updateAllergies: updateAllergiesReducer,
  deleteAllergies: deleteAllergiesReducer,

  ///// ECG ////////
  addECG: postECGReducer,
  ECGList: getECGReducer,
  oneECG: getOneECGReducer,
  updateECG: updateECGReducer,
  deleteECG: deleteECGReducer,

  /// X Ray
  addXray: postXRAYReducer,
  XrayList: getXRAYReducer,
  oneXray: getOneXRAYReducer,
  updateXray: updateXRAYReducer,
  deleteXray: deleteXRAYReducer,

  /// Radiology
  OneRadiology: getOneRadiologyReducer,
  RadiologyList: getRadiologyReducer,
  deleteRadiology: deleteRadiologyReducer,
  AddRadiology: postRadiologyReducer,
  updateRadiology: updateRadiologyReducer,
  ////notifications
  notificationsList: getPatientNotificationsReducer,
  unRead: getPatientUnReadNotificationsReducer,
  markAll: markAllNotificationAssReadReducer,
  markOne: markOneNotificationAssReadReducer,
  // notificationPagination: getPatientNotificationsForPaginationReducer,
  ////PatientProfile
  PatientProfile: getPatientProfileReducer,
  PatientProfileShare: getPatientProfileShareReducer,
  hospitalInvites: getHospitalInvitesReducer,
  markHospitalInviteRequestAsDone: markHospitalInviteRequestAsDoneReducer,
  hospitalInviteRequest: getHospitalInviteRequestReducer,
  updatePatientProfile: updatePatientProfileReducer,
  PatientProfilePicture: postPatientProfilePictureReducer,
  changePassword: changePasswordReducer,
  deletePatient: deletePatientProfileReducer,
  ///patient Share Info
  patientShareInfo: patientShareInformationReducer,
  patientStopShareInfo: patientStopShareInformationReducer,

  ///patient Address
  patientAddress: getPatientAddressReducer,
  patientUpdateAddress: updatePatientAddressReducer,
  patientCreateAddress: postPatientAddressReducer,

  /// patient Emergency
  patientEmergency: getPatientEmergencyReducer,
  patientCreateEmergency: postPatientEmergencyReducer,
  patientUpdateEmergency: updatePatientEmergencyReducer,

  /// Readding
  readdingList: getPatientReaddingReducer,
  addReading: addReaddingReducer,
  removeReading: deleteReaddingReducer,
  oneReading: getOneReaddingReducer,
  updateReading: updateReaddingReducer,
  ///consultations
  consultationsList: getConsultationsReducer,
  oneConsultation: getOneConsultationReducer,
  request: getRequestsReducer,
  Questions: getQuestionsReducer,
  oneQuestion: getOneQuestionsReducer,
  PrePaymentData: getPatientPrePaymentDataReducer,
  postConsultation: postConsultationReducer,
  // Appointmnts
  appointmntsList: getAppointmntsReducer,
  addAppointmnt: addAppointmentReducer,
  deleteAppointmnt: deleteAppointmentReducer,
  consultantAppointment: getConsultantAppointmentReducer,
  emptyconsultantAppointment: getEmptyConsultantAppointmentReducer,
  requestdAppointment: requestAppointmentReducer,

  ////hospitals
  hospitalsQuotationsList: getHospitalsQuotationsReducer,
  addHospitalReservation: addHospitalReservationReducer,
  OneHospitalQuotation: getOneHospitalsQuotationsReducer,
  contactAdmin: contactToAdminReducer,

  //payment
  paymentList: getPaymentList,

  /// Common
  bloodTypes: geBloodTypesReducer,
  consultationTypes: getConsultationTypeListReducer,
  patientTypes: getPatientTypesReducer,
  clientSecret: getClientSecret,
  authFailed: getAuthFailed,
});
// const rootReducer = (state, action) => {
//   // if (action.type === "LOGOUT_SUCSES") {
//   //   state.PatientProfile.data = null;
//   //   console.log(state.PatientProfile);
//   // }
//   return appReducer(state, action);
// };

// export default rootReducer;
