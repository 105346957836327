import { Box, Button, Text, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  pageTransition,
  pageVariants,
} from "../../../global/Animations/Animations";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const EmptyMedical = ({ imgeURL, title, buttonPath, secondTitle }) => {
  const { colorMode } = useColorMode();
  const sharedToken = useQuery().get("sharedToken");
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Box
        className="row justify-content-center shadow-sm rounded m-0 py-5"
        fontWeight="semibold"
        fontSize="xl"
        bg={checkBgColorMode(colorMode)}
      >
        <Box className="col-md-4 col-12 d-flex justify-content-center">
          <img className="" src={imgeURL} alt="imge" />
        </Box>
        <Text
          className="col-12 text-center mt-4"
          fontWeight="black"
          fontSize="3xl"
          color={checkColorMode(colorMode)}
        >
          {title}
        </Text>
        <Text
          fontWeight="semibold"
          fontSize="lg"
          color="gray.400"
          className="col-12 text-center mb-4 mt-1"
        >
          {sharedToken
            ? `There are no ${title.toLowerCase()} to display`
            : `Add your first ${secondTitle}`}
        </Text>
        {!sharedToken && (
          <Box className="col-12 text-center">
            <Button
              className="rounded text-white my-2"
              bg={checkColorMode(colorMode)}
              size="lg"
              _hover={{ bg: checkColorModeHover(colorMode) }}
              _active={{ bg: "primary" }}
              as={Link}
              to={`${buttonPath}`}
              fontSize="17px"
            >
              <span className="me-2">
                <i className="fas fa-plus"></i>
              </span>{" "}
              Add New
            </Button>
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

export default EmptyMedical;
