const INIT_COMMON_STATE = {
  loading: false,
  error: null,
  data: [],
};
export const getConsultationTypeListReducer = (
  state = INIT_COMMON_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_CONSULTATION_TYPE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_CONSULTATION_TYPE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_CONSULTATION_TYPE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getPatientTypesReducer = (
  state = INIT_COMMON_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_PATIENT_TYPE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_PATIENT_TYPE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_PATIENT_TYPE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const geBloodTypesReducer = (
  state = INIT_COMMON_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_BLOOD_TYPE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_BLOOD_TYPE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_BLOOD_TYPE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
