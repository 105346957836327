const INIT_RADIOLOGY_STATR = {
  loading: false,
  error: null,
  data: null,
};

export const getRadiologyReducer = (
  state = INIT_RADIOLOGY_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_RADIOLOGY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_RADIOLOGY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_RADIOLOGY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneRadiologyReducer = (
  state = INIT_RADIOLOGY_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_ONE_RADIOLOGY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_RADIOLOGY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_RADIOLOGY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const postRadiologyReducer = (
  state = INIT_RADIOLOGY_STATR,
  { type, payload }
) => {
  switch (type) {
    case "POST_RADIOLOGY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case "POST_RADIOLOGY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_RADIOLOGY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const updateRadiologyReducer = (
  state = INIT_RADIOLOGY_STATR,
  { type, payload }
) => {
  switch (type) {
    case "UPDATE_RADIOLOGY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_RADIOLOGY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_RADIOLOGY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const deleteRadiologyReducer = (
  state = INIT_RADIOLOGY_STATR,
  { type, payload }
) => {
  switch (type) {
    case "DELETE_RADIOLOGY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_RADIOLOGY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "DELETE_RADIOLOGY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
