import React, { useState, useEffect } from "react";
import {
  Text,
  Box,
  Button,
  useColorMode,
  Circle,
  useToast,
} from "@chakra-ui/react";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../global/changeColor";
import moment from "moment";
import chest from "../../assets/chest.png";
import { connect } from "react-redux";
import {
  consultationTypeList,
  postPatientconsultation,
  getAllNotifications,
  getUnReadNotifications,
  getPatientconsultations,
  getPatientEmergencyInfo,
  getPatientAddress,
} from "../../store/actions";
import { Link } from "react-router-dom";
import AlertComponent from "../../global/Alert.Component";
import { GetSpecialityIcon } from "../../global/GetSpecialityIcon";

const DraftConsultation = ({
  history,
  consultationTypeList,
  consultationTypes,
  postPatientconsultation,
  getAllNotifications,
  getUnReadNotifications,
  getPatientconsultations,
  getPatientEmergencyInfo,
  getPatientAddress,
  postedConsultation,
  patientAddress,
  patientEmergency,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [draft, setDraft] = useState({});
  const [ConsultationType, setConsultationType] = useState([]);

  useEffect(() => {
    getPatientEmergencyInfo();
    getPatientAddress();
  }, [getPatientEmergencyInfo, getPatientAddress]);

  useEffect(() => {
    const draftConsultation = JSON.parse(localStorage.draftConsultation);
    setDraft(draftConsultation);
  }, []);

  useEffect(() => {
    consultationTypeList();
  }, [consultationTypeList]);

  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);

  const deleteDraft = () => {
    localStorage.removeItem("draftConsultation");
    history.push("/");
  };

  const submitConsultation = () => {
    if (patientAddress && patientEmergency) {
      postPatientconsultation(
        draft,
        history,
        toast,
        getAllNotifications,
        getUnReadNotifications,
        getPatientconsultations
      );
    } else {
      if (!patientAddress) {
        localStorage.setItem("consultationRequest", JSON.stringify(draft));
        history.push(
          `/profile/add_address?redirectTo=${history.location.pathname}`
        );
        return toast({
          title: "Please add your address before making a consultation request",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      } else if (!patientEmergency) {
        localStorage.setItem("consultationRequest", JSON.stringify(draft));
        history.push(
          `/profile/add_emargency?redirectTo=${history.location.pathname}`
        );
        return toast({
          title:
            "Please add your emergency info before making a consultation request",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    }
  };
  const renderView = () => (
    <Box className="container my-5">
      <Box className="row">
        <Box className="col-12">
          <Text
            fontSize="2xl"
            fontWeight="semibold"
            className="mb-3"
            color={checkColorMode(colorMode)}
          >
            Draft Consultation
          </Text>
          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-12 p-4  rounded shadow-sm"
          >
            <div className="row m-0 align-items-center">
              <Box className="col-12">
                <Text
                  className="h4 fw-bold mb-0"
                  color={checkColorMode(colorMode)}
                >
                  {draft?.symptoms}
                </Text>
                <Text color={"smallTextGray"} fontSize="13px">
                  {moment(draft?.created_at).format("DD/MM/YYYY hh:mm A")}
                </Text>
              </Box>
              <Box className="col-12 my-3 d-flex justify-content-between align-items-center flex-wrap">
                <div className="d-flex align-items-center m-0 p-0 my-2">
                  <Circle size="40px" bg={checkColorMode(colorMode)}>
                    {draft && draft.consultationTypeId ? (
                      <img
                        className="image-fluid"
                        src={GetSpecialityIcon(
                          Number(draft.consultationTypeId)
                        )}
                        alt="chest"
                      />
                    ) : (
                      ""
                    )}
                  </Circle>
                  <Box className="p-0 ms-2">
                    <Text color="gray.400" fontSize="13px" fontWeight="600">
                      Speciality
                    </Text>
                    <Text fontSize="12px" fontWeight="700">
                      {ConsultationType.length
                        ? ConsultationType.find(
                            (element) =>
                              element.id === Number(draft.consultationTypeId)
                          ).name
                        : ""}
                    </Text>
                  </Box>
                </div>
                <Box
                  className={`pending text-center rounded px-5 my-2`}
                  textTransform="capitalize"
                >
                  draft
                </Box>
              </Box>
              <Box
                boxShadow="xs"
                className="rounded p-3 mt-2"
                bg="smallTextGrayLight"
                border={`1px solid`}
                borderColor="smallTextGrayLightBorder"
              >
                {draft?.symptomsExplained}
              </Box>
            </div>
          </Box>

          <div className="row p-0 justify-content-between align-items-center m-0 my-3">
            <div className="col-md-4 col-12 mb-3 row m-0 px-0">
              <div className="col-md-6 col-12 my-1">
                <Button
                  as={Link}
                  to="add-consultation"
                  w="100%"
                  size="lg"
                  fontSize={16}
                  bg={"secondary"}
                  color="#fff"
                  _hover={{
                    bg: "secondary_hover",
                    color: "white",
                  }}
                >
                  <span className="me-4">
                    <i className="fas fa-pen"></i>
                  </span>{" "}
                  Edit
                </Button>
              </div>

              <div className="col-md-6 col-12 my-1">
                <AlertComponent
                  removeOne={deleteDraft}
                  nameForAlert={"consultation"}
                  draft={true}
                />
              </div>
            </div>

            <div className="col-md-3 col-12 mb-3">
              <Button
                w="100%"
                size="lg"
                fontSize={16}
                bg={checkColorMode(colorMode)}
                color="#fff"
                _hover={{ bg: checkColorModeHover(colorMode) }}
                isLoading={postedConsultation.loading}
                onClick={submitConsultation}
              >
                Submit
                <span className="ms-3 fw-bold">
                  <i className="fas fa-arrow-right"></i>
                </span>
              </Button>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    consultationTypes: state.consultationTypes,
    postedConsultation: state.postConsultation,
    patientAddress: state.patientAddress.data,
    patientEmergency: state.patientEmergency.data,
  };
};

export default connect(mapStateToProps, {
  consultationTypeList,
  postPatientconsultation,
  getAllNotifications,
  getUnReadNotifications,
  getPatientconsultations,
  getPatientEmergencyInfo,
  getPatientAddress,
})(DraftConsultation);
