import React from "react";
import Uppy from "@uppy/core";
import { Dashboard, useUppy } from "@uppy/react";
// import XHRUpload from "@uppy/xhr-upload";

import Tus from "@uppy/tus";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { useHistory } from "react-router-dom";
import { useToast, useColorMode } from "@chakra-ui/react";
import { connect } from "react-redux";
import { updateRadiology } from "../../../store/actions";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL;

function Uploader({ id, updateRadiology, procedureDate, setProcedureDate }) {
  const history = useHistory();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const uppy = useUppy(() => {
    return new Uppy({
      onBeforeFileAdded: (currentFile) => {
        if (!currentFile.type) {
          return false;
        }
        if (
          currentFile.type.includes("image/") ||
          currentFile.type === "application/pdf" ||
          currentFile.type === "application/dicom" ||
          currentFile.type === "application/octet-stream"
        ) {
          if (currentFile.data.webkitRelativePath) {
            currentFile.meta.relativePath = currentFile.data.webkitRelativePath;
          }
          return currentFile;
        }
        return false;
      },
    })
      .use(Tus, {
        method: "post",
        endpoint: `${API_URL}/patient/radiology/${id}/upload`,
        chunkSize: 2000000,
        headers: function (file) {
          return {
            "X-Api-Key": "du7lik773nspohl0",
            Authorization: `Bearer ${localStorage.token}`,
          };
        },
        formData: true,
        fieldName: "files[]",
      })
      .on("complete", ({ failed, successful }) => {
        if (failed.length) {
          console.log("failed");
        }
        if (successful.length) {
          const radiologyItem = localStorage.radiologyItem
            ? JSON.parse(localStorage.radiologyItem)
            : null;
          if (radiologyItem) {
            radiologyItem.procedure_date = moment(new Date()).format("X");
            updateRadiology(radiologyItem, id, toast, history, true);
          }
          if (procedureDate) {
            // console.log(procedureDate);
            setProcedureDate(moment(new Date()).format("X"));
          }
          toast({
            title: "Your files uploaded successfully",
            status: "success",
            duration: 7000,
            isClosable: true,
            position: "top-right",
          });
        }
      });
  });
  return (
    <div className="col-md-10 col-12">
      <Dashboard
        uppy={uppy}
        proudlyDisplayPoweredByUppy={false}
        showProgressDetails={true}
        fileManagerSelectionType="folders"
        disabled={id ? false : true}
        width="100%"
        className={colorMode === "dark" ? "uppy-dark" : ""}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { updateRadiology })(Uploader);
