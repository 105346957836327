import React, { useState, useEffect } from "react";
import { style } from "../../../global/style";
import {
  Text,
  Box,
  Image,
  Circle,
  Button,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { connect } from "react-redux";
import chest from "../../../assets/chest.png";
import contact from "../../../assets/contact.png";
import hospital from "../../../assets/hospital.png";
import { motion } from "framer-motion";

import {
  pageTransition,
  pageVariants,
} from "../../../global/Animations/Animations";
import AppointmentDatePicker from "./Requestes/Appointment/AppointmentDatePicker";
import HospitalModael from "./Requestes/Hospital/HospitalModael";
import {
  closePatientconsultation,
  requestAppointmntsFromConsultant,
  getPatientOneConsultation,
} from "../../../store/actions";
import AlertComponent from "../../../global/Alert.Component";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { filterString } from "../consultationChild";
import DoctorInfoModal from "../doctorInfoModal/doctorInfoModal";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";
import moment from "moment";
import { GetSpecialityIcon } from "../../../global/GetSpecialityIcon";

const OverView = ({
  Consultation,
  consultationTypes,
  medicalReport,
  history,
  appointment,
  closePatientconsultation,
  requestAppointmntsFromConsultant,
  requestdAppointment,
  getPatientOneConsultation,
}) => {
  const PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY;
  const stripeTestPromise = loadStripe(PUBLIC_KEY);

  const [consultation, setConsultation] = useState(null);
  const [ConsultationType, setConsultationType] = useState([]);

  const toast = useToast();
  const { colorMode } = useColorMode();
  useEffect(() => {
    // closePatientconsultation(719, toast, history);
    if (Consultation) setConsultation(Consultation);
  }, [Consultation]);

  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);

  const closeConsultation = () => {
    closePatientconsultation(consultation.id, toast, history);
  };

  const renderData = () => {
    if (Consultation) {
      return (
        <>
          <Box
            as={motion.div}
            initial="out"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className=" col-12 p-2 py-4 shadow-sm rounded row m-0"
            bg={checkBgColorMode(colorMode)}
          >
            <div className="col-12 p-2 px-3">
              <Text
                fontSize="2xl"
                color={checkColorMode(colorMode)}
                fontWeight="bold"
              >
                {Consultation.symptoms}
              </Text>
              <Text fontSize="xs" color="smallTextGray">
                {moment
                  .unix(Consultation.createdAt)
                  .format("DD/MM/YYYY - hh:mm A")}
              </Text>
            </div>

            <div className="col-12 row flex-wrap m-0 my-3 align-items-center justify-content-between">
              <div className="col-md-6 col-12 row flex-wrap m-0 p-0 my-3">
                <div className="col-md-6 d-flex align-items-center m-0 p-0 my-2">
                  <Circle size="40px" bg={checkColorMode(colorMode)}>
                    {Consultation.consultationTypeId ? (
                      <img
                        className="image-fluid"
                        src={GetSpecialityIcon(
                          Number(Consultation.consultationTypeId)
                        )}
                        alt="chest"
                      />
                    ) : (
                      ""
                    )}
                  </Circle>

                  <Box className="p-0 ms-2">
                    <Text color="gray.400" fontSize="13px" fontWeight="600">
                      Speciality
                    </Text>
                    <Text fontSize="12px" fontWeight="700">
                      {ConsultationType.length
                        ? ConsultationType.find(
                            (element) =>
                              element.id === Consultation.consultationTypeId
                          ).name
                        : ""}
                    </Text>
                  </Box>
                </div>
                {Consultation.consultant.name ? (
                  <div className="col-md-6 d-flex align-items-center m-0 p-0 my-2">
                    <DoctorInfoModal doctor={Consultation.consultant} />
                  </div>
                ) : null}
              </div>

              <div className="col-md-3 text-end p-0">
                <Box
                  className={`pending text-center rounded`}
                  textTransform="capitalize"
                >
                  {filterString(Consultation.statusString) ===
                  "Appointment passed"
                    ? "Appointment finished"
                    : filterString(Consultation.statusString)}
                </Box>
              </div>
            </div>
            <div className="col-12 rounded ">
              <Box
                boxShadow="xs"
                className="rounded p-3"
                bg={style.smallTextGrayLight}
                border={`1px solid ${style.smallTextGrayLightBorder}`}
              >
                {Consultation.symptomsExplained}
              </Box>
            </div>
          </Box>

          {medicalReport && !appointment && consultation?.status !== "done" ? (
            <Box
              bg={checkBgColorMode(colorMode)}
              className="col-12  p-2 py-4 shadow-sm rounded row m-0 mt-3 justify-content-center align-items-center"
            >
              <Box className="col-md-4 col-12">
                <Image src={contact} alt="Dan Abramov" className="img-fluid" />
              </Box>
              <Box className="col-md-8 col-12 row align-items-center">
                <Text
                  fontSize="2xl"
                  fontWeight="semibold"
                  className="col-12 my-2"
                >
                  Contact with the doctor
                </Text>

                <Text
                  className="col-12 my-2"
                  fontSize={13}
                  fontWeight="semibold"
                  color={style.smallTextGray}
                >
                  Book an appointment with the Doctor now
                </Text>

                <Box className="col-12 row m-0 justify-content-between">
                  {/* <Text
                    className="rounded me-3"
                    bg="gray.100"
                    w="260px"
                    p="13px"
                  >
                    <Text fontWeight="semibold" fontSize={15} className="ms-4">
                      <span className="mx-4">
                        <i className="fas fa-pound-sign"></i>
                      </span>{" "}
                      You Pay: 250GBP
                    </Text>
                  </Text> */}

                  {/* medical-report-added */}
                  <Box className="col-md-5">
                    {Consultation?.status === "schedule-updated" && (
                      <Elements stripe={stripeTestPromise}>
                        <AppointmentDatePicker
                          consultant={
                            Consultation ? Consultation.consultant : null
                          }
                          consultationId={consultation ? consultation.id : null}
                          appointment={appointment}
                          consultation={Consultation}
                          fromOverView={true}
                        />
                      </Elements>
                    )}

                    {Consultation?.status === "medical-report-added" && (
                      <Button
                        color="#fff"
                        size="lg"
                        background={checkColorMode(colorMode)}
                        _hover={{
                          bg: checkColorModeHover(colorMode),
                          color: "#fff",
                        }}
                        isLoading={requestdAppointment.loading}
                        onClick={() => {
                          requestAppointmntsFromConsultant(
                            consultation.id,
                            toast,
                            getPatientOneConsultation
                          );
                        }}
                        w={"100%"}
                        minW={"230px"}
                      >
                        {"Request Appointment"}
                      </Button>
                    )}

                    <Box className="m-0 p-0 mt-3 ">
                      <AlertComponent
                        removeOne={closeConsultation}
                        nameForAlert={"consultation"}
                        consultation={true}
                        fromOverView={true}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
          {/*  &&
          consultation.status === "finished_appointment" */}
          {medicalReport &&
          appointment &&
          consultation &&
          consultation.status === "finished_appointment" ? (
            <Box
              bg={checkBgColorMode(colorMode)}
              className="col-12 p-2 py-4 shadow-sm rounded row m-0 mt-3 align-items-center justify-content-center"
            >
              <Box className="col-md-4 text-center">
                <Image src={hospital} alt="Dan Abramov" className="img-fluid" />
              </Box>
              <Box className="col-md-8 row align-items-center">
                <Text fontSize="2xl" fontWeight="semibold" className="col-12 ">
                  Contact a hospital
                </Text>

                <Text
                  className="col-12"
                  fontSize={13}
                  fontWeight="semibold"
                  color={style.smallTextGray}
                >
                  If you need more help contact a hospital
                </Text>

                <Box className="col-12 d-flex flex-wrap m-0 mt-2 justify-content-between">
                  <Box className="my-2">
                    <HospitalModael
                      ConsultationId={Consultation ? Consultation.id : null}
                      history={history}
                    />
                  </Box>
                  <Box className="my-2">
                    <AlertComponent
                      removeOne={closeConsultation}
                      nameForAlert={"consultation"}
                      consultation={true}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
        </>
      );
    } else {
      return "Something Wrong";
    }
  };
  return <div className="col-12 row m-0 mt-4">{renderData()}</div>;
};

const mapStateToProps = (state) => {
  return {
    consultationTypes: state.consultationTypes,
    requestdAppointment: state.requestdAppointment,
  };
};
export default connect(mapStateToProps, {
  closePatientconsultation,
  requestAppointmntsFromConsultant,
  getPatientOneConsultation,
})(OverView);
