import axios from "axios";

import { handeleSuccess, handeleError } from "../toast";

const API_URL = process.env.REACT_APP_API_URL;

export const getPatientLapResult = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_LAPRESULT_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/lab-results?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/lab-results/`,
      });
      dispatch({
        type: "GET_LAPRESULT_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_LAPRESULT_ERROR",
        payload: error,
      });
    }
  };
};
export const getPatientLapResultForPagination = (
  pageNumber,
  patientId,
  informationShareToken
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_LAPRESULT_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/lab-results?informationShareToken=${informationShareToken}&page=${pageNumber}`
            : `${API_URL}/patient/lab-results?page=${pageNumber}`,
      });
      dispatch({
        type: "GET_LAPRESULT_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_LAPRESULT_ERROR",
        payload: err,
      });
    }
  };
};
export const getOneLapResult = (id, toast, history, patientId, sharedToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_LAPRESULT_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && sharedToken
            ? `${API_URL}/patient/${patientId}/lab-results/show/${id}?informationShareToken=${sharedToken}`
            : `${API_URL}/patient/lab-results/show/${id}`,
      });
      dispatch({
        type: "GET_ONE_LAPRESULT_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast, null, "lab result");
      history.push("/medical-record/lab-result");
      dispatch({
        type: "GET_ONE_LAPRESULT_ERROR",
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};

export const postPatientLapResult = (
  data,
  history,
  toast,
  setChildData,
  getAllNotifications,
  getUnReadNotifications
) => {
  let formData = new FormData();
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file, file.name));
  }
  formData.append("title", data.title);
  return async (dispatch) => {
    dispatch({
      type: "POST_LAPRESULT_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/lab-results`,

        data: formData,
      });
      if (res) {
        handeleSuccess("Your lab result has been added sucsessfuly", toast);
        getAllNotifications();
        getUnReadNotifications();
        history ? history.goBack() : setChildData(res.data);
        dispatch({
          type: "POST_LAPRESULT_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "POST_LAPRESULT_ERROR",
        payload: error,
      });
    }
  };
};

export const updateLapResult = (data, id, history, toast) => {
  let formData = new FormData();
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file));
  }
  formData.append("title", data.title);

  return async (dispatch) => {
    dispatch({
      type: "UPDATE_LAPRESULT_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/lab-results/${id}`,
        data: formData,
      });
      if (res) {
        handeleSuccess("Your lab result has been Updated sucsessfuly", toast);
        history.goBack();
        dispatch({
          type: "UPDATE_LAPRESULT_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_LAPRESULT_ERROR",
        payload: error,
      });
    }
  };
};

export const deleteLapResult = (id, toast, history, goTo) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_LAPRESULT_LOADING",
    });
    try {
      const res = await axios({
        method: "delete",
        url: `${API_URL}/patient/lab-results/${id}`,
      });
      if (res) {
        handeleSuccess("Your lab result has been deleted successfully", toast);
        history.push(goTo);
        dispatch({
          type: "DELETE_LAPRESULT_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "DELETE_LAPRESULT_ERROR",
        payload: error,
      });
    }
  };
};
