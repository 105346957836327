import React from "react";
import { useParams } from "react-router-dom";
import Requestes from "./Requestes/Requestes";
import MedicalReport from "./Requestes/MedicalReport";
import Questions from "./Requestes/questions/Questions.js";
import HospitalOffers from "./Requestes/Hospital/HospitalOffers";
import Appointments from "./Requestes/Appointment/Appointment";
import NotFound from "../../NotFound/NotFound";

const Child = ({
  requestes,
  medicalReport,
  questions,
  history,
  consultation,
  patientProfile,
}) => {
  let { overViewChild } = useParams();
  const renderComponent = () => {
    switch (overViewChild) {
      case "requestes":
        return <Requestes requestes={requestes} />;
      case "medical-report":
        return (
          <MedicalReport
            medicalReport={medicalReport}
            consultation={consultation}
            patient={patientProfile}
          />
        );
      case "questions":
        return <Questions questions={questions} history={history} />;
      case "appointments":
        return <Appointments consultation={consultation} />;
      case "hospital-ofers":
        return (
          <HospitalOffers
            consultation={consultation}
            consultationId={consultation.id}
            history={history}
          />
        );
      default:
        return <NotFound history={history} />;
    }
  };

  return <div>{renderComponent()}</div>;
};

export default Child;
