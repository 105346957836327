import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { InputControl, SelectControl } from "formik-chakra-ui";
import { Text, Button, useToast, useColorMode, Box } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import TelephoneInput from "../../SignUp/phoneNumber";
import Input from "../../../global/Input/Input";
import { connect } from "react-redux";
import { dateYYY_dd_mm } from "../../../global/ConverDate";
import {
  updatePatientProfile,
  getPatientProfile,
  bloodTypeList,
} from "../../../store/actions";
import { checkBgColorMode } from "../../../global/changeColor.js";

const SubmitSchema = Yup.object().shape({
  email: Yup.string().email().required("required"),
  mobile: Yup.string().min(10).required("required"),
  dateOfBirth: Yup.string().min(3).required("required"),
  gender: Yup.string().min(3).required("required"),
  bloodType: Yup.string().required("required"),
});
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const EditMyInfo = ({
  history,
  PatientProfile,
  updatePatientProfile,
  getPatientProfile,
  bloodTypeList,
  blood_Types,
}) => {
  let query = useQuery().get("patientID");
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [bloodTypes, setBloodTypes] = useState([]);
  const [initValue, setInitValue] = useState({
    email: "",
    mobile: "",
    dateOfBirth: "",
    gender: "",
    bloodType: "",
  });
  useEffect(() => {
    setBloodTypes(blood_Types);
  }, [blood_Types]);
  useEffect(() => {
    if (query && PatientProfile) {
      console.log(PatientProfile);
      bloodTypeList();
      setInitValue((initValue) => {
        return {
          ...initValue,
          email: PatientProfile.email,
          mobile: PatientProfile.mobile,
          dateOfBirth: dateYYY_dd_mm(PatientProfile.dateOfBirth),
          gender: PatientProfile.gender,
          bloodType: PatientProfile.bloodType
            ? JSON.stringify(PatientProfile.bloodType)
            : "",
        };
      });
    }
  }, [query, PatientProfile, bloodTypeList]);

  const onFormSubmit = (value) => {
    const dateStimp = new Date(value.dateOfBirth).getTime() / 1000;
    const bloodType = value.bloodType ? JSON.parse(value.bloodType) : false;
    const data = {
      firstName: PatientProfile.firstName,
      middleName: PatientProfile.middleName,
      lastName: PatientProfile.lastName,
      email: value.email,
      mobile: value.mobile,
      dateOfBirth: dateStimp,
      gender: value.gender,
      blood_types_id: (bloodType && bloodType.id) || undefined,
    };
    if (value.mobile.replace(/ /g, "") !== PatientProfile.mobile) {
      updatePatientProfile(
        data,
        history,
        toast,
        "/verify_mobile?from=profile",
        true
      ).then(() => {
        return getPatientProfile();
      });
    } else {
      updatePatientProfile(
        data,
        history,
        toast,
        "/profile/my_info",
        false
      ).then(() => {
        return getPatientProfile();
      });
    }

    //  else {
    //   localStorage.setItem("registrUser", JSON.stringify(PatientProfile));
    //   history.push("/verify_mobile");
    // }
  };
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="EditMyInfo  rounded shadow-sm p-4  d-flex flex-wrap"
    >
      <Formik
        initialValues={initValue}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={SubmitSchema}
      >
        <Form>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Text color="secondary" fontSize="lg" className="fw-bold">
              {" "}
              My Info
            </Text>
            <div>
              <Button
                width={"50px"}
                height="50px"
                bg="#f3e9eb"
                _hover={{ bg: "#f3d3da" }}
                fontSize="20px"
                color="#D42C51"
                className="me-2"
              >
                <Link to={"/profile/my_info"} className="nav-link text-error">
                  <i className="fas fa-times"></i>
                </Link>
              </Button>
              <Button
                width={"50px"}
                height="50px"
                bg="secondary"
                _hover={{ bg: "secondary_hover" }}
                fontSize="20px"
                type="submit"
                color="#fff"
              >
                <i className="fas fa-save"></i>
              </Button>
            </div>
          </div>

          <div className="col-12 row">
            <div className="col-md-6 mt-2">
              <InputControl
                name="email"
                label="E-Mail"
                inputProps={{ placeholder: "E-Mail" }}
              />
            </div>
            <div className="col-md-6  ">
              <label className="w-100 login-label" htmlFor="mobile">
                Phone Number
              </label>
              <div className="pt-2">
                <TelephoneInput name="mobile" />
                <ErrorMessage
                  name="mobile"
                  component="div"
                  className="text-danger errorMsg"
                />
              </div>
            </div>

            <div className="col-md-6">
              <SelectControl
                label="Gender"
                name="gender"
                selectProps={{ placeholder: "Gender" }}
                className="pt-3"
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </SelectControl>
            </div>

            <div className="col-md-6 mt-2">
              <Input
                type="date"
                className={`${
                  colorMode === "dark" ? "css-1ipn0iq" : "css-k7r2wc"
                } mt-2`}
                name="dateOfBirth"
                label="Date of Birth"
              />
            </div>

            <div className="col-md-6 mt-2">
              <SelectControl
                label="Blood type"
                name="bloodType"
                selectProps={{ placeholder: "Blood type" }}
                className="pt-3"
              >
                {bloodTypes.length
                  ? bloodTypes.map((type, index) => (
                      <option value={JSON.stringify(type)} key={index}>
                        {type.name}
                      </option>
                    ))
                  : ""}
              </SelectControl>
            </div>
          </div>
        </Form>
      </Formik>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    PatientProfile: state.PatientProfile.data,
    updatePatientProfile: state.updatePatientProfile.data,
    blood_Types: state.bloodTypes.data,
  };
};
export default connect(mapStateToProps, {
  updatePatientProfile,
  getPatientProfile,
  bloodTypeList,
})(EditMyInfo);
