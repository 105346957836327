import axios from "axios";
import { authConfige } from "../authConfige";

const API_URL = process.env.REACT_APP_API_URL;

export const consultationTypeList = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_CONSULTATION_TYPE_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/consultation-type/`,
        headers: authConfige,
      });
      dispatch({
        type: "GET_CONSULTATION_TYPE_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_CONSULTATION_TYPE_ERROR",
        payload: err,
      });
    }
  };
};
export const patientTypeList = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_PATIENT_TYPE_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient-type/`,
        headers: authConfige,
      });
      dispatch({
        type: "GET_PATIENT_TYPE_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_PATIENT_TYPE_ERROR",
        payload: err,
      });
    }
  };
};
export const bloodTypeList = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_BLOOD_TYPE_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/blood-types`,
        headers: authConfige,
      });
      dispatch({
        type: "GET_BLOOD_TYPE_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_BLOOD_TYPE_ERROR",
        payload: err,
      });
    }
  };
};

export const countriesList = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_COUNTRIES_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/countries`,
        headers: authConfige,
      });
      dispatch({
        type: "GET_COUNTRIES_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_COUNTRIES_ERROR",
        payload: err,
      });
    }
  };
};
