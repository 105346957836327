import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import moment from "moment";
import { checkBgColorMode, checkColorMode } from "../../global/changeColor";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -2000, duration: 5, delay: 10 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000, duration: 5, delay: 3 },
    },
  },
};
const Card = ({ url, item }) => {
  const { colorMode } = useColorMode();
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  return (
    <motion.div variants={variants}>
      <Box
        bg={checkBgColorMode(colorMode)}
        className="mb-2 shadow d-flex align-items-center p-3 rounded"
      >
        <Box
          color={checkColorMode(colorMode)}
          _hover={{ color: checkColorMode(colorMode) }}
          as={Link}
          to={
            sharedToken
              ? `${url}/${item.id}?sharedToken=${sharedToken}`
              : `${url}/${item.id}`
          }
          className="w-75 nav-link"
        >
          <h5 className="h5 fw-bold mb-0">
            {item.title ? item.title : item.value}
          </h5>
          <div className="light-gray" style={{ fontSize: "13px" }}>
            {moment
              .unix(item.updated_at || item.updatedAt)
              .format("DD/MM/YYYY hh:mm A")}
          </div>
        </Box>
        {!sharedToken && (
          <div className=" w-25 text-end d-flex justify-content-end ">
            <Menu>
              <MenuButton as={Button} variant="ghost">
                <i className="fas fa-ellipsis-h"></i>
              </MenuButton>
              <MenuList className="menu-condition shadow">
                <MenuItem>
                  <Box
                    as={Link}
                    to={`${url}/${item.id}`}
                    className="nav-link p-0 w-100 "
                    color={checkColorMode(colorMode)}
                    _hover={{
                      color: checkColorMode(colorMode),
                    }}
                  >
                    <span className="me-3">
                      <i className="fas fa-pencil-alt"></i>
                    </span>
                    Edit
                  </Box>
                </MenuItem>
                <MenuItem>
                  <Box
                    as={Link}
                    to={`${url}/${item.id}`}
                    className="nav-link p-0 w-100 "
                    color={"red.500"}
                    _hover={{
                      color: "red.300",
                    }}
                  >
                    <span className="me-3 text-error">
                      <i className="fas fa-trash"></i>
                    </span>
                    Delete
                  </Box>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        )}
      </Box>
    </motion.div>
  );
};

export default Card;
