import React from "react";
import {
  Text,
  Box,
  Image,
  Circle,
  useColorMode,
  Button,
} from "@chakra-ui/react";
import contact from "../../../../../assets/contact.svg";
import moment from "moment";
import AppointmentDatePicker from "./AppointmentDatePicker";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DoctorInfoModal from "../../../doctorInfoModal/doctorInfoModal";
import {
  checkColorMode,
  checkBgColorMode,
} from "../../../../../global/changeColor";
const Appointments = ({ consultation }) => {
  const PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY;
  const stripeTestPromise = loadStripe(PUBLIC_KEY);
  const { colorMode } = useColorMode();

  const startTime = moment
    .unix(consultation.ConsultantAppointment.appointment_time)
    .format("YYYY-MM-DD HH:mm");

  const EndTime = moment
    .unix(consultation.appointmentEndTime)
    .format("YYYY-MM-DD HH:mm");

  const scheduleEnd = moment
    .unix(consultation.ConsultantAppointment.appointment_time)
    .subtract(48, "hours")
    .format("YYYY-MM-DD HH:mm");
  const checkMeetingTime = moment().isSameOrAfter(startTime); //light it
  const checkMeetingEndTime = moment().isSameOrAfter(EndTime); //remove it

  const check_24_Hours = (times) => {
    if (times) {
      if (moment().isSameOrAfter(scheduleEnd) || times.status === "done") {
        return (
          <Box
            className={`done text-center rounded w-100 ${
              colorMode === "dark" ? "done-dark" : ""
            }`}
          >
            {times.statusString === "Appointment passed"
              ? "Appointment finished"
              : times.statusString}
          </Box>
        );
      } else {
        return (
          <Elements stripe={stripeTestPromise}>
            <Box className="d-flex flex-wrap justify-content-around">
              <Box className="mt-2">
                <AppointmentDatePicker
                  consultant={consultation.consultant}
                  consultationId={consultation.id}
                  btnName="Reschedule"
                  consultation={consultation}
                />
              </Box>
            </Box>
          </Elements>
        );
      }
    }
  };
  return (
    <Box className="row mt-4">
      <Text
        fontSize="xl"
        fontWeight="semibold"
        color={checkColorMode(colorMode)}
        className="col-12"
      >
        Appointment
      </Text>

      <Box className=" col-12 ">
        <Box
          bg={checkBgColorMode(colorMode)}
          className=" p-2 py-4 shadow-sm rounded row  m-0 mt-4"
        >
          <Box className="col-lg-4 col-12 d-flex justify-content-center">
            <Image src={contact} alt="Dan Abramov" className="img-fluid" />
          </Box>
          <Box className="col-lg-8 row m-0 align-items-center justify-content-center">
            <Text
              fontSize="2xl"
              fontWeight="semibold"
              className="col-lg-12 my-2"
            >
              {moment
                .unix(consultation.ConsultantAppointment.appointment_time)
                .format("dddd MMM DD , yyyy - hh:mm A")}
            </Text>
            <Text
              fontSize="sm"
              fontWeight="semibold"
              className="col-12 my-2"
              color={"smallTextGray"}
            >
              {"Your Appointment"}
            </Text>
            <Box className="col-12 row m-0 my-2">
              <Box className="col-md-4 row m-0 align-items-center p-0 my-2">
                <DoctorInfoModal doctor={consultation.consultant} />
              </Box>
              <Box className="col-md-4 row m-0 align-items-center p-0 my-2">
                <Circle
                  size={"40px"}
                  color="#fff"
                  className="col-4"
                  bg={checkColorMode(colorMode)}
                >
                  <i className="fas fa-id-card"></i>
                </Circle>
                <Box className="col-8">
                  <Text
                    fontSize="13px"
                    color={"smallTextGray"}
                    fontWeight="semibold"
                  >
                    GMC Number
                  </Text>
                  <Text fontSize="11px" fontWeight="semibold">
                    #{consultation.consultant.gmc_number}
                  </Text>
                </Box>
              </Box>
              <Box className="col-md-4 row m-0 align-items-center justify-content-end my-2 p-0">
                {checkMeetingEndTime === true ? (
                  ""
                ) : (
                  <Box className="my-2 text-center">
                    <Button
                      minW={"140.17px"}
                      bg={colorMode === "dark" ? null : "secondary"}
                      _hover={{
                        bg: colorMode === "dark" ? null : "secondary_hover",
                      }}
                      size="lg"
                      to={consultation.ConsultantAppointment.online_meeting_url}
                      target="_blank"
                      disabled={!checkMeetingTime}
                      pointerEvents={checkMeetingTime === false ? "none" : ""}
                    >
                      <a
                        className="text-white"
                        href={
                          consultation?.ConsultantAppointment
                            ?.online_meeting_data?.joinUrl
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Join appointment{" "}
                      </a>
                    </Button>
                  </Box>
                )}
                <Box className="text-center d-flex justify-content-center">
                  {check_24_Hours(consultation)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Appointments;
