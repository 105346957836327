import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Circle,
  Button,
  useToast,
  useColorMode,
} from "@chakra-ui/react";

import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { style } from "../../../../../global/style";
import {
  getOneHospitalsQuotations,
  contactToAdmin,
  closePatientconsultation,
} from "../../../../../store/actions";

import moment from "moment";

import AlertComponent from "../../../../../global/Alert.Component";
import {
  checkColorMode,
  checkBgColorMode,
} from "../../../../../global/changeColor";
const ViewOneHispital = ({
  getOneHospitalsQuotations,
  HospitalQuotation,
  consultation,
  contactToAdmin,
  closePatientconsultation,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [oneHospitalOffer, setOneHospitalOffer] = useState(null);
  const [oneHospitalOfferAdmin, setOneHospitalOfferAdmin] = useState(false);
  const params = useParams();
  const toast = useToast();
  const { colorMode } = useColorMode();

  const closeConsultation = () => {
    closePatientconsultation(consultation.id, toast, history);
  };

  useEffect(() => {
    if (consultation.hospitalReservation) {
      getOneHospitalsQuotations(consultation.hospitalReservation.id, params.id);
    }
  }, [getOneHospitalsQuotations, params, consultation]);

  useEffect(() => {
    const { loading, error, data } = HospitalQuotation;
    setLoading(loading);
    setError(error);
    if (data) {
      if (data.status === "admin-contacted") {
        setOneHospitalOfferAdmin(data);
      }
      setOneHospitalOffer(data);
    }
  }, [HospitalQuotation]);

  const renderView = () => {
    if (loading) return "loading...";
    if (error) return "error";
    if (oneHospitalOffer) {
      return (
        <Box className="">
          <Text
            fontSize="xl"
            fontWeight="semibold"
            color={checkColorMode(colorMode)}
            className="mt-4 mb-2"
          >
            {oneHospitalOfferAdmin ? "Your booking" : "Hospital offer"}
          </Text>

          {oneHospitalOfferAdmin ? (
            <>
              <Box
                className="col-12 p-0  shadow-sm rounded row m-0 "
                overflow="hidden"
                bg={checkBgColorMode(colorMode)}
              >
                <Box className="col-12 row m-0 align-items-center justify-content-between">
                  <Box className="col-md-5 ">
                    <Text fontSize="14px" fontWeight="semibold">
                      {oneHospitalOffer.hospital.name}
                    </Text>
                    <Text fontSize="13px" color={style.smallTextGray}>
                      {`${oneHospitalOffer.hospital.street}, ${oneHospitalOffer.hospital.city}, ${oneHospitalOffer.hospital.country}`}
                    </Text>
                  </Box>
                  <Box
                    className="col-md-5 row m-0 my-5 p-0 justify-content-end align-items-center"
                    fontSize="12px"
                    color={checkColorMode(colorMode)}
                    fontWeight="semibold"
                  >
                    <a
                      href={oneHospitalOffer.fileLocation}
                      target="_blanck"
                      className="col-4 p-0"
                      style={{
                        zIndex: 1,
                        position: "relative",
                      }}
                    >
                      <span style={{ fontSize: "15px" }} className="me-1">
                        <i className="fas fa-file-pdf"></i>
                      </span>{" "}
                      Quotation PDF
                    </a>

                    <Text className="col-4">{oneHospitalOffer.price} GBP</Text>
                  </Box>
                </Box>
                <hr className="mt-3" style={{ color: "gray" }} />
                <Box className="col-12 row m-0 align-items-center mt-3">
                  <Box className="col-md-6 row m-0 my-2 p-0 justify-content-between">
                    <Box className="col-6 d-flex p-0 align-items-center">
                      <Circle
                        fontSize={"10px"}
                        bg={checkColorMode(colorMode)}
                        color="#fff"
                        size={9}
                        className="me-2"
                      >
                        <i className="far fa-flag"></i>
                      </Circle>
                      <Box fontSize={"13px"}>
                        <Text color={style.smallTextGray}>
                          Earliest check in
                        </Text>
                        <Text fontWeight="semibold">
                          {moment
                            .unix(oneHospitalOffer.earliestCheckInTime)
                            .format("DD/MM/yyyy")}
                        </Text>
                      </Box>
                    </Box>
                    <Box className="col-6 d-flex p-0 align-items-center">
                      <Circle
                        fontSize={"10px"}
                        bg={checkColorMode(colorMode)}
                        color="#fff"
                        size={9}
                        className="me-2"
                      >
                        <i className="far fa-calendar-alt"></i>
                      </Circle>
                      <Box fontSize={"13px"}>
                        <Text color={style.smallTextGray}>Duration</Text>
                        <Text fontWeight="semibold">{`${oneHospitalOffer.durationInDays} Days`}</Text>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="col-md-6 row m-0 my-2 p-0 justify-content-between">
                    <Box className="col-md-6 my-2 d-flex p-0 align-items-center">
                      <Circle
                        fontSize={"10px"}
                        bg={checkColorMode(colorMode)}
                        color="#fff"
                        size={9}
                        className="me-2"
                      >
                        <i className="fas fa-phone-alt"></i>
                      </Circle>
                      <Box fontSize={"13px"}>
                        <Text color={style.smallTextGray}>Phone number</Text>
                        <Text fontWeight="semibold">
                          {oneHospitalOffer.hospital.phone}
                        </Text>
                      </Box>
                    </Box>
                    <Box className="col-md-6 my-2 d-flex p-0 align-items-center">
                      <Circle
                        fontSize={"10px"}
                        bg={checkColorMode(colorMode)}
                        color="#fff"
                        size={9}
                        className="me-2"
                      >
                        <i className="fas fa-envelope"></i>
                      </Circle>
                      <Box fontSize={"13px"}>
                        <Text color={style.smallTextGray}>Email</Text>
                        <Text fontWeight="semibold">
                          {oneHospitalOffer.hospital.email}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {consultation.status === "done" ? null : (
                <Box className=" mt-5 text-end">
                  <AlertComponent
                    removeOne={closeConsultation}
                    nameForAlert={"consultation"}
                    consultation={true}
                  />
                </Box>
              )}
            </>
          ) : (
            <Box
              bg={colorMode === "dark" ? "black" : "white"}
              className="col-12 p-2 py-4 shadow-sm rounded row m-0 mt-3"
            >
              <Box className="col-12 row m-0 align-items-center">
                <Box className="col-md-4 p-0 my-2">
                  <Text fontSize="14px" fontWeight="semibold">
                    {oneHospitalOffer.hospital.name}
                  </Text>
                  <Text fontSize="13px" color={style.smallTextGray}>
                    {`${oneHospitalOffer.hospital.street}, ${oneHospitalOffer.hospital.city}, ${oneHospitalOffer.hospital.country}`}
                  </Text>
                </Box>

                <Box className="col-md-4 row m-0 my-2 p-0 justify-content-between">
                  <Box className="col-md-6 d-flex p-0 my-2 align-items-center">
                    <Circle
                      fontSize={"10px"}
                      bg={style.primary}
                      color="#fff"
                      size={9}
                      className="me-2"
                    >
                      <i className="far fa-calendar-alt"></i>
                    </Circle>
                    <Box fontSize={"13px"}>
                      <Text color={style.smallTextGray}>Earliest check in</Text>
                      <Text fontWeight="semibold">
                        {moment
                          .unix(oneHospitalOffer.earliestCheckInTime)
                          .format("DD/MM/yyyy")}
                      </Text>
                    </Box>
                  </Box>
                  <Box className="col-md-6 my-2 d-flex p-0 align-items-center">
                    <Circle
                      fontSize={"10px"}
                      bg={style.primary}
                      color="#fff"
                      size={9}
                      className="me-2"
                    >
                      <i className="far fa-calendar-alt"></i>
                    </Circle>
                    <Box fontSize={"13px"}>
                      <Text color={style.smallTextGray}>Duration</Text>
                      <Text fontWeight="semibold">{`${oneHospitalOffer.durationInDays} Days`}</Text>
                    </Box>
                  </Box>
                </Box>

                <Box
                  className="col-md-4 row m-0 my-2 p-0 justify-content-between align-items-center"
                  fontSize="12px"
                  color={style.primary}
                  fontWeight="semibold"
                >
                  <a
                    href={oneHospitalOffer.fileLocation}
                    target="_blanck"
                    className="col-md-4 my-2 p-0"
                    style={{
                      color: style.primary,
                      zIndex: 1,
                      position: "relative",
                    }}
                  >
                    <span style={{ fontSize: "15px" }} className="me-1">
                      <i className="fas fa-file-pdf"></i>
                    </span>{" "}
                    Quotation PDF
                  </a>

                  <Text className="col-md-4 col-6">
                    {oneHospitalOffer.price} GBP
                  </Text>
                  <Button
                    color="#fff"
                    bg={style.primary}
                    _hover={{ bg: style.primary_hover }}
                    width="35px"
                    height="35px"
                    onClick={() =>
                      contactToAdmin(
                        consultation.hospitalReservation.id,
                        params.id,
                        toast
                      )
                    }
                  >
                    <i className="fas fa-phone-alt"></i>
                  </Button>
                </Box>
              </Box>
              {oneHospitalOffer.note ? (
                <Box className="p-3">
                  <Text
                    bg={style.bg_Light_Gray}
                    border={`1px solid ${style.bg_Light_Gray_Hover}`}
                    className="rounded p-3"
                    fontSize={"sm"}
                    fontWeight="semibold"
                  >
                    {oneHospitalOffer.note}
                  </Text>
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      );
    } else {
      return "";
    }
  };

  return renderView();
};

const mapStateToProps = (state) => {
  return {
    HospitalQuotation: state.OneHospitalQuotation,
  };
};
export default connect(mapStateToProps, {
  getOneHospitalsQuotations,
  contactToAdmin,
  closePatientconsultation,
})(ViewOneHispital);
