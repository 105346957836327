const INIT_HOSOITAL_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const getHospitalsQuotationsReducer = (
  state = INIT_HOSOITAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_HOSPITAL_RESERVATION_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_HOSPITAL_RESERVATION_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_HOSPITAL_RESERVATION_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneHospitalsQuotationsReducer = (
  state = INIT_HOSOITAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_ONE_HOSPITAL_RESERVATION_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_HOSPITAL_RESERVATION_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_HOSPITAL_RESERVATION_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const addHospitalReservationReducer = (
  state = INIT_HOSOITAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case "POST_HOSPITAL_RESERVATION_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_HOSPITAL_RESERVATION_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_HOSPITAL_RESERVATION_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const contactToAdminReducer = (
  state = INIT_HOSOITAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case "CONTACT_ADMIN_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "CONTACT_ADMIN_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "CONTACT_ADMIN_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
