import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOneMedical, deleteMedical } from "./../../../store/actions";
import { useLocation, useParams } from "react-router-dom";
import View from "../Repeated-Component/View";
import { useToast } from "@chakra-ui/react";

const ViewMedical = ({ getOneMedical, oneMedical, deleteMedical, history }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [medical, setMedical] = useState(null);
  let params = useParams();
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const toast = useToast();
  const removeMedical = () => {
    deleteMedical(params.id, toast, history, "/medical-record/medical-report");
  };
  useEffect(() => {
    if (params && params.id && params.patientId && sharedToken) {
      getOneMedical(params.id, toast, history, params.patientId, sharedToken);
    } else if (params && params.id && !params.patientId && !sharedToken) {
      getOneMedical(params.id, toast, history);
    }
  }, [getOneMedical, params.id, toast, history, sharedToken]);
  useEffect(() => {
    const { loading, error, data } = oneMedical;
    setLoading(loading);
    setError(error);
    if (data) {
      setMedical(data);
    }
  }, [oneMedical]);
  const renderView = () => {
    return (
      <View
        loading={loading}
        error={error}
        headerText="View Medical Report"
        linkEdite="/medical-record/medical-report/add-medical"
        one={medical}
        params={params}
        removeOne={removeMedical}
        backLink={
          sharedToken
            ? `/medical-report/${params.patientId}/shared-data?sharedToken=${sharedToken}`
            : "/medical-record/medical-report"
        }
        nameForAlert="Medical Report"
      />
    );
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    oneMedical: state.oneMedical,
    deletedMedical: state.deleteMedical,
  };
};
export default connect(mapStateToProps, { getOneMedical, deleteMedical })(
  ViewMedical
);
