const AUTH_IINT_STATE = {
  loading: false,
  error: null,
  user: null,
};

export const loginReducer = (state = AUTH_IINT_STATE, { type, payload }) => {
  switch (type) {
    case "LOGIN_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "LOGIN_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        user: payload,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const signupReducer = (state = AUTH_IINT_STATE, { type, payload }) => {
  switch (type) {
    case "SIGNUP_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "SIGNUP_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        user: payload,
      };
    case "SIGNUP_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
const INIT_LOGOUT_STATR = {
  loading: false,
  error: "",
  message: "",
};

export const logoutReducer = (state = INIT_LOGOUT_STATR, { type, payload }) => {
  switch (type) {
    case "LOGOUT_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "LOGOUT_SUCSES":
      return {
        ...state,
        loading: false,
        error: "",
        message: payload,
      };
    case "LOGOUT_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const forgetPasswordReducer = (
  state = INIT_LOGOUT_STATR,
  { type, payload }
) => {
  switch (type) {
    case "FORGET_PASSWORD_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "FORGET_PASSWORD_SUCSES":
      return {
        ...state,
        loading: false,
        error: "",
        message: payload,
      };
    case "FORGET_PASSWORD_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const patientVerifyMobileReducer = (
  state = INIT_LOGOUT_STATR,
  { type, payload }
) => {
  switch (type) {
    case "VERIFY_MOBILE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "VERIFY_MOBILE_SUCSES":
      return {
        ...state,
        loading: false,
        error: "",
        message: payload,
      };
    case "VERIFY_MOBILE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const resendPatientVerifyMobileReducer = (
  state = INIT_LOGOUT_STATR,
  { type, payload }
) => {
  switch (type) {
    case "RESEND_VERIFY_MOBILE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "RESEND_VERIFY_MOBILE_SUCSES":
      return {
        ...state,
        loading: false,
        error: "",
        message: payload,
      };
    case "RESEND_VERIFY_MOBILE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
