const INIT_ECG_STATR = {
  loading: false,
  error: null,
  data: null,
};

export const getECGReducer = (state = INIT_ECG_STATR, { type, payload }) => {
  switch (type) {
    case "GET_ECG_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ECG_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ECG_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneECGReducer = (state = INIT_ECG_STATR, { type, payload }) => {
  switch (type) {
    case "GET_ONE_ECG_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_ECG_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_ECG_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const postECGReducer = (state = INIT_ECG_STATR, { type, payload }) => {
  switch (type) {
    case "POST_ECG_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_ECG_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_ECG_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const updateECGReducer = (state = INIT_ECG_STATR, { type, payload }) => {
  switch (type) {
    case "UPDATE_ECG_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_ECG_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_ECG_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const deleteECGReducer = (state = INIT_ECG_STATR, { type, payload }) => {
  switch (type) {
    case "DELETE_ECG_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_ECG_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "DELETE_ECG_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
