import React from "react";
import UploadProfilePic from "./uploadProfilePic";
import { connect } from "react-redux";
import { Box, Button, Divider, Text, useColorMode } from "@chakra-ui/react";
import { NavLink, useHistory } from "react-router-dom";
import { patientLogot } from "../../store/actions";
import { checkColorMode, checkColorModeHover } from "../../global/changeColor";

const profileLinks = [
  {
    linkTo: "/profile/my_info",
    text: "My Info",
    icon: "fa fa-user",
  },
  {
    linkTo: "/profile/payment",
    text: " Payment",
    icon: "fa fa-credit-card",
  },
  {
    linkTo: "/profile/change_password",
    text: "Change Password",
    icon: "fa fa-unlock-alt",
  },
];
const ProfileSideNav = ({
  patientProfile,
  patientLogot,
  fromSmallScreen,
  openProfileNav,
  setOpenProfileNav,
}) => {
  let history = useHistory();
  let { colorMode } = useColorMode();
  return (
    <div>
      {openProfileNav ? (
        <Box className="w-100 text-end my-3">
          <Button
            className=""
            color={checkColorMode(colorMode)}
            onClick={() => {
              setOpenProfileNav(!openProfileNav);
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </Box>
      ) : (
        ""
      )}

      <div className=" d-flex justify-content-center w-100 ">
        <UploadProfilePic profile={patientProfile} />
      </div>

      <div className="my-3 w-100 text-center">
        <Text fontSize="lg" fontWeight="semibold" color={"secondary"}>
          {patientProfile.data ? patientProfile.data.fullName : "..."}
        </Text>
      </div>

      <div className="w-100 text-center">
        <Button
          w="180px"
          className=" rounded"
          bg={checkColorMode(colorMode)}
          _hover={{ bg: checkColorModeHover(colorMode) }}
          _active={{ bg: checkColorModeHover(colorMode) }}
          onClick={() => {
            if (fromSmallScreen) {
              setOpenProfileNav(!openProfileNav);
            }
          }}
        >
          <NavLink
            exact
            to={"/profile/share_data"}
            className="nav-link text-white w-100"
          >
            <span className="me-3">
              <i className="fa fa-share"></i>
            </span>
            Share Data
          </NavLink>
        </Button>
      </div>

      <div className="my-4 w-100  fw-600 d-flex justify-content-center">
        <Divider className="w-75" />
      </div>

      <div className="list-wrab w-100 ">
        <ul className="list">
          {profileLinks.map((link, index) => (
            <li
              className={`list-item mb-2 ${
                colorMode === "dark" ? "list-item-dark" : ""
              }`}
              key={index}
              onClick={() => {
                if (fromSmallScreen) {
                  setOpenProfileNav(!openProfileNav);
                }
              }}
            >
              <NavLink
                exact
                activeClassName={
                  colorMode === "dark"
                    ? "active-profile-link-dark"
                    : "active-profile-link"
                }
                to={link.linkTo}
                className={`nav-link rounded w-100 d-flex justify-content-between profile_nav`}
              >
                <div className="d-flex">
                  <Text className="me-3">
                    <i className={link.icon}></i>
                  </Text>
                  <Text fontSize="14px" className="me-1">
                    {link.text}
                  </Text>
                </div>
                <Text>
                  <i className="fas fa-chevron-right"></i>
                </Text>
              </NavLink>
            </li>
          ))}
          <li
            className={`list-item mb-2 ${
              colorMode === "dark" ? "list-item-dark" : ""
            }`}
          >
            <NavLink
              exact
              to="#"
              className="nav-link rounded w-100 d-flex justify-content-between profile_nav "
              onClick={() => {
                patientLogot(history);
              }}
            >
              <div className="d-flex">
                <Text className="me-3">
                  <i className="fas fa-sign-out-alt"></i>
                </Text>
                <Text fontSize="14px" className="me-1">
                  Logout
                </Text>
              </div>
              <Text>
                <i className="fas fa-chevron-right"></i>
              </Text>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    patientProfile: state.PatientProfile,
  };
};
export default connect(mapStateToProps, {
  patientLogot,
})(ProfileSideNav);
