import firebase from "firebase/compat/app"; // Import the Firebase App module (compat version)
import "firebase/compat/auth"; // if you're using Firebase Authentication (compat version)
import "firebase/compat/firestore"; // if you're using Firestore (compat version)
import "firebase/compat/database"; // if you're using Realtime Database (compat version)
import "firebase/compat/storage";
import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBkQX9UzeX08eLpBoUhwdx5Z6l3r24DEnk",
  authDomain: "holdenknight-df392.firebaseapp.com",
  databaseURL: "https://holdenknight-df392.firebaseio.com",
  projectId: "holdenknight-df392",
  storageBucket: "holdenknight-df392.appspot.com",
  messagingSenderId: "1032869607291",
  appId: "1:1032869607291:web:8ba3ceecc1e26756962fde",
  measurementId: "G-YE9J2TYPN0",
};

firebase.initializeApp(firebaseConfig);

// console.log(firebase.initializeApp(firebaseConfig));
export default firebase;
