import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { NavLink, useHistory, Link } from "react-router-dom";
import { logIn } from "../../store/actions";
import { InputControl } from "formik-chakra-ui";
import "./LogIn.scss";
import logo from "../../assets/logo.svg";
import darkLogo from "../../assets/darkLogo.svg";
import PasswordField from "../../global/passwordField";
import { Box, Text, Button, useColorMode, useToast } from "@chakra-ui/react";
import isAuthenticated from "../../utils/isAuthenticated";
import { motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../global/Animations/Animations";
const SignInSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().min(8).required("Required"),
});

const Login = ({ logIn, user }) => {
  const toast = useToast();
  const history = useHistory();
  const cameFrom = history.location.state?.from?.pathname;

  const { colorMode } = useColorMode();
  const [initValue] = useState({
    email: "",
    password: "",
  });
  const onFormSubmit = async (value) => {
    logIn(value.email, value.password, history, toast, cameFrom);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      toast({
        title: "You're already logged in",
        status: "success",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });

      history.push("/");
    }
  }, [history, toast]);
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="login mt-5"
    >
      <div className="row justify-content-center m-0">
        <div className="col-lg-5 col-md-7 col-11">
          <Formik
            initialValues={initValue}
            enableReinitialize
            validationSchema={SignInSchema}
            onSubmit={onFormSubmit}
          >
            <div>
              <p className="fw-bold mb-2">Login</p>
              <Form>
                <Box
                  bg={colorMode === "dark" ? "darkBg" : "white"}
                  className=" rounded shadow-sm p-5 login-row "
                >
                  <div className="header row justify-content-center mb-5">
                    <img
                      src={colorMode === "dark" ? darkLogo : logo}
                      alt="logo"
                      className="w-75"
                    />
                  </div>

                  <div className="input-group mt-2">
                    <InputControl
                      name="email"
                      label="E-mail"
                      inputProps={{ placeholder: "E-mail" }}
                    />
                  </div>

                  <div className="input-group mt-2">
                    <PasswordField label="Password" />
                  </div>

                  <div className="buttonSubmit">
                    <Button
                      type="submit"
                      className="w-100 mt-3 fw-bold"
                      size="lg"
                      isLoading={user.loading}
                      color="#fff"
                      bg={colorMode === "dark" ? "secondary" : "primary"}
                      _hover={{
                        bg:
                          colorMode === "dark"
                            ? "secondary_hover"
                            : "primary_hover",
                      }}
                    >
                      Login
                    </Button>
                  </div>

                  <div className="text-center pt-4">
                    <Text
                      as={NavLink}
                      to="/forgetpassword"
                      className="fw-bold forget-password"
                      color={colorMode === "dark" ? "secondary" : "primary"}
                      _hover={{
                        color: colorMode === "dark" ? "secondary" : "primary",
                      }}
                    >
                      Forgot password?
                    </Text>
                  </div>
                </Box>
              </Form>

              <div className="text-center  p-5 login-row">
                <Button
                  as={Link}
                  to="/signup"
                  color="#fff"
                  size="lg"
                  bg={colorMode === "dark" ? "primary" : "secondary"}
                  _hover={{
                    bg:
                      colorMode === "dark"
                        ? "primary_hover"
                        : "secondary_hover",
                  }}
                  className="w-100 nav-link text-white"
                >
                  New Account? Register
                </Button>
              </div>
            </div>
          </Formik>
        </div>
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.login,
  };
};

export default connect(mapStateToProps, { logIn })(Login);
