import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { checkColorMode } from "../../global/changeColor";

const ConsultationModal = ({ name, component, childData }) => {
  const location = useLocation();
  const history = useHistory();
  const { colorMode } = useColorMode();
  const queryParams = new URLSearchParams(location.search);
  let [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    return setIsOpen(true);
  };
  const onclose = () => {
    if (queryParams.has("readiologyID")) {
      queryParams.delete("readiologyID");
      history.replace({
        search: queryParams.toString(),
      });
    }
    return setIsOpen(false);
  };

  useEffect(() => {
    onclose();
  }, [childData]);
  return (
    <>
      <div className="col-md-3 col-12 mb-3">
        <Button w={"100%"} onClick={onOpen} color={checkColorMode(colorMode)}>
          {name}
        </Button>
        <Modal isOpen={isOpen} onClose={onclose} size="5xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>{component}</ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default ConsultationModal;
