import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import medicalImg from "./../../../assets/medical.png";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateMedical,
  getOneMedical,
  postPatientMedical,
  getAllNotifications,
  getUnReadNotifications,
} from "../../../store/actions";
import Add from "../Repeated-Component/Add";
import { useToast } from "@chakra-ui/react";
const SignInSchema = Yup.object().shape({
  title: Yup.string().min(3).required("Required"),
  files: Yup.mixed().required("File is required"),
});
const AddMedical = ({
  medical,
  updateMedical,
  getOneMedical,
  postPatientMedical,
  history,
  setChildData,
  getAllNotifications,
  getUnReadNotifications,
}) => {
  let params = useParams();
  //
  const toast = useToast();
  //
  const [initValue, setInitValue] = useState({
    note: "",
    title: "",
    files: null,
  });
  const [File, setFile] = useState({});
  useEffect(() => {
    if (params.Eid) {
      getOneMedical(params.Eid);
    }
  }, [params, getOneMedical]);

  useEffect(() => {
    if (params.Eid && medical) {
      setInitValue({
        title: medical.title,
        note: medical.note.replace(/(<([^>]+)>)/gi, ""),
        files: [],
      });
      setFile({
        name: medical.fileName,
      });
    }
  }, [params, medical]);

  const onFormSubmit = (value) => {
    if (params.Eid) {
      return updateMedical(value, params.Eid, history, toast);
    } else {
      postPatientMedical(
        value,
        history,
        toast,
        setChildData,
        getAllNotifications,
        getUnReadNotifications
      );
    }
  };
  const seeFiles = (file) => {
    if (file) {
      setFile(file[0]);
    }
  };

  return (
    <>
      <Add
        Schema={SignInSchema}
        initValue={initValue}
        history={history}
        onFormSubmit={onFormSubmit}
        headerText={`${params.Eid ? "Edit" : "Add"} Medical Report`}
        secondHeaderText="Medical Report"
        therdHeaderText="Add your Medical Report"
        image={medicalImg}
        feildsNames={{
          feild1: {
            name: "title",
            type: "text",
            placeholder: "Briefly tell us about your Medical Report",
          },
          feild2: {
            name: "note",
            type: "",
            placeholder: "Describe in as much detail as you can.",
          },
          feild3: { name: "files", type: "", placeholder: "Add Files" },
        }}
        seeFiles={seeFiles}
        renderFile={File}
        setChildData={setChildData}
      />
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    medical: state.oneMedical.data,
  };
};
export default connect(mapStateToprops, {
  updateMedical,
  getOneMedical,
  postPatientMedical,
  getAllNotifications,
  getUnReadNotifications,
})(AddMedical);
