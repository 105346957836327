import axios from "axios";
import { handeleError, handeleSuccess } from "./toast";

const API_URL = process.env.REACT_APP_API_URL;

export const makePayment = (
    data,
    value,
    history,
    toast,
    getAllNotifications,
    getUnReadNotifications,
    getPatientconsultations,
    postPatientconsultation
) => {
    return async (dispatch) => {
        dispatch({
            type: "MAKE_PAYMENT_LOADING",
        });
        try {
            const res = await axios({
                method: "POST",
                url: `${API_URL}/consultant/pay-consultant`,
                data: data,
            });
            if (res) {
                dispatch({
                    type: "MAKE_PAYMENT_SUCSES",
                    payload: res.data,
                });
                handeleSuccess("You paid 250 GBP", toast)
                // postPatientconsultation(
                //     value,
                //     history,
                //     toast,
                //     getAllNotifications,
                //     getUnReadNotifications,
                //     getPatientconsultations
                // );
            }

        } catch (err) {
            handeleError(err, toast);
            dispatch({
                type: "MAKE_PAYMENT_ERROR",
                payload: err,
            });
        }
    };
};

export const getPresavingCardSecret = () => {
    return async (dispatch) => {
        dispatch({
            type: "GET_PRESAVING_SECRER_LOADING",
        });
        try {
            const res = await axios({
                method: "GET",
                url: `${API_URL}/patient/pre-saving-card`,
            });
            dispatch({
                type: "GET_PRESAVING_SECRER_SUCSES",
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: "GET_PRESAVING_SECRER_ERROR",
                payload: err,
            });
        }
    };
};

export const getPaymentList = () => {
    return async (dispatch) => {
        dispatch({
            type: "GET_PAYMENT_LIST_LOADING",
        });
        try {
            const res = await axios({
                method: "GET",
                url: `${API_URL}/patient/list-cards`,
            });
            dispatch({
                type: "GET_PAYMENT_LIST_SUCSES",
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: "GET_PAYMENT_LIST_ERROR",
                payload: err,
            });
        }
    };
};

export const deletePayment = (data, toast, getPaymentList) => {
    return async (dispatch) => {
        dispatch({
            type: "DELETE_PAYMENT_LOADING",
        });
        try {
            const res = await axios({
                method: "delete",
                url: `${API_URL}/patient/delete-card`,
                data: data

            });
            if (res) {
                handeleSuccess("Your Payment deleted successfully", toast)
                getPaymentList()
            }
            dispatch({
                type: "DELETE_PAYMENT_SUCSES",
                payload: res.data,
            });
        } catch (err) {
            handeleError(err, toast)
            dispatch({
                type: "DELETE_PAYMENT_ERROR",
                payload: err,
            });
        }
    };
};

export const getAuthFailed = () => {
    return async (dispatch) => {
        dispatch({
            type: "GET_AUTH_FAILED_LOADING",
        });
        try {
            const res = await axios({
                method: "GET",
                url: `${API_URL}/patient/auth-failed-payment`,
            });
            dispatch({
                type: "GET_AUTH_FAILED_SUCSES",
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: "GET_AUTH_FAILED_ERROR",
                payload: err,
            });
        }
    };
};

export const paymentConfiguration = () => {
    return async (dispatch) => {
        dispatch({
            type: "GET_PAYMENT_CONFIG_LOADING",
        });
        try {
            const res = await axios({
                method: "GET",
                url: `${API_URL}/payment/configuration`,
            });
            dispatch({
                type: "GET_PAYMENT_CONFIG_SUCSES",
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: "GET_PAYMENT_CONFIG_ERROR",
                payload: err,
            });
        }
    };
};