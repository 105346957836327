import React, { useState, useEffect } from "react";
import {
  Text,
  Box,
  Textarea,
  useToast,
  Button,
  useColorMode,
} from "@chakra-ui/react";

import { style } from "../../../../../global/style";
import {
  getPatientOneQuestions,
  replyPatientQuestion,
} from "../../../../../store/actions";
import { connect } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../../../global/changeColor";
import moment from "moment";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const ReplayQ = ({
  getPatientOneQuestions,
  oneQuestion,
  history,
  replyPatientQuestion,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [question, setQuestion] = useState(null);
  let params = useParams();
  let query = useQuery().get("consultationId");
  let toast = useToast();
  let { colorMode } = useColorMode();
  const Schema = Yup.object().shape({
    reply: Yup.string().required("Required field"),
  });

  const [initValue] = useState({
    reply: "",
  });

  const onFormSubmit = (values) => {
    replyPatientQuestion(values, query, params.qid, history, toast);
  };

  useEffect(() => {
    if (params && query) {
      getPatientOneQuestions(query, params.qid);
    }
  }, [params, query, getPatientOneQuestions]);

  useEffect(() => {
    const { loading, error, data } = oneQuestion;
    setLoading(loading);
    setError(error);
    if (data) {
      setQuestion(data);
    }
  }, [oneQuestion]);

  const renderView = () => {
    if (loading) return "Loading... ";
    if (error) return "Error ";
    if (question) {
      return (
        <>
          <div className="d-flex col-md-6 col-12 ps-0">
            <Button
              onClick={() => history.goBack()}
              className="nav-link h5 me-4 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
              style={{ width: "45px", height: "45px" }}
              color={colorMode === "dark" ? "secondary" : "primary"}
              _hover={{
                color: colorMode === "dark" ? "secondary" : "primary",
              }}
            >
              <i className="fas fa-chevron-left"></i>
            </Button>

            <Text fontSize="3xl" className="fw-bold" color={style.secondary}>
              Answer question
            </Text>
          </div>
          <div className="col-md-6 col-12 ps-0 text-end">
            {/* <Button
                color={style.primary}
                as={Link}
                className="me-2"
                to={`/consultations/over-view/questions/replay-question/${question.id}?consultationId=${question.consultation.id}`}
                variant="ghost"
                _hover={{
                  color: style.primary,
                  background: style.primaryOpacity_hover,
                }}
              >
                <span className="me-2">
                  <i className="fas fa-reply"></i>
                </span>{" "}
                Answer
              </Button> */}
          </div>
          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-12 p-4  rounded mt-4"
          >
            <div className="header row m-0 align-items-center">
              <Box className="col-md-6 ">
                <Text className="h4 fw-bold mb-0" color={style.secondary}>
                  {question ? question.title : null}
                </Text>
                <Text color={style.smallTextGray} fontSize="12px">
                  {question
                    ? moment
                        .unix(question.updatedAt)
                        .format("DD/MM/YYYY - hh:mm A")
                    : null}
                </Text>
              </Box>
            </div>

            <Formik
              initialValues={initValue}
              //   enableReinitialize
              validationSchema={Schema}
              onSubmit={onFormSubmit}
            >
              {({ values, handleChange, handleBlur }) => (
                <Form>
                  <Box
                    boxShadow="xs"
                    className="rounded p-3 mt-3"
                    bg={style.smallTextGrayLight}
                    border={`1px solid ${style.smallTextGrayLightBorder}`}
                  >
                    <Textarea
                      className="mt-2 rounded"
                      value={values.note}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => handleBlur(e)}
                      placeholder={"Answers"}
                      name="reply"
                      size="sm"
                      bg={checkBgColorMode(colorMode)}
                    />
                    <ErrorMessage
                      className="error"
                      component="div"
                      name="reply"
                    />
                  </Box>
                  <Box className="header row m-0 align-items-center justify-content-between mt-3">
                    <Button
                      w={200}
                      size="lg"
                      colorScheme="red"
                      variant="outline"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="lg"
                      w={200}
                      bg={checkColorMode(colorMode)}
                      color="#fff"
                      _hover={{ bg: checkColorModeHover(colorMode) }}
                    >
                      Save{" "}
                      <span className="ms-3">
                        <i className="fas fa-save"></i>
                      </span>
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </>
      );
    }
  };
  return (
    <div className="col-12 d-flex py-4 align-items-center ps-0 justify-content-between flex-wrap">
      {renderView()}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    oneQuestion: state.oneQuestion,
  };
};
export default connect(mapStateToProps, {
  getPatientOneQuestions,
  replyPatientQuestion,
})(ReplayQ);
