import axios from "axios";
import { handeleSuccess, handeleError } from "../toast";

const API_URL = process.env.REACT_APP_API_URL;

export const getPatientAllergies = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ALLERGIES_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/allergies?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/allergies/`,
      });
      dispatch({
        type: "GET_ALLERGIES_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALLERGIES_ERROR",
        payload: error,
      });
    }
  };
};
export const getPatientAllergiesForPagination = (
  pageNumber,
  patientId,
  informationShareToken
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ALLERGIES_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/allergies?informationShareToken=${informationShareToken}&page=${pageNumber}`
            : `${API_URL}/patient/allergies?page=${pageNumber}`,
      });
      dispatch({
        type: "GET_ALLERGIES_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ALLERGIES_ERROR",
        payload: error,
      });
    }
  };
};

export const getOneAllergies = (
  id,
  toast,
  history,
  patientId,
  informationShareToken
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_ALLERGIES_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/allergies/show/${id}?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/allergies/${id}`,
      });
      dispatch({
        type: "GET_ONE_ALLERGIES_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast, null, "allergy");
      history.push("/medical-record/allergies");
      dispatch({
        type: "GET_ONE_ALLERGIES_ERROR",
        payload: error,
      });
    }
  };
};

export const postPatientAllergies = (
  data,
  history,
  toast,
  setChildData,
  getAllNotifications,
  getUnReadNotifications
) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_ALLERGIES_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/allergies`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your allergy has been added successfully", toast);
        getAllNotifications();
        getUnReadNotifications();
        history ? history.goBack() : setChildData(res.data);
        dispatch({
          type: "POST_ALLERGIES_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);

      dispatch({
        type: "POST_ALLERGIES_ERROR",
        payload: error,
      });
    }
  };
};

export const updateAllergies = (data, id, history, toast) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_ALLERGIES_LOADING",
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/patient/allergies/${id}`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your allergy has been Updated successfully", toast);

        history.goBack();
        dispatch({
          type: "UPDATE_ALLERGIES_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_ALLERGIES_ERROR",
        payload: error,
      });
    }
  };
};

export const deleteAllergies = (id, toast, history, goTo) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_ALLERGIES_LOADING",
    });
    try {
      const res = await axios({
        method: "DELETE",
        url: `${API_URL}/patient/allergies/${id}`,
      });
      if (res) {
        handeleSuccess("Your allergy deleted successfully", toast);
        history.push(goTo);
        dispatch({
          type: "DELETE_ALLERGIES_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);

      dispatch({
        type: "DELETE_ALLERGIES_ERROR",
        payload: error,
      });
    }
  };
};
