import axios from "axios";
import { handeleSuccess, handeleError } from "./toast";

const API_URL = process.env.REACT_APP_API_URL;

export const getHospitalsQuotations = (id, pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_HOSPITAL_RESERVATION_LOADING",
    }); ///01201719133
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/hospital-reservations/${id}/quotations?page=${pageNumber}`,
      });

      dispatch({
        type: "GET_HOSPITAL_RESERVATION_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_HOSPITAL_RESERVATION_ERROR",
        payload: err,
      });
    }
  };
};
export const getOneHospitalsQuotations = (id, quotationId) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_HOSPITAL_RESERVATION_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/hospital-reservations/${id}/quotations/${quotationId}`,
      });
      dispatch({
        type: "GET_ONE_HOSPITAL_RESERVATION_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_ONE_HOSPITAL_RESERVATION_ERROR",
        payload: err,
      });
    }
  };
};

export const addHospitalReservation = (id, data, toast, onClose, history) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_HOSPITAL_RESERVATION_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/consultation-request/${id}/hospital-reservation`,
        data: data,
      });
      if (res) {
        handeleSuccess(
          "Your hospital reservation has been created successfully",
          toast
        );
        history.push(
          "/consultations/over-view/hospital-ofers?consultationId=589"
        );
        onClose();
        dispatch({
          type: "POST_HOSPITAL_RESERVATION_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      // onClose();
      dispatch({
        type: "POST_HOSPITAL_RESERVATION_ERROR",
        payload: err,
      });
    }
  };
};

export const contactToAdmin = (id, quotationId, toast) => {
  return async (dispatch) => {
    dispatch({
      type: "CONTACT_ADMIN_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/hospital-reservations/${id}/quotations/${quotationId}/contact-admin`,
      });
      if (res) {
        handeleSuccess("Our representative will call you shortly", toast);
        dispatch({
          type: "CONTACT_ADMIN_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "CONTACT_ADMIN_ERROR",
        payload: err,
      });
    }
  };
};
