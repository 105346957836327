import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { connect } from "react-redux";
import {
  getPaymentList,
  getAuthFailed,
  getPresavingCardSecret,
} from "../../../store/actions";

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY;
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const PaymentConfig = ({ getPresavingCardSecret, clientSecret }) => {
  const [clientSec, setClientSecret] = useState();

  useEffect(() => {
    getPresavingCardSecret();
  }, [getPresavingCardSecret]);

  useEffect(() => {
    if (clientSecret) {
      setClientSecret(clientSecret.client_secret);
    }
  }, [clientSecret]);

  const options = {
    clientSecret: clientSec,
  };

  return (
    <Elements stripe={stripeTestPromise} options={options}>
      <PaymentForm clientSec={clientSec} />
    </Elements>
  );
};
const mapStateToProps = (state) => {
  return {
    clientSecret: state.clientSecret.data,
  };
};
export default connect(mapStateToProps, {
  getPaymentList,
  getAuthFailed,
  getPresavingCardSecret,
})(PaymentConfig);
