import React, { useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import { connect } from "react-redux";
import { consultationTypeList } from "../../store/actions";

const Common = ({ consultationTypeList, consultationTypes, itemId }) => {
  const [ConsultationType, setConsultationType] = useState([]);

  useEffect(() => {
    consultationTypeList();
  }, [consultationTypeList]);

  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);

  return (
    <Text fontSize="13px" fontWeight="700">
      {ConsultationType.length && itemId
        ? ConsultationType.find((element) => element.id === itemId).name
        : ""}
    </Text>
  );
};
const mapStateToProps = (state) => {
  return {
    consultationTypes: state.consultationTypes,
  };
};
export default connect(mapStateToProps, {
  consultationTypeList,
})(Common);
