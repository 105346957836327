const INIT_LAP_RESULT_STATR = {
  loading: false,
  error: null,
  data: null,
};

export const getLapResultReducer = (
  state = INIT_LAP_RESULT_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_LAPRESULT_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_LAPRESULT_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_LAPRESULT_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneLapResultReducer = (
  state = INIT_LAP_RESULT_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_ONE_LAPRESULT_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_LAPRESULT_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_LAPRESULT_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const addLapResultReducer = (
  state = INIT_LAP_RESULT_STATR,
  { type, payload }
) => {
  switch (type) {
    case "POST_LAPRESULT_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_LAPRESULT_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_LAPRESULT_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const updateLapResultReducer = (
  state = INIT_LAP_RESULT_STATR,
  { type, payload }
) => {
  switch (type) {
    case "UPDATE_LAPRESULT_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_LAPRESULT_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_LAPRESULT_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const deleteLapResultReducer = (
  state = INIT_LAP_RESULT_STATR,
  { type, payload }
) => {
  switch (type) {
    case "DELETE_LAPRESULT_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_LAPRESULT_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "DELETE_LAPRESULT_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
