import React from "react";

import { Box, Text, useToast } from "@chakra-ui/react";
import AlertComponent from "../../global/Alert.Component";
import { deletePatientProfile } from "../../store/actions/patientActions";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const DeleteAccount = () => {
  let history = useHistory();
  let toast = useToast();
  console.log(history);
  const removeAccount = () => {
    deletePatientProfile(history, toast);
  };
  return (
    <div className="container my-5">
      <Box className="row">
        <Text className="my-3" fontSize="20px">
          We want to inform you that a new account deletion requirement has been
          added to empower users with better control over their data. Please
          check your Data safety form in{" "}
          <a
            href="https://notifications.google.com/g/p/ANiao5plCPyAtvzbEkl7REJnZ_4XUiHwsgunO1hUFp4Pbf-YH0qc-sykM4C_KCisdsRe2JGWb2DsfVuSUEI0b8eJxIu6f_BZWKywazEsaEROe95h2QaEkojvVyui4H7E2Onf-Lijd9n7qHx9Bb_DM1XzPMvcZNfUN3mt3sGAALHenJqfxU2fuoNWUfOlpL3_tg7hL1vdzHucdRvekaJb2aU8HHigjNkvzldY10Mfca68zSjP9zuXEp0gMwErqjIJJHM"
            target="_blanck"
          >
            Play Console
          </a>{" "}
          and ensure it complies with the account deletion requirements.
        </Text>
        <Text className="my-3" fontSize="20px">
          All developers are required to update their Data safety form in
          <a
            href="https://notifications.google.com/g/p/ANiao5plCPyAtvzbEkl7REJnZ_4XUiHwsgunO1hUFp4Pbf-YH0qc-sykM4C_KCisdsRe2JGWb2DsfVuSUEI0b8eJxIu6f_BZWKywazEsaEROe95h2QaEkojvVyui4H7E2Onf-Lijd9n7qHx9Bb_DM1XzPMvcZNfUN3mt3sGAALHenJqfxU2fuoNWUfOlpL3_tg7hL1vdzHucdRvekaJb2aU8HHigjNkvzldY10Mfca68zSjP9zuXEp0gMwErqjIJJHM"
            target="_blanck"
          >
            {" "}
            Play Console
          </a>{" "}
          by December 7, 2023. Failure to update by this date may impact your
          ability to update your app in the future.
        </Text>
        <Text className="my-3" fontSize="20px">
          We encourage you to review all your apps to ensure compliance. If you
          need additional time, you can request an extension until May 31, 2024,
          in{" "}
          <a
            href="https://notifications.google.com/g/p/ANiao5plCPyAtvzbEkl7REJnZ_4XUiHwsgunO1hUFp4Pbf-YH0qc-sykM4C_KCisdsRe2JGWb2DsfVuSUEI0b8eJxIu6f_BZWKywazEsaEROe95h2QaEkojvVyui4H7E2Onf-Lijd9n7qHx9Bb_DM1XzPMvcZNfUN3mt3sGAALHenJqfxU2fuoNWUfOlpL3_tg7hL1vdzHucdRvekaJb2aU8HHigjNkvzldY10Mfca68zSjP9zuXEp0gMwErqjIJJHM"
            target="_blanck"
          >
            {" "}
            Play Console{" "}
          </a>
          .
        </Text>
        <Text className="mt-5" fontSize="20px">
          Thank you for your cooperation!
        </Text>
        <Box className="d-flex justify-content-end">
          <AlertComponent
            nameForAlert="account"
            removeOne={removeAccount}
            account="Account"
          />
        </Box>
      </Box>
    </div>
  );
};
export default connect(null, { deletePatientProfile })(DeleteAccount);
