import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { InputControl } from "formik-chakra-ui";
import { Text, Button, useToast, useColorMode, Box } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import TelephoneInput from "../../SignUp/phoneNumber";
import * as Yup from "yup";
import {
  postPatientEmergencyInfo,
  updatePatientEmergencyInfo,
  getPatientEmergencyInfo,
} from "../../../store/actions";
import { connect } from "react-redux";
import { handeleError } from "../../../store/actions/toast";
import { checkBgColorMode } from "../../../global/changeColor.js";

const SubmitSchema = Yup.object().shape({
  name: Yup.string().min(3).required(),
  email: Yup.string().email().required(),
  mobile: Yup.string().min(10, "mobile must be at least 10 number").required(),
  // address: Yup.string().min(3).required(),
});
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const EditEmargency = ({
  history,
  patientEmergency,
  postPatientEmergencyInfo,
  updatePatientEmergencyInfo,
  getPatientEmergencyInfo,
}) => {
  let query = useQuery().get("emargencyID");
  let redirectTo = useQuery().get("redirectTo");
  const toast = useToast();
  const { colorMode } = useColorMode();

  const [initValue, setInitValue] = useState({
    email: "",
    mobile: "",
    address: "",
    name: "",
  });

  useEffect(() => {
    if (query) {
      return getPatientEmergencyInfo();
    }
  }, [query, getPatientEmergencyInfo]);

  useEffect(() => {
    if (
      !query &&
      patientEmergency &&
      patientEmergency.data &&
      patientEmergency.data.email
    ) {
      handeleError(null, toast, "You already have an Emergency");
      history.push("/profile/my_info");
    }
  }, [query, patientEmergency, toast, history]);

  useEffect(() => {
    if (query && patientEmergency) {
      setInitValue((initValue) => {
        return {
          ...initValue,
          email: patientEmergency.data?.email
            ? patientEmergency.data.email
            : "",
          mobile: patientEmergency.data?.mobile
            ? patientEmergency.data?.mobile
            : "",
          address: patientEmergency.data?.address
            ? patientEmergency.data?.address
            : "",
          name: patientEmergency.data?.name ? patientEmergency.data.name : "",
        };
      });
    }
  }, [query, patientEmergency]);

  const onFormSubmit = (value) => {
    value.address = "address";
    if (query) {
      updatePatientEmergencyInfo(value, query, toast, history).then(() => {
        getPatientEmergencyInfo();
      });
    } else {
      postPatientEmergencyInfo(value, toast, history, redirectTo).then(() => {
        getPatientEmergencyInfo();
      });
    }
  };

  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="EditEmargency  rounded shadow-sm p-4  d-flex flex-wrap"
    >
      <Formik
        initialValues={initValue}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={SubmitSchema}
      >
        <Form>
          <div className="col-12 d-flex justify-content-between align-items-center">
            <Text color="secondary" fontSize="lg" className="fw-bold">
              {" "}
              Emergency Info
            </Text>
            <div>
              <Button
                width={"50px"}
                height="50px"
                bg="#f3e9eb"
                _hover={{ bg: "#f3d3da" }}
                fontSize="20px"
                color="#D42C51"
                className="me-2"
              >
                <Link to={"/profile/my_info"} className="nav-link text-error">
                  <i className="fas fa-times"></i>
                </Link>
              </Button>
              <Button
                width={"50px"}
                height="50px"
                bg="secondary"
                _hover={{ bg: "secondary_hover" }}
                fontSize="20px"
                type="submit"
                color="#fff"
              >
                <i className="fas fa-save"></i>
              </Button>
            </div>
          </div>
          <div className="col-12 row">
            <div className="col-md-6 mt-2">
              <InputControl
                name="name"
                label="Name"
                inputProps={{ placeholder: "Name" }}
              />
            </div>
            <div className="col-md-6  ">
              <label className="w-100 login-label" htmlFor="mobile">
                Phone Number
              </label>
              <div className="pt-2">
                <TelephoneInput name="mobile" />
                <ErrorMessage
                  name="mobile"
                  component="div"
                  className="text-danger errorMsg"
                />
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <InputControl
                name="email"
                label="E-Mail"
                inputProps={{ placeholder: "E-Mail" }}
              />
            </div>
            {/* <div className="col-md-12 mt-2">
              <InputControl
                name="address"
                label="Address"
                inputProps={{ placeholder: "Address" }}
              />
            </div> */}
          </div>
        </Form>
      </Formik>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    patientEmergency: state.patientEmergency,
  };
};
export default connect(mapStateToProps, {
  postPatientEmergencyInfo,
  updatePatientEmergencyInfo,
  getPatientEmergencyInfo,
})(EditEmargency);
