import React, { useEffect, useState } from "react";
import { useRouteMatch, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPatientEcg,
  getPatientEcgForPagination,
} from "./../../../store/actions";
import FirstView from "../Repeated-Component/FirstView";
import Pagination from "../../Pagination";
import ECG from "./../../../assets/ECG.png";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const EcgChild = ({
  getPatientEcg,
  ECGList,
  getPatientEcgForPagination,
  history,
}) => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const params = useParams();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Ecg, setEcg] = useState([]);
  const [filterEcg, setFilterEcg] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  let query = useQuery().get("page");

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    if (!query) getPatientEcg(params.patientId, sharedToken);
  }, [getPatientEcg, query, params, sharedToken]);

  useEffect(() => {
    if (query) getPatientEcgForPagination(query, params.patientId, sharedToken);
  }, [getPatientEcgForPagination, query, params, sharedToken]);

  useEffect(() => {
    if (Ecg.length > 0) {
      setFilterEcg(Ecg);
    }
  }, [Ecg]);

  useEffect(() => {
    const { loading, error, data } = ECGList;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      setEcg(list);
      setFilterEcg(list);
      setTotalResults(data.meta.total);
      setCuttentPage(data.meta.current_page);
    }
  }, [ECGList]);
  const renderView = () => {
    return (
      <>
        <FirstView
          headerName="ECG"
          buttonLink={`${url}/add-ecg`}
          cardLink={`${url}/view-ecg`}
          data={Ecg}
          filterData={filterEcg}
          setFilter={setFilterEcg}
          loading={loading}
          error={error}
          imageForEmptyView={ECG}
          secondTitle="ECG"
        />
        {totalResults > 15 && filterEcg.length > 10 ? (
          <Pagination
            pages={pagesNumber}
            nextPage={nextPage}
            prevPage={prevPage}
            specificPage={specificPage}
            currentPage={currentPage}
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return renderView();
};
const mapStateToprops = (state) => {
  return {
    ECGList: state.ECGList,
  };
};
export default connect(mapStateToprops, {
  getPatientEcg,
  getPatientEcgForPagination,
})(EcgChild);
