import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  Avatar,
  Circle,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import "react-day-picker/dist/style.css";
import {
  getConsultantAppointment,
  addPatientAppointmnts,
  getEmptyConsultantAppointmnt,
  getPatientPrePaymentData,
  getPaymentList,
  deletePatientAppointmnts,
  bookAppointmnt,
} from "../../../../../store/actions";
import { connect } from "react-redux";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";
// import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import { useStripe } from "@stripe/react-stripe-js";
// import { handeleError } from "../../../../../store/actions/toast";
import {
  checkColorMode,
  checkColorModeHover,
} from "../../../../../global/changeColor";

const AppointmentDatePicker = ({
  getPatientPrePaymentData,
  getPaymentList,
  paymentList,
  PrePaymentData,
  consultationId,
  consultant,
  getConsultantAppointment,
  consultantAppointment,
  addPatientAppointmnts,
  bookAppointmnt,
  getEmptyConsultantAppointmnt,
  emptyAppointment,
  btnName,
  appointment,
  deletePatientAppointmnts,
  consultation,
  fromOverView,
}) => {
  const [consultantAppointments, setConsultantAppointment] = useState([]);
  const [emptyAppoin, setEmptyAppoi] = useState([]);
  // const [selectedDate, handleDateChange] = useState(new Date());
  // const [selectedDays, setSelectedDays] = useState([]);
  const [time, setTime] = useState(true);
  // const [appointmentTime, setAppointmentTime] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const [scheduleId, setScheduleId] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const toast = useToast();
  const { colorMode } = useColorMode();
  // const stripe = useStripe();

  const handelSubmit = () => {
    // const data = {
    //   appointment_time: appointmentTime,
    //   patient_consultation_request_id: consultationId,
    // };
    bookAppointmnt(
      toast,
      history,
      time,
      setTime,
      onClose,
      consultation.id,
      scheduleId
    );

    // if (paymentList.length) {
    //   addPatientAppointmnts(
    //     data,
    //     toast,
    //     history,
    //     time,
    //     setTime,
    //     onClose,
    //     paymentList,
    //     consultation,
    //     PrePaymentData,
    //     stripe,
    //     deletePatientAppointmnts
    //   );
    // } else {
    //   return toast({
    //     title: "please add a payment method before making an appointment",
    //     status: "error",
    //     duration: 4000,
    //     isClosable: true,
    //     position: "top-right",
    //   });
    // }

    // if (paymentList.length) {
    //   try {
    //     if (!appointment) {
    //       const { error, ...res } = await stripe.handleCardPayment(PrePaymentData.client_secret, {
    //         payment_method: PrePaymentData.paymentMethodId,
    //       })
    //       if (!error) {
    //         const data = {
    //           appointment_time: appointmentTime,
    //           patient_consultation_request_id: consultationId,
    //         };
    //         addPatientAppointmnts(
    //           data,
    //           toast,
    //           history,
    //           time,
    //           setTime,
    //           onClose
    //         );
    //       } else {
    //         console.log(error)
    //       }
    //     } else {
    //       // addPatientAppointmnts(
    //       //   data,
    //       //   toast,
    //       //   history,
    //       //   time,
    //       //   setTime,
    //       //   onClose
    //       // );
    //     }

    //   } catch (error) {
    //     handeleError(error, toast)
    //   }
    // } else {
    //   return toast({
    //     title: "Please add a payment method before making a Appointment request",
    //     status: "error",
    //     duration: 4000,
    //     isClosable: true,
    //     position: "top-right",
    //   });
    // }
  };

  useEffect(() => {
    getConsultantAppointment(consultation.id);
  }, [getConsultantAppointment, consultation]);

  useEffect(() => {
    if (consultantAppointment) {
      consultantAppointment.sort(function (a, b) {
        return moment(a.from_time) - moment(b.from_time);
      });

      setConsultantAppointment(consultantAppointment);
      // const days = [];
      // consultantAppointment.data.map((item) => {
      //   days.push(item.day);
      //   setSelectedDays(days);
      // });
    }
  }, [consultantAppointment]);

  useEffect(() => {
    if (emptyAppointment && emptyAppointment.data) {
      setEmptyAppoi(emptyAppointment.data);
    }
  }, [emptyAppointment]);

  useEffect(() => {
    if (emptyAppoin.length <= 0) {
      setTime(true);
    }
  }, [emptyAppoin]);

  useEffect(() => {
    getPaymentList();
  }, [getPaymentList]);

  useEffect(() => {
    if (paymentList && paymentList.length) {
      getPatientPrePaymentData();
    }
  }, [paymentList, getPatientPrePaymentData]);

  return (
    <>
      <Button
        w={"100%"}
        color="#fff"
        size="lg"
        background={checkColorMode(colorMode)}
        _hover={{ bg: checkColorModeHover(colorMode) }}
        onClick={onOpen}
        minW={"230px"}
      >
        {btnName ? btnName : "Book Appointment"}
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="lg" fontWeight="semibold" color={"secondary"}>
              Book Appointment
            </Text>
            <Box className="d-flex mt-4">
              <Box className="d-flex align-items-center">
                <Avatar
                  bg={checkColorMode(colorMode)}
                  size="sm"
                  src={consultant.picture}
                  name={consultant.name}
                  className="me-2"
                  color="#FFF"
                />
                <Box>
                  <Text color={"smallTextGray"} fontSize="13px">
                    Doctor
                  </Text>
                  <Text fontSize="13px" fontWeight="semibold">
                    {consultant.name}
                  </Text>
                </Box>
              </Box>
              <Box className="d-flex align-items-center ms-5">
                <Circle
                  bg={checkColorMode(colorMode)}
                  size="32px"
                  src={consultant.picture}
                  name={consultant.name}
                  className="me-2"
                  color="#fff"
                  fontSize="12px"
                >
                  <i className="fas fa-address-card"></i>
                </Circle>
                <Box>
                  <Text color={"smallTextGray"} fontSize="13px">
                    GMC Number
                  </Text>
                  <Text fontSize="13px" fontWeight="semibold">
                    #{consultant.gmc_number}
                  </Text>
                </Box>
              </Box>
              <Box></Box>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box className="row m-0 justify-content-center">
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  disableToolbar="true"
                  disablePast="true"
                  orientation="landscape"
                  variant="static"
                  openTo="date"
                  value={selectedDate}
                  onChange={(day) => {
                    let tms = moment(day).format("X");
                    handleDateChange(day);
                    getEmptyConsultantAppointmnt(consultant.id, tms);
                    setTime(true);
                  }}
                  renderDay={(
                    day,
                    selectedDate,
                    isInCurrentMonth,
                    dayComponent
                  ) => {
                    var days = [
                      "Sunday",
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                    ];
                    const date = new Date(day);
                    const isSelected =
                      isInCurrentMonth &&
                      selectedDays.includes(days[date.getDay()]);
                    return (
                      <div className={isSelected ? "selected" : ""}>
                        {dayComponent}
                      </div>
                    );
                  }}
                />
              </MuiPickersUtilsProvider> */}
              <Text
                className="col-10"
                fontSize="16px"
                fontWeight="semibold"
                color="gray.400"
              >
                Select Time
              </Text>
              {consultantAppointments && consultantAppointments.length
                ? consultantAppointments.map((item, index) => (
                    <Box key={item.id} className=" col-9">
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        className="p-2 shadow-sm my-2 text-center rounded w-100"
                        onClick={() => {
                          setDateSelected(item.from_time);
                          setTime(false);
                          setScheduleId(item.id);
                        }}
                      >
                        {moment
                          .unix(item.from_time)
                          .format("ddd DD MMM yyyy - h:mm A")}
                      </Button>
                    </Box>
                  ))
                : ""}
            </Box>
          </ModalBody>

          <Box className="row m-0 ps-2 pe-2" transition=".3s ease-in-out">
            <Box className="col-12">
              <Text className="my-3" fontSize={"12px"}>
                Appointment time
              </Text>
              {dateSelected ? (
                <Box className="d-flex">
                  <Text
                    className="p-2 px-3 rounded"
                    bg={checkColorMode(colorMode)}
                    color="#fff"
                  >
                    {moment
                      .unix(dateSelected)
                      .format("dddd DD MMMM yyyy - h:mm A")}
                  </Text>
                </Box>
              ) : (
                ""
              )}
              {/* <Box className="row m-0">
                {emptyAppoin.length ? (
                  emptyAppoin.map((item, index) => {
                    return (
                      <Box
                        key={index}
                        className="p-1 text-center AppointmentTime"
                        border={`1px solid`}
                        width="160px"
                        borderRadius={"20px"}
                        borderColor="gray.300"
                        shadow="md"
                        fontSize={"15px"}
                        color={style.primary}
                        fontWeight="semibold"
                        cursor="pointer"
                        _hover={{
                          transition: ".3s",
                          bg: style.primary,
                          color: "#fff",
                        }}
                        onClick={(e) => {
                          setTime(!time);
                          e.target.classList.toggle("toTime");
                          setAppointmentTime(item.from_time);
                        }}
                      >
                        {`${moment
                          .unix(item.from_time)
                          .format("hh:mm")} - ${moment
                          .unix(item.to_time)
                          .format("hh:mm A")}`}
                      </Box>
                    );
                  })
                ) : (
                  <>
                    <Text fontSize="14px" color="red.300">
                      There is no Appointment please select Another day
                    </Text>
                    <Text fontSize="14px" color="gray.500" className="mt-2">
                      <span className="fw-bold">Available days is</span>
                      {consultantAppointments.length
                        ? consultantAppointments.map((item, index) => (
                            <span className="" key={index}>
                              {" "}
                              ( {item.day} ){" "}
                            </span>
                          ))
                        : ""}
                    </Text>
                  </>
                )}
              </Box> */}
              <Box className="row m-0 my-4 align-items-center">
                {/* <Box
                  fontSize="13px"
                  className="col-5 rounded d-flex justify-content-center align-items-center"
                  fontWeight="semibold"
                  bg={style.bg_Light_Gray}
                  h="40px"
                >
                  <Text>
                    <span className="me-2" style={{ fontSize: "16px" }}>
                      <i className="fas fa-pound-sign"></i>
                    </span>{" "}
                    You Pay: 250 GBP
                  </Text>
                </Box> */}
                <Box className="col-7 ">
                  <Button
                    w="100%"
                    className="col-7"
                    disabled={time}
                    bg={checkColorMode(colorMode)}
                    color="#fff"
                    fontWeight="normal"
                    fontSize="15px"
                    _hover={{ bg: checkColorModeHover(colorMode) }}
                    onClick={handelSubmit}
                  >
                    Confirm
                    {/* and Pay */}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    consultantAppointment: state.consultantAppointment.data,
    emptyAppointment: state.emptyconsultantAppointment.data,
    paymentList: state.paymentList.data,
    PrePaymentData: state.PrePaymentData.data,
  };
};
export default connect(mapStateToProps, {
  getConsultantAppointment,
  addPatientAppointmnts,
  getEmptyConsultantAppointmnt,
  getPatientPrePaymentData,
  getPaymentList,
  deletePatientAppointmnts,
  bookAppointmnt,
})(AppointmentDatePicker);
