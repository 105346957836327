import { Formik, Form, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import ConsultationRequest from "../../assets/ConsultationRequest.png";
import * as Yup from "yup";

import {
  Textarea,
  Button,
  Text,
  useToast,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import { InputControl, SelectControl } from "formik-chakra-ui";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  postPatientconsultation,
  getAllNotifications,
  getUnReadNotifications,
  getPatientconsultations,
  getPatientPrePaymentData,
  getPaymentList,
  getPatientEmergencyInfo,
  getPatientAddress,
} from "../../store/actions";
import ConsultationModal from "./consultation_modal";
///////
import AddCondition from "../MidicalRecord/conditions/add-condition/AddCondition";
import AddMedical from "../MidicalRecord/midical-report/add-medical";
import MyReading from "../MyReadinga/MyReading";
import AddEcg from "../MidicalRecord/ecg/AddEcg";
import AddAllergies from "../MidicalRecord/Allergies/AddAllergies";
///
import AddLapResult from "../MidicalRecord/lab-result/addLapResult";
import AddXray from "../MidicalRecord/Xray/AddXray";
import AddRadiology from "../MidicalRecord/radiology/Add-Radiology";
import { motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../global/Animations/Animations";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../global/changeColor";
import moment from "moment";

// import { useStripe } from "@stripe/react-stripe-js";

//////
const Schema = Yup.object().shape({
  symptoms: Yup.string()
    .min(3, "Symptoms must be at least 3 characters")
    .required("Symptoms is a required field"),
  symptomsExplained: Yup.string()
    .min(3, "Explanation of symptoms must be at least 3 characters")
    .required("Symptoms explained is a required field"),
  consultationTypeId: Yup.string().required("Specialty is a required field"),
});

const Addconsaltation = ({
  consultationTypes,
  postPatientconsultation,
  history,
  getPatientconsultations,
  getAllNotifications,
  getUnReadNotifications,
  getPatientPrePaymentData,
  getPaymentList,
  paymentList,
  PrePaymentData,
  postedConsultation,
  getPatientEmergencyInfo,
  getPatientAddress,
  patientAddress,
  patientEmergency,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  // const stripe = useStripe();
  const [ConsultationTypes, setconsultationTypes] = useState([]);
  const [submitdData] = useState(
    localStorage.consultationRequest
      ? JSON.parse(localStorage.consultationRequest)
      : null
  );
  const [draftConsultation] = useState(
    localStorage.draftConsultation
      ? JSON.parse(localStorage.draftConsultation)
      : null
  );
  const [childData, setChildData] = useState();
  const [consultationTypeId, setConsultationTypeId] = useState(null);
  const [symptoms, setSymptoms] = useState(null);
  const [symptomsExplained, setSymptomsExplained] = useState(null);
  const [initVal, setInitValue] = useState({
    consultationTypeId: "",
    symptoms: "",
    symptomsExplained: "",
  });
  const onFormSubmit = async (value) => {
    if (patientAddress && patientEmergency) {
      postPatientconsultation(
        value,
        history,
        toast,
        getAllNotifications,
        getUnReadNotifications,
        getPatientconsultations
      );
    } else {
      // if (!paymentList.length) {
      //   localStorage.setItem("consultationRequest", JSON.stringify(value));
      //   history.push(
      //     `/profile/add_payment?redirectTo=${history.location.pathname}`
      //   );
      //   return toast({
      //     title:
      //       "Please add a payment method before making a consultation request",
      //     status: "error",
      //     duration: 4000,
      //     isClosable: true,
      //     position: "top-right",
      //   });
      // }
      // else
      if (!patientAddress) {
        localStorage.setItem("consultationRequest", JSON.stringify(value));
        history.push(
          `/profile/add_address?redirectTo=${history.location.pathname}`
        );
        return toast({
          title: "Please add your address before making a consultation request",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      } else if (!patientEmergency) {
        localStorage.setItem("consultationRequest", JSON.stringify(value));
        history.push(
          `/profile/add_emargency?redirectTo=${history.location.pathname}`
        );
        return toast({
          title:
            "Please add your emergency info before making a consultation request",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    }

    // if (paymentList.length) {
    //   try {
    //     const res = await stripe.handleCardPayment(
    //       PrePaymentData.client_secret,
    //       {
    //         payment_method: PrePaymentData.paymentMethodId,
    //       }
    //     );
    //     if (res) {
    //       postPatientconsultation(
    //         value,
    //         history,
    //         toast,
    //         getAllNotifications,
    //         getUnReadNotifications,
    //         getPatientconsultations
    //       );
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // } else {
    //   return toast({
    //     title:
    //       "Please add a payment method before making a consultation request",
    //     status: "error",
    //     duration: 4000,
    //     isClosable: true,
    //     position: "top-right",
    //   });
    // }
  };
  const saveAsDraft = () => {
    const timeNow = moment();
    const draftConsultation = {
      patientId: localStorage.patientId,
      created_at: timeNow,
      consultationTypeId,
      symptoms,
      symptomsExplained,
    };
    localStorage.setItem(
      "draftConsultation",
      JSON.stringify(draftConsultation)
    );
    history.push("draft-consultation");
  };
  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setconsultationTypes(consultationTypes.data);
    }
  }, [consultationTypes]);

  useEffect(() => {
    getPaymentList();
    getPatientEmergencyInfo();
    getPatientAddress();
  }, [getPaymentList, getPatientEmergencyInfo, getPatientAddress]);

  useEffect(() => {
    if (paymentList && paymentList.length) {
      getPatientPrePaymentData();
    }
  }, [paymentList, getPatientPrePaymentData]);

  useEffect(() => {
    if (submitdData || draftConsultation) {
      setInitValue((oldValue) => {
        return {
          ...oldValue,
          ...submitdData,
          ...draftConsultation,
        };
      });
    }
  }, [submitdData]);

  useEffect(() => {
    if (draftConsultation) {
      setInitValue((oldValue) => {
        return {
          ...oldValue,
          ...draftConsultation,
        };
      });
      setConsultationTypeId(draftConsultation.consultationTypeId);
      setSymptoms(draftConsultation.symptoms);
      setSymptomsExplained(draftConsultation.symptomsExplained);
    }
  }, [draftConsultation]);
  return (
    <motion.div
      className="container my-5"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="row m-0">
        <Formik
          enableReinitialize
          initialValues={initVal}
          onSubmit={onFormSubmit}
          validationSchema={Schema}
        >
          {({ values, handleBlur, handleChange }) => (
            <Form className="row m-0">
              <Text
                fontSize="2xl"
                fontWeight="semibold"
                className="mb-3"
                color={checkColorMode(colorMode)}
              >
                Request Consultation
              </Text>
              <Box
                bg={checkBgColorMode(colorMode)}
                className="row rounded shadow-sm p-3  justify-content-center m-0"
              >
                <div className="my-5">
                  <div className="d-flex justify-content-center">
                    <img
                      className="image-fluid"
                      src={ConsultationRequest}
                      alt="consaltation_image"
                    />
                  </div>
                </div>

                <div className="text-center">
                  <Text
                    fontSize="3xl"
                    fontWeight="700"
                    color={checkColorMode(colorMode)}
                  >
                    What are your symptoms?
                  </Text>
                  <Text fontSize="md" fontWeight="400" color="gray.400">
                    Please describe what you are feeling
                  </Text>
                </div>

                <div className="row justify-content-center flex-wrap p-0">
                  <div className="col-md-4 mt-2 ">
                    <SelectControl
                      name="consultationTypeId"
                      selectProps={{
                        placeholder: "Select speciality",
                        style: { fontSize: "14px" },
                      }}
                      className="mt-3 SelectControl"
                      onChange={(e) => setConsultationTypeId(e.target.value)}
                    >
                      {ConsultationTypes.length
                        ? ConsultationTypes.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </SelectControl>
                  </div>
                  <div className=" col-md-4 mt-2 ">
                    <InputControl
                      type="text"
                      name="symptoms"
                      className="rounded mt-3"
                      inputProps={{
                        placeholder: "What are your symptoms? Sore throat",
                        style: { fontSize: "13.5px" },
                      }}
                      onInput={(e) => setSymptoms(e.target.value)}
                    />
                  </div>
                  <div className="col-md-8 col-12 mt-2">
                    <Textarea
                      className="mt-3 rounded"
                      value={values.symptomsExplained}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => handleBlur(e)}
                      name="symptomsExplained"
                      placeholder="Please explain your symptoms in detail."
                      size="sm"
                      fontSize="14px"
                      onInput={(e) => setSymptomsExplained(e.target.value)}
                    />
                    <ErrorMessage
                      component="div"
                      name="symptomsExplained"
                      style={{ color: "#d42c51", fontSize: "14px" }}
                      className="mt-1"
                    />
                  </div>
                  <div className="col-md-8 col-12 my-3">
                    <Text fontWeight={700} color={checkColorMode(colorMode)}>
                      Add More
                    </Text>
                  </div>
                  <div className="col-md-8 row p-0 m-0">
                    <ConsultationModal
                      childData={childData}
                      name="Conditions"
                      component={<AddCondition setChildData={setChildData} />}
                    />
                    <ConsultationModal
                      childData={childData}
                      name="Medical Report"
                      component={<AddMedical setChildData={setChildData} />}
                    />
                    <ConsultationModal
                      childData={childData}
                      name="Readings"
                      component={<MyReading />}
                    />
                    <ConsultationModal
                      childData={childData}
                      name="ECG"
                      component={<AddEcg setChildData={setChildData} />}
                    />
                    <ConsultationModal
                      childData={childData}
                      name="Allergies"
                      component={<AddAllergies setChildData={setChildData} />}
                    />
                    <ConsultationModal
                      childData={childData}
                      name="MRI/CT Scan"
                      component={
                        <AddRadiology
                          setChildData={setChildData}
                          history={history}
                        />
                      }
                    />
                    <ConsultationModal
                      childData={childData}
                      name="Lab Result"
                      component={<AddLapResult setChildData={setChildData} />}
                    />
                    <ConsultationModal
                      childData={childData}
                      name="X Ray"
                      component={<AddXray setChildData={setChildData} />}
                    />
                  </div>
                </div>
              </Box>

              <div className="row p-0 justify-content-between align-items-center m-0 my-3">
                <div className="col-md-6 col-12 mb-3 row m-0 ">
                  <div className="col-md-6 col-12 my-1">
                    <Button
                      w="100%"
                      size="lg"
                      fontSize={16}
                      bg={"secondary"}
                      color="#fff"
                      _hover={{ bg: "secondary_hover" }}
                      isLoading={postedConsultation.loading}
                      disabled={
                        !consultationTypeId || !symptoms || !symptomsExplained
                      }
                      onClick={saveAsDraft}
                    >
                      Save as a draft
                    </Button>
                  </div>

                  <div className="col-md-6 col-12 my-1">
                    <Button
                      colorScheme="red"
                      variant="outline"
                      w="100%"
                      size="lg"
                      fontSize={16}
                    >
                      <Link
                        to="/consultations"
                        className="nav-link text-danger w-100"
                      >
                        Cancel
                      </Link>
                    </Button>
                  </div>
                </div>

                <div className="col-md-3 col-12 mb-3">
                  <Button
                    w="100%"
                    size="lg"
                    type="submit"
                    fontSize={16}
                    bg={checkColorMode(colorMode)}
                    color="#fff"
                    _hover={{ bg: checkColorModeHover(colorMode) }}
                    isLoading={postedConsultation.loading}
                  >
                    Submit
                    {/* and Pay */}
                    <span className="ms-3 fw-bold">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};
const mapStateToProps = (state) => {
  return {
    consultationTypes: state.consultationTypes,
    paymentList: state.paymentList.data,
    PrePaymentData: state.PrePaymentData.data,
    postedConsultation: state.postConsultation,
    patientAddress: state.patientAddress.data,
    patientEmergency: state.patientEmergency.data,
  };
};
export default connect(mapStateToProps, {
  postPatientconsultation,
  getAllNotifications,
  getUnReadNotifications,
  getPatientconsultations,
  getPatientPrePaymentData,
  getPaymentList,
  getPatientEmergencyInfo,
  getPatientAddress,
})(Addconsaltation);
