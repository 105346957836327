import React, { useState, useEffect } from "react";

import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  List,
  ListItem,
  Box,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import AlertComponent from "../../global/Alert.Component";

import { NavLink, Link, useHistory } from "react-router-dom";
import { navList } from "./NavBar";
import { connect } from "react-redux";
import { patientLogot, deletePatientProfile } from "../../store/actions";
function DrawerComponent({
  patientProfile,
  patientLogot,
  deletePatientProfile,
}) {
  const [patient, setPatient] = useState();
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const history = useHistory();
  const toast = useToast();

  const deleteAccount = () => {
    deletePatientProfile(history, toast);
  };

  useEffect(() => {
    if (patientProfile) {
      setPatient(patientProfile);
    }
  }, [patientProfile]);

  return (
    <>
      <Button
        ref={btnRef}
        onClick={onOpen}
        color={colorMode === "dark" ? "secondary" : "primary"}
        fontSize="20px"
      >
        <i className="fas fa-bars"></i>
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <Button
            maxW="20px"
            onClick={() => toggleColorMode()}
            className="ms-2 mt-2"
          >
            {colorMode === "dark" ? (
              <span>
                <i className="fas fa-moon"></i>
              </span>
            ) : (
              <span>
                <i className="fas fa-moon"></i>
              </span>
            )}
          </Button>
          <DrawerCloseButton />

          <DrawerBody>
            <Box>
              <Menu>
                <MenuButton
                  size="lg"
                  as={Button}
                  variant="ghost"
                  className="mt-5 w-100"
                  bg={
                    colorMode === "dark"
                      ? "secondary_hover_Opacity"
                      : "primaryOpacity_hover"
                  }
                >
                  <div className="d-flex align-items-center justify-content-center ">
                    <div className="me-1">
                      <Avatar
                        size="lg"
                        name={patient ? patient.fullName : null}
                        src={patient ? patient.picture : null}
                        bg={colorMode === "dark" ? "secondary" : "primary"}
                      />
                    </div>
                    <div className="">
                      <Text
                        fontSize={{
                          base: "18px",
                        }}
                      >
                        {patient
                          ? `${patient.firstName} ${patient.lastName}`
                          : null}{" "}
                      </Text>
                      <Text
                        fontSize="13px"
                        color="gray.400"
                        className="text-start userType"
                      >
                        {localStorage.getItem("userType")
                          ? localStorage.getItem("userType")
                          : ""}
                      </Text>
                    </div>
                  </div>
                </MenuButton>
                <MenuList className="menu-notification p-1 shadow">
                  <MenuItem className="d-flex flex-wrap" maxW="250px">
                    <Text
                      fontWeight="semibold"
                      color={"secondary"}
                      className="w-100 my-3 text-center"
                    >
                      {patient ? patient.fullName : null}
                    </Text>

                    <Button
                      onClick={onClose}
                      as={Link}
                      to="/profile/share_data"
                      className="text-white w-100 d-flex justify-content-center rounded"
                      bg={colorMode === "dark" ? "secondary" : "primary"}
                      _hover={{
                        bg:
                          colorMode === "dark"
                            ? "secondary_hover"
                            : "primary_hover",
                      }}
                    >
                      <span className="me-4">
                        <i className="fa fa-share"></i>
                      </span>
                      <Text fontSize="18px" className="me-1">
                        Share Data
                      </Text>
                    </Button>
                  </MenuItem>

                  <MenuItem className="p-3" onClick={onClose}>
                    <Link
                      to="/profile/my_info"
                      className="nav-link p-0 w-100  d-flex justify-content-between "
                    >
                      <div className="d-flex">
                        <Text className="me-3" color="#979797">
                          <i className="fa fa-user"></i>
                        </Text>
                        <Text fontSize="15px" className="me-1" color="#979797">
                          My Info
                        </Text>
                      </div>
                      <Text color="#979797">
                        <i className="fas fa-chevron-right"></i>
                      </Text>
                    </Link>
                  </MenuItem>

                  <MenuItem className="p-3" onClick={onClose}>
                    <Link
                      to="/profile/payment"
                      className="nav-link p-0 w-100  d-flex justify-content-between"
                    >
                      <div className="d-flex">
                        <Text className="me-3" color="#979797">
                          <i className="fa fa-credit-card"></i>
                        </Text>
                        <Text fontSize="15px" className="me-1" color="#979797">
                          Payment
                        </Text>
                      </div>
                      <Text color="#979797">
                        <i className="fas fa-chevron-right"></i>
                      </Text>
                    </Link>
                  </MenuItem>

                  <MenuItem className="p-3" onClick={onClose}>
                    <Link
                      to="/profile/change_password"
                      className="nav-link p-0 w-100  d-flex justify-content-between"
                    >
                      <div className="d-flex">
                        <Text className="me-3" color="#979797">
                          <i className="fa fa-unlock-alt"></i>
                        </Text>
                        <Text fontSize="15px" className="me-1" color="#979797">
                          Change Password
                        </Text>
                      </div>
                      <Text color="#979797">
                        <i className="fas fa-chevron-right"></i>
                      </Text>
                    </Link>
                  </MenuItem>

                  <MenuItem
                    className="p-3"
                    onClick={() => {
                      patientLogot();
                      onClose();
                    }}
                  >
                    <Link
                      to="#"
                      className="nav-link p-0 w-100  d-flex justify-content-between"
                    >
                      <div className="d-flex">
                        <Text className="me-3" color="#979797">
                          <i className="fas fa-sign-out-alt"></i>{" "}
                        </Text>
                        <Text fontSize="15px" className="me-1" color="#979797">
                          Logout
                        </Text>
                      </div>
                      <Text color="#979797">
                        <i className="fas fa-chevron-right"></i>
                      </Text>
                    </Link>
                  </MenuItem>

                  <AlertComponent
                    deleteAccount={true}
                    nameForAlert="account"
                    removeOne={deleteAccount}
                  />
                </MenuList>
              </Menu>
            </Box>
            <List spacing={3} className="mt-5 ">
              <ListItem className="w-100">
                <Button
                  onClick={onClose}
                  color={colorMode === "dark" ? "secondary" : "primary"}
                  as={NavLink}
                  className=" w-100"
                  exact
                  to={"/"}
                  _hover={{
                    color: colorMode === "dark" ? "secondary" : "primary",
                    bg:
                      colorMode === "dark"
                        ? "secondary_hover_Opacity"
                        : "primaryOpacity_hover",
                    borderLeft: `solid 0.16rem`,
                    borderColor: colorMode === "dark" ? "secondary" : "primary",
                  }}
                  _focus={{ border: "none" }}
                  activeClassName={
                    colorMode === "dark"
                      ? "active-sideNav-link-dark"
                      : "active-sideNav-link"
                  }
                >
                  Home
                </Button>
              </ListItem>
              {navList.map((link, index) => (
                <ListItem className="w-100" key={index}>
                  <Button
                    onClick={onClose}
                    color={colorMode === "dark" ? "secondary" : "primary"}
                    as={NavLink}
                    className="w-100"
                    to={link.linkTo}
                    _hover={{
                      color: colorMode === "dark" ? "secondary" : "primary",
                      bg:
                        colorMode === "dark"
                          ? "secondary_hover_Opacity"
                          : "primaryOpacity_hover",
                      borderLeft: `solid 0.16rem`,
                      borderColor:
                        colorMode === "dark" ? "secondary" : "primary",
                    }}
                    _focus={{ border: "none" }}
                    activeClassName={
                      colorMode === "dark"
                        ? "active-sideNav-link-dark"
                        : "active-sideNav-link"
                    }
                  >
                    {link.text}
                  </Button>
                </ListItem>
              ))}
            </List>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    logout: state.logout,
    patientProfile: state.PatientProfile.data,
    unRead: state.unRead,
  };
};
export default connect(mapStateToProps, { patientLogot, deletePatientProfile })(
  DrawerComponent
);
