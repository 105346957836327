import axios from "axios";
import { types } from "../types/types";
const { notificationTypes } = types;

const API_URL = process.env.REACT_APP_API_URL;

export const getAllNotifications = (pageNumber = 1) => {
  return async (dispatch) => {
    dispatch({
      type: notificationTypes.GET_NOTIFICATIONS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/notifications?page=${pageNumber}`,
      });
      if (res) {
        dispatch({
          type: notificationTypes.GET_NOTIFICATIONS_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: notificationTypes.GET_NOTIFICATIONS_ERROR,
        payload: err,
      });
    }
  };
};

export const getUnReadNotifications = () => {
  return async (dispatch) => {
    dispatch({
      type: notificationTypes.GET_UNREAD_NOTIFICATIONS_SUCSES,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/notifications/unread-count`,
      });
      dispatch({
        type: notificationTypes.GET_UNREAD_NOTIFICATIONS_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: notificationTypes.GET_UNREAD_NOTIFICATIONS_ERROR,
        payload: err.response,
      });
    }
  };
};

export const markAllNotificationsAsRead = (
  getAllNotifications,
  query,
  setMarkAll,
  getUnReadNotifications
) => {
  return async (dispatch) => {
    dispatch({
      type: notificationTypes.MARK_ALL_AS_READIND_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/notifications/mark-all-as-read`,
      });
      if (res) {
        if (getAllNotifications) getAllNotifications(query ? query : 1);
        if (setMarkAll) setMarkAll(false);
        if (getUnReadNotifications) getUnReadNotifications();
        dispatch({
          type: notificationTypes.MARK_ALL_AS_READIND_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: notificationTypes.MARK_ALL_AS_READIND_ERROR,
        payload: err.response,
      });
    }
  };
};

export const markOneNotificationAsRead = (
  id,
  getAllNotifications,
  query,
  getUnReadNotifications
) => {
  return async (dispatch) => {
    dispatch({
      type: notificationTypes.MARK_ONE_AS_READING_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/notifications/${id}/mark-as-read`,
      });
      if (res) {
        getAllNotifications(query);
        getUnReadNotifications();
        dispatch({
          type: notificationTypes.MARK_ONE_AS_READING_SUCSES,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: notificationTypes.MARK_ONE_AS_READING_ERROR,
        payload: err.response,
      });
    }
  };
};
