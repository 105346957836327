const INIT_XRAY_STATR = {
  loading: false,
  error: null,
  data: null,
};

export const getXRAYReducer = (state = INIT_XRAY_STATR, { type, payload }) => {
  switch (type) {
    case "GET_XRAY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_XRAY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_XRAY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneXRAYReducer = (
  state = INIT_XRAY_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_ONE_XRAY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_XRAY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_XRAY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const postXRAYReducer = (state = INIT_XRAY_STATR, { type, payload }) => {
  switch (type) {
    case "POST_XRAY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_XRAY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_XRAY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const updateXRAYReducer = (
  state = INIT_XRAY_STATR,
  { type, payload }
) => {
  switch (type) {
    case "UPDATE_XRAY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_XRAY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_XRAY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const deleteXRAYReducer = (
  state = INIT_XRAY_STATR,
  { type, payload }
) => {
  switch (type) {
    case "DELETE_XRAY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_XRAY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "DELETE_XRAY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
