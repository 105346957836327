export const convertDateAndTime = (date) => {
  let dateObject = new Date(date * 1000);
  let month = dateObject.getMonth() + 1;
  let year = dateObject.getFullYear();
  let day = dateObject.getDate();
  let hours = dateObject.getHours();
  let minutes = "0" + dateObject.getMinutes();
  let seconds = "0" + dateObject.getSeconds();

  return `${month}/${day}/${year} - ${hours} : ${minutes.substr(
    -2
  )} : ${seconds.substr(-2)}`;
};

export const convertDate = (date) => {
  let dateObject = new Date(date * 1000);
  let month = dateObject.getMonth() + 1;
  let year = dateObject.getFullYear();
  let day = dateObject.getDate();
  return `${month}/${day}/${year} `;
};

const dateHelper = dateHelperFactory();
export const formatMe = (date) => {
  const vals = [...`yyyy,mm,dd,hh,mmi,ss,mms`.split(`,`)];
  const myDate = dateHelper(date).toArr(...vals);
  return `${myDate.slice(0, 3).join(`/`)} ${myDate.slice(3, 6).join(`:`)}.${
    myDate.slice(-1)[0]
  }`;
};
export const formatMe2 = (date) => {
  const vals = [...`mm,dd,yyyy,hh,mmi,amPm`.split(`,`)];
  const myDate = dateHelper(date).toArr(...vals);
  return `${myDate.slice(0, 3).join(`/`)} - ${myDate
    .slice(3, 5)
    .join(`:`)} ${myDate.slice(5, 6)}`;
};
export const formatTime = (date) => {
  const vals = [...`mm,dd,yyyy,hh,mmi,amPm`.split(`,`)];
  const myDate = dateHelper(date).toArr(...vals);
  return `${myDate.slice(3, 5).join(`:`)}`;
};

function dateHelperFactory() {
  const padZero = (val, len = 2) => `${val}`.padStart(len, `0`);
  const setValues = (date) => {
    let vals = {
      yyyy: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours() % 12,
      mi: date.getMinutes(),
      // s: date.getSeconds(),
      // ms: date.getMilliseconds(),
      amPm: date.getHours() >= 12 ? "PM" : "AM",
    };
    Object.keys(vals)
      .filter((k) => k !== `yyyy`)
      .forEach(
        (k) => (vals[k[0] + k] = padZero(vals[k], (k === `ms` && 3) || 2))
      );
    return vals;
  };

  return (date) => ({
    values: setValues(date),
    toArr(...items) {
      return items.map((i) => this.values[i]);
    },
  });
}

export const dateYYY_dd_mm = (date) => {
  return `${dateHelper(new Date(date * 1000)).values.yyyy}-${
    dateHelper(new Date(date * 1000)).values.mm
  }-${dateHelper(new Date(date * 1000)).values.dd}`;
};
// const date = new Date();
// const last7day = date.getDate() - 7;
// const month = date.getMonth() + 1;
// console.log(date.getMonth());

export const getLast7Days = (list) => {
  const date = new Date();
  const last7day = date.getDate() - 7;
  const month = date.getMonth() + 1;

  const lastWeek = list.filter((item) => {
    const dates = new Date(item.createdAt * 1000 || item.created_at * 1000);

    return dates.getDate() > last7day && dates.getMonth() + 1 === month;
  });
  return lastWeek;
};
