import { Text, Button, useColorMode, Box } from "@chakra-ui/react";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Consultation from "../../assets/Consultation.png";
import { style } from "../../global/style";
import { checkBgColorMode } from "../../global/changeColor";

const FirstviewConsaltation = () => {
  const { colorMode } = useColorMode();

  let { url } = useRouteMatch();
  return (
    <Box className="container">
      <Box
        bg={checkBgColorMode(colorMode)}
        className="row rounded shadow-sm p-3 m-0 justify-content-center my-5"
      >
        <div className="col-4 my-5">
          <img
            className="image-fluid"
            src={Consultation}
            alt="consaltation_image"
          />
        </div>
        <div className="text-center">
          <Text fontSize="3xl" fontWeight="700">
            Start Your first consultation
          </Text>
          <Text fontSize="md" fontWeight="400" color="gray.400">
            Please tap the button below to begin your consultation
          </Text>
        </div>
        {/* <div className="d-flex justify-content-center my-3">
          <Text
            bg={colorMode === "dark" ? "gray.800" : "gray.100"}
            className="rounded "
            w="320px"
            p="14px"
          >
            <span className="mx-4 ">
              <i className="fas fa-pound-sign"></i>
            </span>
            <span className="fw-bold ms-4">You Pay: 250GBP</span>
          </Text>
        </div> */}
        <div className="col-lg-4 col-12 my-3">
          <Button
            size="lg"
            w="100%"
            background={style.primary}
            _hover={{ bg: style.primary_hover }}
          >
            <Link
              to={`${url}/add-consultation`}
              className="nav-link p-0 text-white w-100"
            >
              Request Consultation
            </Link>
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default FirstviewConsaltation;
