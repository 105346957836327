import axios from "axios";
import { handeleError, handeleSuccess } from "../toast";
const API_URL = process.env.REACT_APP_API_URL;
export const getPatientEcg = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ECG_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/ecgs?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/ecgs/`,
      });
      dispatch({
        type: "GET_ECG_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ECG_ERROR",
        payload: error,
      });
    }
  };
};
export const getPatientEcgForPagination = (
  pageNumber,
  patientId,
  informationShareToken
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ECG_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/ecgs?informationShareToken=${informationShareToken}&page=${pageNumber}`
            : `${API_URL}/patient/ecgs?page=${pageNumber}`,
      });
      dispatch({
        type: "GET_ECG_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ECG_ERROR",
        payload: error,
      });
    }
  };
};

export const getOneEcg = (id, toast, history, patientId, sharedToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_ECG_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && sharedToken
            ? `${API_URL}/patient/${patientId}/ecgs/show/${id}?informationShareToken=${sharedToken}`
            : `${API_URL}/patient/ecgs/show/${id}`,
      });
      dispatch({
        type: "GET_ONE_ECG_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast, null, "ECG");
      history.push("/medical-record/ecg");
      dispatch({
        type: "GET_ONE_ECG_ERROR",
        payload: error,
      });
    }
  };
};

export const postPatientEcg = (
  data,
  history,
  toast,
  setChildData,
  getAllNotifications,
  getUnReadNotifications
) => {
  let formData = new FormData();
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file, file.name));
  }
  formData.append("title", data.title);
  return async (dispatch) => {
    dispatch({
      type: "POST_ECG_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/ecgs`,

        data: formData,
      });
      if (res) {
        handeleSuccess("Your ECG has been added successfully", toast);
        getAllNotifications();
        getUnReadNotifications();
        history ? history.goBack() : setChildData(res.data);

        dispatch({
          type: "POST_ECG_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "POST_ECG_ERROR",
        payload: error,
      });
    }
  };
};

export const updateEcg = (data, id, history, toast) => {
  let formData = new FormData();
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file));
  }
  formData.append("title", data.title);

  return async (dispatch) => {
    dispatch({
      type: "UPDATE_ECG_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/ecgs/${id}`,

        data: formData,
      });
      if (res) {
        handeleSuccess("Your Ecg has been updated successfully", toast);
        history.goBack();
        dispatch({
          type: "UPDATE_ECG_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_ECG_ERROR",
        payload: error,
      });
    }
  };
};

export const deleteEcg = (id, toast, history, goTo) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_ECG_LOADING",
    });
    try {
      const res = await axios({
        method: "DELETE",
        url: `${API_URL}/patient/ecgs/${id}`,
      });
      if (res) {
        handeleSuccess("Your ECG deleted successfully", toast);
        history.push(goTo);
        dispatch({
          type: "DELETE_ECG_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);

      dispatch({
        type: "DELETE_ECG_ERROR",
        payload: error,
      });
    }
  };
};
