import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { InputControl } from "formik-chakra-ui";
import { Text, Button, useToast, useColorMode, Box } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import {
  postPatientAddress,
  updatePatientAddress,
  getPatientAddress,
} from "../../../store/actions";
import { connect } from "react-redux";
import * as Yup from "yup";
import { handeleError } from "../../../store/actions/toast";
import { checkBgColorMode } from "../../../global/changeColor.js";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const SubmitSchema = Yup.object().shape({
  country: Yup.string().required(),
  city: Yup.string().required(),
  street: Yup.string().required(),
  postel_code: Yup.string().required("Postal code is a required field"),
});
const EditMyAddress = ({
  history,
  patientAddress,
  postPatientAddress,
  updatePatientAddress,
  getPatientAddress,
}) => {
  const { colorMode } = useColorMode();
  let query = useQuery().get("addressID");
  let redirectTo = useQuery().get("redirectTo");
  const toast = useToast();
  const [initValue, setInitValue] = useState({
    country: "",
    city: "",
    street: "",
    postel_code: "",
  });
  useEffect(() => {
    if (query) {
      return getPatientAddress();
    }
  }, [query, getPatientAddress]);

  useEffect(() => {
    if (!query && patientAddress && patientAddress.country) {
      handeleError(null, toast, "You already have an address");
      history.push("/profile/my_info");
    }
  }, [query, patientAddress, toast, history]);

  useEffect(() => {
    if (query && patientAddress) {
      setInitValue((initValue) => {
        return {
          ...initValue,
          country: patientAddress.country ? patientAddress.country : "",
          city: patientAddress.city ? patientAddress.city : "",
          street: patientAddress.street ? patientAddress.street : "",
          postel_code: patientAddress.postelCode
            ? patientAddress.postelCode
            : "",
        };
      });
    }
  }, [query, patientAddress]);

  const onFormSubmit = (value) => {
    if (query) {
      updatePatientAddress(value, query, toast, history).then(() => {
        return getPatientAddress();
      });
    } else {
      postPatientAddress(value, toast, history, redirectTo).then(() => {
        return getPatientAddress();
      });
    }
  };

  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="EditMyAddress  rounded shadow-sm p-4  d-flex flex-wrap"
    >
      <Formik
        initialValues={initValue}
        enableReinitialize
        onSubmit={onFormSubmit}
        validationSchema={SubmitSchema}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <div className="col-12 d-flex justify-content-between align-items-center">
              <Text color="secondary" fontSize="lg" className="fw-bold">
                {" "}
                My Address
              </Text>
              <div>
                <Button
                  width={"50px"}
                  height="50px"
                  bg="#f3e9eb"
                  _hover={{ bg: "#f3d3da" }}
                  fontSize="20px"
                  color="#D42C51"
                  className="me-2"
                >
                  <Link to={"/profile/my_info"} className="nav-link text-error">
                    <i className="fas fa-times"></i>
                  </Link>
                </Button>
                <Button
                  width={"50px"}
                  height="50px"
                  bg="secondary"
                  _hover={{ bg: "secondary_hover" }}
                  fontSize="20px"
                  type="submit"
                  color="#fff"
                >
                  <i className="fas fa-save"></i>
                </Button>
              </div>
            </div>

            <div className="col-12 row">
              <div className="col-md-6">
                <label className="w-100 " htmlFor="country">
                  Country
                </label>

                <div className="mt-2 ">
                  <CountryDropdown
                    classes={`${
                      colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
                    } text-secondary`}
                    name="country"
                    defaultOptionLabel="Select a country"
                    value={values.country}
                    onChange={(_, e) => handleChange(e)}
                    onBlur={(_, e) => handleBlur(e)}
                  />
                  {/* <span><i className='fas fa-angle-down'></i></span> */}
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "14px" }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <label className="w-100 " htmlFor="city">
                  City
                </label>
                <div className="mt-2 ">
                  <RegionDropdown
                    classes={`${
                      colorMode === "dark" ? "css-xpongc" : "css-1c6j008"
                    } text-secondary`}
                    name="city"
                    blankOptionLabel="No country selected"
                    defaultOptionLabel="Now select a City"
                    country={values.country}
                    value={values.city}
                    onChange={(_, e) => handleChange(e)}
                    onBlur={(_, e) => handleBlur(e)}
                  />
                  {/* <span><i className='fas fa-angle-down'></i></span> */}
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-danger"
                    style={{ fontSize: "14px" }}
                  />
                </div>
              </div>

              <div className="col-md-8 mt-2">
                <InputControl
                  name="street"
                  label="Street"
                  inputProps={{ placeholder: "Street" }}
                />
              </div>

              <div className="col-md-4 mt-2">
                <InputControl
                  name="postel_code"
                  label="Postal code"
                  inputProps={{ placeholder: "Postal codef", type: "text" }}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    patientAddress: state.patientAddress.data,
  };
};
export default connect(mapStateToProps, {
  postPatientAddress,
  updatePatientAddress,
  getPatientAddress,
})(EditMyAddress);
