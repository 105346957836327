import React, { useState, useEffect } from "react";
import { useRouteMatch, useLocation, useHistory, Link } from "react-router-dom";
import { Circle, Box, Text, Image, useColorMode } from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  getAllNotifications,
  markAllNotificationsAsRead,
} from "../../store/actions";
import Pagination from "../Pagination";
import { LinksAndImages } from "./helperImageAndLinkes";
import { style } from "../../global/style";
import { formatMe2 } from "../../global/ConverDate";
import MenuComponent from "./menu.Component";
import moment from "moment";
import {
  checkColorMode,
  checkBgColorMode,
  checkColorModeHover,
} from "../../global/changeColor";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
export const checkDate = (date) => {
  const sendDate = moment.unix(date).format("YYYY-MM-DD HH:mm");
  const twoDaysLeft = moment()
    .subtract(1, "days")
    .startOf("day")
    .format("YYYY-MM-DD HH:mm");
  if (moment(sendDate).isBefore(twoDaysLeft)) {
    return true;
  } else {
    return false;
  }
};
const Notifications = ({
  getAllNotifications,
  notificationsList,
  markAllNotificationsAsRead,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Notifications, setNotifications] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  const [markAll, setMarkAll] = useState(false);

  const { url } = useRouteMatch();
  const { colorMode } = useColorMode();
  // const now = moment(new Date())
  //   .subtract(2, "days")
  //   .startOf("day")
  //   .format("MM/DD/YYYY");

  // console.log(moment("05/08/2023").isBefore(now));

  let query = useQuery().get("page");
  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    getAllNotifications(query);
  }, [query, getAllNotifications]);

  useEffect(() => {
    const { loading, error, data } = notificationsList;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      list.map((item) => {
        if (item.readAt == null) {
          setMarkAll(true);
        }
        return null;
      });
      setNotifications(list);
      setTotalResults(data.meta.total);
      setCuttentPage(data.meta.current_page);
    }
  }, [notificationsList]);

  const renderView = () => {
    if (loading) {
      return "loading";
    }
    if (error) {
      return "error";
    }
    if (Notifications.length) {
      return Notifications.map((item, index) => (
        <Box
          key={index}
          className="col-12 row m-0 shadow-sm mb-2 p-4  rounded text-dark align-items-center"
          pointerEvents={
            item.readAt && checkDate(item.createdAt) ? "none" : "auto"
          }
          opacity={item.readAt && checkDate(item.createdAt) ? ".6" : "1"}
          bg={checkBgColorMode(colorMode)}
        >
          {LinksAndImages(item).anchor ? (
            <Link
              to={LinksAndImages(item).link}
              className="col-md-8 row m-0 text-dark"
              disabled={true}
            >
              <div className="col-md-7 row align-items-center m-0 ">
                <Box w="20%">
                  <Circle
                    size="40px"
                    bg={checkColorMode(colorMode)}
                    color="#fff"
                  >
                    {LinksAndImages(item).img ? (
                      <Image
                        maxW={"80%"}
                        maxH="70%"
                        className="image-fluid"
                        src={LinksAndImages(item).img}
                        alt={item.data.type ? item.data.type : "no"}
                      />
                    ) : (
                      <i className={LinksAndImages(item).className}></i>
                    )}
                  </Circle>
                </Box>

                <Box className="col-9 p-0">
                  <Text color="smallTextGray" fontSize="15px" fontWeight="600">
                    Activity
                  </Text>
                  <Text
                    fontSize="13px"
                    fontWeight="700"
                    color={colorMode === "dark" ? "white" : "dark"}
                  >
                    {item.data && item.data.title ? item.data.title : "null"}
                  </Text>
                </Box>
              </div>
              <Box className="col-md-5 ">
                <Box className="">
                  <Text color="smallTextGray" fontSize="15px" fontWeight="600">
                    Time
                  </Text>
                  <Text
                    fontSize="13px"
                    fontWeight="700"
                    color={colorMode === "dark" ? "white" : "dark"}
                  >
                    {item.updatedAt
                      ? moment
                          .unix(item.updatedAt)
                          .format("DD/MM/YYYY - hh:mm A")
                      : "null"}
                  </Text>
                </Box>
              </Box>
            </Link>
          ) : (
            <a
              href={LinksAndImages(item).link}
              className="col-md-8 row m-0 text-dark"
              target={LinksAndImages(item).target || null}
            >
              <div className="col-md-7 row align-items-center m-0 ">
                <Box w="20%">
                  <Circle size="40px" bg={style.primary} color="#fff">
                    {LinksAndImages(item).img ? (
                      <Image
                        maxW={"80%"}
                        maxH="70%"
                        className="image-fluid"
                        src={LinksAndImages(item).img}
                        alt={item.data.type ? item.data.type : "no"}
                      />
                    ) : (
                      <i className={LinksAndImages(item).className}></i>
                    )}
                  </Circle>
                </Box>

                <Box className="col-9 p-0">
                  <Text color="gray.400" fontSize="15px" fontWeight="600">
                    Activity
                  </Text>
                  <Text fontSize="13px" fontWeight="700">
                    {item.data && item.data.title ? item.data.title : "null"}
                  </Text>
                </Box>
              </div>
              <Box className="col-md-5 ">
                <Box className="">
                  <Text color="gray.400" fontSize="15px" fontWeight="600">
                    Time
                  </Text>
                  <Text fontSize="13px" fontWeight="700">
                    {item.updatedAt
                      ? formatMe2(new Date(item.updatedAt * 1000))
                      : "null"}
                  </Text>
                </Box>
              </Box>
            </a>
          )}

          <Box className="col-4 text-end">
            <MenuComponent
              item={item}
              LinksAndImages={LinksAndImages}
              style={style}
              query={query}
            />
          </Box>
        </Box>
      ));
    }
  };
  return (
    <div className="container">
      <div className="row m-0">
        <Box className="col-12 row my-4 m-0 align-items-center">
          <Text
            fontSize="3xl"
            fontWeight="semibold"
            className="col-md-6"
            color={checkColorMode(colorMode)}
          >
            My Activity
          </Text>
          <Box className="col-md-6 row m-0 justify-content-end">
            <Box
              w="auto"
              fontSize="xl"
              fontWeight="semibold"
              color={checkColorMode(colorMode)}
              transition=".3s"
              _hover={{
                color: checkColorModeHover(colorMode),
              }}
              cursor={markAll ? "pointer" : "not-allowed"}
              className="rounded p-1 px-2"
              onClick={() => {
                markAllNotificationsAsRead(
                  getAllNotifications,
                  query,
                  setMarkAll
                );
              }}
              pointerEvents={markAll ? "normal" : "none"}
              opacity={markAll ? 1 : 0.5}
            >
              <Box className="me-3 d-flex">
                <Text className="me-3" color={"secondary"}>
                  <i
                    className={markAll ? "fas fa-question" : "fas fa-check"}
                  ></i>{" "}
                </Text>
                Mark All As Read
              </Box>
            </Box>
          </Box>
        </Box>

        {renderView()}

        {totalResults > 15 ? (
          <Pagination
            pages={pagesNumber}
            nextPage={nextPage}
            prevPage={prevPage}
            specificPage={specificPage}
            currentPage={currentPage}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const mapStateToprops = (state) => {
  return {
    notificationsList: state.notificationsList,
    unRead: state.unRead,
    markAll: state.markAll,
    markOne: state.markOne,
  };
};
export default connect(mapStateToprops, {
  getAllNotifications,
  markAllNotificationsAsRead,
})(Notifications);
