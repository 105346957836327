import React, { useState, useEffect } from "react";
import { Text, Button, Box, useToast, useColorMode } from "@chakra-ui/react";
import Art from "../../../assets/Art.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getPaymentList, deletePayment } from "../../../store/actions";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";

const Payment = ({
  getPaymentList,
  paymentList,
  PatientProfile,
  deletePayment,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [payList, setPayList] = useState([]);
  const toast = useToast();
  const { colorMode } = useColorMode();
  useEffect(() => {
    getPaymentList();
  }, [getPaymentList]);
  useEffect(() => {
    const { loading, error, data } = paymentList;
    setLoading(loading);
    setError(error);
    if (data) {
      setPayList(data);
    }
  }, [paymentList]);

  const RenderView = () => {
    if (loading) return "";
    if (error) return "error";
    if (payList.length) {
      return (
        <Box
          bg={checkBgColorMode(colorMode)}
          className="rounded shadow-sm p-4 m-0  row flex-wrap"
        >
          <Text color="secondary" fontSize="lg" className="fw-bold">
            {" "}
            Payment
          </Text>
          {payList.map((item, index) => (
            <Box key={index} className="row m-0 mt-4 justify-content-between">
              <Box
                className="col-sm-5 rounded p-3 row m-0"
                bg="primary"
                color={"#fff"}
                fontSize={"14px"}
              >
                <div className="col-10 p-0">
                  {PatientProfile ? PatientProfile.fullName : ""}
                </div>
                <Box
                  className="col-2 p-0"
                  color="secondary"
                  fontWeight="semibold"
                  fontStyle="italic"
                >
                  {item.brand}
                </Box>
                <Text className="mt-4 p-0" fontSize={"2xl"}>
                  **** **** **** {item.last4}
                </Text>
                <Box className="col-12 row m-0 p-0 mt-2">
                  <Box className="col-6 p-0 row m-0">
                    <Box className="p-0">EXPIRY DATE</Box>
                    <Box className="p-0">**/**</Box>
                  </Box>
                  <Box className="col-6 p-0 row m-0">
                    <Box className="p-0">CVC</Box>
                    <Box className="p-0">***</Box>
                  </Box>
                </Box>
              </Box>
              <Box className="col-sm-4 text-end mt-3">
                <Box className=" text-end">
                  {" "}
                  <Button
                    width={"50px"}
                    height="50px"
                    bg="#f3e9eb"
                    _hover={{ bg: "#f3d3da" }}
                    fontSize="20px"
                    color="#D42C51"
                    className="me-2"
                    onClick={() =>
                      deletePayment(
                        { paymentMethodId: item.id },
                        toast,
                        getPaymentList
                      )
                    }
                  >
                    <i className="fas fa-times"></i>
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      );
    } else {
      return (
        <Box
          bg={checkBgColorMode(colorMode)}
          className="Payment  rounded shadow-sm p-4  d-flex flex-wrap justify-content-center"
        >
          <div className="col-12">
            <Text color="secondary" fontSize="lg" className="fw-bold">
              {" "}
              Payment
            </Text>
          </div>

          <div className="col-md-6 col-12 my-5">
            <img className="image-fluid" src={Art} alt="Art" />
          </div>
          <div className="col-12 text-center">
            <Text fontSize="4xl" className="fw-bold col-12">
              {" "}
              Add Your Credit Card
            </Text>
          </div>
          <div className="col-12 text-center">
            <Text fontSize="lg" className="fw-normal my-4">
              {" "}
              Add your credit card to use the app
            </Text>
          </div>
          <div>
            <Button
              size="lg"
              bg={checkColorMode(colorMode)}
              _hover={{ bg: checkColorModeHover(colorMode) }}
            >
              <Link to="/profile/add_payment" className="nav-link text-white">
                <span className="me-3">
                  <i className="fas fa-plus"></i>
                </span>{" "}
                Add
              </Link>
            </Button>
          </div>
          {/* <div className="col-12 text-center">
            <PaymentConfig />
          </div> */}
        </Box>
      );
    }
  };
  return <RenderView />;
};
const mapStateToProps = (state) => {
  return {
    paymentList: state.paymentList,
    PatientProfile: state.PatientProfile.data,
  };
};
export default connect(mapStateToProps, { getPaymentList, deletePayment })(
  Payment
);
