export const types = {
  conditionTypes: {
    //// get List
    GET_CONDITION_LOADING: "GET_CONDITION_LOADING",
    GET_CONDITION_SUCSES: "GET_CONDITION_SUCSES",
    GET_CONDITION_ERROR: "GET_CONDITION_ERROR",
    //////// git one
    GET_ONE_CONDITION_LOADING: "GET_ONE_CONDITION_LOADING", 
    GET_ONE_CONDITION_SUCSES: "GET_ONE_CONDITION_SUCSES",
    GET_ONE_CONDITION_ERROR: "GET_ONE_CONDITION_ERROR",
    ///////  post
    POST_CONDITION_LOADING: "POST_CONDITION_LOADING",
    POST_CONDITION_SUCSES: "POST_CONDITION_SUCSES",
    POST_CONDITION_ERROR: "POST_CONDITION_ERROR",
    /// update
    UPDATE_CONDITION_LOADING: "UPDATE_CONDITION_LOADING",
    UPDATE_CONDITION_SUCSES: "UPDATE_CONDITION_SUCSES",
    UPDATE_CONDITION_ERROR: "UPDATE_CONDITION_ERROR",
    /// update
    DELETE_CONDITION_LOADING: "DELETE_CONDITION_LOADING",
    DELETE_CONDITION_SUCSES: "DELETE_CONDITION_SUCSES",
    DELETE_CONDITION_ERROR: "DELETE_CONDITION_ERROR",
  },
  profileTypes: {
    CHANGE_PASSWORD_LOADING: "CHANGE_PASSWORD_LOADING",
    CHANGE_PASSWORD_SUCSES: "CHANGE_PASSWORD_SUCSES",
    CHANGE_PASSWORD_ERORR: "CHANGE_PASSWORD_ERORR",
  },
  readingTypes: {
    GET_READINGS_LOADING: "GET_READINGS_LOADING",
    GET_READINGS_SUCSES: "GET_READINGS_SUCSES",
    GET_READINGS_ERROR: "GET_READINGS_ERROR",

    GET_ONE_READINGS_LOADING: "GET_ONE_READINGS_LOADING",
    GET_ONE_READINGS_SUCSES: "GET_ONE_READINGS_SUCSES",
    GET_ONE_READINGS_ERROR: "GET_ONE_READINGS_ERROR",

    POST_READINGS_LOADING: "POST_READINGS_LOADING",
    POST_READINGS_SUCSES: "POST_READINGS_SUCSES",
    POST_READINGS_ERROR: "POST_READINGS_ERROR",

    UPDATE_READINGS_LOADING: "UPDATE_READINGS_LOADING",
    UPDATE_READINGS_SUCSES: "UPDATE_READINGS_SUCSES",
    UPDATE_READINGS_ERROR: "UPDATE_READINGS_ERROR",

    DELETE_READINGS_LOADING: "DELETE_READINGS_LOADING",
    DELETE_READINGS_SUCSES: "DELETE_READINGS_SUCSES",
    DELETE_READINGS_ERROR: "DELETE_READINGS_ERROR",
  },
  notificationTypes: {
    GET_NOTIFICATIONS_LOADING: "GET_NOTIFICATIONS_LOADING",
    GET_NOTIFICATIONS_SUCSES: "GET_NOTIFICATIONS_SUCSES",
    GET_NOTIFICATIONS_ERROR: "GET_NOTIFICATIONS_ERROR",

    GET_FOR_PAGINATION_NOTIFICATIONS_LOADING:
      "GET_FOR_PAGINATION_NOTIFICATIONS_LOADING",
    GET_FOR_PAGINATION_NOTIFICATIONS_SUCSES:
      "GET_FOR_PAGINATION_NOTIFICATIONS_SUCSES",
    GET_FOR_PAGINATION_NOTIFICATIONS_ERROR:
      "GET_FOR_PAGINATION_NOTIFICATIONS_ERROR",

    GET_UNREAD_NOTIFICATIONS_LOADING: "GET_UNREAD_NOTIFICATIONS_LOADING",
    GET_UNREAD_NOTIFICATIONS_SUCSES: "GET_UNREAD_NOTIFICATIONS_SUCSES",
    GET_UNREAD_NOTIFICATIONS_ERROR: "GET_UNREAD_NOTIFICATIONS_ERROR",

    MARK_ALL_AS_READIND_LOADING: "MARK_ALL_AS_READIND_LOADING",
    MARK_ALL_AS_READIND_SUCSES: "MARK_ALL_AS_READIND_SUCSES",
    MARK_ALL_AS_READIND_ERROR: "MARK_ALL_AS_READIND_ERROR",

    MARK_ONE_AS_READING_LOADING: "MARK_ONE_AS_READING_LOADING",
    MARK_ONE_AS_READING_SUCSES: "MARK_ONE_AS_READING_SUCSES",
    MARK_ONE_AS_READING_ERROR: "MARK_ONE_AS_READING_ERROR",


  },
  consultationsTypes:{

    GET_QUESTIONS_LOADING:"GET_QUESTIONS_LOADING",
    GET_QUESTIONS_SUCSES:"GET_QUESTIONS_SUCSES",
    GET_QUESTIONS_ERROR:"GET_QUESTIONS_ERROR",

    
  }
};
