import Audiology from "../assets/speciality-icon/Audiology.png";
import Bones from "../assets/speciality-icon/Bones.png";
import Dermatology from "../assets/speciality-icon/Dermatology.png";
import General from "../assets/speciality-icon/General.png";
import Heart from "../assets/speciality-icon/Heart.png";
import Hematology from "../assets/speciality-icon/Hematology.png";
import Neurology from "../assets/speciality-icon/Neurology.png";
import Nose from "../assets/speciality-icon/Nose.png";
import Stomach from "../assets/speciality-icon/Stomach.png";
import Surgery from "../assets/speciality-icon/Surgery.png";
import chest from "../assets/speciality-icon/chest.png";

export const GetSpecialityIcon = (id) => {
  if (id === 2) return Audiology;
  if (id === 4) return Heart;
  if (id === 5) return Stomach;
  if (id === 6) return Dermatology;
  if (id === 9) return Nose;
  if (id === 11) return Stomach;
  if (id === 13) return Surgery;
  if (id === 16) return Hematology;
  if (id === 20) return Neurology;
  if (id === 21) return Neurology;
  if (id === 22) return chest;
  if (id === 23) return Bones;

  return General;
};
