import React, { useState, useEffect } from "react";
import { Box, Image, Text, Button, useColorMode } from "@chakra-ui/react";

import contact from "../../assets/contact.png";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getPatientOneConsultation } from "../../store/actions";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../global/changeColor";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const AppointmentThankYou = ({ consultation, getPatientOneConsultation }) => {
  const [oneConsultation, setOneConsultation] = useState(null);
  const { colorMode } = useColorMode();
  let query = useQuery().get("consultationId");
  useEffect(() => {
    if (query) getPatientOneConsultation(query);
  }, [query, getPatientOneConsultation]);
  useEffect(() => {
    if (consultation) setOneConsultation(consultation.data);
  }, [consultation]);
  return (
    <Box className="row m-0 justify-content-center">
      <Box
        bg={checkBgColorMode(colorMode)}
        className="col-8 shadow rounded p-4 row m-0 mt-5 justify-content-center"
      >
        <Box className="d-flex justify-content-center">
          <Image src={contact} alt="contact" />
        </Box>
        <Text
          color={checkColorMode(colorMode)}
          className="text-center mt-5"
          fontSize="2xl"
          fontWeight="semibold"
        >
          {" "}
          Thank You
        </Text>

        <Text
          color={"smallTextGray"}
          className="text-center "
          fontSize="sm"
          fontWeight="semibold"
        >
          {" "}
          Your appointment has been set successfully
        </Text>
        <Button
          size="lg"
          as={Link}
          to={`/consultations/over-view/appointments?consultationId=${
            oneConsultation ? oneConsultation.id : query
          }`}
          color="white"
          bg={checkColorMode(colorMode)}
          fontSize="14px"
          _hover={{ bg: checkColorModeHover(colorMode), color: "white" }}
          w={300}
          className="mt-4"
        >
          View Consultation
        </Button>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    consultation: state.oneConsultation.data,
  };
};
export default connect(mapStateToProps, { getPatientOneConsultation })(
  AppointmentThankYou
);
