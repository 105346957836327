import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import Xray from "./../../../assets/Xray.png";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateXray,
  getOneXray,
  postPatientXray,
  getAllNotifications,
  getUnReadNotifications,
} from "../../../store/actions";
import Add from "../Repeated-Component/Add";

import { useToast } from "@chakra-ui/react";

const SignInSchema = Yup.object().shape({
  title: Yup.string().min(3).required("Required"),
  files: Yup.mixed().required("File is required"),
});
const AddXray = ({
  oneXray,
  history,
  updateXray,
  getOneXray,
  postPatientXray,
  setChildData,
  getAllNotifications,
  getUnReadNotifications,
}) => {
  let params = useParams();
  //
  const toast = useToast();
  //
  const [initValue, setInitValue] = useState({
    note: "",
    title: "",
    files: null,
  });
  const [File, setFile] = useState({});

  useEffect(() => {
    if (params.Eid) {
      getOneXray(params.Eid);
    }
  }, [params, getOneXray]);

  useEffect(() => {
    if (params.Eid && oneXray) {
      setInitValue({
        title: oneXray.title,
        note: oneXray.note,
        files: [],
      });
      setFile({
        name: oneXray.fileName,
      });
    }
  }, [oneXray, params]);

  const onFormSubmit = (value) => {
    if (params.Eid) {
      return updateXray(value, params.Eid, history, toast);
    } else
      postPatientXray(
        value,
        history,
        toast,
        setChildData,
        getAllNotifications,
        getUnReadNotifications
      );
  };
  const seeFiles = (file) => {
    if (file) {
      setFile(file[0]);
    }
  };
  return (
    <>
      <Add
        Schema={SignInSchema}
        initValue={initValue}
        history={history}
        onFormSubmit={onFormSubmit}
        headerText={`${params.Eid ? "Edit" : "Add"} X Ray`}
        secondHeaderText="X Ray"
        therdHeaderText="Add your X Ray"
        image={Xray}
        feildsNames={{
          feild1: {
            name: "title",
            type: "text",
            placeholder: "Briefly describe your X Ray",
          },
          feild2: {
            name: "note",
            type: "",
            placeholder: "Describe in as much detail as you can.",
          },
          feild3: { name: "files", type: "", placeholder: "" },
        }}
        seeFiles={seeFiles}
        renderFile={File}
        setChildData={setChildData}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    oneXray: state.oneXray.data,
  };
};
export default connect(mapStateToProps, {
  updateXray,
  getOneXray,
  postPatientXray,
  getAllNotifications,
  getUnReadNotifications,
})(AddXray);
