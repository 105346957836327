import allergy from "../../assets/medical-record/allergy.png";
import condition from "../../assets/medical-record/condition.png";
import ctScan from "../../assets/medical-record/ctScan.png";
import Egc from "../../assets/medical-record/Egc.png";
import labResult from "../../assets/medical-record/lab-result.png";
import medicalReport from "../../assets/medical-record/medical-report.png";
import Xray from "../../assets/medical-record/Xray.png";
import bloodPressure from "../../assets/bloodPressure.png";
import bloodSugar from "../../assets/bloodSugar.png";
import height from "../../assets/height.png";
import temperature from "../../assets/temperature.png";
import weight from "../../assets/weight.png";
import HKCall from "../../assets/svg-icon/HkCall.svg";
import Consultaion from "../../assets/svg-icon/Consultaion.svg";
import Hospital from "../../assets/svg-icon/Hospital.svg";
import Question from "../../assets/svg-icon/Question.svg";

export const LinksAndImages = (notic) => {
  if (notic && notic.scanType) {
    switch (notic && notic.scanType) {
      case "medical_report":
        return {
          anchor: true,
          link: `/medical-record/medical-report/add-midical`,
          img: medicalReport,
        };

      case "condition":
        return {
          anchor: true,
          link: `/medical-record/conditions/add-conditions`,
          img: condition,
        };

      case "allergy":
        return {
          anchor: true,
          link: `/medical-record/allergies/add-allergies`,
          img: allergy,
        };

      case "radiology-xray":
        return {
          anchor: true,
          link: `/medical-record/x-ray/add-xray`,
          img: Xray,
        };

      case "lab_result":
        return {
          anchor: true,
          link: `/medical-record/lab-result/add-Lab-result`,
          img: labResult,
        };
      case "radiology-mri":
        return {
          anchor: true,
          link: `/medical-record/mri-ct scan/add-radiology`,
          img: labResult,
        };

      case "new-radiology":
        return {
          anchor: false,
          link: `https://viewer.staging.pat-rec.com?file=625fd0/${notic.data.id}`,
          img: ctScan,
          target: "_blank",
        };

      case "ecg":
        return {
          anchor: true,
          link: `/medical-record/ecg/add-ecg`,
          img: Egc,
        };

      case "reading-temperature":
        return {
          anchor: true,
          link: `/my-reading`,
          img: temperature,
        };

      case "reading-weight":
        return {
          anchor: true,
          link: `/my-reading`,
          img: weight,
        };

      case "reading-blood-sugar":
        return {
          anchor: true,
          link: `/my-reading`,
          img: bloodSugar,
        };

      case "reading-heart-rate":
        return {
          anchor: true,
          link: `/my-reading`,
          img: null,
          className: "fas fa-heartbeat",
        };

      case "reading-height":
        return {
          anchor: true,
          link: `/my-reading`,
          img: height,
        };

      case "reading-blood-pressure":
        return {
          anchor: true,
          link: `/my-reading`,
          img: bloodPressure,
        };

      default:
        return {
          anchor: false,
          link: `#`,
          img: null,
        };
    }
  } else {
    switch (notic && notic.data.type) {
      case "medical_report":
        return {
          anchor: true,
          link: `/medical-record/medical-report/view-midical/${notic.data.id}`,
          img: medicalReport,
        };
      case "admin-contact":
        return {
          anchor: true,
          link: `/consultations/over-view?consultationId=${notic.data.id}`,
          img: HKCall,
        };
      case "hospital-booking-confirmed":
        return {
          anchor: true,
          link: `/consultations/over-view?consultationId=${notic.data.id}`,
          img: HKCall,
        };
      case "hospital-booking-cancelled":
        return {
          anchor: true,
          link: `/consultations/over-view?consultationId=${notic.data.id}`,
          img: HKCall,
        };
      case "consultant-added-availability":
        return {
          anchor: true,
          link: `/consultations/over-view?consultationId=${notic.data.id}`,
          img: HKCall,
        };
      case "hospital_quotation":
        return {
          anchor: true,
          link: `/consultations/over-view/hospital-ofers/View-hispital/${notic.data.id}?consultationId=${notic.data.consultationRequestId}`,
          img: Hospital,
        };
      case "requested-quotation":
        return {
          anchor: true,
          link: `/consultations/over-view/hospital-ofers?consultationId=${notic.data.consultationRequestId}`,
          img: Hospital,
        };
      case "condition":
        return {
          anchor: true,
          link: `/medical-record/conditions/view-conditions/${notic.data.id}`,
          img: condition,
        };

      case "allergy":
        return {
          anchor: true,
          link: `/medical-record/allergies/view-allergies/${notic.data.id}`,
          img: allergy,
        };

      case "radiology-xray":
        return {
          anchor: true,
          link: `/medical-record/x-ray/view-xray/${notic.data.id}`,
          img: Xray,
        };

      case "lab_result":
        return {
          anchor: true,
          link: `/medical-record/lab-result/view-Lab-result/${notic.data.id}`,
          img: labResult,
        };

      case "new-radiology":
        return {
          anchor: true,
          link: `/medical-record/mri-ct scan/view-radiology/${notic.data.id}`,
          img: ctScan,
        };

      case "ecg":
        return {
          anchor: true,
          link: `/medical-record/ecg/view-ecg/${notic.data.id}`,
          img: Egc,
        };

      case "reading-temperature":
        return {
          anchor: true,
          link: `/my-reading`,
          img: temperature,
        };

      case "reading-weight":
        return {
          anchor: true,
          link: `/my-reading`,
          img: weight,
        };

      case "reading-blood-sugar":
        return {
          anchor: true,
          link: `/my-reading`,
          img: bloodSugar,
        };

      case "reading-heart-rate":
        return {
          anchor: true,
          link: `/my-reading`,
          img: null,
          className: "fas fa-heartbeat",
        };

      case "reading-height":
        return {
          anchor: true,
          link: `/my-reading`,
          img: height,
        };

      case "reading-blood-pressure":
        return {
          anchor: true,
          link: `/my-reading`,
          img: bloodPressure,
        };
      case "consultation_request":
        return {
          anchor: true,
          link: `/consultations/over-view?consultationId=${notic.data.id}`,
          img: Consultaion,
        };
      case "consultation_accepted":
        return {
          anchor: true,
          link: `/consultations/over-view?consultationId=${notic.data.id}`,
          img: Consultaion,
        };
      case "new-question":
        return {
          anchor: true,
          link: `/consultations/over-view/questions/view-question/${notic.data.id}?consultationId=${notic.data.consultationRequestId}`,
          img: Question,
        };

      case "scan_request":
        return {
          anchor: true,
          link: `/medical-record/conditions`,
          img: Consultaion,
        };

      default:
        return {
          anchor: false,
          link: `#`,
          img: null,
        };
    }
  }
};
