import React, { useState, useEffect } from "react";
import { Text, Box, Input, useColorMode } from "@chakra-ui/react";

import { useRouteMatch, useLocation, Link } from "react-router-dom";
import Pagination from "../../../../Pagination";
import {
  checkColorMode,
  checkBgColorMode,
} from "../../../../../global/changeColor";
import moment from "moment";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const QuestionsChild = ({ questions, history }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Questions, setQuestions] = useState([]);
  const [filterQuestions, setFilterQuestions] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  const { url } = useRouteMatch();
  let consultationId = useQuery().get("consultationId");
  const { colorMode } = useColorMode();
  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?consultationId=${consultationId}&page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?consultationId=${consultationId}&page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?consultationId=${consultationId}&page=${pageNum}`);
  };

  useEffect(() => {
    const { loading, error, data } = questions;
    setLoading(loading);
    setError(error);
    if (data) {
      const viewQuestion = data.data.filter((item) => item.reply == null);

      setQuestions(viewQuestion);
      setFilterQuestions(viewQuestion);
      setTotalResults(data.meta.total);
      setCuttentPage(data.meta.current_page);
    }
  }, [questions]);
  const onInputChange = (e) => {
    let term = e.target.value;
    if (Questions && filterQuestions) {
      if (term.trim().length > 0) {
        const filterD = Questions.filter((one) =>
          one.title.toLowerCase().includes(term.trim().toLowerCase())
        );
        setFilterQuestions(filterD);
      } else {
        setFilterQuestions(Questions);
      }
    } else {
      return null;
    }
  };
  const renderView = () => {
    if (loading) {
      return "loading";
    }
    if (error) {
      return "error";
    }
    if (Questions.length) {
      return (
        <Box>
          <Box className="d-flex justify-content-between my-4 align-items-center">
            <Text
              color={checkColorMode(colorMode)}
              fontSize="2xl"
              fontWeight="semibold"
            >
              Questions
            </Text>
            <div className="me-3 input-search">
              <Input
                w="300px"
                fontSize="15px"
                placeholder="Search"
                size="lg"
                className="ps-5"
                bg={checkBgColorMode(colorMode)}
                onChange={onInputChange}
              />
              <Text color={checkColorMode(colorMode)} className="icon-search ">
                <i className="fas fa-search"></i>
              </Text>
            </div>
          </Box>
          {filterQuestions.map((item, index) => (
            <Box
              as={Link}
              to={`${url}/view-question/${item.id}?consultationId=${item.consultationRequestId}`}
              key={index}
              className="my-2 shadow row m-0 align-items-center p-3 rounded"
              bg={checkBgColorMode(colorMode)}
              _hover={{ color: colorMode === "dark" ? "white" : "black" }}
            >
              <div className="col-md-8 row">
                <Text fontSize="xl" fontWeight="semibold">
                  {item.title}
                </Text>
                <Text
                  fontSize="12px"
                  color="smallTextGray"
                  fontWeight="semibold"
                >
                  {moment.unix(item.updatedAt).format("DD/MM/YYYY - hh:mm A")}
                </Text>
              </div>
            </Box>
          ))}
        </Box>
      );
    } else {
      return "No questions";
    }
  };
  return (
    <div className="my-3">
      {renderView()}{" "}
      {totalResults > 15 ? (
        <Pagination
          pages={pagesNumber}
          nextPage={nextPage}
          prevPage={prevPage}
          specificPage={specificPage}
          currentPage={currentPage}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default QuestionsChild;
