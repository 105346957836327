import React from "react";
import { Box, Text, Circle, useColorMode } from "@chakra-ui/react";
import moment from "moment";
import {
  checkColorMode,
  checkBgColorMode,
} from "../../../../../global/changeColor";

const ContactInfo = ({ Contact }) => {
  const { colorMode } = useColorMode();
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="col-12 p-2 py-4 shadow-sm rounded row m-0 mt-3"
    >
      <Text color="secondary" fontSize="xl" fontWeight="semibold">
        Contact with hospital
      </Text>
      <Text fontSize="12px" color="smallTextGray">
        {moment.unix(Contact?.updatedAt).format("DD/MM/yyyy - hh:mm A")}
      </Text>
      <Box className="col-12 row m-0 mt-3">
        <Box className="col-md-4 d-flex p-0 align-items-center my-2">
          <Circle
            fontSize={"10px"}
            bg={checkColorMode(colorMode)}
            color="#fff"
            size={9}
            className="me-2"
          >
            <i className="far fa-flag"></i>
          </Circle>
          <Box fontSize={"13px"}>
            <Text color="smallTextGray">Eliigibillity to enter the UK?</Text>
            <Text fontWeight="semibold">
              {Contact && Contact.eligibleToEnterUk ? "Yes" : "No"}
            </Text>
          </Box>
        </Box>
        <Box className="col-md-4 d-flex p-0 align-items-center my-2">
          <Circle
            fontSize={"10px"}
            bg={checkColorMode(colorMode)}
            color="#fff"
            size={9}
            className="me-2"
          >
            <i className="fas fa-calendar-alt"></i>
          </Circle>
          <Box fontSize={"13px"}>
            <Text color="smallTextGray">
              Do you have a preferred check in date?
            </Text>
            <Text fontWeight="semibold">
              {Contact && Contact.preferredCheckInDate ? "Yes" : "No"}
            </Text>
          </Box>
        </Box>
        <Box className="col-md-4 d-flex p-0 align-items-center my-2">
          <Circle
            fontSize={"10px"}
            bg={checkColorMode(colorMode)}
            color="#fff"
            size={9}
            className="me-2"
          >
            <i className="fas fa-calendar-alt"></i>
          </Circle>
          <Box fontSize={"13px"}>
            <Text color="smallTextGray">Preferred Check in date</Text>
            <Text fontWeight="semibold">
              {Contact && Contact.preferredCheckInDate
                ? moment
                    .unix(Contact.preferredCheckInDate)
                    .format("dddd MMM yyyy")
                : "No"}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box className="col-12 p-3">
        <Text
          bg={colorMode === "dark" ? "gray.800" : "bg_Light_Gray"}
          border={`1px solid`}
          borderColor="bg_Light_Gray_Hover"
          className="rounded p-3"
          fontSize={"sm"}
          fontWeight="semibold"
        >
          {Contact && Contact.note ? Contact.note : ""}
        </Text>
      </Box>
    </Box>
  );
};
export default ContactInfo;
