import { types } from "../../types/types";
const { consultationsTypes } = types;

const INIT_CONSULTATIONS_STATR = {
  loading: false,
  error: null,
  data: null,
};
export const getPatientPrePaymentDataReducer = (
  state = INIT_CONSULTATIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_PAYMENT_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_PAYMENT_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_PAYMENT_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const getConsultationsReducer = (
  state = INIT_CONSULTATIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_CONSULTATION_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_CONSULTATION_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_CONSULTATION_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneConsultationReducer = (
  state = INIT_CONSULTATIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_ONE_CONSULTATION_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_CONSULTATION_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_CONSULTATION_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getRequestsReducer = (
  state = INIT_CONSULTATIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_REQUESTS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_REQUESTS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_REQUESTS_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const getQuestionsReducer = (
  state = INIT_CONSULTATIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case consultationsTypes.GET_QUESTIONS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case consultationsTypes.GET_QUESTIONS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case consultationsTypes.GET_QUESTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneQuestionsReducer = (
  state = INIT_CONSULTATIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_ONE_QUESTIONS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_QUESTIONS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_QUESTIONS_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const postConsultationReducer = (
  state = INIT_CONSULTATIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case "POST_CONSULTATION_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_CONSULTATION_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_CONSULTATION_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
