import React from "react";
import { Box, Button, Text, useColorMode } from "@chakra-ui/react";
import notFound from "../../assets/svg-icon/404.svg";
import { checkColorMode, checkBgColorMode } from "../../global/changeColor";
const NotFound = ({ history }) => {
  const { colorMode } = useColorMode();
  return (
    <div className="container mt-5">
      <div className="row m-0 justify-content-center">
        <Box
          bg={checkBgColorMode(colorMode)}
          className="col-md-6 col-12 p-3 pb-5 rounded shadow row m-0"
        >
          <img src={notFound} alt="NotFound" />

          <Text
            color={checkColorMode(colorMode)}
            fontWeight="bold"
            fontSize="xl"
            className="text-center mb-4"
          >
            The page you requested was not found
          </Text>
          <Box className="col-12 text-center">
            <Button
              w="60%"
              size="lg"
              bg={"secondary"}
              color={"#fff"}
              _hover={{ color: "#fff", bg: "secondary_hover" }}
              onClick={() => history.goBack()}
            >
              Go Back
            </Button>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default NotFound;
