import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Text,
  Box,
  Avatar,
  Circle,
} from "@chakra-ui/react";
import { style } from "../../../global/style";
import doctorImg from "../../../assets/doctor.png";

const DoctorInfoModal = ({ doctor }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handelBtn = (e) => {
    e.preventDefault();
    onOpen();
  };
  return (
    <>
      <Box
        onClick={(e) => {
          handelBtn(e);
        }}
        cursor="pointer"
        className="d-flex align-items-center"
        zIndex="100"
      >
        <div className="me-2">
          <Avatar
            bg={style.primary}
            size="md"
            src={doctor ? doctor.picture : doctorImg}
            name={doctor ? doctor.name : "Name"}
            color="#FFF"
          />
        </div>

        <Box className="p-1">
          <Text color="gray.400" fontSize="15px" fontWeight="600">
            Doctor
          </Text>
          <Text fontSize="13px" fontWeight="700">
            {doctor ? doctor.name : "Name"}
          </Text>
        </Box>
      </Box>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody className="p-4">
            <Box className="d-flex justify-content-between align-items-center">
              <Text
                fontSize="2xl"
                fontWeight="semibold"
                color={style.secondary}
              >
                Doctor info
              </Text>
              <Text fontSize={"20px"} cursor="pointer" onClick={onClose}>
                <i className="far fa-times-circle"></i>
              </Text>
            </Box>
            {/* ///////////// */}
            <Box className="row align-items-center">
              <Box className="col-md-7 d-flex align-items-center my-4">
                <div className="me-2">
                  <Avatar
                    bg={style.primary}
                    size="xl"
                    src={doctor ? doctor.picture : "doctorImg"}
                    name={doctor ? doctor.name : "Name"}
                    color="#FFF"
                  />
                </div>

                <Box className="p-1">
                  <Text color="gray.400" fontSize="16px" fontWeight="600">
                    Doctor
                  </Text>
                  <Text fontSize="18px" fontWeight="700">
                    {doctor ? doctor.name : "Name"}
                  </Text>
                </Box>
              </Box>
              <Box className="col-md-4 d-flex align-items-center my-2">
                <Circle color={"#fff"} size={"40px"} bg={style.primary}>
                  <span>
                    <i className="fas fa-id-card"></i>
                  </span>
                </Circle>
                <Box className="ms-2">
                  <Text
                    fontSize="14px"
                    color={style.smallTextGray}
                    fontWeight="semibold"
                  >
                    GMC Number
                  </Text>
                  <Text fontSize="14px" fontWeight="bold">
                    #{doctor ? doctor.gmc_number : ""}
                  </Text>
                </Box>
              </Box>
            </Box>
            {/* ///////////// */}
            <Box className="row m-0 fw-bold my-2">
              {doctor ? doctor.body : ""}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DoctorInfoModal;
