import axios from "axios";
import { handeleSuccess, handeleError } from "../toast";

const API_URL = process.env.REACT_APP_API_URL;

export const getPatientXray = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_XRAY_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/xrays?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/xrays/`,
      });
      dispatch({
        type: "GET_XRAY_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_XRAY_ERROR",
        payload: error,
      });
    }
  };
};

export const getPatientXrayForPagination = (
  pageNumber,
  patientId,
  informationShareToken
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_XRAY_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/xrays?informationShareToken=${informationShareToken}&page=${pageNumber}`
            : `${API_URL}/patient/xrays?page=${pageNumber}`,
      });
      dispatch({
        type: "GET_XRAY_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_XRAY_ERROR",
        payload: error,
      });
    }
  };
};

export const getOneXray = (id, toast, history, patientId, sharedToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_XRAY_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && sharedToken
            ? `${API_URL}/patient/${patientId}/xrays/show/${id}?informationShareToken=${sharedToken}`
            : `${API_URL}/patient/xrays/show/${id}`,
      });
      dispatch({
        type: "GET_ONE_XRAY_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast, null, "x ray");
      history.push("/medical-record/x-ray");
      dispatch({
        type: "GET_ONE_XRAY_ERROR",
        payload: error,
      });
    }
  };
};

export const postPatientXray = (
  data,
  history,
  toast,
  setChildData,
  getAllNotifications,
  getUnReadNotifications
) => {
  let formData = new FormData();
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file, file.name));
  }
  formData.append("title", data.title);
  return async (dispatch) => {
    dispatch({
      type: "POST_XRAY_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/xrays`,

        data: formData,
      });
      ////////////
      if (res) {
        handeleSuccess("Your X Ray has been added successfully", toast);
        getAllNotifications();
        getUnReadNotifications();
        history ? history.goBack() : setChildData(res.data);
        ////////
        dispatch({
          type: "POST_XRAY_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);

      dispatch({
        type: "POST_XRAY_ERROR",
        payload: error.response,
      });
    }
  };
};

export const updateXray = (data, id, history, toast) => {
  let formData = new FormData();
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file));
  }
  formData.append("title", data.title);

  return async (dispatch) => {
    dispatch({
      type: "UPDATE_XRAY_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/xrays/${id}`,
        data: formData,
      });
      if (res) {
        handeleSuccess("Your X Ray has been updated successfully", toast);
        history.goBack();
        dispatch({
          type: "UPDATE_XRAY_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);

      dispatch({
        type: "UPDATE_XRAY_ERROR",
        payload: error,
      });
    }
  };
};

export const deleteXray = (id, toast, history, goTo) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_XRAY_LOADING",
    });
    try {
      const res = await axios({
        method: "DELETE",
        url: `${API_URL}/patient/xrays/${id}`,
      });
      if (res) {
        handeleSuccess("Your X Ray has been deleted successfully", toast);
        history.push(goTo);
        dispatch({
          type: "DELETE_XRAY_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);

      dispatch({
        type: "DELETE_XRAY_ERROR",
        payload: error,
      });
    }
  };
};
