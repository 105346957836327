import allergy from "../../../../assets/medical-record/allergy.png";
import condition from "../../../../assets/medical-record/condition.png";
import ctScan from "../../../../assets/medical-record/ctScan.png";
import Egc from "../../../../assets/medical-record/Egc.png";
import labResult from "../../../../assets/medical-record/lab-result.png";
import medicalReport from "../../../../assets/medical-record/medical-report.png";
import Xray from "../../../../assets/medical-record/Xray.png";
import bloodPressure from "../../../../assets/bloodPressure.png";
import bloodSugar from "../../../../assets/bloodSugar.png";
import height from "../../../../assets/height.png";
import temperature from "../../../../assets/temperature.png";
import weight from "../../../../assets/weight.png";
export const helperImageAndLinks = (item) => {
  const { consultation_request_id, scan_type, related_to_id } = item;
  switch (scan_type) {
    case "radiology-xray":
      return {
        link: `/medical-record/x-ray/add-xray`,
        img: Xray,
      };

    case "allergy":
      return {
        link: `/medical-record/allergies/add-allergies`,
        img: allergy,
      };

    case "ecg":
      return {
        link: `/medical-record/ecg/add-ecg`,
        img: Egc,
      };

    case "lab_result":
      return {
        link: `/medical-record/lab-result/add-Lab-result`,
        img: labResult,
      };

    case "condition":
      return {
        link: `/medical-record/conditions/add-conditions`,
        img: condition,
      };

    case "consultant-question":
      return {
        link: `/consultations/over-view/questions/view-question/${related_to_id}?consultationId=${consultation_request_id}`,
        img: null,
        className: "far fa-question-circle",
      };

    case "new-radiology":
      return {
        link: `/medical-record/mri-ct scan/add-radiology`,
        img: ctScan,
      };

    case "medical_report":
      return {
        link: `/medical-record/medical-report/add-midical`,
        img: medicalReport,
      };

    case "radiology-mri":
      return {
        link: `/medical-record/mri-ct scan/add-radiology`,
        img: ctScan,
      };

    case "reading-height":
      return {
        link: `/my-reading`,
        img: height,
      };

    case "reading-weight":
      return {
        link: `/my-reading`,
        img: weight,
      };

    case "reading-blood-pressure":
      return {
        link: `/my-reading`,
        img: bloodPressure,
      };

    case "reading-blood-sugar":
      return {
        link: `/my-reading`,
        img: bloodSugar,
      };

    case "reading-heart-rate":
      return {
        link: `/my-reading`,
        img: null,
        className: "fas fa-heartbeat",
      };

    case "reading-temperature":
      return {
        link: `/my-reading`,
        img: temperature,
      };

    default:
      return {
        link: `#`,
        img: null,
        className: "",
      };
  }
};
