import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOneCondition, removeCondition } from "../../../../store/actions";
import { useParams, useLocation } from "react-router-dom";
import View from "../../Repeated-Component/View";
import { useToast } from "@chakra-ui/react";
const ViewConditions = ({
  getOneCondition,
  oneCondition,
  removeCondition,
  history,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [condition, setCondition] = useState(null);
  let params = useParams();
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const toast = useToast();

  const remove = () => {
    removeCondition(params.id, toast, history, "/medical-record/conditions");
  };
  useEffect(() => {
    if (params && params.id && params.patientId && sharedToken) {
      getOneCondition(params.id, toast, history, params.patientId, sharedToken);
    } else if (params && params.id && !params.patientId && !sharedToken) {
      getOneCondition(params.id, toast, history);
    }
  }, [getOneCondition, toast, history, params.id, sharedToken]);
  useEffect(() => {
    const { loading, error, data } = oneCondition;
    setLoading(loading);
    setError(error);
    if (data) {
      setCondition(data);
    }
  }, [oneCondition]);
  const renderView = () => {
    return (
      <View
        loading={loading}
        error={error}
        headerText="View Condition"
        linkEdite="/medical-record/conditions/add-conditions"
        one={condition}
        params={params}
        removeOne={remove}
        backLink={
          sharedToken
            ? `/conditions/${params.patientId}/shared-data?sharedToken=${sharedToken}`
            : "/medical-record/conditions"
        }
        nameForAlert="Condition"
      />
    );
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    oneCondition: state.oneCondition,
  };
};
export default connect(mapStateToProps, { getOneCondition, removeCondition })(
  ViewConditions
);
