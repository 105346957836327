import { extendTheme } from "@chakra-ui/react";

const theme = {
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: {},
  },
  colors: {
    primary: "#21118D",
    primary_hover: "rgba(33,17,141,.8)",
    primaryOpacity: "rgba(33,17,141,.2)",
    primaryOpacity_hover: "rgb(33 17 141 / 4%)",
    smallTextGray: "#8F9698",
    smallTextGrayLight: "rgba(151,151,151,.3)",
    smallTextGrayLightBorder: "rgba(151,151,151,.5)",
    info: "#FFB100",
    infoOpacity: "rgba(255,177,0,.2)",
    background_white: "#fff",
    bg_Light_Gray: "#e9ecef",
    bg_Light_Gray_Hover: "#dbdbdb",
    appBackground: "#D8D8D8",
    black: "#000",
    white: "#fff",
    secondary: "#30B8BA", //green
    secondaryOpacity: "rgba(48, 184, 186, .2)", //green
    secondary_hover: "rgba(48, 184, 186, .8)", //green hover
    secondary_hover_Opacity: "rgba(48, 184, 186, .1)", //green hover
    error: "#D42C51", //red
    error_hover: "rgb(212 44 81 / 4%)",
    darkBg: "#1B2D3E",
  },
};

export default extendTheme(theme);
