import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import { Text, Box, Image, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion";
import moment from "moment";
import {
  getHospitalInviteRequest,
  markHospitalInviteRequestAsDone,
} from "../store/actions";
import { pageTransition, pageVariants } from "../global/Animations/Animations";
import {
  getPatientCondition,
  getPatientRreadingList,
  getPatientMedical,
  getPatientLapResult,
  getPatientAllergies,
  getPatientXray,
  getPatientEcg,
  getPatientRadiology,
} from "../store/actions";
import { checkBgColorMode, checkColorMode } from "../global/changeColor";
import Hospital from "../assets/svg-icon/Hospital.svg";
import AlertComponent from "../global/Alert.Component";

const getStatusLabel = (status) => {
  switch (status) {
    case "signed-up":
      return "Signed Up";
    case "finished-requests":
      return "Requests Submitted";
    default:
      break;
  }
};

const getAddLinkFromRequest = (scanType) => {
  switch (scanType) {
    case "condition":
      return "/medical-record/conditions/add-conditions";
    case "radiology-ct":
      return "/medical-record/mri-ct%20scan/add-radiology";
    case "allergy":
      return "/medical-record/allergies/add-allergies";
    case "medical_report":
      return "/medical-record/medical-report/add-midical";
    case "ecg":
      return "/medical-record/ecg/add-ecg";
    case "radiology-xray":
      return "/medical-record/x-ray/add-xray";
    case "lab_result":
      return "/medical-record/lab-result/add-Lab-result";
    case "reading-weight":
    case "reading-height":
    case "reading-heart-rate":
    case "reading-temperature":
    case "reading-blood-sugar":
    case "reading-blood-pressure":
    case "reading":
      return "/my-reading";
    default:
      return "";
  }
};
const getListingLinkFromRequest = (scanType) => {
  switch (scanType) {
    case "condition":
      return "/medical-record/conditions";
    case "radiology-ct":
      return "/medical-record/mri-ct%20scan";
    case "allergy":
      return "/medical-record/allergies";
    case "medical_report":
      return "/medical-record/medical-report";
    case "ecg":
      return "/medical-record/ecg";
    case "radiology-xray":
      return "/medical-record/x-ray";
    case "lab_result":
      return "/medical-record/lab-result";
    case "reading-weight":
    case "reading-height":
    case "reading-heart-rate":
    case "reading-temperature":
    case "reading-blood-sugar":
    case "reading-blood-pressure":
    case "reading":
      return "/my-reading";
    default:
      return "";
  }
};

const HospitalRequest = ({
  getHospitalInviteRequest,
  markHospitalInviteRequestAsDone,
  hospitalInviteRequest,
  getPatientCondition,
  getPatientRreadingList,
  getPatientMedical,
  getPatientLapResult,
  getPatientAllergies,
  getPatientXray,
  getPatientEcg,
  getPatientRadiology,
  conditionList,
  medicalList,
  LapResultList,
  AllergiesList,
  ECGList,
  XrayList,
  RadiologyList,
  readdingList,
}) => {
  const { colorMode } = useColorMode();
  const [showBtn, setShowBtn] = useState(false);
  const check = [];

  useEffect(() => {
    if (check.length) {
      const isThere = check.find((element) => element.done === false);
      if (!isThere) {
        setShowBtn(true);
      }
    }
  }, [check]);

  useEffect(() => {
    if (Number(hospitalInviteRequest?.data?.requests.length) === 0) {
      setShowBtn(true);
    }
  }, [hospitalInviteRequest]);

  const gitTypeScanCount = (scanType) => {
    switch (scanType) {
      case "condition":
        const conditionCount = `${conditionList.data?.data?.length} Condition`;
        if (Number(conditionCount[0]) > 0) {
          check.push({ done: true });
        } else {
          check.push({ done: false });
        }
        return conditionCount;

      case "radiology-ct":
        const radiologyCount = `${RadiologyList.data?.data?.length} MRI / CT Scan`;
        if (Number(radiologyCount[0]) > 0) {
          check.push({ done: true });
        } else {
          check.push({ done: false });
        }
        return radiologyCount;
      case "allergy":
        const allergyCount = `${AllergiesList.data?.data?.length} Allergy`;

        if (Number(allergyCount[0]) > 0) {
          check.push({ done: true });
        } else {
          check.push({ done: false });
        }

        return allergyCount;
      case "medical_report":
        const medicalReportCount = `${medicalList.data?.data?.length} Medical Report`;

        if (Number(medicalReportCount[0]) > 0) {
          check.push({ done: true });
        } else {
          check.push({ done: false });
        }

        return medicalReportCount;
      case "ecg":
        const ecgCount = `${ECGList.data?.data?.length} ECG`;

        if (Number(ecgCount[0]) > 0) {
          check.push({ done: true });
        } else {
          check.push({ done: false });
        }

        return ecgCount;
      case "radiology-xray":
        const xrayCount = `${XrayList.data?.data?.length} X Ray`;

        if (Number(xrayCount[0]) > 0) {
          check.push({ done: true });
        } else {
          check.push({ done: false });
        }

        return xrayCount;
      case "lab_result":
        const labResultCount = `${LapResultList.data?.data?.length} Lab Result`;
        if (Number(labResultCount[0]) > 0) {
          check.push({ done: true });
        } else {
          check.push({ done: false });
        }
        return labResultCount;
      case scanType:
        if (scanType.includes("reading")) {
          const readingCount = `${readdingList.data?.length} Reading`;
          if (Number(readingCount[0]) > 0) {
            check.push({ done: true });
          } else {
            check.push({ done: false });
          }
          return readingCount;
        }
        break;
      default:
        return "";
    }
  };
  const { requestId } = useParams();
  const history = useHistory();

  useEffect(() => {
    getHospitalInviteRequest(requestId);
    getPatientCondition();
    getPatientRreadingList();
    getPatientMedical();
    getPatientLapResult();
    getPatientAllergies();
    getPatientXray();
    getPatientEcg();
    getPatientRadiology();
  }, [
    getHospitalInviteRequest,
    markHospitalInviteRequestAsDone,
    getPatientCondition,
    getPatientRreadingList,
    getPatientMedical,
    getPatientLapResult,
    getPatientAllergies,
    getPatientXray,
    getPatientEcg,
    getPatientRadiology,
    requestId,
  ]);

  const markAllInviteRequestAsDone = () => {
    markHospitalInviteRequestAsDone(requestId);
    history.push("/");
  };

  return (
    <motion.div
      className="container mt-5"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Box
        bg={checkBgColorMode(colorMode)}
        className="row shadow rounded p-4 row m-0 mt-5 justify-content-between align-items-center"
      >
        <div className="row w-50">
          <Text fontSize="4xl" fontWeight="semibold">
            {hospitalInviteRequest?.data?.name}
          </Text>
          <Text fontSize="xl" color={checkColorMode(colorMode)}>
            {hospitalInviteRequest?.data?.status === "finished-requests"
              ? "Your requests have been submitted, you can still add more conditions and they will be included with your patient information."
              : hospitalInviteRequest?.data?.invitationText}
          </Text>
          <Text
            className="mt-2"
            fontSize="sm"
            fontWeight="semibold"
            color="smallTextGray"
          >
            Starting on:{" "}
            {moment(hospitalInviteRequest?.data?.createdAt * 1000).format(
              "LLLL"
            )}
          </Text>
        </div>
        <div className="col-lg-4">
          <div className="d-flex align-items-center">
            <Image
              src={Hospital}
              alt="hospital icon"
              className="img-fluid bg-black rounded"
            />
            <Text
              className="mx-3 d-flex w-full text-center"
              fontSize="xl"
              fontWeight="semibold"
            >
              {hospitalInviteRequest?.data?.hospital?.name}
            </Text>
          </div>
          <div
            className={`done text-center rounded mt-2 ${
              colorMode === "dark" ? "done-dark" : ""
            }`}
          >
            {getStatusLabel(hospitalInviteRequest?.data?.status)}
          </div>
        </div>
        {hospitalInviteRequest?.data?.status !== "finished-requests" && (
          <div className="col-lg-8">
            {showBtn ? (
              <AlertComponent
                removeOne={markAllInviteRequestAsDone}
                nameForAlert="Mark Requests as Done"
                hospitalInvite={true}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </Box>
      {hospitalInviteRequest?.data?.requests?.length > 0 && (
        <Text className="mt-5 mb-2" fontSize="4xl" fontWeight="semibold">
          Hospital Requests
        </Text>
      )}
      {hospitalInviteRequest?.data?.requests.map((item, index) => (
        <Box
          bg={checkBgColorMode(colorMode)}
          key={index}
          className="my-4 shadow d-flex justify-content-between align-items-center p-3 rounded"
        >
          <Link
            to={`${getAddLinkFromRequest(item.scan_type)}`}
            className={`${
              colorMode === "dark" ? "text-white" : "text-dark"
            } nav-link`}
          >
            <h5 className="h5 mb-2 fw-bold mb-0">{item.scan_name}</h5>
            <h6 className="h6 mb-0">{item.notes}</h6>
          </Link>
          <Box
            as={Link}
            cursor="pointer"
            _hover={{ color: "#FFB100" }}
            to={`${getListingLinkFromRequest(item.scan_type)}`}
            className="nav-link pending cursor-pointer text-center rounded"
          >
            {gitTypeScanCount(item.scan_type)} added
          </Box>
        </Box>
      ))}
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    hospitalInviteRequest: state.hospitalInviteRequest,
    conditionList: state.patientCondition,
    medicalList: state.medicalList,
    LapResultList: state.LapResultList,
    AllergiesList: state.AllergiesList,
    ECGList: state.ECGList,
    XrayList: state.XrayList,
    RadiologyList: state.RadiologyList,
    readdingList: state.readdingList,
  };
};

export default connect(mapStateToProps, {
  getHospitalInviteRequest,
  markHospitalInviteRequestAsDone,
  getPatientCondition,
  getPatientRreadingList,
  getPatientMedical,
  getPatientLapResult,
  getPatientAllergies,
  getPatientXray,
  getPatientEcg,
  getPatientRadiology,
})(HospitalRequest);
