const INIT_PROFILE_PICTURE_STATE = {
  loading: false,
  error: null,
  data: {},
};

export const postPatientProfilePictureReducer = (
  state = INIT_PROFILE_PICTURE_STATE,
  { type, payload }
) => {
  switch (type) {
    case "PICTURE_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "PICTURE_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "PICTURE_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
