import React, { useState, useEffect } from "react";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import Pagination from "../../../../Pagination";
import {
  Box,
  Text,
  Circle,
  Button,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import ContactInfo from "./ContactInfo";
import {
  closePatientconsultation,
  getHospitalsQuotations,
} from "../../../../../store/actions";
import { connect } from "react-redux";
import moment from "moment";
import AlertComponent from "../../../../../global/Alert.Component";
import {
  checkColorMode,
  checkColorModeHover,
  checkBgColorMode,
} from "../../../../../global/changeColor";
// const useQuery = () => {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// };

const HospitalChild = ({
  consultation,
  consultationId,
  getHospitalsQuotations,
  hospitalReservation,
  hospitalsQuotationsList,
  closePatientconsultation,
}) => {
  const history = useHistory();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hospitalOffers, setHospitalOffers] = useState([]);
  // const [hospitalBooking, setHospitalBooking] = useState(null);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  const { url } = useRouteMatch();
  const closeConsultation = () => {
    closePatientconsultation(consultationId, toast, history);
  };
  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}?&consultationId=${consultationId}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}?&consultationId=${consultationId}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}?&consultationId=${consultationId}`);
  };

  useEffect(() => {
    if (hospitalReservation) {
      getHospitalsQuotations(hospitalReservation.id);
    }
  }, [hospitalReservation, getHospitalsQuotations]);

  useEffect(() => {
    if (hospitalsQuotationsList) {
      const { loading, error, data } = hospitalsQuotationsList;
      setLoading(loading);
      setError(error);
      if (data && data.data) {
        const list = data.data;
        setHospitalOffers(list);

        setTotalResults(data.meta.total);
        setCuttentPage(data.meta.current_page);

        // const booking = list.find((item) => item.status === "admin-contacted");
        // if (booking) {
        //   setHospitalBooking(booking);
        // }
      }
    }
  }, [hospitalsQuotationsList]);

  const renderView = () => {
    if (loading) return "loading...";
    if (error) return "error";
    if (hospitalOffers.length) {
      return (
        <Box>
          {hospitalOffers.map((item, index) => (
            <Box
              as={Link}
              to={`${url}/View-hispital/${item.id}?consultationId=${consultationId}`}
              key={index}
              className="col-12 p-2 py-4 shadow-sm rounded row m-0 mt-3"
              bg={checkBgColorMode(colorMode)}
              _hover={{ color: "white" }}
            >
              <Box className="col-12 row m-0 align-items-center">
                <Box className="col-md-4 my-2 p-0">
                  <Text fontSize="14px" fontWeight="semibold">
                    {item.hospital.name}
                  </Text>
                  <Text fontSize="13px" color={"smallTextGray"}>
                    {`${item.hospital.street}, ${item.hospital.city}, ${item.hospital.country}`}
                  </Text>
                </Box>

                <Box className="col-md-4 row m-0 p-0 justify-content-between my-2">
                  <Box className="col-md-6 d-flex p-0 align-items-center my-2">
                    <Circle
                      fontSize={"10px"}
                      bg={checkColorMode(colorMode)}
                      color="#fff"
                      size={9}
                      className="me-2"
                    >
                      <i className="far fa-flag"></i>
                    </Circle>
                    <Box fontSize={"13px"}>
                      <Text color="smallTextGray">Earliest check in</Text>
                      <Text fontWeight="semibold">
                        {moment
                          .unix(item.earliestCheckInTime)
                          .format("DD/MM/yyyy")}
                      </Text>
                    </Box>
                  </Box>
                  <Box className="col-md-6 d-flex p-0 align-items-center my-2">
                    <Circle
                      fontSize={"10px"}
                      bg={checkColorMode(colorMode)}
                      color="#fff"
                      size={9}
                      className="me-2"
                    >
                      <i className="far fa-flag"></i>
                    </Circle>
                    <Box fontSize={"13px"}>
                      <Text color="smallTextGray">Duration</Text>
                      <Text fontWeight="semibold">{`${item.durationInDays} Days`}</Text>
                    </Box>
                  </Box>
                </Box>

                <Box
                  className="col-md-4 row m-0 p-0 justify-content-between align-items-center"
                  fontSize="12px"
                  color={checkColorMode(colorMode)}
                  fontWeight="semibold"
                >
                  <Text
                    href={item.fileLocation}
                    target="_blanck"
                    className="col-md-4 p-0"
                    style={{
                      zIndex: 1,
                      position: "relative",
                    }}
                  >
                    <span style={{ fontSize: "14px" }} className="me-1">
                      <i className="fas fa-file-pdf"></i>
                    </span>{" "}
                    Quotation PDF
                  </Text>

                  <Text className="col-4 p-0">{item.price} GBP</Text>
                  <Button
                    color="#fff"
                    bg={checkColorMode(colorMode)}
                    _hover={{ bg: checkColorModeHover(colorMode) }}
                    width="35px"
                    height="35px"
                    disabled={item.status === "admin-contacted"}
                  >
                    <i className="fas fa-phone-alt"></i>
                  </Button>
                </Box>
              </Box>
              {item.note ? (
                <Box className="p-3">
                  <Text
                    bg="bg_Light_Gray"
                    border={`1px solid `}
                    borderColor="bg_Light_Gray_Hover"
                    className="rounded p-3"
                    fontSize={"sm"}
                    fontWeight="semibold"
                  >
                    {item.note}
                  </Text>
                </Box>
              ) : null}
            </Box>
          ))}

          {consultation?.status === "done" ? (
            ""
          ) : (
            <Box className=" mt-5 text-end">
              <AlertComponent
                removeOne={closeConsultation}
                nameForAlert={"consultation"}
                consultation={true}
              />
            </Box>
          )}
        </Box>
      );
    } else {
      return "No offers exists yet!";
    }
  };
  return (
    <Box>
      <ContactInfo Contact={hospitalReservation} />

      <Box className="hospital-offers">
        <Text
          color={checkColorMode(colorMode)}
          fontSize="xl"
          fontWeight="semibold"
          className="mt-4 mb-2"
        >
          Hospital offers
        </Text>
        {renderView()}
      </Box>
      {totalResults > 15 ? (
        <Pagination
          pages={pagesNumber}
          nextPage={nextPage}
          prevPage={prevPage}
          specificPage={specificPage}
          currentPage={currentPage}
        />
      ) : (
        ""
      )}
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    hospitalsQuotationsList: state.hospitalsQuotationsList,
  };
};
export default connect(mapStateToProps, {
  getHospitalsQuotations,
  closePatientconsultation,
})(HospitalChild);
