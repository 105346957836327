import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOneXray, deleteXray } from "./../../../store/actions";
import { useLocation, useParams } from "react-router-dom";
import View from "../Repeated-Component/View";
import { useToast } from "@chakra-ui/react";
const ViewXray = ({ getOneXray, oneXray, deleteXray, history }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [xray, setXray] = useState(null);
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  let params = useParams();
  const toast = useToast();
  const remove = () => {
    deleteXray(params.id, toast, history, "/medical-record/x-ray");
  };
  useEffect(() => {
    if (params && params.id && params.patientId && sharedToken) {
      getOneXray(params.id, toast, history, params.patientId, sharedToken);
    } else if (params && params.id && !params.patientId && !sharedToken) {
      getOneXray(params.id, toast, history);
    }
  }, [params.id, sharedToken, getOneXray, toast, history]);
  useEffect(() => {
    const { loading, error, data } = oneXray;
    setLoading(loading);
    setError(error);
    if (data) {
      setXray(data);
    }
  }, [oneXray]);
  const renderView = () => {
    return (
      <View
        loading={loading}
        error={error}
        headerText="View X Ray"
        linkEdite="/medical-record/x-ray/add-xray"
        one={xray}
        params={params}
        removeOne={remove}
        backLink={
          sharedToken
            ? `/x-ray/${params.patientId}/shared-data?sharedToken=${sharedToken}`
            : "/medical-record/x-ray"
        }
        nameForAlert="X Ray"
      />
    );
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    oneXray: state.oneXray,
  };
};
export default connect(mapStateToProps, { getOneXray, deleteXray })(ViewXray);
