import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./store/reducers";
import thank from "redux-thunk";
import axios from "axios";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

axios.interceptors.request.use((config) => {
  config.headers = {
    Accept: "application/json",
    "X-Api-Key": "du7lik773nspohl0",
    AUTHORIZATION: `Bearer ${
      localStorage.token ? localStorage.token : localStorage.registrToken
    }`,
    "Content-Type": "application/json",
  };
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      history.push("/login");
    }
    if (error.response.status === 403) {
      console.log("dd");
    }
    return Promise.reject(error);
  }
);

const store = createStore(reducers, applyMiddleware(thank));
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
