import React, { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import HospitalChild from "./hospitalChild";
import ViewOneHispital from "./ViewOneHispital";

const HospitalOffers = ({ consultationId, consultation }) => {
  const [hospitalReservation, setHospitalReservation] = useState(null);

  useEffect(() => {
    if (consultation && consultation.hospitalReservation) {
      setHospitalReservation(consultation.hospitalReservation);
    }
  }, [consultation]);
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route
          exact
          path={path}
          render={() => (
            <HospitalChild
              consultation={consultation}
              consultationId={consultationId}
              hospitalReservation={hospitalReservation}
            />
          )}
        />
        <Route
          path={`${path}/View-hispital/:id`}
          render={() => <ViewOneHispital consultation={consultation} />}
        />
      </Switch>
    </>
  );
};

export default HospitalOffers;
