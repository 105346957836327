import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  Textarea,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import { style } from "../../../../../global/style";
import "./hospital.scss";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { addHospitalReservation } from "../../../../../store/actions/HospitalReservation";
import { connect } from "react-redux";
import {
  checkColorMode,
  checkColorModeHover,
} from "../../../../../global/changeColor";

const Schema = Yup.object().shape({
  eligibleToEnterUk: Yup.string().required("Required field"),
  note: Yup.string().required("Required field"),
});
const HospitalModael = ({
  ConsultationId,
  addHospitalReservation,
  history,
}) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [preferredCheckInDate, setPreferredCheckInDate] = useState(true);
  const [initValue] = useState({
    eligibleToEnterUk: "",
    preferredCheckInDate: null,
    note: "",
  });

  const onFormSubmit = (values) => {
    if (values.preferredCheckInDate) {
      const tms = moment(values.preferredCheckInDate).format("X");
      addHospitalReservation(
        ConsultationId,
        {
          eligibleToEnterUk: JSON.parse(values.eligibleToEnterUk),
          preferredCheckInDate: tms,
          note: values.note,
        },
        toast,
        onClose,
        history
      );
    } else {
      addHospitalReservation(
        ConsultationId,
        {
          eligibleToEnterUk: JSON.parse(values.eligibleToEnterUk),
          note: values.note,
        },
        toast,
        onClose,
        history
      );
    }
  };

  return (
    <>
      <Button
        color="white"
        size="lg"
        background={checkColorMode(colorMode)}
        _hover={{ bg: checkColorModeHover(colorMode) }}
        _active={{ bg: checkColorModeHover(colorMode) }}
        onClick={onOpen}
      >
        Contact Hospital
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Box>
              <Text
                className="my-2"
                fontSize="lg"
                fontWeight="semibold"
                color="secondary"
              >
                Contact with hospitals
              </Text>
              <Box className="d-flex">
                <Text
                  className="my-2 p-2 rounded"
                  fontSize="13px"
                  fontWeight="semibold"
                  color={style.secondary}
                  bg={colorMode === "dark" ? "gray.600" : "bg_Light_Gray"}
                >
                  UK Visa issuance usually takes 4-6 weeks
                </Text>
              </Box>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={initValue}
              //   enableReinitialize
              validationSchema={Schema}
              onSubmit={onFormSubmit}
            >
              {({ values, setFieldValue, handleChange, handleBlur }) => (
                <>
                  <Form>
                    <Box transition="ease-in-out .3s">
                      <Text
                        fontSize="15px"
                        color={checkColorMode(colorMode)}
                        fontWeight="semibold"
                        className="my-2"
                      >
                        Answer this questions
                      </Text>
                      <Text
                        fontSize="14px"
                        fontWeight="semibold"
                        className="my-2 mt-4"
                      >
                        Eligibillty to enter the UK?
                      </Text>
                      <Field
                        type="radio"
                        className="btn-check"
                        name="eligibleToEnterUk"
                        id="success-outlined"
                        value="true"
                      />
                      <label
                        className="btn btn-outline-success success-check"
                        htmlFor="success-outlined"
                      >
                        <i className="fas fa-check"></i> Yes
                      </label>
                      <Field
                        type="radio"
                        className="btn-check"
                        name="eligibleToEnterUk"
                        id="danger-outlined"
                        value="false"
                      />
                      <label
                        className="btn btn-outline-danger danger-check ms-2"
                        htmlFor="danger-outlined"
                      >
                        <i className="fas fa-times"></i> No
                      </label>
                    </Box>
                    <Box transition="ease-in-out .3s">
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="eligibleToEnterUk"
                      />
                    </Box>
                    <Box className="mt-4">
                      <Text
                        fontSize="14px"
                        fontWeight="semibold"
                        className="my-2 "
                      >
                        Do you have a preferred Check In Date?
                      </Text>
                      <Field
                        type="radio"
                        className="btn-check"
                        id="success"
                        value="true"
                        name="checkInDate"
                      />
                      <label
                        className="btn btn-outline-success success-check"
                        htmlFor="success"
                        onClick={() => {
                          setPreferredCheckInDate(false);
                        }}
                      >
                        <i className="fas fa-check"></i> Yes
                      </label>
                      <Field
                        type="radio"
                        className="btn-check"
                        id="danger"
                        value="false"
                        name="checkInDate"
                      />
                      <label
                        className="btn btn-outline-danger danger-check ms-2"
                        htmlFor="danger"
                        onClick={() => {
                          setPreferredCheckInDate(true);
                        }}
                      >
                        <i className="fas fa-times"></i> No
                      </label>
                    </Box>
                    <Box className="mt-3">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk={true}
                          label="Select Date"
                          format="dd/MM/yyyy"
                          size="small"
                          value={values.preferredCheckInDate}
                          onChange={(value) =>
                            setFieldValue("preferredCheckInDate", value)
                          }
                          variant="inline"
                          inputVariant="outlined"
                          disablePast="true"
                          disabled={preferredCheckInDate}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <Box className="mt-4">
                      <Text fontWeight="semibold" fontSize="14px">
                        More details
                      </Text>
                      <Textarea
                        className="mt-2 rounded"
                        value={values.note}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                        placeholder={"Note"}
                        name="note"
                        size="sm"
                      />
                      <ErrorMessage
                        className="error"
                        component="div"
                        name="note"
                      />
                    </Box>
                    <Button
                      minW={200}
                      fontSize="14px"
                      size="lg"
                      bg={style.primary}
                      color="#fff"
                      type="submit"
                      _hover={{ bg: style.primary_hover }}
                      className="mt-2"
                    >
                      Send your conditon{" "}
                    </Button>
                  </Form>
                </>
              )}
            </Formik>
          </ModalBody>
          {/* <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default connect(null, { addHospitalReservation })(HospitalModael);
