import { Button, useToast } from "@chakra-ui/react";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TelephoneInput from "./SignUp/phoneNumber";
import * as Yup from "yup";
import { connect } from "react-redux";
import { getPatientProfile, updatePatientProfile } from "../store/actions";

const mobileSchema = Yup.object().shape({
  mobile: Yup.string().min(10).required("required"),
});
// const useQuery = () => {
//   const { search } = useLocation();
//   return React.useMemo(() => new URLSearchParams(search), [search]);
// };

const ChangeMobile = ({
  secondView,
  setSecondView,
  user,
  updatePatientProfile,
  getPatientProfile,
}) => {
  let history = useHistory();
  let toast = useToast();
  const [secondInitValue, setSecondInitValue] = useState({
    mobile: "",
  });
  const onMobileSubmit = (values) => {
    const userInfo = {
      ...user,
      mobile: values.mobile,
    };
    updatePatientProfile(
      userInfo,
      history,
      toast,
      "/verify_mobile?from=profile",
      true,
      secondView,
      setSecondView
    ).then(() => {
      return getPatientProfile();
    });
  };
  useEffect(() => {
    if (user) {
      setSecondInitValue((secondInitValue) => {
        return {
          ...secondInitValue,
          mobile: localStorage.userMobile ? localStorage.userMobile : "",
        };
      });
    }
  }, [user, setSecondInitValue]);
  return (
    <Formik
      initialValues={secondInitValue}
      validationSchema={mobileSchema}
      enableReinitialize
      onSubmit={onMobileSubmit}
    >
      <Form>
        <div className="text-end">
          <Button
            type="button"
            className="ms-1"
            color="red"
            onClick={() => {
              setSecondView(!secondView);
              history.push("/verify_mobile?from=profile");
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </div>
        <div>
          <label className="w-100 login-label" htmlFor="mobile">
            Phone Number
          </label>
          <div className="pt-2">
            <TelephoneInput name="mobile" />
            <ErrorMessage
              name="mobile"
              component="div"
              className="text-danger errorMsg"
            />
          </div>
          <Button
            type="submit"
            className="w-100 mt-3 fw-bold"
            size="lg"
            bg="primary"
            color="#fff"
            _hover={{ bg: "primary_hover" }}
          >
            Update
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  updatePatientProfile,
  getPatientProfile,
})(ChangeMobile);
