import React, { useEffect, useState } from "react";
import { useRouteMatch, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPatientLapResult,
  getPatientLapResultForPagination,
} from "./../../../store/actions";
import FirstView from "../Repeated-Component/FirstView";
import Pagination from "../../Pagination";
import labResult from "./../../../assets/labResult.png";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const LapResultChild = ({
  getPatientLapResult,
  LapResultList,
  getPatientLapResultForPagination,
  history,
}) => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const params = useParams();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [lapResult, setLapResult] = useState([]);
  const [filterLapResult, setFilterLapResult] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  let query = useQuery().get("page");

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    if (!query) getPatientLapResult(params.patientId, sharedToken);
  }, [getPatientLapResult, query, params, sharedToken]);

  useEffect(() => {
    if (query)
      getPatientLapResultForPagination(query, params.patientId, sharedToken);
  }, [getPatientLapResultForPagination, query, params, sharedToken]);

  useEffect(() => {
    const { loading, error, data } = LapResultList;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      setLapResult(list);
      setFilterLapResult(list);
      setTotalResults(data.meta.total);
      setCuttentPage(data.meta.current_page);
    }
  }, [LapResultList]);
  const renderView = () => {
    return (
      <>
        <FirstView
          headerName="Lab Results"
          buttonLink={`${url}/add-Lab-result`}
          cardLink={`${url}/view-Lab-result`}
          data={lapResult}
          filterData={filterLapResult}
          setFilter={setFilterLapResult}
          loading={loading}
          error={error}
          imageForEmptyView={labResult}
          secondTitle="Lab Result"
        />
        {totalResults > 15 && filterLapResult.length > 10 ? (
          <Pagination
            pages={pagesNumber}
            nextPage={nextPage}
            prevPage={prevPage}
            specificPage={specificPage}
            currentPage={currentPage}
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return renderView();
};
const mapStateToprops = (state) => {
  return {
    LapResultList: state.LapResultList,
  };
};
export default connect(mapStateToprops, {
  getPatientLapResult,
  getPatientLapResultForPagination,
})(LapResultChild);
