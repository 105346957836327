import React, { useState, useEffect } from "react";

import { InputControl } from "formik-chakra-ui";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { signupUser, updatePatientProfile } from "../../../store/actions";
import { useLocation } from "react-router-dom";

import "react-phone-input-2/lib/style.css";
import TelephoneInput from "../phoneNumber";
import PasswordField from "../../../global/passwordField";
import { Button, useToast } from "@chakra-ui/react";
import { style } from "../../../global/style";
import moment from "moment";

const thirdSecondStepSubmitSchema = Yup.object().shape({
  email: Yup.string().email("Email must be a valid email").required("Required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Required"),
  mobile: Yup.string().required("Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const Step3 = ({
  history,
  signupUser,
  currentUser,
  updatePatientProfile,
  email,
}) => {
  const toast = useToast();
  let queryEdit = useQuery().get("edit");
  const disabledEmail = Boolean(email);
  const [initValue3, setInitValue3] = useState({
    email: email || "",
    password: "",
    mobile: "",
    passwordConfirmation: "",
  });
  const [registrUser] = useState(localStorage.registrUser);

  useEffect(() => {
    const prevValues = JSON.parse(localStorage.getItem("step3"));
    if (prevValues) {
      setInitValue3(prevValues);
    }
  }, []);

  const onFormSubmit = (value) => {
    localStorage.setItem("step3", JSON.stringify(value));
    const stepOne = JSON.parse(localStorage.getItem("step1"));
    const stepTwo = JSON.parse(localStorage.getItem("step2"));
    let strDate = stepTwo.dateOfBirth;
    const tms = moment(new Date(strDate)).format("X");
    const userInfo = { ...stepOne, ...stepTwo, ...value };
    delete userInfo.passwordConfirmation;
    userInfo.dateOfBirth = tms;
    const phoneNm = userInfo.mobile.replace(/\s/g, "");
    userInfo.mobile = phoneNm;
    localStorage.setItem("registrUser", JSON.stringify(userInfo));
    let bloodType = JSON.parse(userInfo.blood_types_id).id;
    userInfo.blood_types_id = bloodType;
    if (queryEdit) {
      if (queryEdit === "email") {
        updatePatientProfile(userInfo, history, toast, "/verify_email");
      } else {
        // const token = localStorage.getItem("registrToken");
        updatePatientProfile(userInfo, history, toast, "/verify_mobile");
      }
    } else {
      signupUser(userInfo, history, toast);
    }
  };
  return (
    <Formik
      initialValues={initValue3}
      validationSchema={thirdSecondStepSubmitSchema}
      enableReinitialize
      onSubmit={onFormSubmit}
    >
      <div>
        <Form>
          <div className="first-two">
            <div className="input-group mt-2">
              <InputControl
                name="email"
                label="E-mail"
                isDisabled={disabledEmail || registrUser}
                inputProps={{ placeholder: "E-mail" }}
              />
            </div>

            <div className="input-group mt-2 ">
              <label className="w-100 login-label" htmlFor="mobile">
                Mobile
              </label>
              <TelephoneInput name="mobile" />
              <ErrorMessage
                name="mobile"
                component="div"
                className="text-danger errorMsg"
              />
            </div>

            <div className="input-group mt-2">
              <PasswordField
                label="Password"
                disabled={registrUser ? true : false}
              />
            </div>
            <div className="input-group mt-2">
              <PasswordField
                label="Re-enter Password"
                name="passwordConfirmation"
                disabled={registrUser ? true : false}
              />
            </div>
          </div>
          <div className="buttonSubmit row">
            <div className="col-6">
              <Button
                type="button"
                className="w-100 rounded login-btn mt-3 text-dark shadow-sm fw-bold"
                bg={"#ebf7fd"}
                size="lg"
                _hover={{ background: "#ebf9fd" }}
                onClick={() => {
                  history.push(`/signup?step=2`);
                }}
                disabled={registrUser}
              >
                Back
              </Button>
            </div>
            <div className="col-6">
              <Button
                type="submit"
                className="w-100 rounded mt-3 fw-bold"
                size="lg"
                isLoading={currentUser.loading}
                bg={style.primary}
                color="#fff"
                _hover={{ bg: style.primary_hover }}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Formik>
  );
};
const mapStateToProps = (state, props) => {
  return {
    currentUser: state.signup,
    email: props.email,
  };
};
export default connect(mapStateToProps, {
  signupUser,
  updatePatientProfile,
})(Step3);
