import { types } from "../../types/types";
const { conditionTypes } = types;
const INIT_CONDITIONS_STATR = {
  loading: false,
  error: null,
  data: null,
};

export const getConditionsReducer = (
  state = INIT_CONDITIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case conditionTypes.GET_CONDITION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case conditionTypes.GET_CONDITION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case conditionTypes.GET_CONDITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const getOneConditionsReducer = (
  state = INIT_CONDITIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case conditionTypes.GET_ONE_CONDITION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case conditionTypes.GET_ONE_CONDITION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case conditionTypes.GET_ONE_CONDITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const postConditionsReducer = (
  state = INIT_CONDITIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case conditionTypes.POST_CONDITION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case conditionTypes.POST_CONDITION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case conditionTypes.POST_CONDITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const updateConditionsReducer = (
  state = INIT_CONDITIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case conditionTypes.UPDATE_CONDITION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case conditionTypes.UPDATE_CONDITION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case conditionTypes.UPDATE_CONDITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};

export const deleteConditionsReducer = (
  state = INIT_CONDITIONS_STATR,
  { type, payload }
) => {
  switch (type) {
    case conditionTypes.DELETE_CONDITION_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case conditionTypes.DELETE_CONDITION_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case conditionTypes.DELETE_CONDITION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
