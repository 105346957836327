const INIT_APPOINTMENTS_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const getAppointmntsReducer = (
  state = INIT_APPOINTMENTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_APPOINTMENTS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_APPOINTMENTS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_APPOINTMENTS_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const addAppointmentReducer = (
  state = INIT_APPOINTMENTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "POST_APPOINTMENTS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_APPOINTMENTS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_APPOINTMENTS_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const requestAppointmentReducer = (
  state = INIT_APPOINTMENTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "REQUEST_APPOINTMENTS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "REQUEST_APPOINTMENTS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "REQUEST_APPOINTMENTS_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const deleteAppointmentReducer = (
  state = INIT_APPOINTMENTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "DELETE_APPOINTMENTS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_APPOINTMENTS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "DELETE_APPOINTMENTS_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getEmptyConsultantAppointmentReducer = (
  state = INIT_APPOINTMENTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_EMPTY_CONSULTANT_APPOINTMENTS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_EMPTY_CONSULTANT_APPOINTMENTS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_EMPTY_CONSULTANT_APPOINTMENTS_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const getConsultantAppointmentReducer = (
  state = INIT_APPOINTMENTS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_CONSULTANT_APPOINTMENTS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_CONSULTANT_APPOINTMENTS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_CONSULTANT_APPOINTMENTS_ERROR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
