import React, { useState, useEffect } from "react";
import { SelectControl } from "formik-chakra-ui";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, useColorMode } from "@chakra-ui/react";
import { connect } from "react-redux";
import { bloodTypeList } from "../../../store/actions";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";

import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

const SecondStepSubmitSchema = Yup.object().shape({
  dateOfBirth: Yup.string().min(3).required("Required").nullable(),
  gender: Yup.string().min(3).required("Required"),
  blood_types_id: Yup.string().required("Required"),
});

const Step2 = ({ history, blood_Types, bloodTypeList }) => {
  const [bloodTypes, setBloodTypes] = useState([]);
  const [initValue2, setInitValue2] = useState({
    dateOfBirth: null,
    gender: "",
    blood_types_id: "",
  });
  const { colorMode } = useColorMode();
  useEffect(() => {
    bloodTypeList();
    const prevValues = JSON.parse(localStorage.getItem("step2"));
    if (prevValues) {
      setInitValue2(prevValues);
    }
  }, [bloodTypeList]);

  useEffect(() => {
    setBloodTypes(blood_Types);
  }, [blood_Types]);
  const onSecondStepSubmit = (value) => {
    localStorage.setItem("step2", JSON.stringify(value));
    history.push(`/signup?step=3`);
  };

  return (
    <>
      <Formik
        initialValues={initValue2}
        validationSchema={SecondStepSubmitSchema}
        enableReinitialize
        onSubmit={onSecondStepSubmit}
      >
        {({ values, setFieldValue }) => (
          <div>
            <Form>
              <div className="first-two">
                <div className="input-group mt-2">
                  <label htmlFor="dateOfBirth" className="fw-800">
                    Date of birth
                  </label>
                  <Flatpickr
                    value={values.dateOfBirth}
                    onChange={([date]) => {
                      setFieldValue("dateOfBirth", date);
                    }}
                    options={{
                      maxDate: new Date().fp_incr(0),
                      dateFormat: "d / m / Y",
                    }}
                    placeholder="Select date"
                    name="dateOfBirth"
                    className={
                      colorMode === "dark"
                        ? "css-1c6j008 mt-2"
                        : "css-k7r2wc mt-2"
                    }
                  />
                  <ErrorMessage
                    className="chakra-form__error-message css-170ki1a"
                    name="dateOfBirth"
                    component="div"
                  />
                </div>

                <div className="input-group mt-2 ">
                  <SelectControl
                    label="Gender"
                    name="gender"
                    selectProps={{ placeholder: "Gender" }}
                    className="pt-3"
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </SelectControl>
                </div>

                <div className="input-group mt-2">
                  <SelectControl
                    label="Blood type"
                    name="blood_types_id"
                    selectProps={{ placeholder: "Blood type" }}
                    className="pt-3"
                  >
                    {bloodTypes.length
                      ? bloodTypes.map((type, index) => (
                          <option value={JSON.stringify(type)} key={index}>
                            {type.name}
                          </option>
                        ))
                      : ""}
                  </SelectControl>
                </div>
              </div>

              <div className="buttonSubmit row">
                <div className="col-6">
                  <Button
                    type="button"
                    className=" w-100 rounded login-btn mt-3 text-dark shadow-sm fw-bold"
                    bg={colorMode === "dark" ? "#718096" : "#ebf7fd"}
                    size="lg"
                    _hover={{
                      background: colorMode === "dark" ? "#A0AEC0" : "#ebf9fd",
                    }}
                    onClick={() => {
                      history.push(`/signup?step=1`);
                    }}
                  >
                    Back
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    type="submit"
                    className="w-100 rounded mt-3 fw-bold"
                    size="lg"
                    bg="primary"
                    color={"#fff"}
                    _hover={{ bg: "primary_hover" }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    blood_Types: state.bloodTypes.data,
  };
};
export default connect(mapStateToProps, { bloodTypeList })(Step2);
