import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Text,
  Switch,
  Input,
  InputGroup,
  Button,
  Box,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  PatientShareInformation,
  PatientStopShareInformation,
  getPatientProfile,
} from "../../../store/actions/patientActions";

import { Formik, Form, Field } from "formik";
import { style } from "../../../global/style";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";
import moment from "moment";
const ShareData = ({
  PatientShareInformation,
  PatientStopShareInformation,
  patient,
  getPatientProfile,
}) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [switchText, setSwitchText] = useState("Disabled");
  const [isChecked, setIsChecked] = useState(false);
  const [initValue, setInitValue] = useState({
    informationShareToken: "",
  });

  const onCopy = () => {
    const copy = document.getElementById("shareToken");
    copy.select();
    copy.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copy.value);
    return toast({
      title: "The Link Copied successfully",
      status: "success",
      duration: 7000,
      isClosable: true,
      position: "top-right",
    });
  };

  useEffect(() => {
    if (patient && patient.informationShareToken) {
      setIsChecked(true);
      setSwitchText("Enabled");
      setInitValue({
        informationShareToken: `${window.location.origin}/${patient.id}/shared-data?sharedToken=${patient.informationShareToken}`,
      });
    } else {
      setIsChecked(false);
      setSwitchText("Disabled");
      setInitValue({
        informationShareToken: "",
      });
    }
  }, [patient]);

  const onFormSubmit = (value) => {
    console.log(value);
  };
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="shareData rounded shadow-sm p-4"
    >
      <div className="d-flex justify-content-between">
        <Text
          fontSize={{ base: "lg", md: "2xl", lg: "3xl" }}
          fontWeight="semibold"
        >
          Share Data
        </Text>
        <div className="d-flex align-items-center">
          <Text
            className={
              switchText === "Disabled" ? "me-2 fw-600" : `me-2 fw-primary`
            }
          >
            {switchText}
          </Text>
          <Switch
            isChecked={isChecked}
            onChange={(e) => {
              if (e.target.checked) {
                return PatientShareInformation(
                  toast,
                  setSwitchText,
                  setIsChecked
                ).then(() => {
                  getPatientProfile();
                });
              } else {
                return PatientStopShareInformation(
                  toast,
                  setSwitchText,
                  setIsChecked
                ).then(() => {
                  getPatientProfile();
                });
              }
            }}
          />
        </div>
      </div>
      <div className="row justify-content-center ">
        <div className="d-flex justify-content-center ">
          <Text
            fontSize={{ base: "2xl", md: "4xl", lg: "6xl" }}
            color={checkColorMode(colorMode)}
            className="fw-bold "
          >
            {patient && patient.informationShareTokenExpiryTime
              ? moment
                  .unix(patient.informationShareTokenExpiryTime)
                  .format("DD/MM - hh:mm A")
              : "00:00"}
          </Text>
        </div>
        <div className="d-flex justify-content-center ">
          <Text fontSize="sm" fontWeight="semibold" color={style.smallTextGray}>
            Valid until
          </Text>
        </div>
        <div className="d-flex justify-content-center my-4">
          <Text fontSize="sm" fontWeight="semibold" className="text-center">
            Your data has been shared and includes medical reports, reading and
            your personal information
          </Text>
        </div>

        <div className="row justify-content-center my-4">
          <Formik
            initialValues={initValue}
            enableReinitialize
            onSubmit={onFormSubmit}
          >
            {() => (
              <Form className="col-md-6 col-12">
                <InputGroup size="lg" className="my-2">
                  <Field
                    as={Input}
                    type={"text"}
                    placeholder="Your information Share "
                    name={"informationShareToken"}
                    id="shareToken"
                    disabled
                  />
                </InputGroup>

                <Button
                  type="submit"
                  size="lg"
                  className="w-100 mt-2 "
                  bg={checkColorMode(colorMode)}
                  color="#fff"
                  _hover={{ bg: checkColorModeHover(colorMode) }}
                  onClick={onCopy}
                  disabled={!isChecked}
                >
                  <span className="me-4">
                    <i className="fas fa-share-alt"></i>
                  </span>{" "}
                  Share Link
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    patient: state.PatientProfile.data,
    patientShareInfo: state.patientShareInfo,
    patientStopShareInfo: state.patientStopShareInfo,
  };
};
export default connect(mapStateToProps, {
  getPatientProfile,
  PatientShareInformation,
  PatientStopShareInformation,
})(ShareData);
