import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import AddXray from "./AddXray";
import ViewXray from "./ViewXray";
import XrayChild from "./XrayChild";

const Xray = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={XrayChild} />
        <Route exact path={`${path}/add-xray`} component={AddXray} />
        <Route path={`${path}/add-xray/:Eid`} component={AddXray} />
        <Route path={`${path}/view-xray/:id`} component={ViewXray} />
      </Switch>
    </>
  );
};
export default Xray;
