import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, useToast } from "@chakra-ui/react";
import PasswordField from "../../../global/passwordField";
import { style } from "../../../global/style";
import { connect } from "react-redux";
import { resetPassword } from "../../../store/actions";
import { useHistory } from "react-router-dom";

const thirdSecondStepSubmitSchema = Yup.object().shape({
  password: Yup.string().min(3).required("Required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});
const LastStep = ({ resetPassword }) => {
  const toast = useToast();
  const history = useHistory();
  const [initValue3] = useState({
    password: "",
    passwordConfirmation: "",
  });

  const onFormSubmit = (value) => {
    return resetPassword(value.password, history, toast);
  };

  return (
    <Formik
      initialValues={initValue3}
      validationSchema={thirdSecondStepSubmitSchema}
      enableReinitialize
      onSubmit={onFormSubmit}
    >
      <Form className=" bg-white rounded shadow-sm login-row">
        <div className="last-step">
          <div className="Forget-header">
            <h5 className="fw-bold">New Password</h5>
            <p
              className="my-3 fw-bold text-secondary"
              style={{ fontSize: "14px" }}
            >
              Please enter the new password
            </p>
          </div>

          <div className="input-group mt-2">
            <PasswordField label="New Password" />
          </div>
          <div className="input-group mt-2">
            <PasswordField
              label="Re-enter New Password"
              name="passwordConfirmation"
            />
          </div>
          <div className="buttonSubmit">
            <Button
              type="submit"
              h="54px"
              className="rounded mt-3"
              bg={style.primary}
              colorScheme={style.primary}
              _hover={{ background: style.primary_hover }}
              w="100%"
              fontWeight="bold"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
export default connect(null, { resetPassword })(LastStep);
