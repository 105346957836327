const INIT_ALLERGIES_STATR = {
  loading: false,
  error: null,
  data: null,
};

export const getAllergiesReducer = (
  state = INIT_ALLERGIES_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_ALLERGIES_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ALLERGIES_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ALLERGIES_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneAllergiesReducer = (
  state = INIT_ALLERGIES_STATR,
  { type, payload }
) => {
  switch (type) {
    case "GET_ONE_ALLERGIES_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_ONE_ALLERGIES_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ONE_ALLERGIES_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const postAllergiesReducer = (
  state = INIT_ALLERGIES_STATR,
  { type, payload }
) => {
  switch (type) {
    case "POST_ALLERGIES_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_ALLERGIES_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_ALLERGIES_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const updateAllergiesReducer = (
  state = INIT_ALLERGIES_STATR,
  { type, payload }
) => {
  switch (type) {
    case "UPDATE_ALLERGIES_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_ALLERGIES_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_ALLERGIES_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const deleteAllergiesReducer = (
  state = INIT_ALLERGIES_STATR,
  { type, payload }
) => {
  switch (type) {
    case "DELETE_ALLERGIES_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "DELETE_ALLERGIES_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "DELETE_ALLERGIES_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
