import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOneEcg, deleteEcg } from "./../../../store/actions";
import { useParams, useLocation } from "react-router-dom";
import View from "../Repeated-Component/View";
import { useToast } from "@chakra-ui/react";

const ViewEcg = ({ getOneEcg, oneECG, deleteEcg, history }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [ecg, setEcg] = useState(null);
  let params = useParams();
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const toast = useToast();
  const remove = () => {
    deleteEcg(params.id, toast, history, "/medical-record/ecg");
  };
  useEffect(() => {
    if (params && params.id && params.patientId && sharedToken) {
      getOneEcg(params.id, toast, history, params.patientId, sharedToken);
    } else if (params && params.id && !params.patientId && !sharedToken) {
      getOneEcg(params.id, toast, history);
    }
  }, [getOneEcg, params.id, toast, history, sharedToken]);

  useEffect(() => {
    const { loading, error, data } = oneECG;
    setLoading(loading);
    setError(error);
    if (data) {
      setEcg(data);
    }
  }, [oneECG]);
  const renderView = () => {
    return (
      <View
        loading={loading}
        error={error}
        headerText="View ECG"
        linkEdite="/medical-record/ecg/add-ecg"
        one={ecg}
        params={params}
        removeOne={remove}
        backLink={
          sharedToken
            ? `/ecg/${params.patientId}/shared-data?sharedToken=${sharedToken}`
            : "/medical-record/ecg"
        }
        nameForAlert="ECG"
      />
    );
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    oneECG: state.oneECG,
  };
};
export default connect(mapStateToProps, { getOneEcg, deleteEcg })(ViewEcg);
