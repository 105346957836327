export const pageVariants = {
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
export const pageTransition = {
  type: "tween",
  ease: "easeOut",
  duration: 0.5,
};
