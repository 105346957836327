import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import conditions from "../../../../assets/conditions.png";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updatePatientCondition,
  getOneCondition,
  postPatientCondition,
  getAllNotifications,
  getUnReadNotifications,
} from "../../../../store/actions";
import Add from "../../Repeated-Component/Add";

import { useToast } from "@chakra-ui/react";

const SignInSchema = Yup.object().shape({
  value: Yup.string().min(3).required("Required"),
  description: Yup.string().min(3).required("Required"),
});
const AddCondition = ({
  oneCondition,
  history,
  updatePatientCondition,
  getOneCondition,
  postPatientCondition,
  setChildData,
  getAllNotifications,
  getUnReadNotifications,
}) => {
  let params = useParams();
  //
  const toast = useToast();
  //
  const [initValue, setInitValue] = useState({
    value: "",
    description: "",
  });

  useEffect(() => {
    if (params.Eid) {
      getOneCondition(params.Eid);
    }
  }, [params, getOneCondition]);

  useEffect(() => {
    if (params.Eid && oneCondition) {
      setInitValue({
        value: oneCondition.value,
        description: oneCondition.description,
      });
    }
  }, [oneCondition, params]);

  const onFormSubmit = (value) => {
    if (params.Eid) {
      return updatePatientCondition(value, params.Eid, history, toast);
    } else
      postPatientCondition(
        value,
        history,
        toast,
        setChildData,
        getAllNotifications,
        getUnReadNotifications
      );
  };

  return (
    <>
      <Add
        Schema={SignInSchema}
        initValue={initValue}
        backLink={"/medical-record/conditions"}
        history={history}
        onFormSubmit={onFormSubmit}
        headerText={`${params.Eid ? "Edit" : "Add "} Condition`}
        secondHeaderText="Conditions"
        therdHeaderText="Add your Conditions"
        image={conditions}
        feildsNames={{
          feild1: {
            name: "value",
            type: "text",
            placeholder: "Briefly tell us about your Condition",
          },
          feild2: {
            name: "description",
            type: "",
            placeholder: "Describe in as much detail as you can.",
          },
        }}
        setChildData={setChildData}
      />
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    oneCondition: state.oneCondition.data,
  };
};
export default connect(mapStateToprops, {
  updatePatientCondition,
  getOneCondition,
  postPatientCondition,
  getAllNotifications,
  getUnReadNotifications,
})(AddCondition);
