import React from "react";
import { Text, Button, Box, useColorMode } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor.js";

const MyInfo = ({
  loading,
  error,
  patientProfile,
  patientAddress,
  patientEmergency,
}) => {
  const { colorMode } = useColorMode();
  const renderView = () => {
    if (loading) {
      return "Loading";
    }
    if (error) {
      return "Error";
    }
    if (patientProfile) {
      return (
        <div>
          <Box
            bg={checkBgColorMode(colorMode)}
            className="MyInfo rounded shadow-sm p-4  d-flex flex-wrap mb-4"
          >
            <div className="col-12 d-flex justify-content-between align-items-center">
              <Text fontSize="lg" color="secondary" className="fw-bold">
                {" "}
                My Info
              </Text>
              <Button
                width={"50px"}
                height="50px"
                bg={checkColorMode(colorMode)}
                _hover={{ bg: checkColorModeHover(colorMode) }}
                fontSize="17px"
              >
                <Link
                  to={
                    patientProfile
                      ? `/profile/edit_info?patientID=${patientProfile.id}`
                      : "/profile/edit_info"
                  }
                  className="nav-link text-white"
                >
                  <i className="fas fa-pen"></i>
                </Link>
              </Button>
            </div>

            <div className="col-12 d-flex flex-wrap">
              <div className="col-md-4 col-12 p-0 mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  E-Mail
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientProfile
                    ? `${patientProfile.email.substr(0, 25)}`
                    : ""}
                </Text>
              </div>

              <div className="col-md-4 col-12 p-0 mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Phone Number
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientProfile ? patientProfile.mobile : ""}
                </Text>
              </div>

              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Gender
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                  textTransform="capitalize"
                >
                  {patientProfile ? patientProfile.gender : ""}
                </Text>
              </div>

              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Date of Birth
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientProfile
                    ? moment
                        .unix(patientProfile.dateOfBirth)
                        .format("DD/MM/YYYY")
                    : ""}
                </Text>
              </div>
              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Blood Type
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientProfile && patientProfile.bloodType
                    ? patientProfile.bloodType.name
                    : ""}
                </Text>
              </div>
            </div>
          </Box>

          <Box
            bg={checkBgColorMode(colorMode)}
            className="MyInfo rounded shadow-sm p-4  d-flex flex-wrap mb-4"
          >
            <div className="col-12 d-flex justify-content-between align-items-center">
              <Text color="secondary" fontSize="lg" className="fw-bold">
                {" "}
                My Address
              </Text>
              <Button
                width={"50px"}
                height="50px"
                bg={checkColorMode(colorMode)}
                _hover={{ bg: checkColorModeHover(colorMode) }}
                fontSize="17px"
              >
                <Link
                  to={
                    patientAddress
                      ? `/profile/edit_address?addressID=${patientAddress.id}`
                      : "/profile/add_address"
                  }
                  className="nav-link text-white"
                >
                  <i className="fas fa-pen"></i>
                </Link>
              </Button>
            </div>
            <div className="col-12 d-flex flex-wrap">
              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Country
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientAddress ? patientAddress.country : ""}
                </Text>
              </div>

              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  City
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientAddress ? patientAddress.city : ""}
                </Text>
              </div>
              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Street
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientAddress ? patientAddress.street : ""}
                </Text>
              </div>
              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Postal Code
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientAddress && patientAddress.postelCode
                    ? patientAddress.postelCode
                    : ""}
                </Text>
              </div>
            </div>
          </Box>

          <Box
            bg={checkBgColorMode(colorMode)}
            className="MyInfo rounded shadow-sm p-4  d-flex flex-wrap"
          >
            <div className="col-12 d-flex justify-content-between align-items-center">
              <Text color="secondary" fontSize="lg" className="fw-bold">
                {" "}
                Emergency Info
              </Text>
              <Button
                width={"50px"}
                height="50px"
                bg={checkColorMode(colorMode)}
                _hover={{ bg: checkColorModeHover(colorMode) }}
                fontSize="17px"
              >
                <Link
                  to={
                    patientEmergency
                      ? `/profile/edit_emargency?emargencyID=${patientEmergency.id}`
                      : "/profile/add_emargency"
                  }
                  className="nav-link text-white"
                >
                  <i className="fas fa-pen"></i>
                </Link>
              </Button>
            </div>
            <div className="col-12 d-flex flex-wrap">
              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Name
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientEmergency ? patientEmergency.name : ""}
                </Text>
              </div>
              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Phone Number
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientEmergency ? patientEmergency.mobile : ""}
                </Text>
              </div>
              <div className="col-md-4 col-12 p-0  mt-3">
                <Text fontWeight="semibold" color={"smallTextGray"}>
                  Email
                </Text>
                <Text
                  fontSize="sm"
                  fontWeight="semibold"
                  color={checkColorMode(colorMode)}
                >
                  {patientEmergency
                    ? `${patientEmergency.email.substr(0, 25)}`
                    : ""}
                </Text>
              </div>
              {/* <div className="col-md-4 col-12 p-0  mt-3">
              <div fontWeight="semibold" color={"smallTextGray"}>
                Address
              </div>
              <div    fontWeight="semibold"
                  color={checkColorMode(colorMode)}>
                User.Address
              </div>
            </div> */}
            </div>
          </Box>
        </div>
      );
    } else {
      return "someting wrong";
    }
  };
  return renderView();
};
export default connect(null)(MyInfo);
