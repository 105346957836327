import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import hospital from "../../assets/hospital.svg";
import "./Home.scss";
import {
  getAllNotifications,
  getHospitalInvites,
  getPatientAppointmnts,
  getPatientMedical,
  getPatientLapResult,
  getPatientconsultations,
  getUnReadNotifications,
  getHospitalInviteRequest,
} from "../../store/actions";
import { motion } from "framer-motion";
import { getLast7Days } from "../../global/ConverDate";
import {
  Box,
  Text,
  Image,
  Button,
  Divider,
  Circle,
  useColorMode,
} from "@chakra-ui/react";
import Consultation from "../../assets/Consultation.png";
import { style } from "../../global/style";
import Common from "../Common/Common";

import chest from "../../assets/chest.png";
import { authConfige } from "../../store/authConfige";
import MenuComponent from "../Notifications/menu.Component";
import { LinksAndImages } from "../Notifications/helperImageAndLinkes";
import {
  pageTransition,
  pageVariants,
} from "../../global/Animations/Animations";
import { checkDate } from "../Notifications/Notifications";
import { filterString } from "../Consultations/consultationChild";
import DoctorInfoModal from "../Consultations/doctorInfoModal/doctorInfoModal";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
  checkWhiteAndBlackColor,
} from "../../global/changeColor";
import moment from "moment";
import { GetSpecialityIcon } from "../../global/GetSpecialityIcon";

const Home = ({
  getAllNotifications,
  getHospitalInvites,
  notificationsList,
  hospitalInvites,
  getPatientAppointmnts,
  Appointments,
  getPatientMedical,
  medicalList,
  getPatientLapResult,
  LapResultList,
  getPatientconsultations,
  ConsultationsList,
  getUnReadNotifications,
  getHospitalInviteRequest,
  hospitalInviteRequest,
  PatientProfile,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [appointments, setAppointments] = useState(0);
  const [appointmentsLastWeek, setAppointmentsLastWeek] = useState(0);

  const [medical, setMedical] = useState(0);
  const [medicalLastWeek, setMedicalLastWeek] = useState(0);

  const [labResult, setLabResult] = useState(0);
  const [labResultLastWeek, setLabResultLastWeek] = useState(0);

  const [consultations, setConsultations] = useState(0);
  const [consultationsLastWeek, setConsultationsLastWeek] = useState(0);
  const [currentConsulatation, setCurrentConsulatation] = useState(null);
  const [Notifications, setNotifications] = useState([]);
  const [showHospitalSection, setShowHospitalSection] = useState(true);
  const [draftExist, setDraftExist] = useState(null);
  const { colorMode } = useColorMode();

  const renderList = [
    {
      header: "All Consultations",
      firstNum: consultations,
      secondNum: consultationsLastWeek,
    },
    {
      header: "Medical Reports",
      firstNum: medical,
      secondNum: medicalLastWeek,
    },
    {
      header: "Appointments",
      firstNum: appointments,
      secondNum: appointmentsLastWeek,
    },
    {
      header: "Lab Results",
      firstNum: labResult,
      secondNum: labResultLastWeek,
    },
  ];

  const token = localStorage.token;
  useEffect(() => {
    if (token) {
      authConfige.AUTHORIZATION = `Bearer ${localStorage.token}`;
      getAllNotifications();
      getHospitalInvites();
      getPatientAppointmnts();
      getPatientMedical();
      getPatientLapResult();
      getPatientconsultations();
      getUnReadNotifications();
    }
  }, [
    token,
    getAllNotifications,
    getHospitalInvites,
    getPatientAppointmnts,
    getPatientMedical,
    getPatientLapResult,
    getPatientconsultations,
    getUnReadNotifications,
  ]);
  useEffect(() => {
    if (hospitalInvites && hospitalInvites.length > 0) {
      getHospitalInviteRequest(hospitalInvites[0].id);
    }
  }, [hospitalInvites, getHospitalInviteRequest]);

  useEffect(() => {
    if (hospitalInviteRequest && hospitalInviteRequest.data) {
      if (hospitalInviteRequest.data.status === "finished-requests") {
        setShowHospitalSection(false);
      } else {
        setShowHospitalSection(true);
      }
    }
  }, [hospitalInviteRequest]);

  useEffect(() => {
    if (Appointments && Appointments.data) {
      setAppointments(Appointments.data.meta.total);
      const last7days = getLast7Days(Appointments.data.data);
      if (last7days.length) {
        setAppointmentsLastWeek(last7days.length);
      }
    }
    if (medicalList && medicalList.data) {
      setMedical(medicalList.data.meta.total);
      const last7days = getLast7Days(medicalList.data.data);
      if (last7days.length) {
        setMedicalLastWeek(last7days.length);
      }
    }
    if (LapResultList && LapResultList.data) {
      setLabResult(LapResultList.data.meta.total);
      const last7days = getLast7Days(LapResultList.data.data);
      if (last7days.length) {
        setLabResultLastWeek(last7days.length);
      }
    }
    if (ConsultationsList) {
      const { loading, error, data } = ConsultationsList;
      setLoading(loading);
      setError(error);
      if (data && data.data) {
        const list = data.data;
        const notDone = list.filter((item) => item.status !== "done");
        setConsultations(list.length);
        setCurrentConsulatation(notDone[0]);
        const last7days = getLast7Days(list);
        if (last7days.length) {
          setConsultationsLastWeek(last7days.length);
        }
      }
    }
    if (notificationsList) {
      const { data } = notificationsList;
      const newData = data.slice(0, 3);
      setNotifications(newData);
    }
  }, [
    Appointments,
    medicalList,
    LapResultList,
    ConsultationsList,
    notificationsList,
    hospitalInvites,
  ]);
  useEffect(() => {
    const draft = localStorage.draftConsultation;
    if (draft) {
      setDraftExist(JSON.parse(draft));
    }
  }, []);
  const renderHospitalRequestsView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      return "error";
    }
    if (hospitalInvites && hospitalInvites.length > 0 && showHospitalSection) {
      return (
        <Box
          bg={checkBgColorMode(colorMode)}
          className="d-flex flex-column text-center p-4 rounded shadow-sm my-3 align-items-center justify-content-center"
        >
          <Box className="w-full text-center">
            <Image
              width={400}
              src={hospital}
              alt="Dan Abramov"
              className="img-fluid"
            />
            <Text
              fontSize="3xl"
              fontWeight="semibold"
              className="my-2 col-md-12"
            >
              Hospital Request
            </Text>
            <Text
              fontSize="md"
              fontWeight="semibold"
              className="my-2 col-md-12"
              color={"smallTextGray"}
            >
              Please tap the button below to view the request
            </Text>
          </Box>
          <Box className="my-4 w-25">
            <Button
              as={Link}
              to={"/hospital/request/" + hospitalInvites[0].id}
              h="57px"
              bg={checkColorMode(colorMode)}
              color="white"
              w="100%"
              className="rounded"
              _hover={{ bg: checkColorModeHover(colorMode), color: "white" }}
            >
              View Request
            </Button>
          </Box>
        </Box>
      );
    }
  };
  const renderView = () => {
    if (loading) {
      return "";
    }
    if (error) {
      return "error";
    }

    if (currentConsulatation) {
      return (
        <Box
          as={Link}
          to={`/consultations/over-view?consultationId=${currentConsulatation.id}`}
          className="row m-0"
          _hover={{
            color: checkWhiteAndBlackColor(colorMode),
          }}
        >
          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-12 p-4 rounded shadow-sm my-3"
          >
            <div className="row align-items-center justify-content-between">
              <Text
                variant="WhitColor"
                fontWeight="semibold"
                className="col-sm-6 col-12"
              >
                My current consultation
              </Text>
              {/* <div className="col-sm-6 col-12 text-end">
               
              </div> */}
              <div className="w-auto sm-marginY d-none d-sm-block">
                <Button className="pending rounded " textTransform="capitalize">
                  {" "}
                  {filterString(currentConsulatation.statusString) ===
                  "Appointment passed"
                    ? "Appointment finished"
                    : filterString(currentConsulatation.statusString)}
                </Button>
              </div>
            </div>
            <Divider className="my-4" color="smallTextGrayLight" />
            <div className="row">
              <div className="col-md-6 col-12">
                <Text fontSize="xl" fontWeight="semibold">
                  {currentConsulatation.symptoms}
                </Text>
                <Text color="smallTextGray" fontSize="12px">
                  {moment
                    .unix(currentConsulatation.createdAt)
                    .format("DD/MM/YYYY - hh:mm A")}{" "}
                </Text>
              </div>

              <div className="col-md-6 col-12 d-flex flex-wrap align-items-center justify-content-between">
                {/* Speciality */}
                <div className="d-flex align-items-center my-3">
                  <Circle
                    size="40px"
                    bg={checkColorMode(colorMode)}
                    color="white"
                    className="me-2"
                  >
                    {currentConsulatation.consultationTypeId ? (
                      <img
                        className="image-fluid"
                        src={GetSpecialityIcon(
                          Number(currentConsulatation.consultationTypeId)
                        )}
                        alt="chest"
                      />
                    ) : (
                      ""
                    )}
                  </Circle>
                  <Box className="col-9" fontSize="13.5px">
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      color="smallTextGray"
                    >
                      Speciality
                    </Text>
                    <Common itemId={currentConsulatation.consultationTypeId} />
                  </Box>
                </div>
                {/* Doctor */}
                {currentConsulatation.consultantId ? (
                  <div className=" d-flex align-items-center">
                    <DoctorInfoModal doctor={currentConsulatation.consultant} />
                  </div>
                ) : null}
                <div className="w-auto sm-marginY d-block d-sm-none">
                  <Button
                    className="pending rounded mt-4"
                    textTransform="capitalize"
                  >
                    {" "}
                    {filterString(currentConsulatation.statusString)}
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Box>
      );
    } else {
      if (draftExist && Number(draftExist.patientId) === PatientProfile?.id) {
        return (
          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-12 p-4  rounded shadow-sm row m-0 align-item-center justify-content-between"
          >
            <Box className="col-12">
              <Text
                className="h4 fw-bold mb-0"
                color={checkColorMode(colorMode)}
              >
                {draftExist?.symptoms}
              </Text>
              <Text color={"smallTextGray"} fontSize="13px">
                {moment(draftExist?.created_at).format("DD/MM/YYYY hh:mm A")}
              </Text>
            </Box>
            <Box
              boxShadow="xs"
              className="rounded p-3 mt-2 col-12"
              bg="smallTextGrayLight"
              border={`1px solid`}
              borderColor="smallTextGrayLightBorder"
            >
              {draftExist?.symptomsExplained}
            </Box>
            <Box
              className={`pending text-center rounded px-5 my-3 col-md-3 col-12`}
              textTransform="capitalize"
            >
              draft
            </Box>
            <Button
              as={Link}
              to="/consultations/draft-consultation"
              className="my-3"
              color={checkColorMode(colorMode)}
              _hover={{ color: checkColorMode(colorMode) }}
            >
              Complete your request
            </Button>
          </Box>
        );
      } else {
        return (
          <Box
            bg={checkBgColorMode(colorMode)}
            className="row p-4 py-5 rounded shadow-sm m-0 my-5  align-items-center justify-content-center"
          >
            <Box className="col-md-4 d-flex justify-content-center">
              <Image src={Consultation} alt="Consultation" />
            </Box>
            <Box className="col-md-8 row m-0">
              <Text
                fontSize="2xl"
                fontWeight="semibold"
                className="my-2 col-md-12"
              >
                Start new consulatation
              </Text>
              <Text
                fontSize="md"
                fontWeight="semibold"
                className="my-2 col-md-12"
                color="smallTextGray"
              >
                Please tap the button below to begin your consultation
              </Text>
              {/* pay wording it will changed */}
              <Box className="col-md-12 row m-0 p-0">
                {/* <div className="col-md-6 my-3">
                  <Box
                    h="57px"
                    className=" row m-0 rounded ps-0 align-items-center text-center"
                    bg={style.bg_Light_Gray}
                    fontWeight="bold"
                  >
                    <Text>
                      <span className="me-3">
                        <i className="fas fa-pound-sign"></i>
                      </span>{" "}
                      You pay: 250GBP
                    </Text>
                  </Box>
                </div> */}
                <Box className="col-md-6 my-3">
                  <Button
                    as={Link}
                    to="/consultations/add-consultation"
                    h="57px"
                    bg={checkColorMode(colorMode)}
                    color="white"
                    w="100%"
                    className="rounded"
                    _hover={{
                      bg: checkColorModeHover(colorMode),
                      color: "white",
                    }}
                  >
                    Request Consultation
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      }
    }
  };
  const renderMainView = () => {
    if (loading) return "";
    else {
      return (
        <motion.div
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
          className="Home container my-5"
        >
          {renderHospitalRequestsView()}
          <div className="row">
            {renderList.map((item, index) => (
              <Box className="col-lg-3 col-md-4 col-sm-6 col-12" key={index}>
                <Box
                  bg={checkBgColorMode(colorMode)}
                  className="p-4 rounded shadow-sm my-3"
                >
                  <Text fontSize="xl" fontWeight="semibold">
                    {item.header}
                  </Text>
                  <Text
                    className="my-3 fw-bolder h5"
                    color={checkColorMode(colorMode)}
                  >
                    {item.firstNum}
                  </Text>
                  <Box
                    className="row flex-row fw-bold"
                    fontSize="13px"
                    color="smallTextGray"
                  >
                    <div className="col-6">This Week</div>
                    <div className="col-6 text-end">{item.secondNum}</div>
                  </Box>
                </Box>
              </Box>
            ))}
          </div>
          {/* Notification */}
          {renderView()}
          {Notifications.length ? (
            <Box
              bg={checkBgColorMode(colorMode)}
              className="row m-0 rounded shadow-sm my-3 align-items-center"
            >
              <div className="col-12 p-3 row m-0 my-3 align-items-center">
                <div className="col-6">
                  <Text fontWeight="semibold">My Activity</Text>
                </div>
                <div className="col-6 text-end">
                  <Button
                    as={Link}
                    to="/notifications"
                    color={checkColorMode(colorMode)}
                    _hover={{ color: checkColorMode(colorMode) }}
                  >
                    More
                  </Button>
                </div>
              </div>

              {Notifications.map((item, index) => (
                <Box
                  className="m-0 p-0"
                  key={index}
                  pointerEvents={
                    item.readAt && checkDate(item.createdAt) ? "none" : "auto"
                  }
                  opacity={
                    item.readAt && checkDate(item.createdAt) ? ".6" : "1"
                  }
                >
                  <div className="col-12 d-flex justify-content-center">
                    <Divider
                      className="text-center"
                      width="95%"
                      color="smallTextGrayLight"
                    />
                  </div>
                  <Box className="col-12 p-3 row m-0 align-items-center justify-content-between">
                    <Box className="col-9 row">
                      {" "}
                      <div className="col-md-7 col-12 d-flex align-items-center sm-marginbottom">
                        <Circle
                          size="40px"
                          bg={checkColorMode(colorMode)}
                          color="white"
                          className="me-2"
                        >
                          {LinksAndImages(item).img ? (
                            <Image
                              maxW={"80%"}
                              maxH="70%"
                              className="image-fluid"
                              src={LinksAndImages(item).img}
                              alt={item.data.type ? item.data.type : "no"}
                            />
                          ) : (
                            <i className={LinksAndImages(item).className}></i>
                          )}
                        </Circle>
                        <Box className="col-9" fontSize="13.5px">
                          <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            color="smallTextGray"
                          >
                            Activity
                          </Text>
                          <Text fontSize="13px" fontWeight="700">
                            {item.data && item.data.title
                              ? item.data.title
                              : "null"}
                          </Text>
                        </Box>
                      </div>
                      <div className="col-md-5 col-12 d-flex align-items-center">
                        <Box className="col-9 " fontSize="13.5px">
                          <Text
                            fontSize="sm"
                            fontWeight="semibold"
                            color="smallTextGray"
                          >
                            Time
                          </Text>
                          <Text fontSize="12px" fontWeight="700">
                            {item.updatedAt
                              ? moment
                                  .unix(item.updatedAt)
                                  .format("DD/MM/YYYY - hh:mm A")
                              : ""}
                          </Text>
                        </Box>
                      </div>
                    </Box>

                    <Box className="col-3 p-0 text-end">
                      {item.readAt && checkDate(item.createdAt) ? (
                        ""
                      ) : (
                        <MenuComponent
                          LinksAndImages={LinksAndImages}
                          style={style}
                          item={item}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : null}
        </motion.div>
      );
    }
  };
  return renderMainView();
};
const mapStateToprops = (state) => {
  return {
    user: state.user,
    notificationsList: state.notificationsList.data,
    hospitalInvites: state.hospitalInvites.data,
    Appointments: state.appointmntsList,
    medicalList: state.medicalList,
    LapResultList: state.LapResultList,
    ConsultationsList: state.consultationsList,
    hospitalInviteRequest: state.hospitalInviteRequest,
    PatientProfile: state.PatientProfile.data,
  };
};
export default connect(mapStateToprops, {
  getAllNotifications,
  getHospitalInvites,
  getHospitalInviteRequest,
  getPatientAppointmnts,
  getPatientMedical,
  getPatientLapResult,
  getPatientconsultations,
  getUnReadNotifications,
})(Home);
