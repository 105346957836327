const INIT_EMERGENCY_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const getPatientEmergencyReducer = (
  state = INIT_EMERGENCY_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_EMERGENCY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "GET_EMERGENCY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_EMERGENCY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const postPatientEmergencyReducer = (
  state = INIT_EMERGENCY_STATE,
  { type, payload }
) => {
  switch (type) {
    case "POST_EMERGENCY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "POST_EMERGENCY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_EMERGENCY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const updatePatientEmergencyReducer = (
  state = INIT_EMERGENCY_STATE,
  { type, payload }
) => {
  switch (type) {
    case "UPDATE_EMERGENCY_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_EMERGENCY_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_EMERGENCY_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
