import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import AddRadiology from "./Add-Radiology";
import EditReadiology from "./Edit-Readiology";

import RadiologyChild from "./Radiology-Child";
import ViewRadiology from "./View-radiology";
const Radiology = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={RadiologyChild} />
      <Route path={`${path}/add-radiology`} component={AddRadiology} />
      <Route path={`${path}/view-radiology/:id`} component={ViewRadiology} />
      <Route path={`${path}/edit-radiology/:Eid`} component={EditReadiology} />
    </Switch>
  );
};
export default Radiology;
