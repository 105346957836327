import React, { useState, useEffect } from "react";
import { InputControl } from "formik-chakra-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Button, Text, useToast, Box, useColorMode } from "@chakra-ui/react";
import { connect } from "react-redux";
import {
  postPatientVerifyMobile,
  resendPatientVerifyMobile,
} from "../store/actions";
import { useLocation } from "react-router-dom";

import { style } from "../global/style";
import ChangeMobile from "./ChangeMobile";
import { checkBgColorMode } from "../global/changeColor";

const SignUpSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .min(6, "Please Entar a valid code")
    .max(6, "verificationCode must be at most 6 number")
    .required("Required"),
});

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const VerifyMobile = ({
  history,
  resendPatientVerifyMobile,
  resendVerifyMobile,
  postPatientVerifyMobile,
}) => {
  let query = useQuery().get("from");
  const toast = useToast();
  const { colorMode } = useColorMode();
  const [user, setuser] = useState();
  const [secondView, setSecondView] = useState(false);
  const [counter, setCounter] = useState(0);
  const [localCounter] = useState(localStorage.counter);
  const [initValue] = useState({
    verificationCode: "",
  });
  useEffect(() => {
    if (localCounter) setCounter(Number(localCounter));
  }, []);
  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
        localStorage.setItem("counter", counter);
      }, 1000);
    return () => {
      clearInterval(timer);
      if (localStorage.counter <= 1) {
        localStorage.removeItem("counter");
      }
    };
  }, [counter]);
  useEffect(() => {
    const registrUser = JSON.parse(localStorage.getItem("registrUser"));
    if (registrUser) {
      setuser(registrUser);
    } else {
      history.push("/signup");
      toast({
        title: "Complete your registration first",
        status: "success",
        duration: 7000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [history, toast]);

  const onFormSubmit = (value) => {
    const data = {
      verificationCode: String(value.verificationCode),
    };
    postPatientVerifyMobile(data, toast, history);
    // console.log(value);
    // history.push("/verify_email");
  };

  const resendVerify = () => {
    setCounter(60);
    return resendPatientVerifyMobile(toast);
  };
  return (
    <div className="row justify-content-center my-5">
      <div className="col-md-5 col-12">
        <Box
          bg={checkBgColorMode(colorMode)}
          className="rounded shadow-sm login-row"
        >
          {secondView ? (
            <ChangeMobile
              secondView={secondView}
              setSecondView={setSecondView}
              user={user}
            />
          ) : (
            <Formik
              initialValues={initValue}
              validationSchema={SignUpSchema}
              enableReinitialize
              onSubmit={onFormSubmit}
            >
              <div>
                <Form>
                  <div className="d-flex justify-content-center flex-wrap">
                    <div
                      className="text-white d-flex justify-content-center align-items-center rounded-circle"
                      style={{
                        width: "130px",
                        height: "130px",
                        backgroundColor: "#42B79E",
                        fontSize: "60px",
                      }}
                    >
                      <span>
                        <i className="fas fa-check"></i>
                      </span>
                    </div>
                    <div className="w-100 text-center">
                      <Text fontSize="2xl" className="fw-bold mt-4">
                        Verify your mobile number
                      </Text>
                      <Text className="fw-600 mt-1">
                        We’ve sent you a verification code via SMS to the number{" "}
                        <span className="text-secondary">
                          {localStorage.userMobile
                            ? localStorage.userMobile
                            : user?.mobile}
                        </span>
                        <Button
                          className="ms-1"
                          color={style.primary}
                          onClick={() => {
                            if (query) {
                              setSecondView(!secondView);
                              history.push("/verify_mobile?from=profile");
                            } else {
                              history.push("/signup?step=3&edit=mobile");
                            }
                          }}
                        >
                          <i className="fas fa-pen"></i>
                        </Button>
                      </Text>
                    </div>
                  </div>
                  <div className="first-four">
                    <div className="input-group mt-2">
                      <InputControl
                        name="verificationCode"
                        inputProps={{
                          placeholder: "Enter verification code",
                          type: "number",
                        }}
                      />
                    </div>
                  </div>

                  <div className="buttonSubmit row">
                    <div className="col-12">
                      <Button
                        type="submit"
                        className="w-100 mt-3 fw-bold"
                        size="lg"
                        bg={style.primary}
                        color="#fff"
                        _hover={{ bg: style.primary_hover }}
                      >
                        Verify
                      </Button>
                    </div>
                    <div className="col-12 text-center my-3">
                      <h1>Didn't receive code?</h1>
                    </div>
                    <div className="col-12">
                      <Button
                        type="button"
                        color="#fff"
                        size="lg"
                        bg={style.secondary}
                        _hover={{ bg: style.secondary_hover }}
                        className="w-100"
                        onClick={resendVerify}
                        isLoading={resendVerifyMobile.loading}
                        disabled={counter >= 1}
                      >
                        {counter >= 1 ? counter : "Resend"}
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </Formik>
          )}
        </Box>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    resendVerifyMobile: state.resendPatientVerifyMobile,
  };
};
export default connect(mapStateToProps, {
  postPatientVerifyMobile,
  resendPatientVerifyMobile,
})(VerifyMobile);
