import axios from "axios";
import { types } from "../../types/types";
import { handeleError, handeleSuccess } from "../toast";
const { readingTypes } = types;

const API_URL = process.env.REACT_APP_API_URL;

export const getPatientRreadingList = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: readingTypes.GET_READINGS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/readings?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/readings/`,
      });
      dispatch({
        type: readingTypes.GET_READINGS_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: readingTypes.GET_READINGS_ERROR,
        payload: err,
      });
    }
  };
};

export const get_one_Patient_Rreading = (id) => {
  return async (dispatch) => {
    dispatch({
      type: readingTypes.GET_ONE_READINGS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/readings/show/${id}`,
      });
      dispatch({
        type: readingTypes.GET_ONE_READINGS_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: readingTypes.GET_ONE_READINGS_ERROR,
        payload: err,
      });
    }
  };
};

export const post_Patient_Rreading = (data, toast) => {
  return async (dispatch) => {
    dispatch({
      type: readingTypes.POST_READINGS_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/readings`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your Reading has been added successfully", toast);
        dispatch({
          type: readingTypes.POST_READINGS_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: readingTypes.POST_READINGS_ERROR,
        payload: error,
      });
    }
  };
};
export const update_Patient_Rreading = (data, id, toast) => {
  return async (dispatch) => {
    dispatch({
      type: readingTypes.UPDATE_READINGS_LOADING,
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/patient/readings/${id}`,

        data: data,
      });

      if (res) {
        handeleSuccess("Your reading has been updated successfully", toast);
        dispatch({
          type: readingTypes.UPDATE_READINGS_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: readingTypes.UPDATE_READINGS_ERROR,
        payload: error,
      });
    }
  };
};

export const delete_Patient_Rreading = (id, toast, getPatientRreadingList) => {
  return async (dispatch) => {
    dispatch({
      type: readingTypes.DELETE_READINGS_LOADING,
    });
    try {
      const res = await axios({
        method: "DELETE",
        url: `${API_URL}/patient/readings/${id}`,
      });
      handeleSuccess("Your reading deleted successfully", toast);
      getPatientRreadingList();
      dispatch({
        type: readingTypes.DELETE_READINGS_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: readingTypes.DELETE_READINGS_ERROR,
        payload: err,
      });
    }
  };
};
