import { types } from "../../types/types";
const { readingTypes } = types;
const INIT_READDING_STATE = {
  loading: false,
  error: null,
  data: null,
};

export const getPatientReaddingReducer = (
  state = INIT_READDING_STATE,
  { type, payload }
) => {
  switch (type) {
    case readingTypes.GET_READINGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case readingTypes.GET_READINGS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case readingTypes.GET_READINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const addReaddingReducer = (
  state = INIT_READDING_STATE,
  { type, payload }
) => {
  switch (type) {
    case readingTypes.POST_READINGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case readingTypes.POST_READINGS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case readingTypes.POST_READINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
export const updateReaddingReducer = (
  state = INIT_READDING_STATE,
  { type, payload }
) => {
  switch (type) {
    case readingTypes.UPDATE_READINGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case readingTypes.UPDATE_READINGS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case readingTypes.UPDATE_READINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const getOneReaddingReducer = (
  state = INIT_READDING_STATE,
  { type, payload }
) => {
  switch (type) {
    case readingTypes.GET_ONE_READINGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case readingTypes.GET_ONE_READINGS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case readingTypes.GET_ONE_READINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const deleteReaddingReducer = (
  state = INIT_READDING_STATE,
  { type, payload }
) => {
  switch (type) {
    case readingTypes.DELETE_READINGS_LOADING:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case readingTypes.DELETE_READINGS_SUCSES:
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case readingTypes.DELETE_READINGS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
