import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import "./NavBar.scss";
import logo from "../../assets/logo.svg";
import darkLogo from "../../assets/darkLogo.svg";
import AlertComponent from "../../global/Alert.Component";
import {
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Divider,
  Text,
  Circle,
  Image,
  Box,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  getAllNotifications,
  getUnReadNotifications,
  markAllNotificationsAsRead,
  deletePatientProfile,
} from "./../../store/actions";

import { formatMe2 } from "./../../global/ConverDate";

import isAuthenticated from "../../utils/isAuthenticated";
import { patientLogot } from "../../store/actions";
import { LinksAndImages } from "../Notifications/helperImageAndLinkes";
import { style } from "../../global/style";
import DrawerComponent from "./Drawer";
import { checkDate } from "../Notifications/Notifications";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
export const navList = [
  {
    text: "Medical Records",
    linkTo: "/medical-record/conditions",
    classNames: "fw-600 p-3 m-1",
  },
  {
    text: "Consultations",
    linkTo: "/consultations",
    classNames: "fw-600 p-3 m-1",
  },
  {
    text: "My Readings",
    linkTo: "/my-reading",
    classNames: "fw-600 p-3 m-1",
  },
];
const NavBar = ({
  getAllNotifications,
  notificationsList,
  patientLogot,
  patientProfile,
  getUnReadNotifications,
  unRead,
  markAllNotificationsAsRead,
  deletePatientProfile,
  deletedPatient,
}) => {
  let history = useHistory();
  let query = useQuery().get("page");
  const [patient, setPatient] = useState();
  const [unReadNoti, setUnReadNoti] = useState();
  const [NotificationsList, setNotificationsList] = useState([]);
  const [menuOpen, setMenuOpen] = useState();
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const deleteAccount = () => {
    deletePatientProfile(history, toast);
  };
  useEffect(() => {
    const token = localStorage.token;
    if (token) getUnReadNotifications();
  }, [getUnReadNotifications]);
  useEffect(() => {
    const token = localStorage.token;
    if (token && patientProfile && !query) {
      getAllNotifications();
    }
  }, [query, patientProfile, getAllNotifications]);

  useEffect(() => {
    if (patientProfile) {
      setPatient(patientProfile);
    }
  }, [patientProfile]);

  useEffect(() => {
    setUnReadNoti(unRead);
    setNotificationsList(notificationsList.data);
  }, [unRead, notificationsList]);

  useEffect(() => {
    if (menuOpen)
      markAllNotificationsAsRead(
        getAllNotifications,
        null,
        null,
        getUnReadNotifications
      );
  }, [menuOpen, markAllNotificationsAsRead, getUnReadNotifications]);

  const navLinkes = () => {
    return (
      <>
        <div className="d-none d-lg-flex col-sm-10 col-12 row align-items-center justify-content-between m-0">
          <ul className="col-8 navbar-nav flex-row m-auto">
            <li className="nav-item ">
              <Text
                transition="0.2s ease-in-out"
                as={NavLink}
                exact
                to={"/"}
                activeClassName={
                  colorMode === "dark"
                    ? "header-links-active-dark"
                    : "header-links-active"
                }
                className={"fw-600 p-3 m-1"}
                paddingBottom="32px !important"
                _hover={{
                  color: colorMode === "dark" ? "secondary" : "primary",
                  borderBottom: "1.2px solid",
                  paddingBottom: "32px !important",
                }}
              >
                {" "}
                Home
              </Text>
            </li>
            {navList.map((link, index) => (
              <li className="nav-item " key={index}>
                <Text
                  transition="0.2s ease-in-out"
                  as={NavLink}
                  to={link.linkTo}
                  activeClassName={
                    colorMode === "dark"
                      ? "header-links-active-dark"
                      : "header-links-active"
                  }
                  className={link.classNames}
                  paddingBottom="32px !important"
                  _hover={{
                    color: colorMode === "dark" ? "secondary" : "primary",
                    borderBottom: "1.2px solid",
                    paddingBottom: "32px !important",
                  }}
                >
                  {link.text}
                </Text>
              </li>
            ))}
          </ul>
          <div className="col-4">
            <Menu>
              {({ isOpen }) => {
                if (isOpen) setMenuOpen(isOpen);
                return (
                  <>
                    <MenuButton
                      className="me-2"
                      as={Button}
                      variant="ghost"
                      color={"gray.500"}
                    >
                      <Box className="btnNotification">
                        <i className="far fa-bell"></i>
                        {unReadNoti &&
                        unReadNoti.data &&
                        unReadNoti.data.count > 0 ? (
                          <span className="badge">
                            <Circle
                              color={"#fff"}
                              size={"17px"}
                              fontSize={"10px"}
                              bg={style.error}
                            >
                              {unReadNoti.data.count}
                            </Circle>
                          </span>
                        ) : null}
                      </Box>
                    </MenuButton>

                    <MenuList
                      className="menu-notification  shadow rounded row m-0"
                      maxH="275"
                      maxW="275"
                      style={{ overflowY: "scroll" }}
                    >
                      {notificationsList?.loading ? (
                        <Box className="text-center mt-2" fontWeight="semibold">
                          Loading ....
                        </Box>
                      ) : (
                        <>
                          {NotificationsList &&
                          NotificationsList.data &&
                          NotificationsList.data.length ? (
                            NotificationsList.data.map((notic, index) => (
                              <span key={index} className="p-0">
                                <MenuItem
                                  className="col-12"
                                  pointerEvents={
                                    notic.readAt && checkDate(notic.createdAt)
                                      ? "none"
                                      : "auto"
                                  }
                                  opacity={
                                    notic.readAt && checkDate(notic.createdAt)
                                      ? ".6"
                                      : "1"
                                  }
                                >
                                  <Link
                                    to={LinksAndImages(notic).link}
                                    className={`nav-link p-0 w-100 ${
                                      colorMode === "dark"
                                        ? "text-white"
                                        : "text-dark"
                                    }  d-flex align-items-center`}
                                  >
                                    <Circle
                                      size="35px"
                                      bg={
                                        colorMode === "dark"
                                          ? "secondary"
                                          : "primary"
                                      }
                                      color="#fff"
                                      className="me-2"
                                    >
                                      {LinksAndImages(notic).img ? (
                                        <Image
                                          maxW={"80%"}
                                          maxH="70%"
                                          className="image-fluid"
                                          src={LinksAndImages(notic).img}
                                          alt={
                                            notic.data.type
                                              ? notic.data.type
                                              : "no"
                                          }
                                        />
                                      ) : (
                                        <i
                                          className={
                                            LinksAndImages(notic).className
                                          }
                                        ></i>
                                      )}
                                    </Circle>
                                    <div>
                                      <Text fontSize="sm" fontWeight="semibold">
                                        {notic.data.body}
                                      </Text>
                                      <Text
                                        color={style.smallTextGray}
                                        fontSize="11px"
                                      >
                                        {formatMe2(
                                          new Date(notic.updatedAt * 1000)
                                        )}
                                      </Text>
                                    </div>
                                  </Link>
                                </MenuItem>
                                <div className="col-12 px-3 py-1">
                                  <Divider color={style.smallTextGrayLight} />
                                </div>
                              </span>
                            ))
                          ) : (
                            <Box
                              className="text-center mt-2"
                              fontWeight="semibold"
                            >
                              No notification yet
                            </Box>
                          )}
                        </>
                      )}
                    </MenuList>
                  </>
                );
              }}
            </Menu>

            <Menu>
              <MenuButton as={Button} variant="ghost">
                <div className="d-flex align-items-center flex-wrap">
                  <div className="me-1">
                    <Avatar
                      size="sm"
                      name={patient ? patient.fullName : null}
                      src={patient ? patient.picture : null}
                      bg={colorMode === "dark" ? "secondary" : "primary"}
                      color="#FFF"
                    />
                  </div>
                  <div className="ms-1 bg-d">
                    <Text
                      fontSize={{
                        base: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "16px",
                      }}
                    >
                      {patient ? `${patient.firstName} ` : ""}
                    </Text>
                    <Text
                      fontSize="13px"
                      color="gray.400"
                      className="text-start userType"
                    >
                      {localStorage.getItem("userType")
                        ? localStorage.getItem("userType")
                        : ""}
                    </Text>
                  </div>
                </div>
              </MenuButton>

              <MenuList className="menu-notification p-1 shadow">
                <MenuItem className="d-flex flex-wrap" maxW="250px">
                  <div className="w-100">
                    <Avatar
                      size="md"
                      name={patient ? patient.fullName : null}
                      src={patient ? patient.picture : null}
                      bg={colorMode === "dark" ? "secondary" : "primary"}
                      color="#FFF"
                    />
                  </div>
                  <Text
                    fontWeight="semibold"
                    color={colorMode === "dark" ? "secondary" : "primary"}
                    className="w-100 my-3"
                  >
                    {patient ? patient.fullName : ""}
                  </Text>
                  <Button
                    as={Link}
                    to="/profile/share_data"
                    className="nav-link text-white w-100 rounded"
                    bg={colorMode === "dark" ? "secondary" : "primary"}
                    _hover={{
                      bg:
                        colorMode === "dark"
                          ? "secondary_hover"
                          : "primary_hover",
                    }}
                  >
                    <span className="me-4">
                      <i className="fa fa-share"></i>
                    </span>
                    <Text fontSize="18px" className="me-1">
                      Share Data
                    </Text>
                  </Button>
                </MenuItem>

                <MenuItem
                  as={Link}
                  to="/profile/my_info"
                  className="nav-link p-0 w-100  d-flex justify-content-between p-3"
                >
                  <div className="d-flex">
                    <Text className="me-3" color="#979797">
                      <i className="fa fa-user"></i>
                    </Text>
                    <Text fontSize="15px" className="me-1" color="#979797">
                      My Info
                    </Text>
                  </div>
                  <Text color="#979797">
                    <i className="fas fa-chevron-right"></i>
                  </Text>
                </MenuItem>

                <MenuItem
                  as={Link}
                  to="/profile/payment"
                  className="nav-link p-0 w-100  d-flex justify-content-between p-3"
                >
                  <div className="d-flex">
                    <Text className="me-3" color="#979797">
                      <i className="fa fa-credit-card"></i>
                    </Text>
                    <Text fontSize="15px" className="me-1" color="#979797">
                      Payment
                    </Text>
                  </div>
                  <Text color="#979797">
                    <i className="fas fa-chevron-right"></i>
                  </Text>
                </MenuItem>

                <MenuItem
                  as={Link}
                  to="/profile/change_password"
                  className="nav-link w-100  d-flex justify-content-between p-3"
                >
                  <div className="d-flex">
                    <Text className="me-3" color="#979797">
                      <i className="fa fa-unlock-alt"></i>
                    </Text>
                    <Text fontSize="15px" className="me-1" color="#979797">
                      Change Password
                    </Text>
                  </div>
                  <Text color="#979797">
                    <i className="fas fa-chevron-right"></i>
                  </Text>
                </MenuItem>

                <MenuItem
                  as={Link}
                  to="/login"
                  className="nav-link w-100  d-flex justify-content-between p-3"
                  onClick={() => {
                    patientLogot(history);
                  }}
                >
                  <div className="d-flex">
                    <Text className="me-3" color="#979797">
                      <i className="fas fa-sign-out-alt"></i>{" "}
                    </Text>
                    <Text fontSize="15px" className="me-1" color="#979797">
                      Logout
                    </Text>
                  </div>
                  <Text color="#979797">
                    <i className="fas fa-chevron-right"></i>
                  </Text>
                </MenuItem>

                <AlertComponent
                  deleteAccount={true}
                  nameForAlert="account"
                  removeOne={deleteAccount}
                />
              </MenuList>
            </Menu>
            <Button
              maxW="20px"
              onClick={() => toggleColorMode()}
              className="ms-2"
            >
              {colorMode === "dark" ? (
                <span>
                  <i className="fas fa-moon"></i>
                </span>
              ) : (
                <span>
                  <i className="fas fa-moon"></i>
                </span>
              )}
            </Button>
          </div>
        </div>

        <div className="d-xl-none d-lg-none d-xl-flex col-5 p-0 text-end me-2 ">
          <Menu>
            {({ isOpen }) => {
              if (isOpen) setMenuOpen(isOpen);

              return (
                <>
                  <MenuButton
                    className="me-2"
                    as={Button}
                    variant="ghost"
                    color={"gray.500"}
                  >
                    <Box className="btnNotification">
                      <i className="far fa-bell"></i>
                      {unReadNoti &&
                      unReadNoti.data &&
                      unReadNoti.data.count > 0 ? (
                        <span className="badge">
                          <Circle
                            color={"#fff"}
                            size={"17px"}
                            fontSize={"10px"}
                            bg={style.error}
                          >
                            {unReadNoti.data.count}
                          </Circle>
                        </span>
                      ) : null}
                    </Box>
                  </MenuButton>

                  <MenuList
                    className="menu-notification  shadow rounded row m-0"
                    maxH="275"
                    maxW="275"
                    style={{ overflowY: "scroll" }}
                  >
                    {notificationsList?.loading ? (
                      <Box className="text-center mt-2" fontWeight="semibold">
                        Loading ....
                      </Box>
                    ) : (
                      <>
                        {NotificationsList && NotificationsList.data ? (
                          NotificationsList.data.map((notic, index) => (
                            <span key={index} className="p-0">
                              <MenuItem
                                className="col-12"
                                // pointerEvents={
                                //   notic.readAt && checkDate(notic.createdAt)
                                //     ? "none"
                                //     : "auto"
                                // }
                                // opacity={
                                //   notic.readAt && checkDate(notic.createdAt)
                                //     ? ".6"
                                //     : "1"
                                // }
                              >
                                <Link
                                  to={LinksAndImages(notic).link}
                                  className={`${
                                    colorMode === "dark"
                                      ? "text-white"
                                      : "text-dark"
                                  } nav-link p-0 w-100 text-dark d-flex align-items-center`}
                                >
                                  <Circle
                                    size="35px"
                                    bg={
                                      colorMode === "dark"
                                        ? "secondary"
                                        : "primary"
                                    }
                                    color="#fff"
                                    className="me-2"
                                  >
                                    {LinksAndImages(notic).img ? (
                                      <Image
                                        maxW={"80%"}
                                        maxH="70%"
                                        className="image-fluid"
                                        src={LinksAndImages(notic).img}
                                        alt={
                                          notic.data.type
                                            ? notic.data.type
                                            : "no"
                                        }
                                      />
                                    ) : (
                                      <i
                                        className={
                                          LinksAndImages(notic).className
                                        }
                                      ></i>
                                    )}
                                  </Circle>
                                  <div>
                                    <Text fontSize="sm" fontWeight="semibold">
                                      {notic.data.body}
                                    </Text>
                                    <Text
                                      color={style.smallTextGray}
                                      fontSize="11px"
                                    >
                                      {formatMe2(
                                        new Date(notic.updatedAt * 1000)
                                      )}
                                    </Text>
                                  </div>
                                </Link>
                              </MenuItem>
                              <div className="col-12 px-3 py-1">
                                <Divider color={style.smallTextGrayLight} />
                              </div>
                            </span>
                          ))
                        ) : (
                          <Box
                            className="text-center mt-2"
                            fontWeight="semibold"
                          >
                            No notification yet
                          </Box>
                        )}
                      </>
                    )}
                  </MenuList>
                </>
              );
            }}
          </Menu>
          <DrawerComponent />
        </div>
      </>
    );
  };

  const renderAuthButtons = () => {
    // <i className="fas fa-sun"></i>
    // <i className="fas fa-moon"></i>
    if (!isAuthenticated()) {
      return (
        <Button maxW="20px" onClick={() => toggleColorMode()}>
          {colorMode === "dark" ? (
            <span>
              <i className="fas fa-moon"></i>
            </span>
          ) : (
            <span>
              <i className="fas fa-moon"></i>
            </span>
          )}
        </Button>
      );
    } else {
      return navLinkes();
    }
  };
  return (
    <Box className="container-fluid NavBar shadow p-0">
      <Box
        bg={colorMode === "dark" ? "darkBg" : "white"}
        className="row  justify-content-between align-items-center p-2 m-0"
      >
        <div className="col-lg-2 col-6 me-auto">
          <NavLink to="/" className="">
            <img src={colorMode === "dark" ? darkLogo : logo} alt="logo" />
          </NavLink>
        </div>
        <>{renderAuthButtons()}</>
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => {
  return {
    notificationsList: state.notificationsList,
    logout: state.logout,
    patientProfile: state.PatientProfile.data,
    unRead: state.unRead,
    deletedPatient: state.deletePatient,
  };
};
export default connect(mapStateToProps, {
  getAllNotifications,
  patientLogot,
  getUnReadNotifications,
  markAllNotificationsAsRead,
  deletePatientProfile,
})(NavBar);
