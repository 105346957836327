import axios from "axios";

import { handeleSuccess, handeleError } from "../toast";

const API_URL = process.env.REACT_APP_API_URL;

export const getPatientRadiology = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_RADIOLOGY_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/radiology?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/radiology/`,
      });
      dispatch({
        type: "GET_RADIOLOGY_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_RADIOLOGY_ERROR",
        payload: err,
      });
    }
  };
};

export const getPatientRadiologyForpagination = (
  pageNumber,
  patientId,
  informationShareToken
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_RADIOLOGY_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/radiology?informationShareToken=${informationShareToken}&page=${pageNumber}`
            : `${API_URL}/patient/radiology?page=${pageNumber}`,
      });
      dispatch({
        type: "GET_RADIOLOGY_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_RADIOLOGY_ERROR",
        payload: err.response.data,
      });
    }
  };
};

export const getOneRadiology = (id, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_RADIOLOGY_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/radiology/${id}`,
      });
      dispatch({
        type: "GET_ONE_RADIOLOGY_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      handeleError(err, toast, null, " MRI/CT scan");
      history.goBack();
      dispatch({
        type: "GET_ONE_RADIOLOGY_ERROR",
        payload: err,
      });
    }
  };
};

export const postPatientRadiology = (
  data,
  toast,
  history,
  setChildData,
  getAllNotifications,
  getUnReadNotifications
) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_RADIOLOGY_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/radiology`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your MRI/CT scan has been added successfully", toast);
        localStorage.setItem("radiologyItem", JSON.stringify(data));
        getAllNotifications();
        getUnReadNotifications();
        history
          ? history.push(`?readiologyID=${res.data.id}`)
          : setChildData(res.data);
        dispatch({
          type: "POST_RADIOLOGY_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error);

      dispatch({
        type: "POST_RADIOLOGY_ERROR",
        payload: error.response,
      });
    }
  };
};

export const updateRadiology = (data, id, toast, history, firstTime) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_RADIOLOGY_LOADING",
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/patient/radiology/${id}`,

        data: data,
      });
      if (res) {
        if (!firstTime) {
          history.goBack();
          handeleSuccess(
            "Your MRI/CT scan has been Updated successfully",
            toast
          );
        } else {
          localStorage.removeItem("radiologyItem");
          history.push("/medical-record/mri-ct scan");
        }

        dispatch({
          type: "UPDATE_RADIOLOGY_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_RADIOLOGY_ERROR",
        payload: error.response,
      });
    }
  };
};

export const deleteRadiology = (id, toast, history, goTo) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_RADIOLOGY_LOADING",
    });
    try {
      const res = await axios({
        method: "DELETE",
        url: `${API_URL}/patient/radiology/${id}`,
      });
      if (res) {
        handeleSuccess("Your MRI/CT Scan deleted successfully", toast);
        history.push(goTo);
        dispatch({
          type: "DELETE_RADIOLOGY_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);

      dispatch({
        type: "DELETE_RADIOLOGY_ERROR",
        payload: error.response,
      });
    }
  };
};

// export const resetState = () => {
//   return async (dispatch) => {
//     dispatch({
//       type: "RESET_STATE",
//     });
//   };
// };
