import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  Input,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Avatar,
  Circle,
  useColorMode,
} from "@chakra-ui/react";
import { Link, useRouteMatch } from "react-router-dom";

import chest from "../../assets/chest.png";
import FirstviewConsaltation from "./firstView";
import { motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../global/Animations/Animations";
import { checkBgColorMode, checkColorMode } from "../../global/changeColor";
import moment from "moment";
import { GetSpecialityIcon } from "../../global/GetSpecialityIcon";
export const check_status_string = (status, colorMode) => {
  switch (status) {
    case "Awaiting to be assigned":
      return { string: "Pending for test", className: "pending" };
    case "Done":
      return {
        string: "Done",
        className: `done ${colorMode === "dark" ? "done-dark" : ""}`,
      };

    default:
      return {
        string: status,
        className: "pending",
      };
  }
};
export const filterString = (str) => {
  if (str && str.includes("_")) {
    return str.replace(/_/g, " ");
  }
  if (str && str.includes("-")) {
    return str.replace(/-/g, " ");
  }
  return str;
};
const Consultationchild = ({ consultationList, consultationTypes }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [consultation, setConsultation] = useState([]);
  const [filterConsultation, setFilterConsultation] = useState([]);
  const [ConsultationType, setConsultationType] = useState([]);
  const { colorMode } = useColorMode();
  let { url } = useRouteMatch();

  useEffect(() => {
    if (consultationTypes && consultationTypes.data) {
      setConsultationType(consultationTypes.data);
    }
  }, [consultationTypes]);

  useEffect(() => {
    const { loading, error, data } = consultationList;
    setLoading(loading);
    setError(error);

    if (data && data.data) {
      const list = data.data;
      // const notDone = list.filter((item) => item.status !== "done");
      setConsultation(list);
      setFilterConsultation(list);
    }
  }, [consultationList]);
  const pending = () => {
    const filterData = consultation.filter(
      (element) => element.consultantId == null && element.status !== "done"
    );
    if (filterData.length) {
      setFilterConsultation(filterData);
    } else {
      setFilterConsultation([
        { noMatch: "There are no pending consultations." },
      ]);
    }
  };
  const assigned = () => {
    const filterData = consultation.filter(
      (element) => element.consultantId !== null && element.status !== "done"
    );
    if (filterData.length) {
      setFilterConsultation(filterData);
    } else {
      setFilterConsultation([
        { noMatch: "There are no assigned consultations." },
      ]);
    }
  };
  const done = () => {
    const filterData = consultation.filter(
      (element) => element.status === "done"
    );
    if (filterData.length) {
      setFilterConsultation(filterData);
    } else {
      setFilterConsultation([
        { noMatch: "There are no completed consultations." },
      ]);
    }
  };
  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return " ";
    }
    if (filterConsultation.length) {
      return (
        <motion.div
          className="container my-5 mt-3"
          initial="out"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          <div className="row">
            <div className="h4 py-3 fw-bold d-flex flex-wrap align-items-center justify-content-between">
              <Text
                fontSize="2xl"
                className="my-4"
                fontWeight="700"
                color={checkColorMode(colorMode)}
              >
                Consultations
              </Text>

              <div className="d-flex flex-wrap align-items-center">
                <div className="me-3 mb-2 input-search">
                  <Input
                    w="270px"
                    placeholder="Search"
                    size="lg"
                    className="ps-5"
                    onChange={(e) => {
                      let term = e.target.value;
                      if (term.trim().length > 0) {
                        const filterD = consultation.filter((one) =>
                          one.symptoms
                            .toLowerCase()
                            .includes(term.trim().toLowerCase())
                        );
                        if (filterD.length) {
                          setFilterConsultation(filterD);
                        } else {
                          setFilterConsultation([
                            { noMatch: "There are no matching consultations." },
                          ]);
                        }
                      } else {
                        setFilterConsultation(consultation);
                      }
                    }}
                  />
                  <Text
                    color={checkColorMode(colorMode)}
                    className="icon-search"
                  >
                    <i className="fas fa-search"></i>
                  </Text>
                </div>

                <div className=" mb-2">
                  <Menu>
                    <MenuButton
                      as={Button}
                      color={checkColorMode(colorMode)}
                      bg={
                        colorMode === "dark"
                          ? "secondaryOpacity"
                          : "primaryOpacity"
                      }
                      size="lg"
                      _hover={{
                        bg:
                          colorMode === "dark"
                            ? "secondary_hover_Opacity"
                            : "primaryOpacity_hover",
                      }}
                      _active={{
                        bg:
                          colorMode === "dark"
                            ? "secondary_hover_Opacity"
                            : "primaryOpacity_hover",
                      }}
                    >
                      <span className="me-2">
                        <i className="fas fa-filter"></i>
                      </span>{" "}
                      Filter
                    </MenuButton>
                    <MenuList className="shadow p-2 " minW={170}>
                      <MenuItem
                        p={2}
                        fontSize="17px"
                        className="text-center rounded"
                        color={checkColorMode(colorMode)}
                        _hover={{
                          backgroundColor:
                            colorMode === "dark"
                              ? "secondary_hover_Opacity"
                              : "primaryOpacity_hover",
                        }}
                        onClick={() => pending()}
                      >
                        <Text fontWeight="semibold" w={"100%"}>
                          Pending for test
                        </Text>
                      </MenuItem>
                      <MenuItem
                        p={2}
                        fontSize="17px"
                        className="text-center rounded"
                        color={checkColorMode(colorMode)}
                        _hover={{
                          backgroundColor:
                            colorMode === "dark"
                              ? "secondary_hover_Opacity"
                              : "primaryOpacity_hover",
                        }}
                        onClick={() => assigned()}
                      >
                        <Text fontWeight="semibold" w={"100%"}>
                          {" "}
                          Assigned
                        </Text>
                      </MenuItem>
                      <MenuItem
                        p={2}
                        fontSize="17px"
                        className="text-center rounded"
                        color={checkColorMode(colorMode)}
                        _hover={{
                          backgroundColor:
                            colorMode === "dark"
                              ? "secondary_hover_Opacity"
                              : "primaryOpacity_hover",
                        }}
                        onClick={() => done()}
                      >
                        <Text fontWeight="semibold" w={"100%"}>
                          {" "}
                          Done
                        </Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            </div>
            {filterConsultation.map((item, index) => (
              <Box key={index}>
                {!item.noMatch ? (
                  <Box
                    as={Link}
                    to={`${url}/over-view?consultationId=${item.id}`}
                    className="m-0 mb-2  shadow-sm row flex-wrap flex-row align-items-center p-3 pe-0 text-dark rounded "
                    bg={checkBgColorMode(colorMode)}
                  >
                    <div className="col-lg-4 mb-2">
                      <Text
                        fontSize="lg"
                        fontWeight={700}
                        color={checkColorMode(colorMode)}
                      >
                        {item.symptoms}
                      </Text>
                      <Text color="gray.400" fontSize="12px">
                        {moment
                          .unix(item.createdAt)
                          .format("DD/MM/YYYY - hh:mm A")}
                      </Text>
                    </div>

                    <div className="col-lg-3 d-flex  align-items-center mb-2 me-auto">
                      <div className=" me-2">
                        <Circle size="40px" bg={checkColorMode(colorMode)}>
                          {item.consultationTypeId ? (
                            <img
                              className="image-fluid"
                              src={GetSpecialityIcon(
                                Number(item.consultationTypeId)
                              )}
                              alt="chest"
                            />
                          ) : (
                            ""
                          )}
                        </Circle>
                        {/* <Box
                         width="35px"
                         height="35px"
                         className="rounded-circle d-flex justify-content-center align-items-center p-1"
                         color="#fff"
                         bg={style.primary}
                       >
                         
                       </Box> */}
                      </div>

                      <Box className="p-0">
                        <Text color="gray.400" fontSize="15px" fontWeight="600">
                          Speciality
                        </Text>
                        <Text
                          fontSize="13px"
                          fontWeight="700"
                          color={checkColorMode(colorMode)}
                        >
                          {ConsultationType.length
                            ? ConsultationType.find(
                                (element) =>
                                  element.id === item.consultationTypeId
                              ).name
                            : ""}
                        </Text>
                      </Box>
                    </div>
                    {item.consultant.name ? (
                      <div className="col-lg-3 d-flex align-items-center mb-2 me-auto">
                        <div className="me-2">
                          <Avatar
                            bg={checkColorMode(colorMode)}
                            size="md"
                            src={item.consultant.picture}
                            name={item.consultant.name}
                            color="#FFF"
                          />
                        </div>
                        <Box className="p-1">
                          <Text
                            color="gray.400"
                            fontSize="15px"
                            fontWeight="600"
                          >
                            Doctor
                          </Text>
                          <Text
                            fontSize="13px"
                            fontWeight="700"
                            color={checkColorMode(colorMode)}
                          >
                            {item.consultant.name}
                          </Text>
                        </Box>
                      </div>
                    ) : null}

                    <div className="col-lg-2 ">
                      <Box
                        textTransform="capitalize"
                        className={`${
                          check_status_string(item.statusString, colorMode)
                            .className
                        } text-center rounded`}
                      >
                        {filterString(item.statusString) ===
                        "Appointment passed"
                          ? "Appointment finished"
                          : filterString(item.statusString)}
                      </Box>
                    </div>
                  </Box>
                ) : (
                  <Box
                    className="text-center"
                    fontWeight="semibold"
                    fontSize="xl"
                  >
                    {item.noMatch}
                  </Box>
                )}
              </Box>
            ))}
          </div>
        </motion.div>
      );
    } else {
      return <FirstviewConsaltation />;
    }
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    consultationList: state.consultationsList,
    consultationTypes: state.consultationTypes,
  };
};
export default connect(mapStateToProps)(Consultationchild);
