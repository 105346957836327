import React, { useEffect, useRef } from "react";

import { Box, Button, Text, useColorMode } from "@chakra-ui/react";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { checkExt } from "../../../MidicalRecord/Repeated-Component/View";
// import PDFFile from "./PDFFile";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  checkBgColorMode,
  checkColorMode,
  checkButtonColor,
} from "../../../../global/changeColor";
import moment from "moment";
const MedicalReport = ({ medicalReport, consultation, patient }) => {
  const ref = useRef();
  const reportRef = useRef();
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (reportRef && medicalReport) {
      reportRef.current.innerHTML = "";
      reportRef.current.insertAdjacentHTML("beforeend", medicalReport.note);
    }
  }, [reportRef, medicalReport]);
  const renderView = () => {
    if (medicalReport) {
      return (
        <div className="my-3">
          <Text
            fontSize="2xl"
            fontWeight="semibold"
            color={checkColorMode(colorMode)}
            className="my-2"
          >
            Medical Report
          </Text>
          <Box
            bg={checkBgColorMode(colorMode)}
            className="col-md-12 p-3 rounded row m-0 my-3 align-items-center"
          >
            <Box className="row m-0 align-items-center">
              <div className="col-md-6">
                <Text fontSize="xl" color={"secondary"}>
                  {medicalReport.title}
                </Text>
                <Text fontSize="xs" color={"smallTextGray"}>
                  {moment
                    .unix(medicalReport.createdAt)
                    .format("DD/MM/YYYY - hh:mm A")}
                </Text>
              </div>
              <div className="col-md-6 row m-0 justify-content-end">
                <Button
                  // as={PDFDownloadLink}
                  // document={
                  //   <PDFFile
                  //     consultation={consultation}
                  //     patient={patient}
                  //     medicalReport={medicalReport}
                  //   />
                  // }
                  // fileName="Medical Report"
                  className="rounded m-2"
                  bg={checkButtonColor(colorMode)}
                  color={checkColorMode(colorMode)}
                  fontSize="14px"
                  minW="140px"
                >
                  <a
                    href={medicalReport.generatedFile}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: colorMode === "dark" ? "#30B8BA" : "#FFF",
                    }}
                  >
                    <span className="me-3">
                      <i className="fas fa-download"></i>
                    </span>{" "}
                    Download
                  </a>
                </Button>
                <ReactToPrint content={() => ref.current}>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        className="rounded m-2"
                        bg={"secondary"}
                        color="#fff"
                        fontSize="14px"
                        _hover={{ bg: "secondary_hover" }}
                        minW="140px"
                        onClick={handlePrint}
                      >
                        <span className="me-4">
                          <i className="fas fa-print"></i>
                        </span>{" "}
                        Print
                      </Button>
                    )}
                  </PrintContextConsumer>
                </ReactToPrint>
              </div>
            </Box>

            <Box className="row m-0 align-items-center" ref={ref}>
              <Box>
                <Box
                  bg={"smallTextGrayLight"}
                  border={`1px solid `}
                  borderColor="smallTextGrayLightBorder"
                  boxShadow="xs"
                  className="col-12 rounded p-3 mt-3"
                  ref={reportRef}
                ></Box>
                <Box className="p-y mt-3">{checkExt(medicalReport)}</Box>
              </Box>
            </Box>
          </Box>
        </div>
      );
    } else {
      return "nothing to show";
    }
  };
  return <>{renderView()}</>;
};

export default MedicalReport;
