import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import { InputControl } from "formik-chakra-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, Button, useColorMode, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import isAuthenticated from "../../utils/isAuthenticated";
import { style } from "../../global/style";
import { getHospitalInviteRequest } from "../../store/actions";
import axios from "axios";
import { checkBgColorMode } from "../../global/changeColor";
const API_URL = process.env.REACT_APP_API_URL;

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .min(3, "must be at least 3 characters")
    .max(10, "can't be more then 10 characters")
    .required("Required"),
  lastName: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .max(10, "can't be more then 10 characters")
    .min(3)
    .required("Required"),
  middleName: Yup.string()
    .matches(
      /^'?\p{L}+(?:[' ]\p{L}+)*'?$/u,
      "Only alphabetic characters allowed"
    )
    .max(10, "can't be more then 10 characters")
    .min(3, "must be at least 3 characters"),
});
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const SignUp = ({ history, hospitalInviteRequest }) => {
  const [disabledInputs, setDisabledInputs] = useState(false);
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [counter, setCounter] = useState(1);
  const [initValue, setInitValue] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
  });
  let query = useQuery().get("step");
  const { colorMode } = useColorMode();
  const hospitalInvitation = useQuery().get("hospitalInvitation");
  useEffect(() => {
    if (query) {
      setCounter(query);
    }
    if (hospitalInvitation) {
      getHospitalInviteRequest(hospitalInvitation, true);
      axios({
        method: "GET",
        url: `${API_URL}/hospital/invites/${hospitalInvitation}`,
      }).then(({ data }) => {
        setDisabledInputs(true);
        setEmail(data.email);
        const names = data.name?.split(" ");
        if (names.length === 1) {
          setInitValue({
            firstName: names[0],
          });
        } else if (names.length === 2) {
          setInitValue({
            firstName: names[0],
            lastName: names[1],
          });
        } else if (names.length === 3) {
          setInitValue({
            firstName: names[0],
            middleName: names[1],
            lastName: names[2],
          });
        }
      });
    } else {
      setInitValue({
        firstName: "",
        middleName: "",
        lastName: "",
      });
    }
  }, [query, hospitalInvitation]);

  useEffect(() => {
    const prevValues = JSON.parse(localStorage.getItem("step1"));
    if (prevValues) {
      setInitValue(prevValues);
    }
  }, [query]);

  useEffect(() => {
    if (isAuthenticated()) {
      toast({
        title: "You are alredy logged in",
        status: "success",
        duration: 7000,
        isClosable: true,
      });
      history.push("/");
    }
  }, [toast, history]);

  const onFormSubmit = (value) => {
    localStorage.setItem(
      "step1",
      JSON.stringify(
        hospitalInvitation
          ? { ...value, hospital_patient_invitation_id: hospitalInvitation }
          : value
      )
    );
    history.push(
      hospitalInvitation
        ? `/signup?hospitalInvitation=${hospitalInvitation}&step=2&`
        : `/signup?step=2`
    );
  };
  // console.log("render hospitalInviteRequest", hospitalInviteRequest, initValue);
  return (
    <div className="SignUp mt-5">
      <div className="row justify-content-center m-0">
        <div className="col-lg-5 col-md-7 col-11">
          <div className="row mb-2">
            <p className="fw-bold w-50 ">New Account</p>

            {counter < 4 ? (
              <p className="fw-bold w-50 text-end">{`${counter}/3`}</p>
            ) : (
              ""
            )}
          </div>

          <Box
            bg={checkBgColorMode(colorMode)}
            className="rounded shadow-sm login-row"
          >
            {Number(counter) === 1 ? (
              <Formik
                initialValues={initValue}
                validationSchema={SignUpSchema}
                enableReinitialize
                onSubmit={onFormSubmit}
              >
                <div>
                  <Form>
                    <div className="first-step">
                      <div className="input-group mt-2">
                        <InputControl
                          name="firstName"
                          label="First Name"
                          isDisabled={disabledInputs}
                          inputProps={{ placeholder: "First Name" }}
                        />
                      </div>
                      <div className="input-group mt-2 ">
                        <InputControl
                          name="middleName"
                          label="Middle Name"
                          isDisabled={disabledInputs && initValue.middleName}
                          inputProps={{ placeholder: "Middle Name" }}
                        />
                      </div>
                      <div className="input-group mt-2">
                        <InputControl
                          name="lastName"
                          label="Last Name"
                          isDisabled={disabledInputs && initValue.lastName}
                          inputProps={{ placeholder: "Last Name" }}
                        />
                      </div>
                    </div>
                    <div className="buttonSubmit">
                      <Button
                        type="submit"
                        className="w-100 mt-3 fw-bold"
                        size="lg"
                        bg={style.primary}
                        color="#fff"
                        _hover={{ bg: style.primary_hover }}
                      >
                        Next
                      </Button>
                    </div>
                  </Form>
                </div>
              </Formik>
            ) : null}

            {Number(counter) === 2 ? <Step2 history={history} /> : null}
            {Number(counter) === 3 ? (
              <Step3 email={email} history={history} />
            ) : null}
          </Box>
          <div className="text-center  p-5 login-row">
            <Button
              as={Link}
              to="/login"
              color="#fff"
              size="lg"
              bg={style.secondary}
              _hover={{ bg: style.secondary_hover }}
              className="w-100 nav-link text-white"
            >
              Have Account? Login
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
