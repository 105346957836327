import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import AddEcg from "./AddEcg";
import ViewEcg from "./ViewEcg";
import EcgChild from "./EcgChild";
const ECG = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={EcgChild} />
        <Route exact path={`${path}/add-ecg`} component={AddEcg} />
        <Route path={`${path}/add-ecg/:Eid`} component={AddEcg} />
        <Route path={`${path}/view-ecg/:id`} component={ViewEcg} />
      </Switch>
    </>
  );
};
export default ECG;
