import axios from "axios";
import { handeleSuccess, handeleError } from "../toast";
import { types } from "../../types/types";
const { conditionTypes } = types;
const API_URL = process.env.REACT_APP_API_URL;
export const getPatientCondition = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_CONDITION_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/conditions?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/conditions/`,
      });
      dispatch({
        type: "GET_CONDITION_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_CONDITION_ERROR",
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};

export const getPatientConditionForPagination = (
  pageNumber,
  patientId,
  informationShareToken
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_CONDITION_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/conditions?informationShareToken=${informationShareToken}&page=${pageNumber}`
            : `${API_URL}/patient/conditions?page=${pageNumber}`,
      });
      dispatch({
        type: "GET_CONDITION_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_CONDITION_ERROR",
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};

export const getOneCondition = (id, toast, history, patientId, sharedToken) => {
  return async (dispatch) => {
    dispatch({
      type: conditionTypes.GET_ONE_CONDITION_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && sharedToken
            ? `${API_URL}/patient/${patientId}/conditions/show/${id}?informationShareToken=${sharedToken}`
            : `${API_URL}/patient/conditions/${id}`,
      });
      console.log(res);

      dispatch({
        type: conditionTypes.GET_ONE_CONDITION_SUCSES,
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast, null, "condition");
      history.push("/medical-record/conditions");
      dispatch({
        type: conditionTypes.GET_ONE_CONDITION_ERROR,
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};

export const postPatientCondition = (
  data,
  history,
  toast,
  setChildData,
  getAllNotifications,
  getUnReadNotifications
) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_CONDITION_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/conditions`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your condition has been added successfully", toast);
        getAllNotifications();
        getUnReadNotifications();
        history ? history.goBack() : setChildData(res.data);

        dispatch({
          type: "POST_CONDITION_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "POST_CONDITION_ERROR",
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};

export const updatePatientCondition = (data, id, history, toast) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_CONDITION_LOADING",
    });
    try {
      const res = await axios({
        method: "patch",
        url: `${API_URL}/patient/conditions/${id}`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your condition has been updated successfully", toast);
        history.goBack();
        dispatch({
          type: "UPDATE_CONDITION_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_CONDITION_ERROR",
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};
export const removeCondition = (id, toast, history, goTo) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_CONDITION_LOADING",
    });
    try {
      const res = await axios({
        method: "delete",
        url: `${API_URL}/patient/conditions/${id}`,
      });
      if (res) {
        handeleSuccess("Your condition deleted successfully", toast);
        history.replace(goTo);
        dispatch({
          type: "DELETE_CONDITION_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "DELETE_CONDITION_ERROR",
        payload:
          error.response && error.response.data
            ? error.response.data
            : "SomeThing Wrong",
      });
    }
  };
};
