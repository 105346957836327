import { Text, useColorMode } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getPatientRreadingList } from "../../store/actions";
import Readingcard from "./ReadingCard";
import bloodPressureimg from "../../assets/bloodPressure.png";
import bloodSugarimg from "../../assets/bloodSugar.png";
import heightimg from "../../assets/height.png";
import temperatureimg from "../../assets/temperature.png";
import weightimg from "../../assets/weight.png";
import { motion } from "framer-motion";
import {
  pageTransition,
  pageVariants,
} from "../../global/Animations/Animations";
import { useLocation, useParams } from "react-router-dom";
import { checkColorMode } from "../../global/changeColor.js";
const MyReading = ({ getPatientRreadingList, readdingList }) => {
  const location = useLocation();
  const { patientId } = useParams();
  const { colorMode } = useColorMode();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const [readingList, setReaddingList] = useState([]);
  const [bloodPressure, setBloodPressure] = useState();
  const [height, setHeight] = useState();
  const [weight, setWeight] = useState();
  const [heartRate, setHeartRate] = useState();
  const [bloodSugar, setBloodSugar] = useState();
  const [temperature, setTemperature] = useState();
  useEffect(() => {
    getPatientRreadingList(patientId, sharedToken);
  }, [getPatientRreadingList, patientId, sharedToken]);

  useEffect(() => {
    if (readdingList && readdingList.data) {
      setReaddingList(readdingList.data);
    }
  }, [readdingList]);
  useEffect(() => {
    if (readingList.length > 0) {
      setBloodPressure(
        readingList.find((obj) => {
          return obj.type === "blood-pressure";
        })
      );
      setHeight(
        readingList.find((obj) => {
          return obj.type === "height";
        })
      );
      setWeight(
        readingList.find((obj) => {
          return obj.type === "weight";
        })
      );
      setHeartRate(
        readingList.find((obj) => {
          return obj.type === "heart-rate";
        })
      );
      setBloodSugar(
        readingList.find((obj) => {
          return obj.type === "blood-sugar";
        })
      );
      setTemperature(
        readingList.find((obj) => {
          return obj.type === "temperature";
        })
      );
    }
  }, [readingList]);
  return (
    <motion.div
      className="container my-5"
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className="row">
        <Text
          fontSize="3xl"
          fontWeight="semibold"
          color={checkColorMode(colorMode)}
          marginBottom={sharedToken ? "30px" : ""}
        >
          {sharedToken ? "Patient Readings" : "My Readings"}
        </Text>
        <Readingcard
          item={bloodPressure}
          image={bloodPressureimg}
          icon={``}
          name="Blood Pressure"
          feildName="blood-pressure"
        />
        <Readingcard
          item={height}
          image={heightimg}
          icon={``}
          name="Height"
          feildName="height"
        />
        <Readingcard
          item={weight}
          image={weightimg}
          icon={``}
          name="Weight"
          feildName="weight"
        />
        <Readingcard
          item={heartRate}
          icon={`fas fa-heartbeat`}
          name="Heart Rate"
          feildName="heart-rate"
        />
        <Readingcard
          item={bloodSugar}
          image={bloodSugarimg}
          icon={``}
          name="Blood Sugar"
          feildName="blood-sugar"
        />
        <Readingcard
          item={temperature}
          image={temperatureimg}
          icon={``}
          name="Temperature"
          feildName="temperature"
        />
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    readdingList: state.readdingList,
  };
};

export default connect(mapStateToProps, {
  getPatientRreadingList,
})(MyReading);
