import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getOneAllergies, deleteAllergies } from "./../../../store/actions";
import { useLocation, useParams } from "react-router-dom";
import View from "../Repeated-Component/View";
import { useToast } from "@chakra-ui/react";
const ViewAllergies = ({
  getOneAllergies,
  oneAllergies,
  deleteAllergies,
  history,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [allergy, setAllergy] = useState(null);
  let params = useParams();
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const toast = useToast();
  const remove = () => {
    deleteAllergies(params.id, toast, history, "/medical-record/allergies");
  };
  useEffect(() => {
    if (params && params.id && params.patientId && sharedToken) {
      getOneAllergies(params.id, toast, history, params.patientId, sharedToken);
    } else if (params && params.id && !params.patientId && !sharedToken) {
      getOneAllergies(params.id, toast, history);
    }
  }, [getOneAllergies, toast, history, params.id, sharedToken]);

  useEffect(() => {
    const { loading, error, data } = oneAllergies;
    setLoading(loading);
    setError(error);

    if (data) {
      setAllergy(data);
    }
  }, [oneAllergies]);
  const renderView = () => {
    return (
      <View
        loading={loading}
        error={error}
        headerText="View Allergies"
        linkEdite="/medical-record/allergies/add-allergies"
        one={allergy}
        params={params}
        removeOne={remove}
        backLink={
          sharedToken
            ? `/allergies/${params.patientId}/shared-data?sharedToken=${sharedToken}`
            : "/medical-record/allergies"
        }
        nameForAlert="Allergy"
      />
    );
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    oneAllergies: state.oneAllergies,
  };
};
export default connect(mapStateToProps, { getOneAllergies, deleteAllergies })(
  ViewAllergies
);
