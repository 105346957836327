import React, { useEffect, useState } from "react";
import { useRouteMatch, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPatientXray,
  getPatientXrayForPagination,
} from "./../../../store/actions";
import FirstView from "../Repeated-Component/FirstView";
import Pagination from "../../Pagination";
import Xray from "./../../../assets/Xray.png";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const XrayChild = ({
  getPatientXray,
  xrayList,
  getPatientXrayForPagination,
  history,
}) => {
  const { url } = useRouteMatch();
  const location = useLocation();
  const params = useParams();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [xray, setxray] = useState([]);
  const [filterXray, setFilterXray] = useState([]);
  const [currentPage, setCuttentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pagesNumber = Math.ceil(totalResults / 15);
  let query = useQuery().get("page");

  const nextPage = () => {
    const next = currentPage + 1;
    history.push(`${url}?page=${next}`);
  };

  const prevPage = () => {
    const prev = currentPage - 1;
    history.push(`${url}?page=${prev}`);
  };

  const specificPage = (pageNum) => {
    history.push(`${url}?page=${pageNum}`);
  };

  useEffect(() => {
    if (!query) getPatientXray(params.patientId, sharedToken);
  }, [getPatientXray, query, params, sharedToken]);

  useEffect(() => {
    if (query)
      getPatientXrayForPagination(query, params.patientId, sharedToken);
  }, [getPatientXrayForPagination, query, params, sharedToken]);

  useEffect(() => {
    const { loading, error, data } = xrayList;
    setLoading(loading);
    setError(error);
    if (data && data.data) {
      const list = data.data;
      setxray(list);
      setFilterXray(list);
      setTotalResults(data.meta.total);
      setCuttentPage(data.meta.current_page);
    }
  }, [xrayList]);

  const renderView = () => {
    return (
      <>
        <FirstView
          headerName="X Rays"
          buttonLink={`${url}/add-xray`}
          cardLink={`${url}/view-xray`}
          data={xray}
          filterData={filterXray}
          setFilter={setFilterXray}
          loading={loading}
          error={error}
          imageForEmptyView={Xray}
          secondTitle="X Ray"
        />
        {totalResults > 15 && filterXray.length > 10 ? (
          <Pagination
            pages={pagesNumber}
            nextPage={nextPage}
            prevPage={prevPage}
            specificPage={specificPage}
            currentPage={currentPage}
          />
        ) : (
          ""
        )}
      </>
    );
  };
  return renderView();
};
const mapStateToprops = (state) => {
  return {
    xrayList: state.XrayList,
  };
};
export default connect(mapStateToprops, {
  getPatientXray,
  getPatientXrayForPagination,
})(XrayChild);
