import axios from "axios";

import { types } from "../../types/types";
import { handeleError, handeleSuccess } from "../toast";
const { consultationsTypes } = types;
const API_URL = process.env.REACT_APP_API_URL;

export const getPatientconsultations = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_CONSULTATION_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/consultation-request`,
      });
      dispatch({
        type: "GET_CONSULTATION_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_CONSULTATION_ERROR",
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

export const getPatientOneConsultation = (id, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_CONSULTATION_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/consultation-request/single/${id}`,
      });
      // if (res && res.data.status === "done") {
      //   if (history) history.push("/consultations");
      //   toast({
      //     title: "You have closed this consultation before",
      //     status: "error",
      //     duration: 4000,
      //     isClosable: true,
      //     position: "top-right",
      //   });
      // } else {
      //   dispatch({
      //     type: "GET_ONE_CONSULTATION_SUCSES",
      //     payload: res.data,
      //   });
      // }
      dispatch({
        type: "GET_ONE_CONSULTATION_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      handeleError(err, toast);
      history.push("/consultations");
      dispatch({
        type: "GET_ONE_CONSULTATION_ERROR",
        payload: err,
      });
    }
  };
};

export const getPatientPrePaymentData = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_PAYMENT_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/consultation-request/pre-payment`,
      });
      dispatch({
        type: "GET_PAYMENT_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_PAYMENT_ERROR",
        payload: err,
      });
    }
  };
};

export const getPatientScanRequests = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_REQUESTS_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/consultation-request/scan-requests/${id}`,
      });
      dispatch({
        type: "GET_REQUESTS_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_REQUESTS_ERROR",
        payload: err,
      });
    }
  };
};

export const postPatientconsultation = (
  data,
  history,
  toast,
  getAllNotifications,
  getUnReadNotifications,
  getPatientconsultations
) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_CONSULTATION_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/consultation-request`,

        data: data,
      });
      if (res) {
        localStorage.removeItem("consultationRequest");
        localStorage.removeItem("draftConsultation");
        handeleSuccess("Your consultation has been added successfully", toast);
        getPatientconsultations();
        getAllNotifications();
        getUnReadNotifications();
        history.push("/consultations");
        dispatch({
          type: "POST_CONSULTATION_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "POST_CONSULTATION_ERROR",
        payload: err,
      });
    }
  };
};

export const closePatientconsultation = (id, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: "CLOSE_CONSULTATION_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/consultation-request/${id}/close`,
      });
      if (res) {
        handeleSuccess("Your consultation closed successfully", toast);
        history.push("/");
      }
      dispatch({
        type: "CLOSE_CONSULTATION_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "CLOSE_CONSULTATION_ERROR",
        payload: err,
      });
    }
  };
};

export const getPatientQuestions = (id, pageNum = 1) => {
  return async (dispatch) => {
    dispatch({
      type: consultationsTypes.GET_QUESTIONS_LOADING,
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/consultation-request/${id}/question?page=${pageNum}`,
      });
      dispatch({
        type: consultationsTypes.GET_QUESTIONS_SUCSES,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: consultationsTypes.GET_QUESTIONS_ERROR,
        payload: err,
      });
    }
  };
};
export const replyPatientQuestion = (
  data,
  consultationId,
  questionId,
  history,
  toast
) => {
  return async (dispatch) => {
    dispatch({
      type: "REPLY_QUESTION_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/consultation-request/${consultationId}/question/reply/${questionId}`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your answer sent successfully", toast);
        history.goBack();
        dispatch({
          type: "REPLY_QUESTION_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "REPLY_QUESTION_ERROR",
        payload: err,
      });
    }
  };
};

export const getPatientOneQuestions = (
  consultationId,
  questionId,
  toast,
  history
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_QUESTIONS_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/consultation-request/${consultationId}/question/show/${questionId}`,
      });
      dispatch({
        type: "GET_ONE_QUESTIONS_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      handeleError(err, toast);
      history.goBack();
      dispatch({
        type: "GET_ONE_QUESTIONS_ERROR",
        payload: err,
      });
    }
  };
};

export const getConsultantAppointment = (consultationId) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_CONSULTANT_APPOINTMENTS_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/consultation-request/${consultationId}/consultant-schedule`,
        data: "",
      });

      dispatch({
        type: "GET_CONSULTANT_APPOINTMENTS_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_CONSULTANT_APPOINTMENTS_ERROR",
        payload: error.response ? error.response.data : error,
      });
    }
  };
};

////Get empty consultant appointments in date

export const getEmptyConsultantAppointmnt = (consultantId, day) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_EMPTY_CONSULTANT_APPOINTMENTS_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/consultant/${consultantId}/empty-appointments?day=${day}`,
        data: "",
      });
      dispatch({
        type: "GET_EMPTY_CONSULTANT_APPOINTMENTS_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_EMPTY_CONSULTANT_APPOINTMENTS_ERROR",
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
