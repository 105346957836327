import axios from "axios";
import { handeleSuccess, handeleError } from "../toast";

const API_URL = process.env.REACT_APP_API_URL;

export const getPatientMedical = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_MEDICAL_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/medical-reports?informationShareToken=${informationShareToken}`
            : `${API_URL}/patient/medical-reports`,
      });
      dispatch({
        type: "GET_MEDICAL_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_MEDICAL_ERROR",
        payload: error,
      });
    }
  };
};
export const getPatientMedicalForpagination = (
  pageNumber,
  patientId,
  informationShareToken
) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_MEDICAL_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && informationShareToken
            ? `${API_URL}/patient/${patientId}/medical-reports?informationShareToken=${informationShareToken}&page=${pageNumber}`
            : `${API_URL}/patient/medical-reports?page=${pageNumber}`,
      });
      dispatch({
        type: "GET_MEDICAL_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: "GET_MEDICAL_ERROR",
        payload: err,
      });
    }
  };
};

export const postPatientMedical = (
  data,
  history,
  toast,
  setChildData,
  getAllNotifications,
  getUnReadNotifications
) => {
  let formData = new FormData();
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file, file.name));
  }
  formData.append("title", data.title);
  return async (dispatch) => {
    dispatch({
      type: "POST_MEDICAL_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/medical-reports`,

        data: formData,
      });
      if (res) {
        handeleSuccess(
          "Your medical report has been added successfully",
          toast
        );
        getAllNotifications();
        getUnReadNotifications();
        history ? history.goBack() : setChildData(res.data);
        dispatch({
          type: "POST_MEDICAL_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "POST_MEDICAL_ERROR",
        payload: error.response,
      });
    }
  };
};

export const updateMedical = (data, id, history, toast) => {
  let formData = new FormData();
  formData.append("note", data.note);
  if (data.files) {
    data.files.map((file) => formData.append("file", file));
  }
  formData.append("title", data.title);
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_MEDICAL_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/medical-reports/${id}`,

        data: formData,
      });
      if (res) {
        handeleSuccess(
          "Your Medical Report has been Updated successfully",
          toast
        );
        history.goBack();
        dispatch({
          type: "UPDATE_MEDICAL_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_MEDICAL_ERROR",
        payload: error,
      });
    }
  };
};

export const getOneMedical = (id, toast, history, patientId, sharedToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ONE_MEDICAL_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url:
          patientId && sharedToken
            ? `${API_URL}/patient/${patientId}/medical-reports/show/${id}?informationShareToken=${sharedToken}`
            : `${API_URL}/patient/medical-reports/show/${id}`,
      });
      dispatch({
        type: "GET_ONE_MEDICAL_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast, null, "medical report");
      history.push("/medical-record/medical-report");
      dispatch({
        type: "GET_ONE_MEDICAL_ERROR",
        payload: error,
      });
    }
  };
};

export const deleteMedical = (id, toast, history, goTo) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_MEDICAL_LOADING",
    });
    try {
      const res = await axios({
        method: "delete",
        url: `${API_URL}/patient/medical-reports/${id}`,
      });
      if (res) {
        handeleSuccess(
          "Your medical report has been deleted successfully",
          toast
        );
        history.push(goTo);
        dispatch({
          type: "DELETE_MEDICAL_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "DELETE_MEDICAL_ERROR",
        payload: error,
      });
    }
  };
};
