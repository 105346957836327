import axios from "axios";
import { handeleError, handeleSuccess } from "../toast";
const authConfige = {
  "Content-Type": "application/json",
  "X-Api-Key": "du7lik773nspohl0",
};
const API_URL = process.env.REACT_APP_API_URL;
export const logIn = (email, password, history, toast, cameFrom) => {
  return async (dispatch) => {
    dispatch({
      type: "LOGIN_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/login`,
        headers: authConfige,
        data: {
          email: email,
          password: password,
        },
      });
      if (res) {
        if (res.data.mobileVerifiedAt) {
          const draft = localStorage.draftConsultation;
          localStorage.clear();
          if (draft) {
            localStorage.setItem("draftConsultation", draft);
          }
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("patientId", res.data.id);
          localStorage.setItem("userType", "patient");
          handeleSuccess("You’ve successfully logged in", toast);
          if (cameFrom) {
            history.push(cameFrom);
          } else {
            history.push("/");
          }
          dispatch({
            type: "LOGIN_SUCSES",
            payload: res.data,
          });
        } else {
          localStorage.setItem("registrUser", JSON.stringify(res.data));
          localStorage.setItem("registrToken", res.data.token);
          localStorage.setItem("userMobile", res.data.mobile);
          history.push("/verify_mobile?from=profile");
          dispatch({
            type: "LOGIN_SUCSES",
            payload: res.data,
          });
        }
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "LOGIN_ERROR",
        payload: err,
      });
    }
  };
};
export const patientLogot = (history) => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT_LOADING",
    });
    try {
      localStorage.removeItem("patientId");
      localStorage.removeItem("userType");
      localStorage.removeItem("token");
      history.push("/login");
      dispatch({
        type: "LOGOUT_SUCSES",
        payload: "You are logged out sucsessfuly",
      });
    } catch (err) {
      dispatch({
        type: "LOGOUT_ERORR",
        payload: "Something Went Wrong",
      });
    }
  };
};
export const forgetPassword = (email, setCounter, toast) => {
  return async (dispatch) => {
    dispatch({
      type: "FORGET_PASSWORD_LOADING",
    });
    try {
      let res = await axios({
        method: "POST",
        url: `${API_URL}/patient/forgot-password`,
        headers: authConfige,
        data: {
          email: email,
        },
      });
      if (res) {
        handeleSuccess("Code sent successfully", toast);
        setCounter(2);
        dispatch({
          type: "FORGET_PASSWORD_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "FORGET_PASSWORD_ERROR",
        payload: err,
      });
    }
  };
};
//https://staging.pat-rec.com/patient/password/reset
export const resetPassword = (newPassword, histort, toast) => {
  return async (dispatch) => {
    dispatch({
      type: "REST_PASSWORD_LOADING",
    });
    try {
      let res = await axios({
        method: "POST",
        url: `${API_URL}/patient/reset`,
        headers: authConfige,
        data: {
          newPassword: newPassword,
        },
      });
      if (res) {
        handeleSuccess("Your Password Updated successfully", toast);
        histort.push("/login");
        dispatch({
          type: "REST_PASSWORD_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);

      dispatch({
        type: "REST_PASSWORD_ERROR",
        payload: err,
      });
    }
  };
};

export const signupUser = (data, history, toast) => {
  return async (dispatch) => {
    dispatch({
      type: "SIGNUP_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient`,
        headers: authConfige,
        data: data,
      });
      if (res) {
        localStorage.setItem("registrUser", JSON.stringify(data));
        localStorage.setItem("registrToken", res.data.token);
        toast({
          title: "Your account created successfully",
          status: "success",
          duration: 7000,
          isClosable: true,
          position: "top-right",
        });
        history.push(`/verify_mobile`);
        dispatch({
          type: "SIGNUP_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      if (err.response.data.message) {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 7000,
          isClosable: true,
          position: "top-right",
        });
      }
      if (err.response.data.errors) {
        let error = err.response.data.errors;
        Object.entries(error).map(([key, value]) => {
          return toast({
            title: value,
            status: "error",
            duration: 7000,
            isClosable: true,
            position: "top-right",
          });
        });
      }
      dispatch({
        type: "SIGNUP_ERROR",
        payload: err,
      });
    }
  };
};
