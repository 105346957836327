import React from "react";

import Card from "../card";
import HeaderSection from "./headerSection";
import { motion } from "framer-motion";
import {
  pageVariants,
  pageTransition,
} from "../../../global/Animations/Animations";
import { Box } from "@chakra-ui/react";
import EmptyMedical from "./EmptyMedical";

const FirstView = ({
  loading,
  error,
  headerName,
  data,
  buttonLink,
  cardLink,
  filterData,
  setFilter,
  removeRadiology,
  nameForAlert,
  isRadiology,
  imageForEmptyView,
  secondTitle,
}) => {
  const onInputChange = (e) => {
    let term = e.target.value;
    if (data && filterData) {
      if (term.trim().length > 0) {
        const filterD = data.filter((one) =>
          one.title
            ? one.title.toLowerCase().includes(term.trim().toLowerCase())
            : one.value.toLowerCase().includes(term.trim().toLowerCase())
        );
        if (filterD.length) {
          setFilter(filterD);
        } else {
          setFilter([{ noMatch: "There are no matching records." }]);
        }
      } else {
        setFilter(data);
      }
    } else {
      return null;
    }
  };

  const renderView = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return " ";
    }
    if (filterData.length) {
      return (
        <motion.div>
          {filterData.map((item, i) => (
            <Box key={i}>
              {!item.noMatch ? (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  transition={{ duration: 0.6, delay: i * 0.1 }}
                >
                  <Card item={item} url={`${cardLink}`} />
                </motion.div>
              ) : (
                <Box
                  className="text-center"
                  fontWeight="semibold"
                  fontSize="xl"
                >
                  {item.noMatch}
                </Box>
              )}
            </Box>
          ))}
        </motion.div>
      );
    } else {
      return (
        <EmptyMedical
          imgeURL={imageForEmptyView}
          title={headerName}
          secondTitle={secondTitle}
          buttonPath={buttonLink}
        />
      );
    }
  };
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <HeaderSection
        headerName={headerName}
        onInputChange={onInputChange}
        buttonLink={buttonLink}
        inputAndButton={filterData && filterData.length ? true : false}
      />
      {renderView()}
    </motion.div>
  );
};

export default FirstView;
