import React, { useState } from "react";

import * as Yup from "yup";

import Mri from "./../../../assets/MRI.png";

import { connect } from "react-redux";

import {
  postPatientRadiology,
  getAllNotifications,
  getUnReadNotifications,
} from "../../../store/actions";

import { Formik, Form, ErrorMessage } from "formik";

import {
  Button,
  Text,
  useToast,
  Textarea,
  Box,
  useColorMode,
} from "@chakra-ui/react";

import { InputControl } from "formik-chakra-ui";

import Uploader from "./uploader";

import { useLocation, Link } from "react-router-dom";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";
const SignInSchema = Yup.object().shape({
  title: Yup.string().min(3).required("Required"),
});

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const AddRadiology = ({
  postPatientRadiology,
  history,
  setChildData,
  addedReadiolgy,
  getAllNotifications,
  getUnReadNotifications,
}) => {
  const { colorMode } = useColorMode();
  ///////
  const toast = useToast();
  /////
  let readiologyID = useQuery().get("readiologyID");
  ///
  const [initValue] = useState({
    note: "",
    title: "",
    procedure_date: null,
  });
  //////
  const onFormSubmit = (value) => {
    postPatientRadiology(
      value,
      toast,
      history,
      setChildData,
      getAllNotifications,
      getUnReadNotifications
    );
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 d-flex py-4 align-items-center">
            {history && !setChildData ? (
              <Button
                onClick={() => history.goBack()}
                className="h5 me-4 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: "45px", height: "45px" }}
                color={checkColorMode(colorMode)}
              >
                <i className="fas fa-chevron-left"></i>
              </Button>
            ) : null}

            <Text className="h4 fw-bold" color={checkColorMode(colorMode)}>
              Add MRI / CT Scan
            </Text>
          </div>

          <div className="">
            <div className="form row justify-content-center">
              {readiologyID ? (
                <Box
                  bg={checkBgColorMode(colorMode)}
                  className="col-12 p-5 rounded shadow row justify-content-center  flex-wrap"
                >
                  <Text
                    className="col-md-10 col-12 fw-bold my-2"
                    color="smallTextGray"
                    fontSize="sm"
                  >
                    Add More Files
                  </Text>
                  <Uploader id={readiologyID} />
                  <div className="text-center mt-5">
                    <Button
                      onClick={() => {
                        if (setChildData) {
                          return setChildData(initValue);
                        }
                      }}
                      as={Link}
                      to={setChildData ? "#" : `/medical-record/mri-ct scan`}
                      colorScheme="red"
                      variant="outline"
                      size="lg"
                      fontWeight="semibold"
                      h="56px"
                      _hover={{ color: "red" }}
                      width="150px"
                    >
                      Done
                    </Button>
                  </div>
                </Box>
              ) : (
                <Formik
                  initialValues={initValue}
                  enableReinitialize
                  validationSchema={SignInSchema}
                  onSubmit={onFormSubmit}
                >
                  {({ values, handleBlur, handleChange }) => (
                    <Form>
                      <Box
                        bg={checkBgColorMode(colorMode)}
                        className="col-12 p-5  rounded shadow  "
                      >
                        <div className="row justify-content-center">
                          <div className="w-100 d-flex justify-content-center">
                            <img src={Mri} alt="logo" />
                          </div>

                          <div className="w-100 text-center py-5">
                            <Text
                              color={checkColorMode(colorMode)}
                              className="h3 fw-bold"
                            >
                              {" "}
                              Add MRI / CT Scan
                            </Text>
                            <Text color="smallTextGray" className="fw-200">
                              Add your MRI / CT Scan
                            </Text>
                          </div>
                        </div>

                        <div className="w-100 row m-0 justify-content-center">
                          <div className="col-md-10 col-12">
                            <div className=" mt-2 ">
                              <InputControl
                                type={"text"}
                                name={"title"}
                                className="rounded"
                                inputProps={{
                                  placeholder:
                                    "Briefly tell us about your MRI/CT Scan",
                                }}
                              />
                            </div>
                            <div className="">
                              <Textarea
                                className="mt-3 rounded"
                                value={values.note}
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleBlur(e)}
                                name={"note"}
                                placeholder={
                                  "Describe in as much detail as you can."
                                }
                                size="sm"
                              />
                              <ErrorMessage
                                component="div"
                                name={"note"}
                                style={{ color: "#d42c51", fontSize: "14px" }}
                                className="mt-1"
                              />
                            </div>
                          </div>
                        </div>
                      </Box>

                      <div className="col-12 m-0 mt-3  row justify-content-between">
                        <div className="col-md-3 my-2">
                          <Button
                            onClick={() => {
                              if (setChildData) {
                                return setChildData(initValue);
                              }
                            }}
                            as={Link}
                            to={
                              setChildData ? "#" : `/medical-record/mri-ct scan`
                            }
                            colorScheme="red"
                            variant="outline"
                            w="100%"
                            size="lg"
                            fontWeight="semibold"
                            h="56px"
                            _hover={{ color: "red" }}
                          >
                            Cancel
                          </Button>
                        </div>
                        <div className="col-md-3 my-2">
                          <Button
                            type="submit"
                            className="rounded"
                            fontWeight="semibold"
                            size="lg"
                            isLoading={addedReadiolgy.loading}
                            bg={checkColorMode(colorMode)}
                            color="#fff"
                            _hover={{ bg: checkColorModeHover(colorMode) }}
                            w="100%"
                            h="56px"
                          >
                            {/* Save{" "}
                            <span className="ms-4">
                              <i className="fas fa-save"></i>
                            </span> */}
                            Next
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    addedReadiolgy: state.AddRadiology,
  };
};
export default connect(mapStateToprops, {
  postPatientRadiology,
  getAllNotifications,
  getUnReadNotifications,
})(AddRadiology);
