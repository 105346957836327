import "./MidicalRecord.scss";
import {
  NavLink,
  useRouteMatch,
  Switch,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import Conditions from "./conditions/Conditions";
import MidicalReport from "./midical-report/MidicalReport";
import LapResult from "./lab-result/LapResult";
import Allergies from "./Allergies/Allergies";
import Radiology from "./radiology/Radiology";
import Xray from "./Xray/Xray";

import ECG from "./ecg/ECG";

import allergy from "../../assets/medical-record/allergy.png";
import condition from "../../assets/medical-record/condition.png";
import ctScan from "../../assets/medical-record/ctScan.png";
import Egc from "../../assets/medical-record/Egc.png";
import labResult from "../../assets/medical-record/lab-result.png";
import medicalReport from "../../assets/medical-record/medical-report.png";
import XRay from "../../assets/medical-record/Xray.png";
import { AnimatePresence, motion } from "framer-motion";
import { Circle, Image, Box, useColorMode, Text } from "@chakra-ui/react";
import { style } from "../../global/style";
import {
  pageTransition,
  pageVariants,
} from "../../global/Animations/Animations";
import NotFound from "../NotFound/NotFound";
import { checkBgColorMode, checkColorMode } from "../../global/changeColor";
const navigation = [
  {
    navImg: condition,
    navName: "Conditions",
    linkTo: "conditions",
  },
  {
    navImg: medicalReport,
    navName: "Medical Reports",
    linkTo: "medical-report",
  },
  {
    navImg: labResult,
    navName: "Lab Results",
    linkTo: "lab-result",
  },
  {
    navImg: allergy,
    navName: "Allergies",
    linkTo: "allergies",
  },
  {
    navImg: ctScan,
    navName: "MRI / CT Scan",
    linkTo: "mri-ct scan",
  },
  {
    navImg: Egc,
    navName: "ECG",
    linkTo: "ecg",
  },
  {
    navImg: XRay,
    navName: "X Ray",
    linkTo: "x-ray",
  },
];

const MidicalRecord = () => {
  const { colorMode } = useColorMode();
  const { url, path } = useRouteMatch();
  const { patientId } = useParams();
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const updatedNavigation = navigation.map((navItem) => ({
    ...navItem,
    linkTo: sharedToken
      ? `/${navItem.linkTo}/${patientId}/shared-data?sharedToken=${sharedToken}`
      : `${url}/${navItem.linkTo}`,
  }));
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      className="mb-5"
    >
      <Box
        bg={checkBgColorMode(colorMode)}
        className="MidicalRecord container-fluid  pt-3 shadow"
      >
        <div className="container">
          <div className="row">
            <Text
              className="h4 fw-bold col-12"
              color={checkColorMode(colorMode)}
            >
              Medical Records
            </Text>
            <div className="col-12 ps-0 d-none d-lg-flex">
              <ul className="navbar-nav flex-row mt-4">
                {updatedNavigation.map((item, index) => (
                  <li className="nav-item " key={index}>
                    <NavLink
                      activeClassName={
                        colorMode === "dark"
                          ? "midical-active-dark"
                          : "midical-active"
                      }
                      to={item.linkTo}
                      className={`fw-600 py-3 px-1 mx-2 midical-nav d-flex ${
                        colorMode === "dark" ? "midical-nav-dark" : ""
                      }`}
                    >
                      <Circle
                        size="30px"
                        color="#fff"
                        className="me-2 circle"
                        bg={style.smallTextGray}
                        transition="0.2s ease-in-out"
                      >
                        {item.navImg ? (
                          <Image
                            src={item.navImg}
                            alt={item.navName}
                            maxW="50%"
                          />
                        ) : (
                          <i className={item.navIcon}></i>
                        )}
                      </Circle>
                      {item.navName}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="d-xl-none d-lg-none d-xl-flex d-flex flex-wrap medical-small-wrap">
              {updatedNavigation.map((item, index) => (
                <Box w="200px" className="text-start my-1" key={index}>
                  <NavLink
                    activeClassName={
                      colorMode === "dark"
                        ? "midical-active-small-dark"
                        : "midical-active-small"
                    }
                    to={item.linkTo}
                    className={`rounded fw-600 p-2 mx-2 midical-nav-small d-flex align-items-center ${
                      colorMode === "dark" ? "midical-nav-small-dark" : ""
                    }`}
                  >
                    <Circle
                      size="30px"
                      color="#fff"
                      className="me-2 circle"
                      bg={style.smallTextGray}
                      transition="0.2s ease-in-out"
                    >
                      {item.navImg ? (
                        <Image
                          src={item.navImg}
                          alt={item.navName}
                          maxW="50%"
                        />
                      ) : (
                        <i className={item.navIcon}></i>
                      )}
                    </Circle>
                    {item.navName}
                  </NavLink>
                </Box>
              ))}
            </div>
          </div>
        </div>
      </Box>
      {sharedToken ? (
        <div className="container">
          <div className="row ps-0">
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route
                  path={"/conditions/:patientId/shared-data"}
                  component={Conditions}
                />
                <Route
                  path={"/medical-report/:patientId/shared-data"}
                  component={MidicalReport}
                />
                <Route
                  path={"/lab-result/:patientId/shared-data"}
                  component={LapResult}
                />
                <Route
                  path={"/allergies/:patientId/shared-data"}
                  component={Allergies}
                />
                <Route
                  path={"/mri-ct scan/:patientId/shared-data"}
                  component={Radiology}
                />
                <Route path={"/ecg/:patientId/shared-data"} component={ECG} />
                <Route
                  path={"/x-ray/:patientId/shared-data"}
                  component={Xray}
                />
                <Route path="*" exact={true} component={Conditions} />
              </Switch>
            </AnimatePresence>
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row ps-0">
            <AnimatePresence exitBeforeEnter>
              <Switch location={location} key={location.pathname}>
                <Route path={`${path}/conditions`} component={Conditions} />
                <Route
                  path={`${path}/medical-report`}
                  component={MidicalReport}
                />
                <Route path={`${path}/lab-result`} component={LapResult} />
                <Route path={`${path}/allergies`} component={Allergies} />
                <Route path={`${path}/mri-ct scan`} component={Radiology} />
                <Route path={`${path}/ecg`} component={ECG} />
                <Route path={`${path}/x-ray`} component={Xray} />
                <Route path="*" exact={true} component={NotFound} />
              </Switch>
            </AnimatePresence>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default MidicalRecord;
