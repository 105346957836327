import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import ECG from "./../../../assets/ECG.png";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateEcg,
  getOneEcg,
  postPatientEcg,
  getAllNotifications,
  getUnReadNotifications,
} from "../../../store/actions";
import Add from "../Repeated-Component/Add";

import { useToast } from "@chakra-ui/react";

const SignInSchema = Yup.object().shape({
  title: Yup.string().min(3).required("Required"),
  files: Yup.mixed().required("File is required"),
});
const AddEcg = ({
  oneECG,
  history,
  updateEcg,
  getOneEcg,
  postPatientEcg,
  setChildData,
  getAllNotifications,
  getUnReadNotifications,
}) => {
  let params = useParams();
  //
  const toast = useToast();
  //
  const [initValue, setInitValue] = useState({
    note: "",
    title: "",
    files: null,
  });
  const [File, setFile] = useState({});

  useEffect(() => {
    if (params.Eid) {
      getOneEcg(params.Eid);
    }
  }, [params, getOneEcg]);

  useEffect(() => {
    if (params.Eid && oneECG) {
      setInitValue({
        title: oneECG.title,
        note: oneECG.note,
        files: [],
      });
      setFile({
        name: oneECG.fileName,
      });
    }
  }, [oneECG, params]);

  const onFormSubmit = (value) => {
    if (params.Eid) {
      return updateEcg(value, params.Eid, history, toast);
    } else
      postPatientEcg(
        value,
        history,
        toast,
        setChildData,
        getAllNotifications,
        getUnReadNotifications
      );
  };
  const seeFiles = (file) => {
    if (file) {
      setFile(file[0]);
    }
  };

  return (
    <>
      <Add
        Schema={SignInSchema}
        initValue={initValue}
        history={history}
        onFormSubmit={onFormSubmit}
        headerText={`${params.Eid ? "Edit" : "Add"} ECG`}
        secondHeaderText="ECG"
        therdHeaderText="Add your ECG"
        image={ECG}
        feildsNames={{
          feild1: {
            name: "title",
            type: "text",
            placeholder: "Briefly describe your ECG",
          },
          feild2: {
            name: "note",
            type: "",
            placeholder: "Describe in as much detail as you can.",
          },
          feild3: { name: "files", type: "", placeholder: "" },
        }}
        seeFiles={seeFiles}
        renderFile={File}
        setChildData={setChildData}
      />
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    oneECG: state.oneECG.data,
  };
};
export default connect(mapStateToprops, {
  updateEcg,
  getOneEcg,
  postPatientEcg,
  getAllNotifications,
  getUnReadNotifications,
})(AddEcg);
