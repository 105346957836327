const INIT_ADDRESS_STATE = {
  loading: false,
  error: null,
  data: null,
};
export const getPatientAddressReducer = (
  state = INIT_ADDRESS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "GET_ADDRESS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case "GET_ADDRESS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "GET_ADDRESS_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const postPatientAddressReducer = (
  state = INIT_ADDRESS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "POST_ADDRESS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
        data: null,
      };
    case "POST_ADDRESS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "POST_ADDRESS_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
        data: null,
      };
    default:
      return state;
  }
};
export const updatePatientAddressReducer = (
  state = INIT_ADDRESS_STATE,
  { type, payload }
) => {
  switch (type) {
    case "UPDATE_ADDRESS_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "UPDATE_ADDRESS_SUCSES":
      return {
        ...state,
        loading: false,
        error: null,
        data: payload,
      };
    case "UPDATE_ADDRESS_ERORR":
      return {
        ...state,
        loading: false,
        error: payload,
      };
    default:
      return state;
  }
};
