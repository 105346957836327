import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Add from "../Repeated-Component/Add";
import { useParams } from "react-router-dom";
import labResult from "./../../../assets/labResult.png";
import * as Yup from "yup";

import {
  postPatientLapResult,
  updateLapResult,
  getOneLapResult,
  getAllNotifications,
  getUnReadNotifications,
} from "../../../store/actions";

import { useToast } from "@chakra-ui/react";

const Schema = Yup.object().shape({
  title: Yup.string().min(3).required("Required"),
  files: Yup.mixed().required("File is required"),
});
const AddLapResult = ({
  lapResult,
  postPatientLapResult,
  updateLapResult,
  getOneLapResult,
  history,
  setChildData,
  getAllNotifications,
  getUnReadNotifications,
}) => {
  console.log(history);
  ///
  let params = useParams();
  //
  const toast = useToast();
  //
  const [initValue, setInitValue] = useState({
    note: "",
    title: "",
    files: null,
  });
  const [File, setFile] = useState({});

  useEffect(() => {
    if (params.Eid) {
      getOneLapResult(params.Eid);
    }
  }, [params, getOneLapResult]);

  useEffect(() => {
    if (params.Eid && lapResult) {
      setInitValue({
        title: lapResult.title,
        note: lapResult.note,
        files: [],
      });
      setFile({
        name: lapResult.fileName,
      });
    }
  }, [lapResult, params]);

  const onFormSubmit = (value) => {
    console.log(value);
    if (params.Eid) {
      return updateLapResult(value, params.Eid, history, toast);
    } else
      postPatientLapResult(
        value,
        history,
        toast,
        setChildData,
        getAllNotifications,
        getUnReadNotifications
      );
  };

  const seeFiles = (file) => {
    if (file) {
      setFile(file[0]);
    }
  };

  return (
    <Add
      Schema={Schema}
      initValue={initValue}
      history={history}
      onFormSubmit={onFormSubmit}
      headerText={`${params.Eid ? "Edit" : "Add"} Lab Result`}
      secondHeaderText="Lab Result"
      therdHeaderText="Add your Lab Result"
      image={labResult}
      feildsNames={{
        feild1: {
          name: "title",
          type: "text",
          placeholder: "Briefly tell us about your Lab Result",
        },
        feild2: {
          name: "note",
          type: "",
          placeholder: "Describe in as much detail as you can.",
        },
        feild3: { name: "files", type: "", placeholder: "" },
      }}
      seeFiles={seeFiles}
      renderFile={File}
      setChildData={setChildData}
    />
  );
};

const mapStateToprops = (state) => {
  return {
    lapResult: state.oneLapResult.data,
  };
};
export default connect(mapStateToprops, {
  postPatientLapResult,
  updateLapResult,
  getOneLapResult,
  getAllNotifications,
  getUnReadNotifications,
})(AddLapResult);
