import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import View from "../Repeated-Component/View";
import { useToast } from "@chakra-ui/react";
import { deleteRadiology, getOneRadiology } from "../../../store/actions";

const ViewRadiology = ({
  getOneRadiology,
  deleteRadiology,
  oneRadiology,
  deletedRadiology,
  history,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [Radiology, setRadiology] = useState(null);
  let params = useParams();
  const location = useLocation();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const toast = useToast();
  const removeRadiology = () => {
    deleteRadiology(params.id, toast, history, "/medical-record/mri-ct scan");
  };
  useEffect(() => {
    if (params && params.id && params.patientId && sharedToken) {
      getOneRadiology(params.id, toast, history, params.patientId, sharedToken);
    } else if (params && params.id && !params.patientId && !sharedToken) {
      getOneRadiology(params.id, toast, history);
    }
  }, [getOneRadiology, params.id, toast, history, sharedToken]);
  useEffect(() => {
    const { loading, error, data } = oneRadiology;
    setLoading(loading);
    setError(error);
    if (data) {
      setRadiology(data);
    }
  }, [oneRadiology]);
  const renderView = () => {
    return (
      <>
        <View
          loading={loading}
          error={error}
          headerText="View MRI / CT Scan"
          linkEdite="/medical-record/mri-ct scan/edit-radiology"
          one={Radiology}
          params={params}
          removeOne={removeRadiology}
          backLink={
            sharedToken
              ? `/mri-ct scan/${params.patientId}/shared-data?sharedToken=${sharedToken}`
              : "/medical-record/mri-ct scan"
          }
          nameForAlert="MRI / CT Scan"
          viewer={true}
        />
      </>
    );
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    oneRadiology: state.OneRadiology,
    deletedRadiology: state.deleteRadiology,
  };
};
export default connect(mapStateToProps, {
  getOneRadiology,
  deleteRadiology,
})(ViewRadiology);
