import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import MidicalRecord from "../MidicalRecord/MidicalRecord";
import MyReading from "../MyReadinga/MyReading";
import { Avatar, Box, Circle, Text, useColorMode } from "@chakra-ui/react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { convertDate } from "../../global/ConverDate";
import { style } from "../../global/style";
import { getPatientProfileShare } from "../../store/actions/patientActions";
import { checkBgColorMode } from "../../global/changeColor";
// const API_URL = process.env.REACT_APP_API_URL;

const SharedProfileData = ({ patientProfile, getPatientProfileShare }) => {
  const history = useHistory();
  const location = useLocation();
  const { patientId, medicalRecordType } = useParams();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const { colorMode } = useColorMode();
  if (!medicalRecordType) {
    history.push(
      `/conditions/${patientId}/shared-data?sharedToken=${sharedToken}`
    );
  }

  useEffect(() => {
    getPatientProfileShare(patientId, sharedToken);
  }, [getPatientProfileShare, patientId, sharedToken]);

  useEffect(() => {
    const { loading, error, data } = patientProfile;
    setError(error);
    setLoading(loading);
    if (data) {
      setUser(data);
    }
  }, [patientProfile]);

  const render = () => {
    if (loading) {
      return " ";
    }
    if (error) {
      return (
        <Box className="mt-5 text-center" fontWeight="bold" fontSize="2xl">
          There is no information to display here. Please ask the patient to
          share his information
        </Box>
      );
    }
    return (
      <>
        <div className="col-12 row px-4 align-items-center m-0">
          {user && user.informationShareToken ? (
            <Box>
              <Box
                bg={checkBgColorMode(colorMode)}
                className="col-12 d-flex flex-wrap   rounded shadow-sm p-4 mx-0 my-3"
              >
                <Text
                  fontSize="3xl"
                  fontWeight="semibold"
                  color={style.secondary}
                  className="w-100 my-3"
                >
                  Patient Info
                </Text>
                <Box className="w-100 row m-0 mt-3">
                  <Box className="col-md-6 d-flex align-items-center my-2">
                    <Avatar
                      size="lg"
                      src={user.picture}
                      name={user.fullName}
                      className="me-2"
                    />
                    <div className="">
                      <Text color={style.smallTextGray}>Patient</Text>
                      <Text fontWeight="semibold">{user.fullName}</Text>
                    </div>
                  </Box>
                  <Box className="col-md-6 d-flex flex-wrap align-items-center justify-content-between my-2">
                    <Box className="d-flex  align-items-center">
                      <Circle
                        size="44px"
                        color={"#fff"}
                        bg={"rgb(255, 177, 0)"}
                        className="me-2"
                      >
                        <i className="fas fa-thin fa-face-smile"></i>
                      </Circle>
                      <Box>
                        <Text color={style.smallTextGray}>Gender</Text>
                        <Text textTransform="capitalize" fontWeight="semibold">
                          {user.gender}
                        </Text>
                      </Box>
                    </Box>
                    <Box className="d-flex align-items-center">
                      <Circle
                        size="44px"
                        color={"#fff"}
                        bg={style.secondary}
                        className="me-2"
                      >
                        <i className="fas fa-thin fa-calendar"></i>
                      </Circle>
                      <Box>
                        <Text color={style.smallTextGray}>Date of Birth</Text>
                        <Text textTransform="capitalize" fontWeight="semibold">
                          {user.dateOfBirth
                            ? convertDate(user.dateOfBirth)
                            : ""}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* 
                
                */}
              </Box>
              <Box
                bg={checkBgColorMode(colorMode)}
                className="col-12 row  mb-4 m-2 rounded shadow-sm p-2 pb-4 mx-0"
              >
                <MyReading />
              </Box>
              <>
                <MidicalRecord />
              </>
            </Box>
          ) : (
            <Box className="mt-5 text-center" fontWeight="bold" fontSize="2xl">
              There is no information to display here. Please ask the patient to
              share his information
            </Box>
          )}
        </div>
      </>
    );
  };
  return render();
};
const mapStateToProps = (state) => {
  return {
    patientProfile: state.PatientProfileShare,
  };
};
export default connect(mapStateToProps, {
  getPatientProfileShare,
})(SharedProfileData);
