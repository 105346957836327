import React, { useState, useEffect } from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import {
  Box,
  Button,
  Text,
  Input,
  useToast,
  useColorMode,
} from "@chakra-ui/react";

import { Link, useHistory, useLocation } from "react-router-dom";
import { checkBgColorMode } from "../../../global/changeColor";
const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};
const PaymentForm = ({ clientSec }) => {
  let query = useQuery().get("redirectTo");
  const [checkoutError, setCheckoutError] = useState();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const handleCardDetailsChange = (event) => {
    event.error ? setCheckoutError(event.error.message) : setCheckoutError();
  };
  const cardElementStyle = {
    base: {
      lineHeight: "45px",
      color: colorMode === "dark" ? "#FFF" : "#000",
    },
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardNumberElement);
    const { error, ...res } = await stripe.confirmCardSetup(clientSec, {
      payment_method: {
        type: "card",
        card: cardElement,
        billing_details: {
          name: "testing",
        },
      },
    });
    if (!error) {
      try {
        if (res) {
          if (query) {
            history.push(query);
          } else {
            history.push("/profile/payment");
          }
          return toast({
            title: "Your Payment created successfully",
            status: "success",
            duration: 7000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (error) {
        if (error) {
          return toast({
            title: error?.response?.data?.message || error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    } else {
      return toast({
        title: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  useEffect(() => {
    if (checkoutError) {
      return toast({
        title: checkoutError,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  }, [checkoutError, toast]);
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      className="rounded shadow-sm p-4 m-0  row flex-wrap"
    >
      <form onSubmit={handelSubmit}>
        <div className="col-12 d-flex justify-content-between align-items-center">
          <Text color="secondary" fontSize="lg" className="fw-bold">
            {" "}
            Payment
          </Text>
          <div>
            <Button
              width={"50px"}
              height="50px"
              bg="#f3e9eb"
              _hover={{ bg: "#f3d3da" }}
              fontSize="20px"
              color="#D42C51"
              className="me-2"
            >
              <Link to={"/profile/payment"} className="nav-link text-error">
                <i className="fas fa-times"></i>
              </Link>
            </Button>
            <Button
              width={"50px"}
              height="50px"
              bg="secondary"
              _hover={{ bg: "secondary_hover" }}
              fontSize="20px"
              type="submit"
              color="#fff"
            >
              <i className="fas fa-save"></i>
            </Button>
          </div>
        </div>
        <div className="col-12 mt-4 row">
          <div className="col-md-6 col-12">
            <label>Holder Name</label>
            <Input
              name="holderName"
              className="mt-2"
              style={{ padding: "22px 10px" }}
              placeholder="Holder Name"
              color={colorMode === "dark" ? "#FFF" : "#000"}
            />
          </div>

          <div className="col-md-6 col-12">
            <label>Card Number</label>
            <Input
              as={CardNumberElement}
              options={{
                style: cardElementStyle,
              }}
              onChange={handleCardDetailsChange}
              className="mt-2"
            />
          </div>

          <div className="col-md-6 col-12">
            <label>Expiration date</label>
            <Input
              as={CardExpiryElement}
              options={{
                style: cardElementStyle,
              }}
              onChange={handleCardDetailsChange}
              className="mt-2"
            />
          </div>

          <div className="col-md-6 col-12">
            <label>CVC</label>
            <Input
              as={CardCvcElement}
              options={{
                style: cardElementStyle,
              }}
              onChange={handleCardDetailsChange}
              className="mt-2"
            />
          </div>
        </div>
      </form>
    </Box>
  );
};

export default PaymentForm;
