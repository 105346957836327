import React, { useEffect, useState } from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import MyInfo from "./My-Info/MyInfo";
import Payment from "./Payment/payment";
import ChangePassword from "./Change-Password/changePassword";
import ProfileSideNav from "./ProfileSideNav";
import "./profile.scss";
import { connect } from "react-redux";
import {
  getPatientProfile,
  getPatientAddress,
  getPatientEmergencyInfo,
} from "../../store/actions";
import EditEmargency from "./My-Info/EditEmargency";
import EditMyAddress from "./My-Info/EditMyAddress";
import EditMyInfo from "./My-Info/EditMyInfo";
import shareData from "./Shar-Data/shareData";
import PaymentConfig from "./Payment/PaymentConfig";
import { Button, Box, useColorMode, Text } from "@chakra-ui/react";
import { checkBgColorMode, checkColorMode } from "../../global/changeColor.js";
const Profile = ({
  getPatientProfile,
  getPatientAddress,
  getPatientEmergencyInfo,
  patientProfile,
  patientAddress,
  patientEmergency,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [openProfileNav, setOpenProfileNav] = useState(false);
  const { colorMode } = useColorMode();
  useEffect(() => {
    getPatientProfile();
    getPatientAddress();
    getPatientEmergencyInfo();
  }, [getPatientProfile, getPatientAddress, getPatientEmergencyInfo]);

  useEffect(() => {
    const { loading, error, data } = patientProfile;
    setError(error);
    setLoading(loading);
    if (data) {
      setUser(data);
    }
  }, [patientProfile]);

  const { path } = useRouteMatch();
  const renderView = () => {
    return (
      <>
        <div className="container-fluid position-relative">
          <div className="row justify-content-center">
            <div className="col-md-11 col-12 profile my-5">
              <div className="row m-0">
                <div className="col-12 mb-3 d-flex align-items-center">
                  <div className="d-xl-none d-lg-none d-xl-flex me-2">
                    <Button
                      color={checkColorMode(colorMode)}
                      onClick={() => {
                        setOpenProfileNav(!openProfileNav);
                      }}
                    >
                      <span>
                        <i className="fas fa-bars"></i>
                      </span>
                    </Button>
                  </div>

                  <Text
                    color={checkColorMode(colorMode)}
                    className="h3 fw-bold"
                  >
                    My Account
                  </Text>
                </div>

                <div className="col-12 row align-items-start m-0">
                  <Box
                    bg={checkBgColorMode(colorMode)}
                    className="d-none d-lg-block col-3 rounded shadow-sm p-2 d-flex justify-content-center flex-wrap"
                  >
                    <ProfileSideNav />
                  </Box>

                  <Box
                    w="260px"
                    zIndex={200}
                    top={0}
                    bottom={0}
                    left={openProfileNav ? "0" : "-260px"}
                    transition=".3s ease-in-out"
                    className={`position-fixed ${
                      openProfileNav ? "" : "rounded"
                    } shadow-sm p-2 d-flex justify-content-center flex-wrap`}
                    bg={checkBgColorMode(colorMode)}
                  >
                    <ProfileSideNav
                      fromSmallScreen={true}
                      openProfileNav={openProfileNav}
                      setOpenProfileNav={setOpenProfileNav}
                    />
                  </Box>

                  <div className="col-lg-9 col-12">
                    <Switch>
                      <Route
                        path={`${path}/share_data`}
                        component={shareData}
                      />
                      <Route
                        path={`${path}/my_info`}
                        render={() => (
                          <MyInfo
                            loading={loading}
                            error={error}
                            patientProfile={user}
                            patientAddress={patientAddress}
                            patientEmergency={patientEmergency}
                          />
                        )}
                      />
                      <Route path={`${path}/payment`} component={Payment} />
                      <Route
                        path={`${path}/change_password`}
                        component={ChangePassword}
                      />
                      <Route
                        path={`${path}/add_emargency`}
                        component={EditEmargency}
                      />
                      <Route
                        path={`${path}/edit_emargency`}
                        component={EditEmargency}
                      />
                      <Route
                        path={`${path}/add_address`}
                        component={EditMyAddress}
                      />
                      <Route
                        path={`${path}/edit_address`}
                        component={EditMyAddress}
                      />
                      <Route
                        path={`${path}/edit_info`}
                        component={EditMyInfo}
                      />
                      <Route
                        path={`${path}/add_payment`}
                        component={PaymentConfig}
                      />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Box
            className="position-absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg={"rgba(0,0,0,.2)"}
            display={openProfileNav ? "block" : "none"}
            onClick={() => {
              setOpenProfileNav(!openProfileNav);
            }}
          ></Box>
        </div>
      </>
    );
  };
  return renderView();
};
const mapStateToProps = (state) => {
  return {
    patientProfile: state.PatientProfile,
    patientAddress: state.patientAddress.data,
    patientEmergency: state.patientEmergency.data,
  };
};
export default connect(mapStateToProps, {
  getPatientProfile,
  getPatientAddress,
  getPatientEmergencyInfo,
})(Profile);
