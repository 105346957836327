import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import Mri from "./../../../assets/MRI.png";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { updateRadiology, getOneRadiology } from "../../../store/actions";

import { Formik, Form, ErrorMessage } from "formik";

import {
  Button,
  Text,
  Textarea,
  useToast,
  useColorMode,
  Box,
} from "@chakra-ui/react";
import { InputControl } from "formik-chakra-ui";

import Uploader from "./uploader";
import {
  checkBgColorMode,
  checkColorMode,
  checkColorModeHover,
} from "../../../global/changeColor";
const SignInSchema = Yup.object().shape({
  title: Yup.string().min(3).required("Required"),
});

const EditRadiology = ({
  radiology,
  updateRadiology,
  getOneRadiology,
  history,
  updatedRadiology,
}) => {
  ///////
  const toast = useToast();
  const { colorMode } = useColorMode();
  /////
  let params = useParams();
  const [procedureDate, setProcedureDate] = useState(null);
  const [initValue, setInitValue] = useState({
    note: "",
    title: "",
    procedure_date: null,
  });
  useEffect(() => {
    if (params.Eid) {
      getOneRadiology(params.Eid);
    }
  }, [params, getOneRadiology]);

  useEffect(() => {
    if (params.Eid && radiology) {
      setInitValue({
        title: radiology.title,
        note: radiology.note,
        procedure_date: radiology.procedure_date,
      });
    }
  }, [params, radiology]);

  useEffect(() => {
    if (procedureDate) {
      setInitValue((initValue) => {
        return {
          ...initValue,
          procedure_date: procedureDate,
        };
      });
    }
  }, [procedureDate]);

  const onFormSubmit = (value) => {
    if (params.Eid) {
      return updateRadiology(value, params.Eid, toast, history);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 d-flex py-4 align-items-center">
            {history ? (
              <Button
                onClick={() => history.push("/medical-record/mri-ct scan")}
                className="h5 me-4 bg-light-gray rounded-circle d-flex align-items-center justify-content-center"
                style={{ width: "45px", height: "45px" }}
                color={checkColorMode(colorMode)}
              >
                <i className="fas fa-chevron-left"></i>
              </Button>
            ) : null}

            <Text color={checkColorMode(colorMode)} className="h4 fw-bold">
              Edit MRI / CT Scan
            </Text>
          </div>

          <div className="">
            <div className="form row justify-content-center">
              <Formik
                initialValues={initValue}
                enableReinitialize
                validationSchema={SignInSchema}
                onSubmit={onFormSubmit}
              >
                {({ values, handleBlur, handleChange }) => (
                  <Form>
                    <Box
                      bg={checkBgColorMode(colorMode)}
                      className="col-12 p-5  rounded shadow  justify-content-center"
                    >
                      <div className="row justify-content-center">
                        <div className="w-100 d-flex justify-content-center">
                          <img src={Mri} alt="logo" />
                        </div>

                        <div className="w-100 text-center py-5">
                          <Text
                            color={checkColorMode(colorMode)}
                            className="h3 fw-bold"
                          >
                            {" "}
                            Edit MRI / CT Scan
                          </Text>
                          <div className="fw-200 light-gray">
                            Edit your MRI / CT Scan
                          </div>
                        </div>
                      </div>

                      <div className="w-100 d-flex justify-content-center">
                        <div className="w-75">
                          <div className=" mt-2 ">
                            <InputControl
                              type={"text"}
                              name={"title"}
                              className="rounded"
                              inputProps={{
                                placeholder: "Add your MRI / CT Scan",
                              }}
                            />
                          </div>
                          <div className="">
                            <Textarea
                              className="mt-3 rounded"
                              value={values.note}
                              onChange={(e) => handleChange(e)}
                              onBlur={(e) => handleBlur(e)}
                              name={"note"}
                              placeholder={"Note"}
                              size="sm"
                            />
                            <ErrorMessage
                              component="div"
                              name={"note"}
                              style={{ color: "#d42c51", fontSize: "14px" }}
                              className="mt-1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-100 row justify-content-center mt-5 flex-wrap">
                        <Text
                          className="col-md-10 col-12 fw-bold my-2"
                          color="smallTextGray"
                          fontSize="sm"
                        >
                          Add More Files
                        </Text>
                        <Uploader
                          id={params.Eid ? params.Eid : null}
                          procedureDate={true}
                          setProcedureDate={setProcedureDate}
                        />
                      </div>
                    </Box>

                    <div className="col-12 m-0 mt-3  row justify-content-between">
                      <div className="col-md-3">
                        <Button
                          onClick={() => history.goBack()}
                          colorScheme="red"
                          variant="outline"
                          w="100%"
                          size="lg"
                          fontWeight="semibold"
                          h="56px"
                          _hover={{ color: "red" }}
                        >
                          Cancel
                        </Button>
                      </div>

                      <div className="col-md-3">
                        <Button
                          type="submit"
                          className="rounded"
                          fontWeight="semibold"
                          size="lg"
                          isLoading={updatedRadiology.loading}
                          bg={checkColorMode(colorMode)}
                          color="#fff"
                          _hover={{ bg: checkColorModeHover(colorMode) }}
                          w="100%"
                          h="56px"
                        >
                          Save{" "}
                          <span className="ms-4">
                            <i className="fas fa-save"></i>
                          </span>
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToprops = (state) => {
  return {
    radiology: state.OneRadiology.data,
    updatedRadiology: state.updateRadiology,
  };
};
export default connect(mapStateToprops, {
  updateRadiology,
  getOneRadiology,
})(EditRadiology);
