import { Box, Text, Button, useColorMode } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { checkBgColorMode, checkColorMode } from "../../global/changeColor";

const SideNave = ({
  url,
  scanRequests,
  medicalReport,
  QuestionsNoReply,
  appointment,
  hospitalReservation,
  consultation,
  withOpen,
  onOpen,
  closeSideNave,
}) => {
  // const [isOpen, setIsOpen] = useState(true);
  const { colorMode } = useColorMode();
  return (
    <Box
      bg={checkBgColorMode(colorMode)}
      boxShadow="xs"
      className=" w-100  p-3 consultation-sideNav"
    >
      {withOpen ? (
        <Box className="text-end" color={checkColorMode(colorMode)}>
          <Button
            onClick={() => {
              closeSideNave();
            }}
          >
            <i className="fas fa-times"></i>
          </Button>
        </Box>
      ) : (
        ""
      )}
      <ul style={{ listStyle: "none" }}>
        <li className="list-item mb-2">
          <NavLink
            exact
            onClick={() => {
              if (closeSideNave) closeSideNave();
            }}
            to={`/consultations`}
            className="nav-link rounded w-100 d-flex justify-content-between  "
          >
            <Box className="d-flex align-items-center" fontWeight="semibold">
              <Text
                className="me-2"
                fontSize={"lg"}
                color={checkColorMode(colorMode)}
                fontWeight="extrabold"
              >
                <i className="fas fa-chevron-left"></i>
              </Text>
              <Text fontSize="sm" color={checkColorMode(colorMode)}>
                Consultations
              </Text>
            </Box>
          </NavLink>
        </li>

        <li className="list-item my-2">
          <NavLink
            exact
            onClick={() => {
              if (closeSideNave) closeSideNave();
            }}
            activeClassName={
              colorMode === "dark"
                ? "active-consultation-link-dark"
                : "active-consultation-link"
            }
            to={`${url}?consultationId=${consultation?.data?.id}`}
            className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
              colorMode === "dark" ? "consultation_nav-dark" : ""
            }`}
          >
            <Box
              className="d-flex align-items-center"
              fontSize={"sm"}
              fontWeight="bold"
            >
              <Text className="me-2">
                <i className="fas fa-eye"></i>
              </Text>
              <Text className="me-1">OverView</Text>
            </Box>
          </NavLink>
        </li>

        {scanRequests?.length ? (
          <li className="list-item mb-2">
            <NavLink
              exact
              onClick={() => {
                if (closeSideNave) closeSideNave();
              }}
              activeClassName={
                colorMode === "dark"
                  ? "active-consultation-link-dark"
                  : "active-consultation-link"
              }
              to={`${url}/requestes?consultationId=${consultation?.data?.id}`}
              className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
                colorMode === "dark" ? "consultation_nav-dark" : ""
              }`}
            >
              <Box
                className="d-flex align-items-center"
                fontSize={"sm"}
                fontWeight="bold"
              >
                <Text className="me-2">
                  <i className="fas fa-notes-medical"></i>
                </Text>
                <Text className="me-1">Requests</Text>
              </Box>
            </NavLink>
          </li>
        ) : null}

        {medicalReport ? (
          <li className="list-item mb-2">
            <NavLink
              exact
              onClick={() => {
                if (closeSideNave) closeSideNave();
              }}
              activeClassName={
                colorMode === "dark"
                  ? "active-consultation-link-dark"
                  : "active-consultation-link"
              }
              to={`${url}/medical-report?consultationId=${consultation?.data?.id}`}
              className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
                colorMode === "dark" ? "consultation_nav-dark" : ""
              }`}
            >
              <Box
                className="d-flex align-items-center"
                fontSize={"sm"}
                fontWeight="bold"
              >
                <Text className="me-2">
                  <i className="fas fa-notes-medical"></i>
                </Text>
                <Text className="me-1">Medical Report</Text>
              </Box>
            </NavLink>
          </li>
        ) : null}

        {QuestionsNoReply?.length ? (
          <li className="list-item mb-2">
            <NavLink
              activeClassName={
                colorMode === "dark"
                  ? "active-consultation-link-dark"
                  : "active-consultation-link"
              }
              onClick={() => {
                if (closeSideNave) closeSideNave();
              }}
              to={`${url}/questions?consultationId=${consultation?.data?.id}`}
              className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
                colorMode === "dark" ? "consultation_nav-dark" : ""
              }`}
            >
              <Box
                className="d-flex align-items-center"
                fontSize={"sm"}
                fontWeight="bold"
              >
                <Text className="me-2">
                  <i className="far fa-question-circle"></i>
                </Text>
                <Text className="me-1">Questions</Text>
              </Box>
            </NavLink>
          </li>
        ) : null}

        {appointment ? (
          <li className="list-item mb-2">
            <NavLink
              exact
              onClick={() => {
                if (closeSideNave) closeSideNave();
              }}
              activeClassName={
                colorMode === "dark"
                  ? "active-consultation-link-dark"
                  : "active-consultation-link"
              }
              to={`${url}/appointments?consultationId=${consultation?.data?.id}`}
              className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
                colorMode === "dark" ? "consultation_nav-dark" : ""
              }`}
            >
              <Box
                className="d-flex align-items-center"
                fontSize={"sm"}
                fontWeight="bold"
              >
                <Text className="me-2">
                  <i className="far fa-calendar-alt"></i>
                </Text>
                <Text className="me-1">Appointment</Text>
              </Box>
            </NavLink>
          </li>
        ) : null}

        {hospitalReservation ? (
          <li className="list-item mb-2">
            <NavLink
              activeClassName={
                colorMode === "dark"
                  ? "active-consultation-link-dark"
                  : "active-consultation-link"
              }
              onClick={() => {
                if (closeSideNave) closeSideNave();
              }}
              to={`${url}/hospital-ofers?consultationId=${consultation?.data?.id}`}
              className={`nav-link rounded w-100 d-flex justify-content-between consultation_nav ${
                colorMode === "dark" ? "consultation_nav-dark" : ""
              }`}
            >
              <Box
                className="d-flex align-items-center"
                fontSize={"sm"}
                fontWeight="bold"
              >
                <Text className="me-2">
                  <i className="far fa-hospital"></i>
                </Text>
                <Text className="me-1">Hospital offers</Text>
              </Box>
            </NavLink>
          </li>
        ) : null}
      </ul>
    </Box>
  );
};

export default SideNave;
