//{item.type.replace(/-/g, " ")}
import React from "react";
import bloodPressure from "../../assets/bloodPressure.png";
import bloodSugar from "../../assets/bloodSugar.png";
import height from "../../assets/height.png";
import temperature from "../../assets/temperature.png";
import weight from "../../assets/weight.png";
import { Divider, Text, Box, useColorMode, useToast } from "@chakra-ui/react";
import ModalComponenet from "./Modal";
import { useLocation } from "react-router-dom";
import {
  delete_Patient_Rreading,
  getPatientRreadingList,
} from "../../store/actions";
import { connect } from "react-redux";
import AlertComponent from "../../global/Alert.Component";
import moment from "moment";
import { checkBgColorMode, checkColorMode } from "../../global/changeColor";
export const checkReaddingType = (text) => {
  if (text === "height") {
    return {
      text: "CM",
      img: height,
    };
  }
  if (text === "temperature") {
    return {
      text: "C",
      img: temperature,
    };
  }
  if (text === "weight") {
    return {
      text: "KG",
      img: weight,
    };
  }
  if (text === "blood-pressure") {
    return {
      text: "mmHg",
      img: bloodPressure,
    };
  }
  if (text === "heart-rate") {
    return {
      text: "BPM",
      img: null,
      icon: "fas fa-heartbeat",
    };
  }
  if (text === "blood-sugar") {
    return {
      text: "mg/dL",
      img: bloodSugar,
    };
  }
};

const Readingcard = ({
  name,
  image,
  icon,
  item,
  feildName,
  delete_Patient_Rreading,
  removedReading,
  getPatientRreadingList,
}) => {
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  const toast = useToast();
  const { colorMode } = useColorMode();
  const sharedToken = new URLSearchParams(location.search).get("sharedToken");
  const deleteReading = () => {
    if (item) delete_Patient_Rreading(item.id, toast, getPatientRreadingList);
  };
  return sharedToken ? (
    <>
      <div className="col-lg-4 col-md-6 d-flex align-items-center my-2">
        <Box
          className=" rounded-circle d-flex justify-content-center align-items-center text-white"
          style={{
            minWidth: "80px",
            minHeight: "80px",
          }}
          bg={checkColorMode(colorMode)}
        >
          {image ? (
            <img src={image} alt={name} />
          ) : (
            <span style={{ fontSize: "40px" }}>
              <i className={icon}></i>
            </span>
          )}
        </Box>
        <div className="ms-4">
          <Text
            fontSize="19px"
            className="capitalize"
            fontWeight="bold"
            color={checkColorMode(colorMode)}
          >
            {name}
          </Text>
          {item ? (
            <>
              <p style={{ fontSize: "13px" }}>
                {item.value} {checkReaddingType(item.type).text}
              </p>
              <p style={{ fontSize: "13px" }}>
                {moment.unix(item.updatedAt).format("DD/MM/YYYY - hh:mm A")}
              </p>
            </>
          ) : (
            <p style={{ fontSize: "13px" }}>N/A</p>
          )}
        </div>
      </div>
    </>
  ) : (
    <>
      <Box className="col-lg-4 col-md-6 col-12 ">
        <Box
          className="rounded shadow p-4  row mx-0 my-3"
          bg={checkBgColorMode(colorMode)}
        >
          <div className="col-12 d-flex align-items-center p-0">
            <Box
              className=" rounded-circle d-flex justify-content-center align-items-center text-white"
              style={{
                minWidth: "80px",
                minHeight: "80px",
              }}
              bg={checkColorMode(colorMode)}
            >
              {image ? (
                <img src={image} alt={name} />
              ) : (
                <span style={{ fontSize: "40px" }}>
                  <i className={icon}></i>
                </span>
              )}
            </Box>
            <div className="ms-4">
              <Text
                fontSize="19px"
                className="capitalize"
                fontWeight="bold"
                color={checkColorMode(colorMode)}
              >
                {name}
              </Text>
            </div>
          </div>
          <div className="col-12 my-3 p-0">
            <Divider color="gray.200" />
          </div>

          {item ? (
            <div className="col-12 d-flex flex-wrap justify-content-between align-items-center p-0">
              <div className="col-8">
                <div className="">
                  <Text
                    fontSize="2xl"
                    className="fw-bold "
                    color={checkColorMode(colorMode)}
                  >
                    {item.value}
                    <span className="ms-2" style={{ fontSize: "13px" }}>
                      {checkReaddingType(item.type).text}
                    </span>
                  </Text>
                </div>
                <div className="fw-600" style={{ fontSize: "12px" }}>
                  {moment.unix(item.updatedAt).format("DD/MM/YYYY - hh:mm A")}
                </div>
              </div>
              <div className="col-4 d-flex  justify-content-end align-items-center">
                <div className="m-1">
                  <ModalComponenet
                    img={image}
                    name={name}
                    icon={icon}
                    feildName={feildName}
                    itemId={item.id ? item.id : null}
                  />
                </div>
                <div className="m-1">
                  <AlertComponent
                    removeOne={deleteReading}
                    nameForAlert={"Reading"}
                    reading={true}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <ModalComponenet
                noReading={true}
                img={image}
                name={name}
                icon={icon}
                feildName={feildName}
                itemId={item && item.id ? item.id : null}
              />
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    removedReading: state.removeReading,
  };
};
export default connect(mapStateToProps, {
  delete_Patient_Rreading,
  getPatientRreadingList,
})(Readingcard);
