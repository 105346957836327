import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound/NotFound";
import Addconsaltation from "./AddConsaltation";
import AppointmentThankYou from "./AppointmentThankYou";
import Consultationsoverview from "./over-view/consultationsOverView";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import DraftConsultation from "./DraftConsultation";

const PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY;
const stripeTestPromise = loadStripe(PUBLIC_KEY);

const Child = ({ props }) => {
  const history = useHistory();
  let { child } = useParams();
  // const options = props ? {
  //   clientSecret: props.client_secret,
  // } : {};
  const renderComponents = () => {
    switch (child) {
      case "add-consultation":
        return (
          <Elements stripe={stripeTestPromise}>
            <Addconsaltation history={history} />
          </Elements>
        );
      case "over-view":
        return <Consultationsoverview history={history} />;
      case "thank-you":
        return <AppointmentThankYou history={history} />;
      case "draft-consultation":
        return <DraftConsultation history={history} />;
      default:
        return <NotFound history={history} />;
    }
  };
  return <div>{renderComponents()}</div>;
};

export default Child;
