import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";

import MidicalChild from "./medicalChild";
import ViewMedical from "./view-medical";
import AddMedical from "./add-medical";
// import { AnimatePresence } from "framer-motion";

const MidicalReport = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={MidicalChild} />
        <Route exact path={`${path}/add-midical`} component={AddMedical} />
        <Route path={`${path}/add-medical/:Eid`} component={AddMedical} />
        <Route path={`${path}/view-midical/:id`} component={ViewMedical} />
      </Switch>
    </>
  );
};
export default MidicalReport;
