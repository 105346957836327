import React from "react";
import { Box, Text, Circle, useColorMode } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import { helperImageAndLinks } from "./LinksAndImages";
import moment from "moment";
import {
  checkColorMode,
  checkBgColorMode,
} from "../../../../global/changeColor";
const Requestes = ({ requestes }) => {
  const history = useHistory();
  const { colorMode } = useColorMode();
  const renderData = () => {
    if (requestes.length) {
      return (
        <div className="requests row">
          <Box className="my-3 col-12">
            <Text
              fontSize="2xl"
              fontWeight="semibold"
              color={checkColorMode(colorMode)}
            >
              Requestes
            </Text>
          </Box>
          {requestes.map((request, index) => (
            <Box
              as={Link}
              to={helperImageAndLinks(request).link}
              key={index}
              className="col-12 shadow-sm rounded p-3 row m-0 align-items-center my-2"
              bg={checkBgColorMode(colorMode)}
              _hover={{ color: colorMode === "dark" ? "white" : "black" }}
            >
              <Box className="col-md-4 d-flex  align-items-center">
                <Circle
                  size="35px"
                  bg={checkColorMode(colorMode)}
                  className="me-2"
                >
                  {helperImageAndLinks(request).img ? (
                    <img
                      className=" w-50 h-50"
                      src={helperImageAndLinks(request).img}
                      alt="c"
                    />
                  ) : (
                    <span style={{ color: "#fff" }}>
                      <i className={helperImageAndLinks(request).className}></i>
                    </span>
                  )}
                </Circle>
                <Box className=" p-1">
                  <Text
                    color={"smallTextGray"}
                    fontSize="14px"
                    fontWeight="600"
                    textTransform="capitalize"
                  >
                    {request.title}
                  </Text>
                  <Text fontSize="13px" fontWeight="700">
                    {request.body}
                  </Text>
                </Box>
              </Box>
              <Box className="col-md-4 row m-0">
                <Box className="">
                  <Text
                    color={"smallTextGray"}
                    fontSize="14px"
                    fontWeight="600"
                    textTransform="capitalize"
                  >
                    Time
                  </Text>
                  <Text fontSize="13px" fontWeight="700">
                    {moment
                      .unix(request.created_at)
                      .format("DD/MM/YYYY hh:mm A")}
                  </Text>
                </Box>
              </Box>
            </Box>
          ))}
        </div>
      );
    } else {
      return history.goBack();
    }
  };

  return <div>{renderData()}</div>;
};

export default Requestes;
