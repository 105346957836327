import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";

import QuestionsChild from "./QuestionsChild";
import ReplayQ from "./ReplayQ";
import ViewQ from "./ViewQ";

const Questions = ({ questions, history }) => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <QuestionsChild questions={questions} history={history} />
          )}
        />
        <Route path={`${path}/view-question/:id`} component={ViewQ} />
        <Route path={`${path}/replay-question/:qid`} component={ReplayQ} />
      </Switch>
    </>
  );
};

export default Questions;
