import axios from "axios";

import { types } from "../types/types";
import { handeleError, handeleSuccess } from "./toast";
const { profileTypes } = types;
/////////////////////////////////////////////////////
const API_URL = process.env.REACT_APP_API_URL;
//////////Done
export const getPatientProfile = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_PROFILE_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/profile`,
      });
      dispatch({
        type: "GET_PROFILE_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_PROFILE_ERORR",
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
/// Done
export const getPatientProfileShare = (patientId, informationShareToken) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_PROFILE_SHARE_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/${patientId}?informationShareToken=${informationShareToken}`,
      });
      console.log(res);
      dispatch({
        type: "GET_PROFILE_SHARE_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_PROFILE_SHARE_ERORR",
        payload: error.response ? error.response.data : error,
      });
    }
  }; ////////////////
};

export const updatePatientProfile = (
  data,
  history,
  toast,
  goTO,
  mobileChange,
  secondView,
  setSecondView
) => {
  console.log(data);
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_PROFILE_LOADING",
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/patient/profile`,
        data: data,
      });
      if (res) {
        handeleSuccess("Your profile has been updated successfully", toast);
        if (mobileChange) {
          localStorage.clear();
          localStorage.setItem("registrUser", JSON.stringify(res.data));
          localStorage.setItem("registrToken", res.data.token);
          localStorage.setItem("userMobile", res.data.mobile);
          if (secondView) setSecondView(!secondView);
        }
        history.push(goTO);
        dispatch({
          type: "UPDATE_PROFILE_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_PROFILE_ERORR",
        payload: error.response,
      });
    }
  };
};
// /////
export const deletePatientProfile = (history, toast, goTO) => {
  return async (dispatch) => {
    dispatch({
      type: "DELETE_PROFILE_LOADING",
    });
    try {
      const res = await axios({
        method: "DELETE",
        url: `${API_URL}/patient/profile`,
      });
      if (res) {
        handeleSuccess("Your profile has been deleted successfully", toast);
        localStorage.clear();
        history.push("/login");
        dispatch({
          type: "DELETE_PROFILE_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "DELETE_PROFILE_ERORR",
        payload: error.response,
      });
    }
  };
};
///////////////////
export const postPatientProfilePicture = (data, toast) => {
  let formData = new FormData();
  if (data) {
    data.map((file) => formData.append("picture", file, file.name));
  }
  return async (dispatch) => {
    dispatch({
      type: "PICTURE_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/profile-picture`,
        data: formData,
      });
      handeleSuccess(
        "Your Profile Picture has been updated successfully",
        toast
      );
      dispatch({
        type: "PICTURE_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "PICTURE_ERORR",
        payload: error.response,
      });
    }
  };
};

//////////////////Done
export const PatientShareInformation = (toast, setSwitchText, setIsChecked) => {
  return async (dispatch) => {
    dispatch({
      type: "SHARE_INFORMATION_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/generate-information-share-token`,
      });
      if (res) {
        handeleSuccess("Your data has been shared successfully", toast);
        setSwitchText("Enabled");
        setIsChecked(true);
        dispatch({
          type: "SHARE_INFORMATION_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "SHARE_INFORMATION_ERORR",
        payload: error,
      });
    }
  };
};
export const PatientStopShareInformation = (
  toast,
  setSwitchText,
  setIsChecked
) => {
  return async (dispatch) => {
    dispatch({
      type: "STOP_SHARE_INFORMATION_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/stop-information-share-token`,
      });
      if (res) {
        handeleSuccess("Your data has now been stopped", toast);
        setSwitchText("Disabled");
        setIsChecked(false);
      }
      dispatch({
        type: "STOP_SHARE_INFORMATION_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "STOP_SHARE_INFORMATION_ERORR",
        payload: error.response,
      });
    }
  };
};
/////////////////Done
export const postPatientVerifyMobile = (data, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: "VERIFY_MOBILE_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/verify-mobile`,
        data: data,
      });
      handeleSuccess("Successful Code", toast);
      // history.push("/verify_email");
      localStorage.clear();
      history.push("/login");
      dispatch({
        type: "VERIFY_MOBILE_SUCSES",
        payload: res.data,
      });
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "VERIFY_MOBILE_ERORR",
        payload: err.response,
      });
    }
  };
};
export const resendPatientVerifyMobile = (toast) => {
  return async (dispatch) => {
    dispatch({
      type: "RESEND_VERIFY_MOBILE_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/resend-mobile-verification-code`,
      });
      if (res) {
        handeleSuccess("Code was Sent successfully", toast);
        dispatch({
          type: "RESEND_VERIFY_MOBILE_SUCSES",
          payload: res.data,
        });
      }
    } catch (err) {
      handeleError(err, toast);
      dispatch({
        type: "RESEND_VERIFY_MOBILE_ERORR",
        payload: err.response,
      });
    }
  };
};

/////////Done
export const getPatientAddress = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_ADDRESS_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/addresses/mine`,
      });
      dispatch({
        type: "GET_ADDRESS_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_ADDRESS_ERORR",
        payload: error.response,
      });
    }
  };
};
export const postPatientAddress = (data, toast, history, redirectTo) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_ADDRESS_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/addresses`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your address added successfully", toast);
        if (redirectTo) {
          history.push("/consultations/add-consultation");
        } else {
          history.push("/profile/my_info");
        }
        dispatch({
          type: "POST_ADDRESS_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "POST_ADDRESS_ERORR",
        payload: error.response,
      });
    }
  };
};
export const updatePatientAddress = (data, id, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_ADDRESS_LOADING",
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/patient/addresses/${id}`,

        data: data,
      });
      getPatientAddress();
      handeleSuccess("Your Address updated successfully", toast);
      history.push("/profile/my_info");
      dispatch({
        type: "UPDATE_ADDRESS_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_ADDRESS_ERORR",
        payload: error.response,
      });
    }
  };
};

///////////////Done
export const getPatientEmergencyInfo = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_EMERGENCY_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/emergency-contacts/mine`,
      });
      dispatch({
        type: "GET_EMERGENCY_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_EMERGENCY_ERORR",
        payload: error.response,
      });
    }
  };
};

export const postPatientEmergencyInfo = (data, toast, history, redirectTo) => {
  return async (dispatch) => {
    dispatch({
      type: "POST_EMERGENCY_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/emergency-contacts`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your Emergency Info added successfully", toast);
        if (redirectTo) {
          history.push("/consultations/add-consultation");
        } else {
          history.push("/profile/my_info");
        }
        dispatch({
          type: "POST_EMERGENCY_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "POST_EMERGENCY_ERORR",
        payload: error.response,
      });
    }
  };
};

export const updatePatientEmergencyInfo = (data, id, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: "UPDATE_EMERGENCY_LOADING",
    });
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API_URL}/patient/emergency-contacts/${id}`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your Emergency Info updated successfully", toast);
        history.push("/profile/my_info");
        dispatch({
          type: "UPDATE_EMERGENCY_SUCSES",
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: "UPDATE_EMERGENCY_ERORR",
        payload: error.response,
      });
    }
  };
};
///////////////////
export const changePassword = (data, toast, history) => {
  return async (dispatch) => {
    dispatch({
      type: profileTypes.CHANGE_PASSWORD_LOADING,
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/change-password`,

        data: data,
      });
      if (res) {
        handeleSuccess("Your Password Updated successfully", toast);
        history.push("/profile/my_info");
        dispatch({
          type: profileTypes.CHANGE_PASSWORD_SUCSES,
          payload: res.data,
        });
      }
    } catch (error) {
      handeleError(error, toast);
      dispatch({
        type: profileTypes.CHANGE_PASSWORD_ERORR,
        payload: error.response,
      });
    }
  };
};

export const getHospitalInvites = () => {
  return async (dispatch) => {
    dispatch({
      type: "GET_HOSPITAL_INVITES_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/invites/hospital`,
      });
      console.log(res);
      dispatch({
        type: "GET_HOSPITAL_INVITES_SUCSES",
        payload: res.data?.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_HOSPITAL_INVITES_ERORR",
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
export const getHospitalInviteRequest = (requestId) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_HOSPITAL_INVITE_REQUEST_LOADING",
    });
    try {
      const res = await axios({
        method: "GET",
        url: `${API_URL}/patient/invites/hospital/${requestId}`,
      });
      console.log(res);

      dispatch({
        type: "GET_HOSPITAL_INVITE_REQUEST_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "GET_HOSPITAL_INVITE_REQUEST_ERORR",
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
export const markHospitalInviteRequestAsDone = (requestId) => {
  return async (dispatch) => {
    dispatch({
      type: "MARK_HOSPITAL_INVITE_REQUEST_AS_DONE_LOADING",
    });
    try {
      const res = await axios({
        method: "POST",
        url: `${API_URL}/patient/invites/hospital/${requestId}/done`,
      });
      dispatch({
        type: "MARK_HOSPITAL_INVITE_REQUEST_AS_DONE_SUCSES",
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: "MARK_HOSPITAL_INVITE_REQUEST_AS_DONE_ERORR",
        payload: error.response ? error.response.data : error,
      });
    }
  };
};
